// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './IconButton.module.scss';
import {Props} from './IconButton.types';

export function IconButton({
  iconType = 'fill',
  iconSize,
  hotspotSize = iconSize,
  Icon,
  ...props
}: Props) {
  return (
    <button
      {...props}
      className={classNames(styles.container, props.className)}
      style={{...props.style, width: hotspotSize, height: hotspotSize}}
    >
      <Icon
        className={classNames(styles.icon, styles[iconType])}
        style={{width: iconSize, height: iconSize}}
      />
    </button>
  );
}
