// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CreditIcon} from 'assets/svg/3.0/CreditIcon.svg';

import {Card} from '../Card';
import {getLevelByProductId, getTypeByProductId} from '../Plan.container';
import {Props} from './SubscriptionCard.types';

export function SubscriptionCard({
  credit,
  creditPack,
  creditAmount,
  activeProductId,
  subscriptionProductId,
  renewalDate,
}: Props) {
  const currentPlan = getLevelByProductId(activeProductId);
  const nextPlan = getLevelByProductId(subscriptionProductId);

  return (
    <Card
      rows={[
        {
          label: 'Your subscription',
        },
        {
          label: 'Current plan',
          value: `${currentPlan} plan`,
        },
        {
          label: 'Remaining credits',
          value: (
            <>
              <CreditIcon />
              {credit + creditPack} / {creditAmount}
            </>
          ),
        },
      ]}
      next={
        activeProductId !== subscriptionProductId
          ? `${nextPlan} Plan ${
              nextPlan === 'Free'
                ? ''
                : `(${getTypeByProductId(subscriptionProductId)})`
            } will be automatically updated on ${renewalDate}.`
          : undefined
      }
    />
  );
}
