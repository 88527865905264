// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as paymentApi from 'api/payment';
import {useUserContext} from 'contexts/UserContext';
import {retryIgnoreError} from 'lib/retry';
import {useCallback} from 'react';
import {createStripeCallbackUrl, StripeCallbackParams} from 'utils/stripe';

const VIDEO_DOWNLOAD_ITEM_PRODUCT_NAME = 'item_video_download';

async function checkVideoPayment(outTradeNo: string) {
  const {
    data: {isSuccessful},
  } = await paymentApi.checkOrder(outTradeNo);
  if (isSuccessful) return isSuccessful;
  else {
    throw Error('Payment failed');
  }
}

export function useRawVideoPayment() {
  const {
    userInfo: {userId},
  } = useUserContext();

  const checkPaymentStatus = useCallback(async (outTradeNo: string) => {
    try {
      const isSuccessful = await retryIgnoreError(
        {times: 5, interval: 1000},
        checkVideoPayment,
        outTradeNo
      );
      return isSuccessful;
    } catch {
      return false;
    }
  }, []);

  const buyVideo = useCallback(
    async (videoId: string, params: StripeCallbackParams) => {
      const {
        data: {priceId, outTradeNo},
      } = await paymentApi.initiatePrepaymentV2(
        userId,
        VIDEO_DOWNLOAD_ITEM_PRODUCT_NAME
      );
      const {successUrl, failUrl} = createStripeCallbackUrl(outTradeNo, params);
      const {data: session} = await paymentApi.checkoutItemSession(
        priceId,
        outTradeNo,
        videoId,
        successUrl,
        failUrl
      );
      return session;
    },
    [userId]
  );

  return {buyVideo, checkPaymentStatus};
}
