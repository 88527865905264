// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {FailureReason, TaskSchema, TaskStatus, TaskType} from '../types';
import {BaseTask} from './BaseTask';
import {Progress} from './Progress';

export type SimpleTaskType =
  | TaskType.Motion
  | TaskType.RemoveImageBG
  | TaskType.PoseSceneImage
  | TaskType.ImageConditioningVideo
  | TaskType.StyleTransfer;

export class SimpleTask<
  T extends SimpleTaskType = SimpleTaskType
> extends BaseTask {
  static fromJSON<T extends SimpleTaskType>(
    taskType: T,
    {
      id,
      status,
      type,
      create_time,
      progress,
      failure_reason,
      estimated_remaining_time,
      elapsed_total_time,
      asset,
    }: TaskSchema<T>
  ) {
    return new SimpleTask(
      taskType,
      id,
      status,
      type,
      create_time,
      progress && Progress.fromJSON(progress),
      failure_reason,
      estimated_remaining_time,
      elapsed_total_time,
      asset
    );
  }

  constructor(
    readonly taskType: T,
    id: string,
    status: TaskStatus,
    type: string,
    createTime: string,
    progress?: Progress,
    failureReason?: FailureReason,
    estimatedRemainingTime?: number,
    elapsedTotalTime?: number,
    readonly asset?: string
  ) {
    super(
      id,
      status,
      type,
      createTime,
      progress,
      failureReason,
      estimatedRemainingTime,
      elapsedTotalTime
    );
  }
}
