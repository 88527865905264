// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
} from 'react';

interface UnityInstanceContext {
  canvasId: string;
  create: (
    ...[args, onProgress]: Parameters<Window['createUnityInstance']> extends [
      unknown,
      infer A,
      (infer B)?
    ]
      ? [A, B?]
      : never
  ) => ReturnType<Window['createUnityInstance']>;
  cancel: () => void;
}

type Arguments = Parameters<UnityInstanceContext['create']> extends [
  infer A,
  unknown?
]
  ? A
  : never;

const instanceContext = createContext({} as UnityInstanceContext);

export function useUnityInstanceManager() {
  return useContext(instanceContext);
}

export function UnityInstanceManager({
  canvasId,
  children,
}: PropsWithChildren<{canvasId: string}>) {
  const executorRef = useRef<{
    resolve: (
      value: ReturnType<UnityInstanceContext['create']> extends Promise<infer V>
        ? V
        : never
    ) => void;
    reject: (reason?: unknown) => void;
  } | null>(null);
  const currentArgumentsRef = useRef<Arguments | null>(null);
  const nextArgumentsRef = useRef<Arguments | null>(null);

  const start = useCallback(
    async (args: Arguments) => {
      currentArgumentsRef.current = args;
      try {
        const canvas = document.getElementById(
          canvasId
        ) as HTMLCanvasElement | null;
        if (!canvas) {
          throw new Error('Canvas not found');
        }
        const instance = await window.createUnityInstance(canvas, args);
        currentArgumentsRef.current = {
          ...currentArgumentsRef.current,
          dataUrl: '',
          frameworkUrl: '',
        };

        if (executorRef.current && !nextArgumentsRef.current) {
          executorRef.current.resolve(instance);
          executorRef.current = null;
        } else {
          await instance.Quit();
        }
      } catch (e) {
        if (executorRef.current && !nextArgumentsRef.current) {
          executorRef.current.reject(e);
          executorRef.current = null;
        }
      }
      currentArgumentsRef.current = null;

      if (nextArgumentsRef.current) {
        start(nextArgumentsRef.current);
        nextArgumentsRef.current = null;
      }
    },
    [canvasId]
  );

  const create = useCallback(
    (...[args, _]: Parameters<UnityInstanceContext['create']>) => {
      if (executorRef.current) {
        throw new Error('Cancel the previous instance creation first');
      }
      if (currentArgumentsRef.current) {
        if (
          Object.keys(args).some(
            key =>
              (typeof args[key as keyof typeof args] === 'string' ||
                typeof args[key as keyof typeof args] === 'number' ||
                typeof args[key as keyof typeof args] === 'boolean') &&
              args[key as keyof typeof args] !==
                currentArgumentsRef.current?.[key as keyof typeof args]
          )
        ) {
          nextArgumentsRef.current = args;
        }
      }
      return new Promise<
        ReturnType<UnityInstanceContext['create']> extends Promise<infer V>
          ? V
          : never
      >((resolve, reject) => {
        executorRef.current = {resolve, reject};
        if (!currentArgumentsRef.current) {
          start(args);
        }
      });
    },
    [start]
  );

  const cancel = useCallback(() => {
    if (nextArgumentsRef.current) {
      nextArgumentsRef.current = null;
    }
    if (executorRef.current) {
      executorRef.current.reject(new Error('Canceled'));
      executorRef.current = null;
    }
  }, []);

  return (
    <instanceContext.Provider value={{canvasId, create, cancel}}>
      {children}
    </instanceContext.Provider>
  );
}
