// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import classNames from 'classnames';
import {Button} from 'pages/components/Button';
import {GlassInputBox} from 'pages/components/GlassInputBox/GlassInputBox';
import {useRef} from 'react';

import {PasswordErrorMessage} from './AuthForm.module';
import styles from './AuthForm.module.scss';
import {AuthFormDoResetPasswordProps} from './AuthForm.types';

export function AuthFormDoResetPassword({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  validConfirmPassword,
  onConfirm,
  validPassword,
  passwordErrorType,
}: AuthFormDoResetPasswordProps) {
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);

  const focusConfirmPasswordInput = () => {
    const confirmPasswordInput = confirmPasswordInputRef.current;
    if (!confirmPasswordInput) return;
    confirmPasswordInput.focus();
  };
  return (
    <div className={styles.auth_form_container}>
      <GlassInputBox
        className={classNames(styles.password_input, styles.input, 'body-m')}
        placeholder="New password(at least 8 characters)"
        onKeyUp={e =>
          e.key === 'Enter' && validPassword() && focusConfirmPasswordInput()
        }
        value={password}
        onChange={e => setPassword(e.target.value)}
        onBlur={validPassword}
        type="password"
      />
      <GlassInputBox
        className={classNames(styles.password_input, styles.input, 'body-m')}
        placeholder="Confirm password"
        ref={confirmPasswordInputRef}
        onKeyUp={e => e.key === 'Enter' && validPassword() && onConfirm()}
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
        onBlur={() => validConfirmPassword()}
        type="password"
      />

      {passwordErrorType !== null && (
        <PasswordErrorMessage
          errorType={passwordErrorType}
          className={styles.error_message}
        />
      )}
      <div className={classNames(styles.doResetPasswordBox)}>
        <Button
          type="button"
          size="large"
          onClick={onConfirm}
          disabled={passwordErrorType !== null}
        >
          Change password
        </Button>
      </div>
    </div>
  );
}
