// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './BlankData.module.scss';
type BlanckDataProps = {
  quotation: string;
  quotationAuthor: string;
  footerMsg: string;
  footerAction: React.ReactNode;
  className?: string;
  showQuota?: boolean;
};
export const BlankData = function ({
  quotation,
  quotationAuthor,
  footerMsg,
  footerAction,
  className,
  showQuota = true,
}: BlanckDataProps) {
  return (
    <div className={classNames(styles.blanckDataWrap, className)}>
      {showQuota && (
        <div className={styles.blanckDataInfo}>
          {quotation && (
            <div className={styles.quotation}>
              <span className={styles.quotationMark}>“</span>
              <span className={styles.text}>{quotation}</span>
              <span className={styles.quotationMark}>”</span>
            </div>
          )}
          {quotationAuthor && (
            <span className={styles.quotationAuthor}>- {quotationAuthor}</span>
          )}
        </div>
      )}

      <div className={styles.blanckDataFooter}>
        <span className={styles.msg}>{footerMsg || ''}</span>
        {footerAction}
      </div>
    </div>
  );
};
