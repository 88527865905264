// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './Card.module.scss';
import {Props} from './Card.types';

export function Card({rows, next}: Props) {
  return (
    <ul className={styles.container}>
      {rows.map(({label, value}, index) => {
        return (
          <li
            className={classNames(styles.row, index === 0 && styles.title)}
            key={index}
          >
            <span className={styles.label}>{label}</span>
            {value && (
              <span className={classNames(styles.value, !label && styles.fill)}>
                {value}
              </span>
            )}
          </li>
        );
      })}
      {next && <li className={styles.next}>{next}</li>}
    </ul>
  );
}
