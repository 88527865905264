// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CloseIcon} from 'assets/svg/3.0/Close.svg';
import classNames from 'classnames';
import {ComponentType} from 'react';
import {createPortal} from 'react-dom';

import {MaskType, Popover} from '../Popover';
import styles from './Dialog.module.scss';
import {CloseButtonType, Props} from './Dialog.types';

function Dialog({
  children,
  onClose,
  style,
  closeButtonType = CloseButtonType.None,
}: Props) {
  return (
    <div
      className={styles.dialog}
      style={style}
      onClick={e => e.stopPropagation()}
    >
      {closeButtonType === CloseButtonType.Inside && (
        <div
          className={classNames(styles['close-button'], styles.inside)}
          onClick={onClose}
        >
          <CloseIcon className={styles.close} />
        </div>
      )}
      {children}
    </div>
  );
}

export function withDialog(
  dialogProps: Omit<Props, 'children' | 'onClose'> = {}
) {
  return function <P>(Component: ComponentType<P>) {
    type ComponentProps = JSX.IntrinsicAttributes & P;
    type WrappedComponentProps = {
      dialogShowing: boolean;
      onCloseDialog: () => void;
    } & P;

    return function WrappedComponent({
      dialogShowing: showing,
      onCloseDialog: onClose,
      ...props
    }: WrappedComponentProps) {
      if (!showing) {
        return null;
      }

      return createPortal(
        <Popover onClose={onClose} maskType={MaskType.Dark}>
          <Dialog onClose={onClose} {...dialogProps}>
            <Component {...(props as ComponentProps)} />
          </Dialog>
        </Popover>,
        document.getElementById('root') as HTMLElement
      );
    };
  };
}
