// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {withDialog} from 'pages/components/Dialog';

import {BilingualDialogueSceneInsertActionTips} from './BilingualDialogueSceneInsertActionTips';

export const BilingualDialogueSceneInsertActionTipsDialog = withDialog()(
  BilingualDialogueSceneInsertActionTips
);
