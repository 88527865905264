// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useFixedRef} from 'lib/hooks';
import {nanoid} from 'nanoid';
import {useCallback, useEffect, useState} from 'react';

import {Task} from '../models/Task';
import {MotionParams, StoryboardParams, TaskParams, TaskType} from '../types';
import {Callback, Polling} from './polling';

export type CreateTask<
  T extends TaskType,
  P extends StoryboardParams | MotionParams | null
> = <TT extends T, PP extends P>(
  type: TT,
  args: TaskParams<TT, PP>
) => Promise<string>;
export type FindTask<T extends TaskType> = <TT extends T>(
  type: TT,
  id: string
) => Promise<Task<TT>>;
export type PollingCallback<T extends TaskType> = <TT extends T>(
  ...params: Parameters<Callback<TT>>
) => void;

interface PollOptions {
  interval?: number;
  timeout?: number;
  immediately?: boolean;
}

export function useTaskManager<
  T extends TaskType = TaskType,
  P extends StoryboardParams | MotionParams | null =
    | StoryboardParams
    | MotionParams
    | null
>(
  createTask: CreateTask<T, P>,
  findTask: FindTask<T>,
  pollingCallback: PollingCallback<T>
) {
  const pool = useFixedRef<Polling<T>[]>(() => []);
  const [responses, setResponses] = useState<
    {id: string; params: Parameters<Callback<T>>}[]
  >([]);

  const poll = useCallback(
    <TT extends T>(
      type: TT,
      id: string,
      {interval, timeout, immediately}: PollOptions = {}
    ) => {
      if (pool.some(p => p.type === type && p.taskId === id)) return;

      const polling = new Polling(
        type,
        id,
        findTask,
        (...params: Parameters<Callback<TT>>) => {
          const [, , task] = params;
          setResponses(prev => [...prev, {id: nanoid(), params}]);
          return task.closed;
        },
        interval,
        timeout
      );
      pool.push(polling as unknown as Polling<T>);
      polling.start(immediately);
    },
    [findTask, pool]
  );

  const execute = useCallback(
    async <TT extends T, PP extends P>(
      type: TT,
      args: TaskParams<TT, PP>,
      options: Pick<PollOptions, 'interval' | 'timeout'> = {}
    ) => {
      const id = await createTask(type, args);
      poll(type, id, options);
      return id;
    },
    [createTask, poll]
  );

  const updateInterval = useCallback(
    (type: T, id: string, interval: number) => {
      const index = pool.findIndex(p => p.type === type && p.taskId === id);
      if (index < 0) return false;
      pool[index].setInterval(interval);
      return true;
    },
    [pool]
  );

  const clear = useCallback(
    (type: T, id: string) => {
      const index = pool.findIndex(p => p.type === type && p.taskId === id);
      if (index < 0) return false;
      pool[index].clear();
      pool.splice(index, 1);
      return true;
    },
    [pool]
  );

  const clearAll = useCallback(() => {
    for (const polling of [...pool]) {
      polling.clear();
    }
    pool.length = 0;
  }, [pool]);

  useEffect(() => {
    if (responses.length === 0) return;

    const resolved: string[] = [];
    for (const {
      id,
      params: [type, taskId, task],
    } of responses) {
      if (task.closed) {
        clear(type, taskId);
      }
      pollingCallback(type, taskId, task);
      resolved.push(id);
    }
    setResponses(prev => prev.filter(({id}) => !resolved.includes(id)));
  }, [clear, pollingCallback, responses]);

  useEffect(() => {
    return clearAll;
  }, [clearAll]);

  return {execute, poll, clear, clearAll, updateInterval};
}
