// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import {combine} from 'components/Combine';
import {cloneBlobUrl, getBlobMimeType, mimeToExtMap} from 'lib/image';
import {TaskType} from 'modules/ai-frontend/types';
import {fetchAsset, getNewUrlAfterFillBgColor} from 'modules/user-asset/utils';
import {useCallback, useEffect, useRef} from 'react';

import {BackgroundRemoval} from './BackgroundRemoval';
import {HookProps, HookReturn} from './BackgroundRemoval.types';

function useHook({
  currentImageInfo,
  imageSize,
  uploadAsset,
  executeTask,
  onProcessing,
  innerRef,
}: HookProps): HookReturn {
  //存removeBG的的结果，imageInfo[0]代表刚进入时的image以及remove之后的image,imageInfo[1]为真代表remove过了。
  const baseImageInfoRef = useRef<[string, boolean]>();

  const updateBaseImage = useCallback((value?: [string, boolean]) => {
    if (baseImageInfoRef.current) {
      URL.revokeObjectURL(baseImageInfoRef.current[0]);
    }
    baseImageInfoRef.current = value;
  }, []);

  useEffect(() => {
    if (
      currentImageInfo &&
      (!baseImageInfoRef.current || currentImageInfo[1])
    ) {
      //执行reset
      cloneBlobUrl(currentImageInfo[0]).then(({url}) => {
        updateBaseImage([url, false]);
      });
    }
  }, [currentImageInfo, updateBaseImage]);

  useEffect(() => {
    return () => updateBaseImage();
  }, [updateBaseImage]);

  const fillBG = useCallback(
    async (color: string) => {
      if (!baseImageInfoRef.current) return;
      const mimeType = await getBlobMimeType(baseImageInfoRef.current[0]);
      if (!mimeType || !['image/png', 'image/webp'].includes(mimeType)) return;
      const {image, ext}: {image: string; ext?: string} = !color
        ? {
            image: (await cloneBlobUrl(baseImageInfoRef.current[0])).url,
            ext: mimeToExtMap[mimeType || 'image/jpeg'],
          }
        : {
            image: await getNewUrlAfterFillBgColor({
              imageUrl: baseImageInfoRef.current[0],
              imageWidth: imageSize[0],
              imageHeight: imageSize[1],
              newFillColor: color,
            }),
            ext: 'jpg',
          };
      onProcessing('success', {
        currentImage: image,
        fileSuffix: ext,
      });
    },
    [imageSize, onProcessing]
  );

  const removeBG = useCallback(
    async (image: string, fillColor: string) => {
      try {
        if (baseImageInfoRef.current && baseImageInfoRef.current[1]) return;
        onProcessing('start');
        const assetId = await uploadAsset('remove_image_bg', image);
        const task = await executeTask(TaskType.RemoveImageBG, {
          image: assetId,
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const newAlphaImage = await fetchAsset(staticCombiner(task.asset!));
        updateBaseImage([newAlphaImage, true]);
        await fillBG(fillColor);
      } catch (e) {
        onProcessing('failed', e);
      }
    },
    [executeTask, fillBG, onProcessing, updateBaseImage, uploadAsset]
  );

  return {
    removeBG,
    fillBG,
    currentImageInfo,
    ref: innerRef,
  };
}

export const BackgroundRemovalContainer = combine(useHook, [
  'currentImageInfo',
  'imageSize',
  'uploadAsset',
  'executeTask',
  'onProcessing',
  'innerRef',
])(BackgroundRemoval);
