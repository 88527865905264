// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LockIcon} from 'assets/svg/3.0/Lock2.svg';
import classNames from 'classnames';

import styles from './StyleCard.module.scss';
import {Props} from './StyleCard.types';

export function StyleCard({
  label,
  preview,
  isSelected,
  background,
  onSelected,
  disabled,
}: Props) {
  return (
    <li
      className={classNames(styles.container, isSelected && styles.selected)}
      onClick={() => !disabled && !isSelected && onSelected()}
    >
      <div
        className={styles['preview-box']}
        style={{
          background: background ? `url(${background}) no-repeat` : undefined,
          backgroundPosition: 'center',
          backgroundSize: '20%',
        }}
      >
        {disabled && (
          <div className={styles['preview-mask']}>
            <div className={styles['preview-lock']}>
              <LockIcon />
            </div>
          </div>
        )}
        <img src={preview} className={styles.preview} />
      </div>
      {label && <p className={styles.label}>{label}</p>}
    </li>
  );
}
