// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import {PASSWORD_REG} from 'lib/auth';
import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {PasswordErrorType} from '../AuthForm/AuthForm.types';

export function useResentEmail(authType: string): [string, () => void] {
  const locationInfo = useLocation();
  const emailOfResetPassword = useMemo(() => {
    if (authType === 'check-email') {
      return locationInfo.state?.email;
    }
    return '';
  }, [authType, locationInfo.state?.email]);
  const resetEmail = useCallback(() => {
    if (!emailOfResetPassword) return;
    apiServer.sendEmail(emailOfResetPassword);
  }, [emailOfResetPassword]);
  return [emailOfResetPassword, resetEmail];
}
type usePasswordResetReturn = {
  password: string;
  confirmPassword: string;
  passwordErrorType: PasswordErrorType | null;
  validPassword: () => boolean;
  validConfirmPassword: (value?: string) => boolean;
  changePassword: (value: string) => void;
  changeConfirmPassword: (value: string) => void;
  handleSetPasswordErrorType: (errorType: PasswordErrorType | null) => void;
};
export function usePasswordConfirmPassword(): usePasswordResetReturn {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrorType, setPasswordErrorType] =
    useState<PasswordErrorType | null>(null);
  const validPassword = useCallback(() => {
    const isValid = PASSWORD_REG.test(password);
    if (isValid && passwordErrorType === 'PASSWORD_INVALID') {
      setPasswordErrorType(null);
    } else if (!isValid) {
      setPasswordErrorType('PASSWORD_INVALID');
    }
    return isValid;
  }, [password, passwordErrorType]);

  const validConfirmPassword = useCallback(
    (_confirmPassword?: string) => {
      if (passwordErrorType === 'PASSWORD_INVALID') {
        return true;
      }
      const isValid = password === (_confirmPassword || confirmPassword);
      if (isValid) {
        setPasswordErrorType(null);
      } else {
        setPasswordErrorType('COMFIRM_PASSCODE_NOT_MATCH');
      }
      return isValid;
    },
    [confirmPassword, password, passwordErrorType]
  );

  const changePassword = useCallback((value: string) => {
    setPasswordErrorType(null);
    setPassword(value);
    setConfirmPassword('');
  }, []);
  const changeConfirmPassword = useCallback((value: string) => {
    setConfirmPassword(value);
    setPasswordErrorType(null);
  }, []);

  const handleSetPasswordErrorType = useCallback(
    (errorType: PasswordErrorType | null) => {
      setPasswordErrorType(errorType);
    },
    []
  );
  return {
    password,
    confirmPassword,
    passwordErrorType,
    validPassword,
    validConfirmPassword,
    changePassword,
    changeConfirmPassword,
    handleSetPasswordErrorType,
  };
}

export function useQueryString() {
  // 使用React Router的useLocation Hook获取当前location对象
  const {search} = useLocation();

  // 使用URLSearchParams API解析查询字符串
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  // 定义一个函数来获取指定查询参数的值
  const getQueryParam = useCallback(
    (param: string): string | null => {
      return queryParams.get(param);
    },
    [queryParams]
  );

  return getQueryParam;
}
