// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useAPI} from 'contexts/APIContext';
import {useUserContext} from 'contexts/UserContext';
import {formatAspectRatio} from 'lib/ratio';
import {Preference} from 'modules/preference/models/Preference';
import {Project} from 'modules/project/models/Project';
import {useProject} from 'modules/project/services';
import {ProjectType} from 'modules/project/types';
import {thumbnailCombiner} from 'modules/project/utils';
import {SetStateAction, useCallback, useMemo} from 'react';

import {ProjectPage} from './ProjectPage';
import {HookParams, HookReturn} from './ProjectPage.types';

function useHook({project: initialProject}: HookParams): HookReturn {
  const {backendClient} = useAPI();

  const syncProject = useCallback(
    async (project: Project<ProjectType>) => {
      const ratio = formatAspectRatio(project.size);
      try {
        if (!project.id) return;
        await backendClient.updateProject({
          projectId: project.id,
          thumbnailUrl: project.thumbnail
            ? thumbnailCombiner(project.thumbnail)
            : undefined,
          frameRatio: ratio,
          projectJsonContent: JSON.stringify(project),
          projectName: project.storyboard?.title,
          history: project.historyQuantity,
          lang: project.language,
        });
      } catch {
        return;
      }
    },
    [backendClient]
  );

  const {
    project,
    updateProject,
    updateConstraint,
    updateStoryboard,
    updateConfig,
    updateComposition,
    saveHistory,
  } = useProject(initialProject, syncProject);

  const createProject = useCallback(
    async (project: Project<ProjectType>) => {
      if (project.id) return project.id;
      const {data: id} = await backendClient.createProject({
        frameRatio: formatAspectRatio(project.size),
        projectJsonContent: JSON.stringify(project),
      });
      return id;
    },
    [backendClient]
  );

  const {checkoutPreference, updatePreference} = useUserContext();
  const preference = useMemo(
    () => checkoutPreference(project.type),
    [checkoutPreference, project.type]
  );
  const _updatePreference = useCallback(
    (
      state: SetStateAction<Preference<ProjectType>>,
      delay?: number | boolean
    ) => {
      updatePreference(project.type, state, delay);
    },
    [project.type, updatePreference]
  );

  return {
    project,
    updateProject,
    updateConstraint,
    updateStoryboard,
    updateConfig,
    updateComposition,
    saveHistory,
    createProject,
    preference,
    updatePreference: _updatePreference,
  };
}

export const ProjectPageContainer = combine(useHook, ['project'])(ProjectPage);
