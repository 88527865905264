// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectJSON, ProjectType} from '../types';
import {reviewProjectType} from '../utils';
import {
  BilingualDialogueProjectVersionManager,
  LATEST_VERSION as BILINGUAL_DIALOGUE_LATEST_VERSION,
} from './BilingualDialogueProjectVersionManager';
import {
  BilingualStoryProjectVersionManager,
  LATEST_VERSION as BILINGUAL_STORY_LATEST_VERSION,
} from './BilingualStoryProjectVersionManager';
import {
  GeneralStoryProjectVersionManager,
  LATEST_VERSION as GENERAL_STORY_LATEST_VERSION,
} from './GeneralStoryProjectVersionManager';
import {
  LATEST_VERSION as SHORT_VIDEO_LATEST_VERSION,
  ShortVideoProjectVersionManager,
} from './ShortVideoProjectVersionManager';

//不同type的project判读是否是最新版本
export const isLastVersion = (project: ProjectJSON<ProjectType>) => {
  return project.version === getLastVersion(reviewProjectType(project.type));
};

export const getLastVersion = (type: ProjectType) => {
  if (type === 'general_story') {
    return GENERAL_STORY_LATEST_VERSION;
  } else if (type === 'short_video') {
    return SHORT_VIDEO_LATEST_VERSION;
  } else if (type === 'bilingual_story') {
    return BILINGUAL_STORY_LATEST_VERSION;
  } else if (type === 'bilingual_dialogue') {
    return BILINGUAL_DIALOGUE_LATEST_VERSION;
  } else {
    throw new Error('unknown project type');
  }
};

export type ProjectVersionManager<T extends ProjectType> =
  T extends 'general_story'
    ? GeneralStoryProjectVersionManager
    : T extends 'short_video'
    ? ShortVideoProjectVersionManager
    : T extends 'bilingual_story'
    ? BilingualStoryProjectVersionManager
    : T extends 'bilingual_dialogue'
    ? BilingualDialogueProjectVersionManager
    : never;

//根据project的type返回对应的ProjectVersionManager
export function getProjectVersionManagerInstance<T extends ProjectType>(
  type: T
): ProjectVersionManager<T> {
  if (type === 'general_story') {
    return new GeneralStoryProjectVersionManager() as ProjectVersionManager<T>;
  } else if (type === 'short_video') {
    return new ShortVideoProjectVersionManager() as ProjectVersionManager<T>;
  } else if (type === 'bilingual_story') {
    return new BilingualStoryProjectVersionManager() as ProjectVersionManager<T>;
  } else if (type === 'bilingual_dialogue') {
    return new BilingualDialogueProjectVersionManager() as ProjectVersionManager<T>;
  } else throw new Error('Invalid project type');
}
