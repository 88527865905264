// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Children, cloneElement, isValidElement} from 'react';

import {InfoLabel} from '../InfoLabel';
import styles from './SelectCard.module.scss';
import {Props} from './SelectCard.types';

export function SelectCard({label, children, ...props}: Props) {
  //将props传入children
  const _children = Children.map(children, child => {
    if (!child) return null;
    if (isValidElement(child)) {
      return cloneElement(child, props);
    } else {
      return child;
    }
  });
  return (
    <div className={styles.container}>
      <InfoLabel label={label}>{_children}</InfoLabel>
    </div>
  );
}
