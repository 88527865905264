// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as NineToSixteenIcon} from 'assets/svg/3.0/NineToSixteen.svg';
import {ReactComponent as OneToOneIcon} from 'assets/svg/3.0/OneToOne.svg';
import {ReactComponent as SixteenToNineIcon} from 'assets/svg/3.0/SixteenToNine.svg';
import {ReactComponent as FreeCropIcon} from 'assets/svg/outline/FreeCrop.svg';
import classNames from 'classnames';
import {Button} from 'pages/components/Button';

import {CropPanelProps} from '../AssetEditor.types';
import styles from './Panel.module.scss';

const aspectRatios = [
  {ratio: undefined, id: '', label: 'Free', icon: <FreeCropIcon />},
  {ratio: 16 / 9, id: '16by9', label: '16:9', icon: <SixteenToNineIcon />},
  {ratio: 9 / 16, id: '9by16', label: '9:16', icon: <NineToSixteenIcon />},
  {ratio: 1, id: '1by1', label: '1:1', icon: <OneToOneIcon />},
];

export function CropPanel({
  confirmCrop,
  handleChangeAspectRatio,
  aspectRatio,
}: CropPanelProps) {
  return (
    <div className={styles['action-wrap']}>
      <span className={styles['action-wrap-title']}> Crop</span>
      <div className={styles['action-wrap-items-crop']}>
        {aspectRatios.map(item => (
          <div
            key={item.id}
            data-ratio={item.ratio || ''}
            onClick={() => handleChangeAspectRatio(item.ratio)}
            className={classNames(styles['action-wrap-item-crop'], {
              [styles['action-wrap-item-crop-active']]:
                item.ratio === aspectRatio,
            })}
          >
            {item.icon}
            {item.label}
          </div>
        ))}
      </div>

      <Button
        className={styles['action-wrap-btn']}
        theme="primary"
        type="button"
        onClick={confirmCrop}
      >
        Crop
      </Button>
    </div>
  );
}
