// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  COPYRIGHTS_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_OF_SERVICE_PATH,
} from 'utils/path';

import styles from './TermsAndPolicy.module.scss';

export function TermsAndPolicy({linkString = 'and'}: {linkString: string}) {
  return (
    <div className={styles.container}>
      <a
        href={TERMS_OF_SERVICE_PATH}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.termsServiceAndPolicy}
      >
        Terms of Service
      </a>
      <span className={styles?.linkString}>&nbsp;{linkString}&nbsp;</span>
      <a
        href={PRIVACY_POLICY_PATH}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.termsServiceAndPolicy}
      >
        Privacy Policy
      </a>
      <span className={styles?.linkString}>&nbsp;{linkString}&nbsp;</span>
      <a
        href={COPYRIGHTS_PATH}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.termsServiceAndPolicy}
      >
        Copyrights
      </a>
    </div>
  );
}
