// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useErrorToast} from 'pages/AuthPage/AuthForm/AuthForm.module';
import {useState} from 'react';

import {Upload} from './Upload';
import {HookParams, HookReturn} from './Upload.type';

export function useHook({
  disabled,
  uploadFileAndEditAsset,
}: HookParams): HookReturn {
  const {errorToastVisible, setErrorToastVisible, hideErrorToast} =
    useErrorToast();
  const [errorInfo, setErrorInfo] = useState<string>(
    'File maxiam size is 25MB'
  );
  const handleFileChange = async function (
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.preventDefault();
    const uploadedFiles = event.target.files;
    if (!uploadedFiles) return;
    //如果文件大小超过25MB，提示错误
    if (uploadedFiles[0].size > 25 * 1024 * 1024) {
      setErrorInfo('File maxiam size is 25MB');
      setErrorToastVisible(true);
      return;
    }
    const img = new Image();
    img.onload = function () {
      // 在图片加载完成后获取宽度和高度
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if (width > 8000 || height > 8000) {
        setErrorInfo('Upload failed, please try again.');
        setErrorToastVisible(true);
        return;
      }
      const blobImageUrl =
        uploadedFiles[0] && URL.createObjectURL(uploadedFiles[0]);

      uploadFileAndEditAsset({
        file: uploadedFiles[0],
        blobImageUrl,
      });
      event.target.value = '';
    };
    img.src = URL.createObjectURL(uploadedFiles[0]);
  };

  const handleDrop = async function (event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (disabled) return;
    const droppedFiles = event.dataTransfer.files;
    if (!droppedFiles) return;

    const blobImageUrl =
      droppedFiles[0] && URL.createObjectURL(droppedFiles[0]);

    uploadFileAndEditAsset({
      file: droppedFiles[0],
      blobImageUrl,
    });
  };
  return {
    disabled,
    errorToastVisible,
    errorInfo,
    handleFileChange,
    handleDrop,
    hideErrorToast,
  };
}
export const UploadContainer = combine(useHook, [
  'disabled',
  'uploadFileAndEditAsset',
])(Upload);
