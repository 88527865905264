// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';

import {Generate} from './Generate';
function useHook(): object {
  return {};
}
export const GenerateContainer = combine(useHook)(Generate);
