// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {CloseButtonType, withDialog} from 'pages/components/Dialog';

import {ReferralPanel} from './ReferralPanel';

export const ReferralPanelDialog = withDialog({
  closeButtonType: CloseButtonType.Inside,
  style: {
    left: '50%',
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    animation: 'none',
    maxWidth: 'calc(100% - 48px)',
  },
})(ReferralPanel);
