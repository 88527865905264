// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {CloseButtonType, withDialog} from 'pages/components/Dialog';

import {GeneratorContainer as Generator} from './Generator.container';

export const GeneratorDialog = withDialog({
  closeButtonType: CloseButtonType.Inside,
})(Generator);
