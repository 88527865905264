// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {NotificationName, ResponseParam} from 'api/unity';
import {catchSync} from 'lib/exception';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Callback extends Record<string, (...args: any) => unknown> {
  onResponse: (param: ResponseParam) => void;
  onStory3DReady: (param: ResponseParam) => void;
}

export class UnityEvent {
  constructor(private readonly callback: Partial<Callback> = {}) {}

  bind<N extends keyof Callback>(name: N, fn?: Callback[N]) {
    if (!fn) {
      delete this.callback[name];
    } else {
      this.callback[name] = fn;
    }
  }

  private trigger<F extends Callback[keyof Callback]>(
    fn: F,
    ...params: Parameters<F>
  ) {
    fn.call(null, ...params);
  }

  dispatch(name: NotificationName, param: string) {
    const jsonParam = catchSync(JSON.parse.bind(JSON, param));
    switch (name) {
      case 'Response':
        this.callback.onResponse &&
          this.trigger(this.callback.onResponse, jsonParam as ResponseParam);
        break;
      case 'Story3DReady':
        this.callback.Story3DReady &&
          this.trigger(this.callback.Story3DReady, jsonParam as ResponseParam);
        break;
      case 'CameraMoved':
        this.callback.CameraMoved &&
          this.trigger(this.callback.CameraMoved, jsonParam as ResponseParam);
        break;
      default:
        console.error(`Unsupported unity event name: ${name}`);
    }
  }
}
