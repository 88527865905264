// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Popover} from 'components/PopoverNew';
import {debounce} from 'lodash';
import {useCallback, useMemo, useRef, useState} from 'react';

import {Option} from '../../Option';
import {Select} from '../../Select';
import {SelectCard} from '../../SelectCard';
import styles from './CommonSelectWithoutLabel.module.scss';
import {Props, SelectValue} from './CommonSelectWithoutLabel.types';

export function CommonSelectWithoutLabel({
  value: selectValue,
  onChange,
  options,
  placeholderOfOtherInput,
  customizeValue,
  title,
}: Props<SelectValue>) {
  const [isOpen, setIsOpen] = useState(false);
  const [otherValue, setOtherValue] = useState(
    typeof selectValue === 'string' ? '' : selectValue.value
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const selectedValue = useMemo(() => {
    if (typeof selectValue === 'object' && customizeValue !== undefined) {
      return customizeValue;
    }
    return (
      options.find(option => option.value === selectValue)?.label ||
      options[0].label
    );
  }, [customizeValue, options, selectValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebounceChange = useCallback(debounce(onChange, 1000), [onChange]);

  const onChangeValue = useCallback(
    (value: string) => {
      if (customizeValue === undefined) return;
      setOtherValue(value);
      onDebounceChange({type: customizeValue, value});
    },
    [customizeValue, onDebounceChange]
  );

  return (
    <Select
      label=""
      isOpen={isOpen}
      value={
        typeof selectValue === 'object'
          ? selectedValue +
            (selectValue.value ? ' (' + selectValue.value + ')' : '')
          : selectedValue
      }
      onVisibleChange={setIsOpen}
    >
      <SelectCard label={title || ''}>
        <ul className={styles['option-list']}>
          {options.map(option => (
            <li className={styles.item} key={option.value.toString()}>
              <Option
                label={
                  option.tooltip ? (
                    <Popover
                      triggerElement={
                        <span className={styles['language-label']}>
                          {option.label}
                        </span>
                      }
                      trigger="hover"
                      content={
                        <div className={styles['popover-content']}>
                          {option.tooltip}
                        </div>
                      }
                      direction="right"
                      distance={20}
                    />
                  ) : (
                    <span className={styles['language-label']}>
                      {option.label}
                    </span>
                  )
                }
                onCheck={() => {
                  if (typeof option.value === 'object') {
                    inputRef.current?.focus();
                  } else {
                    setIsOpen(false);
                  }

                  if (
                    typeof option.value === 'string' &&
                    option.value !== selectedValue
                  ) {
                    setOtherValue('');
                    onChange(option.value);
                  }
                }}
                isDisabled={false}
                isChecked={option.label === selectedValue}
              />
            </li>
          ))}
          {customizeValue && (
            <input
              type="text"
              value={otherValue}
              className={styles.input}
              placeholder={placeholderOfOtherInput}
              onChange={e => onChangeValue(e.currentTarget.value)}
              ref={inputRef}
              onFocus={() =>
                selectedValue !== customizeValue &&
                onChange({type: customizeValue, value: ''})
              }
              onKeyUp={e => e.code === 'Enter' && setIsOpen(false)}
              maxLength={30}
            />
          )}
        </ul>
      </SelectCard>
    </Select>
  );
}
