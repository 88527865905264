// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as AssetsLibraryIcon} from 'assets/svg/3.0/AssetsLibraryIcon.svg';
import {ReactComponent as CreditIcon} from 'assets/svg/3.0/CreditIcon.svg';
import {ReactComponent as GallearyIcon} from 'assets/svg/3.0/GallearyIcon.svg';
import {ReactComponent as GenerateIcon} from 'assets/svg/3.0/GenerateIcon.svg';
import {ReactComponent as GiftIcon} from 'assets/svg/3.0/Gift.svg';
import {ReactComponent as MootionSvg} from 'assets/svg/3.0/LeftAsideMootion.svg';
import {ReactComponent as MyProjectsIcon} from 'assets/svg/3.0/MyProjectsIcon.svg';
import {ReactComponent as MenuIcon} from 'assets/svg/outline/Menu.svg';
import {ReactComponent as CloseIcon} from 'assets/svg/outline/Plus2.svg';
import classNames from 'classnames';
import {useUserContext} from 'contexts/UserContext';
import {useVisible} from 'lib/hooks';
import {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  COPYRIGHTS_PATH,
  PLAN_PAGE_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_OF_SERVICE_PATH,
  WORKSPACE_PAGE_ASSETS_PATH,
  WORKSPACE_PAGE_GALLERY_PATH,
  WORKSPACE_PAGE_GENERATE_PATH,
  WORKSPACE_PAGE_MYPROJECT_PATH,
} from 'utils/path';

import {ReferralPanelDialog} from '../ReferralPanel';
import styles from './Aside.module.scss';
import {ChangePW} from './ChangePW';
import {UserCard} from './UserCard';

export function Aside() {
  const navigate = useNavigate();
  const location = useLocation();
  const {userInfo, updateCredit} = useUserContext();
  const {credit, plan, planCreditAmount, creditPackAmount} = userInfo;
  const planType = capitalizeFirstLetter(plan);

  const [referralDialogShowing, setReferralDialogShowing] = useState(false);

  const menuList = useMemo(
    () => [
      {
        name: 'Generate',
        icon: GenerateIcon,
        path: WORKSPACE_PAGE_GENERATE_PATH,
      },
      {
        name: 'My projects',
        icon: MyProjectsIcon,
        path: WORKSPACE_PAGE_MYPROJECT_PATH,
      },
      {
        name: 'Gallery',
        icon: GallearyIcon,
        path: WORKSPACE_PAGE_GALLERY_PATH,
      },
      {
        name: 'Asset studio',
        icon: AssetsLibraryIcon,
        path: WORKSPACE_PAGE_ASSETS_PATH,
      },
    ],
    []
  );

  useEffect(() => {
    updateCredit();
  }, [updateCredit]);

  const [dialogShowing, hideDialog, showDialog] = useVisible(false);

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  return (
    <>
      <ReferralPanelDialog
        dialogShowing={referralDialogShowing}
        onCloseDialog={() => setReferralDialogShowing(false)}
        onClose={() => setReferralDialogShowing(false)}
      />
      <div className={classNames(styles.mask, {[styles.active]: active})}></div>
      <aside
        className={classNames(styles.sidebar, {
          [styles.active]: active,
        })}
        id="mootion-aside"
      >
        <div className={styles.mootionWrap}>
          <MootionSvg className={styles.mootion} />
          {!active ? (
            <MenuIcon className={styles.menu} onClick={() => setActive(true)} />
          ) : (
            <CloseIcon
              className={styles.menu}
              onClick={() => setActive(false)}
            />
          )}
        </div>

        <div className={styles.sidebarLinkWrap}>
          {menuList.map(item => {
            return (
              <div
                className={classNames(styles.sidebarLink, {
                  [styles.active]: location.pathname === item.path,
                })}
                onClick={() => navigate(item.path)}
                key={item.path}
              >
                <item.icon />
                {item.name}
              </div>
            );
          })}
        </div>

        <div className={styles.userInfoWrap}>
          <UserCard
            onClickChangePW={showDialog}
            onClose={() => setActive(false)}
          />
          <div
            className={styles['invite-button']}
            onClick={() => setReferralDialogShowing(true)}
          >
            <span>Invite a friend</span>
            <GiftIcon className={styles.icon} />
          </div>
          <div
            className={classNames(styles.planInfo, styles[planType])}
            onClick={() => navigate(PLAN_PAGE_PATH)}
          >
            <div className={styles.planType}>{planType} Plan</div>
            <div className={styles.creditInfo}>
              <CreditIcon />
              {credit + creditPackAmount} / {planCreditAmount}
            </div>
          </div>
        </div>
        <ul className={styles['list']}>
          <li>
            <a
              href={TERMS_OF_SERVICE_PATH}
              target="_blank"
              rel="noreferrer noopener"
            >
              Terms of service
            </a>
          </li>
          <li className={styles.split}></li>
          <li>
            <a
              href={PRIVACY_POLICY_PATH}
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </a>
          </li>
          <li className={styles.split}></li>
          <li>
            <a href={COPYRIGHTS_PATH} target="_blank" rel="noreferrer noopener">
              Copyrights
            </a>
          </li>
        </ul>
      </aside>
      {dialogShowing && <ChangePW visible={true} close={hideDialog} />}
    </>
  );
}
function capitalizeFirstLetter(word: string) {
  if (typeof word !== 'string') return '';
  // 将整个单词转换为小写，然后将首字母转换为大写
  return word
    .toLowerCase()
    .replace(/^./, firstLetter => firstLetter.toUpperCase());
}
