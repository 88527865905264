// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {PlanType} from 'modules/payment/types';

import {ConstructFormDataItem} from './types';

export function constructFormDataItem(
  data: ConstructFormDataItem[0],
  file: ConstructFormDataItem[1]
) {
  const formData = new FormData();
  Object.entries(data.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append('key', data.object_id);
  formData.append('Content-Type', file.type);
  formData.append('file', file);
  return {
    formData,
    url: data.url,
    assetId: data.object_id,
    objectKey: data.object_id,
  };
}
type FillColorProps = {
  imageUrl: string;
  imageWidth: number;
  imageHeight: number;
  newFillColor: string;
};
//fill color
export function getNewUrlAfterFillBgColor({
  imageUrl,
  imageWidth,
  imageHeight,
  newFillColor,
}: FillColorProps) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = imageWidth;
  canvas.height = imageHeight;

  if (!ctx) throw new Error('Canvas context is not supported');
  if (newFillColor !== '') {
    ctx.fillStyle = newFillColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  const image = new Image();
  image.src = imageUrl;
  return new Promise<string>((resolve, reject) => {
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(
        blob => {
          if (!blob) throw new Error('Failed to convert canvas to blob');
          resolve(URL.createObjectURL(blob));
        },
        'image/jpeg',
        0.9
      );
    };
    image.onerror = reject;
  });
}

//与桂林约定就这样取原图URL
export const getRelativeImagePathOfDbBucketByUrl = (
  url: string,
  isThumbUrl = true
): {prefix: string; relativePath: string} | null => {
  if (isThumbUrl) {
    url = url.replace(/\/thumbnails\//, '/');
  }
  const prefix = url.match(/(^https?:\/\/[^\\/]+\/)(.*)$/);
  if (prefix) {
    return {prefix: prefix?.[1] || '', relativePath: prefix?.[2] || ''};
  } else {
    return null;
  }
};

export async function fetchAsset(url: string) {
  const blob = await (await fetch(url)).blob();
  return URL.createObjectURL(blob);
}

export async function checkValidAsset(src: string) {
  const res = await fetch(src);
  if (!res.ok) {
    throw new Error('Invalid asset');
  }
}

export function getFileCapacityLimitByPlan(plan: PlanType) {
  switch (plan) {
    case 'FREE':
      return '1GB';
    case 'STANDARD':
      return '10GB';
    case 'PRO':
      return '100GB';
    default:
      return '1GB';
  }
}
