// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useCallback, useEffect, useMemo, useState} from 'react';

import styles from './Input.module.scss';

export function Input({
  onFocus,
  onBlur,
  onChange,
  className,
  error,
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: string;
}) {
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState(props.value ?? '');
  const isEmpty = useMemo(() => !value, [value]);
  const focusHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      onFocus?.(e);
      setIsFocus(true);
    },
    [onFocus]
  );
  const blurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      onBlur?.(e);
      setIsFocus(false);
    },
    [onBlur]
  );
  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      onChange?.(e);
    },
    [onChange]
  );
  useEffect(() => {
    setValue(props.value ?? '');
  }, [props.value]);
  return (
    <div className={className}>
      <div
        className={classNames(styles.input, {
          [styles.focus]: isFocus,
          [styles.empty]: isEmpty,
          [styles.error]: error !== undefined,
        })}
      >
        <input
          {...props}
          value={value}
          onChange={changeHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />
      </div>
      {error && <div className={styles['error-msg']}>{error}</div>}
    </div>
  );
}
