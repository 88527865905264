// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as TriangleIcon} from 'assets/svg/3.0/Triangle.svg';
import {ReactComponent as HomeIcon} from 'assets/svg/outline/Home.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {WORKSPACE_PAGE_GENERATE_PATH} from 'utils/path';

import styles from './ProgressHeader.module.scss';
import {Props} from './ProgressHeader.types';

export function ProgressHeader({
  current,
  remainingTime,
  title,
  isScrollTop = true,
}: Props) {
  const [isKnown, setIsKnown] = useState(false);

  const navigate = useNavigate();

  return (
    <header
      className={classNames(styles.header, isScrollTop || styles.background)}
    >
      <Popover
        triggerElement={
          <div
            className={styles.home}
            onClick={() => navigate(WORKSPACE_PAGE_GENERATE_PATH)}
          >
            <HomeIcon className={styles.icon} />
          </div>
        }
        trigger="hover"
        content={
          <div className={styles['popover-content']}>Back to workspace</div>
        }
        direction="bottom"
        distance={6}
        popoverClassName={styles['popover-container']}
        className={styles.popover}
      />

      <div className={styles.progress} style={{width: `${current}%`}} />
      <p className={styles.title}>{title}</p>
      <div className={styles.current}>
        <span className={styles.number}>{current}</span>
        <span className={styles.unit}>%</span>
        {/* 当预估剩余任务时间大于15分钟显示 */}
        {!isKnown && remainingTime !== null && remainingTime > 60 * 15 && (
          <div className={styles.hint}>
            <TriangleIcon className={styles.icon} />
            <p>
              The processing will take some minutes, it can run in the
              background.
            </p>

            <p className={styles.bottom}>
              <span className={styles.got} onClick={() => setIsKnown(true)}>
                Got it
              </span>
            </p>
          </div>
        )}
      </div>
    </header>
  );
}
