// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister} from '@lexical/utils';
import {$getRoot, EditorState} from 'lexical';
import {useCallback, useEffect} from 'react';

import {
  $isLimitedParagraphNode,
  $isLimitedTextNode,
  LimitedTextNode,
} from '../nodes';
import {LimitPluginProps} from './types';

export function LimitPlugin({
  maxParagraphCount = Infinity,
  maxParagraphCharacterCount = Infinity,
  maxCharacterCount = Infinity,
}: LimitPluginProps) {
  const [editor] = useLexicalComposerContext();

  const updateParagraphError = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const root = $getRoot();
        const styledParagraphs = root
          .getChildren()
          .filter($isLimitedParagraphNode);
        styledParagraphs.reduce((count, node) => {
          const ret = count + (node.getTextContentSize() > 0 ? 1 : 0);
          node.setIsError(
            ret > maxParagraphCount && node.getTextContentSize() > 0
          );
          return ret;
        }, 0);
      });
    },
    [maxParagraphCount]
  );

  const updateTextError = useCallback(() => {
    editor.update(() => {
      let count = 0;
      $getRoot()
        .getChildren()
        .filter($isLimitedParagraphNode)
        .forEach((node, index) => {
          if (index > 0) {
            count += 1;
          }
          let count2 = 0;
          node
            .getChildren()
            .filter($isLimitedTextNode)
            .forEach(child => {
              const lastCount = count;
              const lastCount2 = count2;
              count += child.getTextContentSize();
              count2 += child.getTextContentSize();
              if (
                lastCount >= maxCharacterCount ||
                lastCount2 >= maxParagraphCharacterCount
              ) {
                child.setIsError(true);
              } else if (count2 > maxParagraphCharacterCount) {
                const [, targetNode] = child.splitText(
                  child.getTextContentSize() -
                    (count2 - maxParagraphCharacterCount)
                ) as [LimitedTextNode, LimitedTextNode];
                targetNode.setIsError(true);
              } else if (count > maxCharacterCount) {
                const [, targetNode] = child.splitText(
                  child.getTextContentSize() - (count - maxCharacterCount)
                ) as [LimitedTextNode, LimitedTextNode];
                targetNode.setIsError(true);
              } else {
                child.setIsError(false);
              }
            });
        });
    });
  }, [editor, maxParagraphCharacterCount, maxCharacterCount]);

  useEffect(() => {
    const state = editor.getEditorState();
    updateParagraphError(state);
    updateTextError();

    return mergeRegister(
      editor.registerUpdateListener(({editorState}) =>
        updateParagraphError(editorState)
      ),
      editor.registerTextContentListener(updateTextError)
    );
  }, [editor, updateParagraphError, updateTextError]);

  return null;
}
