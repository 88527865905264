// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {UnityInstance} from '../types';
import {UnityConnection} from './connection';

export class UnityController {
  private readonly connection = new UnityConnection();
  isReady = false;

  connect(instance: UnityInstance) {
    this.connection.instance = instance;
  }

  disconnect() {
    const instance = this.connection.instance;
    this.connection.instance = null;
    return instance;
  }

  get instance() {
    return this.connection.instance;
  }

  sendRequest(...params: Parameters<UnityConnection['sendRequest']>) {
    return this.connection.sendRequest(...params);
  }

  onResponse(...params: Parameters<UnityConnection['onResponse']>) {
    this.connection.onResponse(...params);
  }
}
