// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import {AssetActionType, AssetEditor} from 'components/AssetEditor';
import {useUserContext} from 'contexts/UserContext';
import {useVisible} from 'lib/hooks';
import {
  blobUrlToFile,
  fileUrlToBlobUrlAndMineType,
  getImageSizeByUrl,
  handleDownload,
} from 'lib/image';
import {
  getFileCapacityLimitByPlan,
  getRelativeImagePathOfDbBucketByUrl,
} from 'modules/user-asset/utils';
import {Modal} from 'pages/components/Modal';
import {Toast} from 'pages/components/Toast';

import {ImageGridInModal} from '../ImageGrid';
import {ImageGrid} from '../ImageGrid/ImageGrid';
import {ImageGridProps} from '../ImageGrid/ImageGrid.type';
import styles from './AssetsLibrary.module.scss';
import {AssetsLibraryProps, ErrorType} from './AssetsLibrary.type';
import {AssetTool} from './AssetTool';

export function AssetsLibraryPage({
  errorToastType,
  assetEditorModalVisible,
  errorToastVisible,
  tempList,
  assetItemList,
  deleteConfirmVisible,
  isAssetsFetchLoading,
  loadMoreRef,
  hasMore,
  assetImgUrl,
  actionType,
  imageInfoRef,
  handleApply,
  hideErrorToast,
  hideAssetEditorModal,
  showAssetEditorModal,
  deleteAssetById,
  refreshAssetList,
  hideDeleteConfirmModal,
  onConfirmDelete,
  handleUploadTempList,
  handleImageError,
  handleUpload,
  uploadFileAndEditAsset,
  setAssetImgUrl,
  setActionType,
  openAssetEditorModal,
}: AssetsLibraryProps) {
  const {
    userInfo: {plan},
  } = useUserContext();

  const [imageGridInModalVisible, hideImageGridInModal, showImageGridInModal] =
    useVisible();

  const handleClickAssets = async (e: React.MouseEvent<HTMLDivElement>) => {
    const imageUrl = (e.currentTarget as HTMLDivElement).dataset.url;
    const assetName = (e.currentTarget as HTMLDivElement).dataset.assetName;
    if (!imageUrl) {
      throw new Error('Image url is empty');
    }
    if (!assetName) {
      throw new Error('Asset name is empty');
    }

    setAssetImgUrl('');
    hideImageGridInModal();
    showAssetEditorModal();
    executeActionToImage(imageUrl || '').then(res => {
      if (res) {
        const {dataURL, width, height, mimeType} = res;
        setAssetImgUrl(dataURL);
        imageInfoRef.current.width = width;
        imageInfoRef.current.height = height;
        imageInfoRef.current.filename = assetName || '';
        imageInfoRef.current.mimeType = mimeType || '';
      } else {
        throw new Error('Asset cannot get signedUrl');
      }
    });
  };
  const executeActionToImage = async (thumbUrl: string) => {
    const fileSplitInfo = getRelativeImagePathOfDbBucketByUrl(thumbUrl, true);
    if (fileSplitInfo) {
      const signedUrlsRes = await apiServer.getSignedUrl([
        {
          fileName: fileSplitInfo.relativePath,
        },
      ]);
      if (signedUrlsRes.data && signedUrlsRes.data.length > 0) {
        const {blobUrl, mimeType} =
          (await fileUrlToBlobUrlAndMineType(signedUrlsRes.data[0])) || {};
        if (blobUrl) {
          const {width, height} = await getImageSizeByUrl(blobUrl);
          return {dataURL: blobUrl, mimeType, width, height};
        }
      }
    }
    throw new Error('Asset cannot get signedUrl');
  };
  const handleClickAssetTool = (type: AssetActionType) => {
    setActionType(type);
    showImageGridInModal();
  };
  const _handleApply = (args: {
    file: File;
    blobImageUrl: string;
    needUpload: boolean;
  }) => {
    hideAssetEditorModal();
    if (args.needUpload) {
      handleApply({
        file: args.file,
        blobImageUrl: args.blobImageUrl,
        needUpload: true,
      });
    }
  };
  const doActionToImage: ImageGridProps['doActionToImage'] = (
    actionType,
    imageUrl,
    assetName
  ) => {
    if (actionType !== 'download') {
      setAssetImgUrl('');
      setActionType(actionType);
      showAssetEditorModal();
    }
    executeActionToImage(imageUrl || '').then(res => {
      if (res) {
        if (actionType === 'download') {
          handleDownload(res.dataURL, assetName);
        } else {
          const {dataURL, width, height} = res;
          setAssetImgUrl(dataURL);
          imageInfoRef.current.width = width;
          imageInfoRef.current.height = height;
          imageInfoRef.current.filename = assetName || '';
        }
      }
    });
  };

  let toastInfo = null;
  if (errorToastVisible) {
    switch (errorToastType) {
      case ErrorType.Capacity:
        toastInfo = {
          title: 'Upload failed',
          content:
            'All files storage capacity is ' +
            getFileCapacityLimitByPlan(plan) +
            '.',
          showCancel: false,
          confirmText: 'OK',
          onOk: hideErrorToast,
        };
        break;
      case ErrorType.UploadFailed:
        toastInfo = {
          title: 'Upload failed',
          content: 'Upload failed, please try again.',
          showCancel: false,
          confirmText: 'OK',
          onOk: hideErrorToast,
        };
        break;
    }
  }
  const _openAssetEditorModal: AssetsLibraryProps['openAssetEditorModal'] =
    args => {
      hideImageGridInModal();
      openAssetEditorModal(args);
    };
  const uploadToAssetLibrary = async (
    blobImageUrl: string,
    fileName: string
  ) => {
    const file = await blobUrlToFile(blobImageUrl, fileName);
    if (file) {
      handleUpload(
        {
          file,
          blobImageUrl,
        },
        'system'
      );
    } else {
      throw new Error('File is empty');
    }
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Asset studio</h1>
      <div className={styles.content}>
        <div className={styles['item-wrap']}>
          <div className={styles['item-label']}>Tools</div>
          <div className={styles['item-tool-btns']}>
            <AssetTool type="rmBg" handleClick={handleClickAssetTool} />
            <AssetTool
              type="styleTransfer"
              handleClick={handleClickAssetTool}
            />
          </div>
        </div>
        <div className={styles['item-wrap']}>
          <div className={styles['item-label']}>Assets</div>
          <ImageGrid
            tempList={tempList}
            assetItemList={assetItemList}
            deleteConfirmVisible={deleteConfirmVisible}
            deleteAssetById={deleteAssetById}
            refreshAssetList={refreshAssetList}
            hideDeleteConfirmModal={hideDeleteConfirmModal}
            onConfirmDelete={onConfirmDelete}
            handleUploadTempList={handleUploadTempList}
            handleImageError={handleImageError}
            loadMoreRef={loadMoreRef}
            hasMore={hasMore}
            isAssetsFetchLoading={isAssetsFetchLoading}
            uploadFileAndEditAsset={uploadFileAndEditAsset}
            doActionToImage={doActionToImage}
          />
        </div>
      </div>
      {imageGridInModalVisible && (
        <ImageGridInModal
          visible={imageGridInModalVisible}
          handleClickAssets={handleClickAssets}
          hideModal={hideImageGridInModal}
          doAfterUpload={_openAssetEditorModal}
        />
      )}

      {assetEditorModalVisible && (
        <Modal
          title="Edit Asset"
          visible={true}
          onCancel={hideAssetEditorModal}
          showCloseIcon={true}
          maskClosable={false}
          footer={null}
        >
          <AssetEditor
            type={actionType}
            imageUrl={assetImgUrl}
            imageWidth={imageInfoRef.current.width}
            imageHeight={imageInfoRef.current.height}
            filename={imageInfoRef.current.filename}
            handleApply={_handleApply}
            uploadToAssetLibrary={uploadToAssetLibrary}
          />
        </Modal>
      )}
      {toastInfo && (
        <Toast
          title={toastInfo.title}
          visible={true}
          showCloseBtn={false}
          onOk={toastInfo.onOk}
          showCancel={toastInfo.showCancel}
          confirmText={toastInfo.confirmText}
        >
          {toastInfo.content}
        </Toast>
      )}
    </div>
  );
}
