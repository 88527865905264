// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {TaskJSON, TaskStatus, TaskType} from '../types';

export class Task<T extends TaskType = TaskType> {
  static fromJSON<T extends TaskType>({
    id,
    type,
    status,
    session,
  }: TaskJSON<T>) {
    return new Task(id, type, status, session);
  }

  get isEndedStatus(): boolean {
    return this.status === 'success' || this.status === 'failure';
  }

  constructor(
    readonly id: string,
    readonly type: T,
    readonly status: TaskStatus,
    readonly session?: string,
    readonly progress?: number,
    readonly estimatedRemainingTime?: number,
    readonly elapsedTotalTime?: number
  ) {}

  patch(
    data: Partial<{
      [K in
        | 'status'
        | 'progress'
        | 'estimatedRemainingTime'
        | 'elapsedTotalTime']: Task<T>[K];
    }>
  ): Task<T> {
    const {
      id,
      type,
      status,
      session,
      progress,
      estimatedRemainingTime,
      elapsedTotalTime,
    } = Object.assign({}, this, data);
    return new Task(
      id,
      type,
      status,
      session,
      progress,
      estimatedRemainingTime,
      elapsedTotalTime
    );
  }

  toJSON(): TaskJSON<T> {
    return {
      id: this.id,
      type: this.type,
      status: this.status,
      session: this.session,
    };
  }
}
