// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

function cloneTextareaStyleToDiv(textarea: HTMLTextAreaElement) {
  const computedStyle = getComputedStyle(textarea);
  const div = document.createElement('div');
  Array.prototype.forEach.call(computedStyle, (key: string) => {
    div.style.setProperty(key, computedStyle.getPropertyValue(key));
  });

  div.style.position = 'fixed';
  div.style.zIndex = '-1';
  div.style.visibility = 'hidden';
  return div;
}

export function getTextareaLines(textarea: HTMLTextAreaElement) {
  const div = cloneTextareaStyleToDiv(textarea);
  document.body.appendChild(div);
  const value = /\n$|\r\n$/.test(textarea.value)
    ? textarea.value + ' '
    : textarea.value;
  div.innerHTML = value
    .split('')
    .map(char => `<span>${char}</span>`)
    .join('');
  let multiple = 1;
  let startY = div.children[0]?.getBoundingClientRect().top ?? 0;
  Array.prototype.forEach.call(div.children, (child: HTMLElement) => {
    const y = child.getBoundingClientRect().top;
    if (y > startY) {
      multiple++;
      startY = y;
    }
  });
  document.body.removeChild(div);

  return multiple;
}
