// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useResourceManager} from 'contexts/ResourceManager';
import {cloneBlobUrl} from 'lib/image';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {StyleTransferPanel} from '../Panels/StyleTransferPanel';
import styles from './StyleTransfer.module.scss';
import {Props, RefProps} from './StyleTransfer.types';

export const StyleTransferal = forwardRef<RefProps, Props>(
  ({transferStyle, currentImageInfo, children, tools}, ref) => {
    const initialImageRef = useRef<string>();
    const {resUrl, getStyles} = useResourceManager();
    const styleList = getStyles();

    const [style, setStyle] = useState<string>(styleList[0].name);

    const updateInitialImage = useCallback((url?: string) => {
      if (initialImageRef.current) {
        URL.revokeObjectURL(initialImageRef.current);
      }
      initialImageRef.current = url;
    }, []);

    const handleTransferStyle = useCallback(async () => {
      //转换风格
      const styleInfo = styleList.find(item => item.name === style);
      if (!styleInfo) {
        throw new Error('styleInfo not found');
      }
      //使用原始图片进行风格转换
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await transferStyle(initialImageRef.current!, styleInfo);
    }, [style, styleList, transferStyle]);

    useEffect(() => {
      if (
        currentImageInfo &&
        (!initialImageRef.current || currentImageInfo[1])
      ) {
        //设置初始值
        setStyle(styleList[0].name);
        cloneBlobUrl(currentImageInfo[0]).then(({url}) => {
          updateInitialImage(url);
        });
      }
    }, [currentImageInfo, styleList, updateInitialImage]);

    useEffect(() => {
      return () => updateInitialImage();
    }, [updateInitialImage]);

    useImperativeHandle(ref, () => ({
      action: handleTransferStyle,
    }));

    return (
      <>
        {children}
        <div className={styles.left}>
          {tools}
          <StyleTransferPanel
            resUrl={resUrl}
            styleList={styleList}
            handleTransferStyle={handleTransferStyle}
            setStyle={setStyle}
            style={style}
          />
        </div>
      </>
    );
  }
);

StyleTransferal.displayName = 'StyleTransferal';
