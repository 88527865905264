// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {changePassword} from 'api/server';
import classNames from 'classnames';
import {ErrorType, useError} from 'contexts/ErrorContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import {Button} from 'pages/components/Button';
import {Modal} from 'pages/components/Modal';
import {useState} from 'react';

import styles from './ChangePW.module.scss';
import {Input} from './Input';

export interface Props {
  visible: boolean;
  close: () => void;
}

export function ChangePW({visible, close}: Props) {
  const [currentPW, setCurrentPW] = useState('');
  const [newPW, setNewPW] = useState('');
  const [confirmPW, setConfirmPW] = useState('');
  const [currentPWError, setCurrentPWError] = useState<string>();
  const [confirmPWError, setConfirmPWError] = useState<string>();
  const {report} = useError();
  const {showNotification} = useNotificationContext();

  const validConfirmPassword = () => {
    if (newPW !== confirmPW) {
      setConfirmPWError('The password confirmation does not match.');
      return false;
    }
    setConfirmPWError(undefined);
    return true;
  };

  const handleUpdate = async () => {
    if (!validConfirmPassword()) return;
    try {
      await changePassword({
        oldPassword: currentPW,
        password: newPW,
        confirmPassword: confirmPW,
      });
      close();
      showNotification({
        type: 'SUCCESS',
        message: 'Password Updated',
      });
    } catch (error: any) {
      if (error?.data?.error === 20001) {
        setCurrentPWError('Invalid Password');
      } else {
        report(ErrorType.Service);
      }
    }
  };
  return (
    <Modal
      maskClosable={false}
      visible={visible}
      onCancel={close}
      title="Change password"
    >
      <div className={styles.container}>
        <div>
          <div className={classNames(styles.text, styles.mb8)}>
            Enter your current password
          </div>
          <Input
            className={styles.mb16}
            type="password"
            placeholder="Current password"
            name="currentPW"
            value={currentPW}
            onChange={e => {
              setCurrentPW(e.target.value);
              setCurrentPWError(undefined);
            }}
            error={currentPWError}
          />

          <div className={classNames(styles.text, styles.mb8)}>
            Enter your new password
          </div>
          <Input
            className={styles.mb8}
            type="password"
            placeholder="New password(at least 8 characters)"
            name="newPW"
            value={newPW}
            onChange={e => {
              setNewPW(e.target.value);
              setConfirmPWError(undefined);
            }}
            error={confirmPWError === undefined ? undefined : ''}
          />
          <Input
            type="password"
            placeholder="Confirm password"
            name="confirmPW"
            value={confirmPW}
            onChange={e => {
              setConfirmPW(e.target.value);
              setConfirmPWError(undefined);
            }}
            error={confirmPWError}
          />
        </div>
        <div className={styles.footer}>
          <Button type="ghost" size="middle" onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={
              currentPW.length < 8 || newPW.length < 8 || confirmPW.length < 8
            }
            theme="primary"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
}
