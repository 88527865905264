// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {createContext, useCallback, useContext, useRef, useState} from 'react';

export type MessageType = 'SUCCESS' | 'ERROR' | 'WARNING';

export interface Message {
  content: string;
  type: MessageType;
}

interface MessageContext {
  message: Message | null;
  showMessage: (message: Message) => void;
}

const DEFAULT_DISPLAY_DURATION = 3000; // 3 seconds

const messageContext = createContext({} as MessageContext);

export function useMessageContext() {
  return useContext(messageContext);
}

export function MessageContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [message, setMessage] = useState<Message | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const showMessage = useCallback((message: Message) => {
    setMessage(message);
    if (timerRef.current) clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      setMessage(null);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }, DEFAULT_DISPLAY_DURATION);
  }, []);

  return (
    <messageContext.Provider value={{message, showMessage}}>
      {children}
    </messageContext.Provider>
  );
}
