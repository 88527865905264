// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export const JSONUtils = {
  /**
   * 将对象序列化为JSON字符串
   * @param obj - 要序列化的JavaScript对象
   * @returns JSON字符串，如果序列化失败，则返回null
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stringify: (obj: any): string | null => {
    try {
      return JSON.stringify(obj);
    } catch (error) {
      console.error('JSON stringify error:', error);
      return null;
    }
  },

  /**
   * 将JSON字符串反序列化为对象
   * @param jsonString - 要反序列化的JSON字符串
   * @returns JavaScript对象，如果反序列化失败，则返回null
   */
  parse: <T>(jsonString: string): T | null => {
    try {
      return JSON.parse(jsonString) as T;
    } catch (error) {
      console.error('JSON parse error:', error);
      return null;
    }
  },
};
