// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {PromptPage} from 'components/PromptPage';
import {getPreProject, setPreProject} from 'contexts/localStorage';
import {useResourceManager} from 'contexts/ResourceManager';
import {useUserContext} from 'contexts/UserContext';
import {noop} from 'lodash';
import {create as createPreference} from 'modules/preference/models/Preference';
import {fromJSON, Project} from 'modules/project/models/Project';
import {TEMPLATES} from 'modules/project/templates';
import {ProjectType} from 'modules/project/types';
import {getCachedProject, reviewProject} from 'modules/project/utils';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {generateIdPath, GENERATING_PATH, PROJECT_PATH} from 'utils/path';

import styles from './PreProjectPage.module.scss';

export function PreProjectPage() {
  const resourceManager = useResourceManager();
  const {userInfo} = useUserContext();
  const navigate = useNavigate();

  //从缓存中获取cache
  const [project, setProject] = useState<Project<ProjectType>>(() => {
    //从缓存中获取cache
    let projectJson;
    try {
      projectJson = getCachedProject(getPreProject());
      if (!projectJson) {
        projectJson = Object.assign({}, TEMPLATES['general_story']);
      }
    } catch (e) {
      console.error(e);
    } finally {
      projectJson = Object.assign({}, TEMPLATES['general_story']);
    }
    //从query中获取project 的prompt
    const input = new URLSearchParams(window.location.search).get('input');
    if (input) {
      projectJson.prompt = input.slice(
        0,
        projectJson.constraint?.prompt_length?.max || 2000
      );
    }
    return reviewProject(fromJSON(projectJson), resourceManager, 'FREE');
  });

  useEffect(() => {
    setPreProject(JSON.stringify(project.toJSON()));
  }, [project]);

  const preference = useMemo(
    () => createPreference(project.type),
    [project.type]
  );

  if (userInfo.userId) {
    navigate(
      `${generateIdPath(
        PROJECT_PATH,
        'new'
      )}?type=general_story&prompt_type=idea`,
      {replace: true}
    );
    return null;
  }

  return (
    <div className={styles.container}>
      <PromptPage
        project={project}
        updateProject={setProject}
        pageType="preProject"
        onGenerate={() => {
          navigate(GENERATING_PATH, {replace: true});
        }}
        preference={preference}
        updatePreference={noop}
      />
    </div>
  );
}
