// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useUserContext} from 'contexts/UserContext';
import {getFileCapacityLimitByPlan} from 'modules/user-asset/utils';
import {Modal} from 'pages/components/Modal';
import {Toast} from 'pages/components/Toast';

import {ErrorType} from '../AssetsLibrary/AssetsLibrary.type';
import {ImageGrid} from './ImageGrid';
import {ImageGridInModalProps} from './ImageGrid.type';
import styles from './ImageGridInModal.module.scss';

export const ImageGridInModal = function ({
  visible,
  hideModal,
  handleClickAssets,

  tempList,
  assetItemList,
  deleteConfirmVisible,
  isAssetsFetchLoading,
  loadMoreRef,
  hasMore,
  errorToastVisible,

  deleteAssetById,
  refreshAssetList,
  hideDeleteConfirmModal,
  onConfirmDelete,
  handleUploadTempList,
  handleImageError,
  handleUploadFileAndEditAsset,

  errorToastType,
  hideErrorToast,
}: ImageGridInModalProps) {
  const {
    userInfo: {plan},
  } = useUserContext();

  let toastInfo = null;
  if (errorToastVisible) {
    switch (errorToastType) {
      case ErrorType.Capacity:
        toastInfo = {
          title: 'Upload failed',
          content:
            'All files storage capacity is ' +
            getFileCapacityLimitByPlan(plan) +
            '.',
          showCancel: false,
          confirmText: 'OK',
          onOk: hideErrorToast,
        };
        break;
      case ErrorType.UploadFailed:
        toastInfo = {
          title: 'Upload failed',
          content: 'Upload failed, please try again.',
          showCancel: false,
          confirmText: 'OK',
          onOk: hideErrorToast,
        };
        break;
    }
  }
  return (
    <>
      <Modal
        visible={visible}
        title="Choose asset"
        className={styles.imageGridInModal}
        onCancel={hideModal}
        maskClosable={false}
      >
        <div className={styles.imageGridWrap}>
          <ImageGrid
            isInModal={true}
            tempList={tempList}
            assetItemList={assetItemList}
            deleteConfirmVisible={deleteConfirmVisible}
            deleteAssetById={deleteAssetById}
            refreshAssetList={refreshAssetList}
            hideDeleteConfirmModal={hideDeleteConfirmModal}
            onConfirmDelete={onConfirmDelete}
            handleUploadTempList={handleUploadTempList}
            handleImageError={handleImageError}
            loadMoreRef={loadMoreRef}
            hasMore={hasMore}
            isAssetsFetchLoading={isAssetsFetchLoading}
            uploadFileAndEditAsset={handleUploadFileAndEditAsset}
            handleClickAssets={handleClickAssets}
          />
        </div>
      </Modal>
      {toastInfo && (
        <Toast
          title={toastInfo.title}
          visible={true}
          showCloseBtn={false}
          onOk={toastInfo.onOk}
          showCancel={toastInfo.showCancel}
          confirmText={toastInfo.confirmText}
        >
          {toastInfo.content}
        </Toast>
      )}
    </>
  );
};
