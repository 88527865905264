// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectJSON, ProjectType} from './types';
import {LATEST_VERSION as BILINGUAL_DIALOGUE_LATEST_VERSION} from './version/BilingualDialogueProjectVersionManager';
import {LATEST_VERSION as BILINGUAL_STORY_LATEST_VERSION} from './version/BilingualStoryProjectVersionManager';
import {LATEST_VERSION as GENERAL_STORY_LATEST_VERSION} from './version/GeneralStoryProjectVersionManager';
import {LATEST_VERSION as SHORT_VIDEO_LATEST_VERSION} from './version/ShortVideoProjectVersionManager';

type Template<T extends ProjectType> = {
  [K in T]: ProjectJSON<T>;
};

export const TEMPLATES: Template<ProjectType> = {
  general_story: {
    id: '',
    version: GENERAL_STORY_LATEST_VERSION,
    author_id: '',
    type: 'general_story',
    constraint: {
      size: {
        options: [
          [1920, 1080],
          [1080, 1920],
          [1080, 1080],
        ],
      },
      prompt_length: {min: undefined, max: 20000},
      scene_subtitle_length: {min: undefined, max: 300},
      scene_quantity: {min: undefined, max: 16},
    },
    size: [1920, 1080],
    language: 'en-US',
    prompt_policy: {
      keep_user_content: false,
      tone: 'Neutral',
      quick_pace: false,
    },
    style: 'photorealistic',
    config: {
      voiceover: 'Ava',
      effect: 'ken_burns',
      title_style: 'Title_EN_Stroke_01',
      subtitle_style: 'Subtitle_EN_Fill_01',
      transition: 'fade',
      thumbnail_type: 'Image',
      thumbnail_include_video: false,
      bgm: 'electric_sunshine',
      voiceover_speed: 'normal',
    },
  },
  bilingual_story: {
    id: '',
    version: BILINGUAL_STORY_LATEST_VERSION,
    author_id: '',
    type: 'bilingual_story',
    constraint: {
      size: {
        options: [
          [1920, 1080],
          [1080, 1920],
          [1080, 1080],
        ],
      },
      prompt_length: {min: undefined, max: 20000},
      scene_subtitle_length: {min: undefined, max: 300},
      scene_quantity: {min: undefined, max: 16},
      vocabulary_phrase_length: {min: undefined, max: 30},
      vocabulary_phrase_quantity: {min: undefined, max: 5},
    },
    size: [1920, 1080],
    language: 'en-GB',
    native_language: 'de-DE',
    prompt_policy: {
      keep_user_content: false,
      proficiency_level: "normal language learners'",
    },
    style: 'photorealistic',
    config: {
      voiceover: 'Claire',
      effect: 'ken_burns',
      title_style: 'Title_EN_Stroke_01',
      subtitle_style: 'Subtitle_EN_Fill_01',
      transition: 'fade',
      thumbnail_type: 'Image',
      thumbnail_include_video: false,
      bgm: 'electric_sunshine',
      voiceover_order: 1,
    },
  },
  short_video: {
    id: '',
    version: SHORT_VIDEO_LATEST_VERSION,
    author_id: '',
    type: 'short_video',
    constraint: {
      size: {
        options: [
          [1920, 1080],
          [1080, 1920],
          [1080, 1080],
        ],
      },
      prompt_length: {min: undefined, max: 20000},
      scene_subtitle_length: {min: undefined, max: 300},
      scene_quantity: {min: undefined, max: 16},
    },
    size: [1080, 1920],
    language: 'en-US',
    prompt_policy: {
      keep_user_content: false,
      tone: 'Neutral',
    },
    style: 'photorealistic',
    config: {
      voiceover: 'Ava',
      effect: 'ken_burns',
      title_style: 'Title_EN_Stroke_01',
      subtitle_style: 'Subtitle_EN_Fill_01',
      transition: 'flash',
      bgm: 'electric_sunshine',
      voiceover_speed: 'fast',
    },
  },
  bilingual_dialogue: {
    id: '',
    version: BILINGUAL_DIALOGUE_LATEST_VERSION,
    author_id: '',
    type: 'bilingual_dialogue',
    constraint: {
      size: {
        options: [
          [1920, 1080],
          [1080, 1920],
          [1080, 1080],
        ],
      },
      prompt_length: {min: undefined, max: 20000},
      scene_subtitle_length: {min: undefined, max: 300},
      scene_quantity: {min: undefined, max: 16},
      vocabulary_phrase_length: {min: undefined, max: 30},
      vocabulary_phrase_quantity: {min: undefined, max: 5},
    },
    size: [1920, 1080],
    language: 'en-GB',
    native_language: 'de-DE',
    characters: null,
    prompt_policy: {
      keep_user_content: false,
      proficiency_level: "normal language learners'",
    },
    style: 'photorealistic',
    config: {
      effect: 'ken_burns',
      title_style: 'Title_EN_Stroke_01_Title_ZH_Stroke_01',
      subtitle_style: 'Subtitle_EN_Stroke_02_Subtitle_SC_Retro_01',
      transition: 'fade',
      thumbnail_type: 'Image',
      thumbnail_include_video: false,
      bgm: 'electric_sunshine',
      voiceover_order: 1,
      voiceovers: ['Claire', 'Ollie', 'Ada'],
    },
  },
};
