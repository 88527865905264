// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ClosedCharacter} from 'modules/character/types';
import {PromptPolicy} from 'modules/project/models/PromptPolicy';
import {ClosedScene} from 'modules/scene/types';

import {ProjectType, Size} from '../../project/types';
import {Task} from '../../task/models/Task';
import {BaseCompositionJSON} from '../types';

export abstract class BaseComposition<T extends ProjectType> {
  constructor(
    readonly id: string,
    readonly size: Size,
    readonly language: string,
    readonly promptPolicy: PromptPolicy<T>,
    readonly prompt: string,
    readonly title: string,
    readonly description: string,
    readonly hashtags: string[],
    readonly characters: ClosedCharacter<T>[],
    readonly scenes: ClosedScene<T>[],
    readonly task?: Task<'composite_video'>,
    readonly createTime?: string,
    readonly thumbnail?: string,
    readonly asset?: string,
    readonly assetProduct?: string
  ) {}

  abstract isValid(): boolean;
  abstract patch(data: Partial<BaseComposition<T>>): BaseComposition<T>;

  toJSON(): BaseCompositionJSON<T> {
    return {
      id: this.id,
      characters: this.characters,
      scenes: this.scenes,
      task: this.task?.toJSON(),
      create_time: this.createTime,
      thumbnail: this.thumbnail,
      asset: this.asset,
      asset_product: this.assetProduct,
    };
  }
}
