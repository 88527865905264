// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useNotificationReducer} from 'modules/notification/reducer';
import {RawNotification} from 'modules/notification/types';
import {NotificationBox} from 'pages/components/NotificationBox';
import {createContext, PropsWithChildren, useContext, useMemo} from 'react';

const notificationContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showNotification: (_notification: RawNotification) => {},
});

export function useNotificationContext() {
  return useContext(notificationContext);
}

export function NotificationContextProvider({children}: PropsWithChildren) {
  const {notifications, showNotification, removeNotification} =
    useNotificationReducer();
  const providerValue = useMemo(() => ({showNotification}), [showNotification]);
  return (
    <notificationContext.Provider value={providerValue}>
      {children}
      {notifications.length > 0 && (
        <NotificationBox
          notifications={notifications}
          onRemove={removeNotification}
        />
      )}
    </notificationContext.Provider>
  );
}
