// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {withDialog} from '../Dialog';
import {LoadingPrompt} from './LoadingPrompt';

const DIALOG_STYLE = {
  width: 380,
  height: 112,
  borderRadius: '16px',
  padding: '24px',
  background: 'linear-gradient(90deg, #e5dfff 0%, #f9dcff 100%)',
  boxShadow:
    '0px 6px 12px 0px rgba(3, 4, 71, 0.1),0px 0.8px 4px 0px rgba(3, 4, 71, 0.08)',
  zIndex: 1000,
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  margin: 'auto',
  transform: 'none',
};

export const LoadingPromptDialog = withDialog({style: DIALOG_STYLE})(
  LoadingPrompt
);
