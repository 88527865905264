// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactNode} from 'react';

export enum MaskType {
  Dark = 'dark',
  White = 'white',
  Transparent = 'transparent',
  Black = 'black',
}

export interface Props {
  children: ReactNode;
  onClose: () => void;
  maskType?: MaskType;
  scrollable?: boolean;
}
