// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DeleteIcon} from 'assets/svg/3.0/Delete.svg';
import {ReactComponent as InsertIcon} from 'assets/svg/3.0/Insert.svg';
import {ReactComponent as MergeNextIcon} from 'assets/svg/3.0/MergeNext.svg';
import {ReactComponent as MergePrevIcon} from 'assets/svg/3.0/MergePrev.svg';
import {ReactComponent as SplitIcon} from 'assets/svg/3.0/Split.svg';
import classNames from 'classnames';
import {useUserContext} from 'contexts/UserContext';
import {PROJECT_RULE} from 'modules/payment/utils';
import {ProjectType} from 'modules/project/types';
import {BilingualDialogueScene} from 'modules/scene/models/BilingualDialogueScene';
import {BilingualStoryScene} from 'modules/scene/models/BilingualStoryScene';
import {ShortVideoScene} from 'modules/scene/models/ShortVideoScene';
import {IconButton} from 'pages/components/Buttons';
import {ToolTip} from 'pages/components/ToolTip';
import {useMemo, useState} from 'react';

import {AnimationType} from '../SceneList.type';
import styles from './SceneActionMenu.module.scss';
import {ActionType, InsertType, Props} from './SceneActionMenu.types';

const DISABLED_ACTION_MAP: Record<ProjectType, ActionType[]> = {
  general_story: [],
  bilingual_story: [],
  bilingual_dialogue: ['split_scene', 'merge_prev_scene', 'merge_next_scene'],
  short_video: [],
};

export function SceneActionMenu({
  scene,
  nextScene,
  prevScene,
  scenesNumber,
  onAction,
  animateStatus,
}: Props<ProjectType>) {
  const {id: sceneId} = scene;
  const {
    userInfo: {plan},
  } = useUserContext();
  const disabledActions = useMemo(() => {
    if (scene instanceof BilingualDialogueScene) {
      return DISABLED_ACTION_MAP.bilingual_dialogue;
    } else if (scene instanceof BilingualStoryScene) {
      return DISABLED_ACTION_MAP.bilingual_story;
    } else if (scene instanceof ShortVideoScene) {
      return DISABLED_ACTION_MAP.short_video;
    } else {
      return DISABLED_ACTION_MAP.general_story;
    }
  }, [scene]);

  const actions = useMemo(
    () =>
      [
        {
          icon: SplitIcon,
          type: 'split_scene',
          isDisabled:
            scene.subtitle === '' ||
            scenesNumber === PROJECT_RULE[plan].maxParagraphsNumber ||
            (animateStatus & AnimationType.isAnimating) ===
              AnimationType.isAnimating,
          onClick: () => onAction('split_scene', sceneId),
        },
        {
          icon: MergePrevIcon,
          type: 'merge_prev_scene',
          isDisabled:
            !prevScene ||
            (animateStatus & AnimationType.isAnimating) ===
              AnimationType.isAnimating ||
            (animateStatus & AnimationType.prevIsAnimating) ===
              AnimationType.prevIsAnimating,
          onClick: () => onAction('merge_prev_scene', sceneId),
        },
        {
          icon: MergeNextIcon,
          type: 'merge_next_scene',
          isDisabled:
            !nextScene ||
            (animateStatus & AnimationType.isAnimating) ===
              AnimationType.isAnimating ||
            (animateStatus & AnimationType.nextIsAnimating) ===
              AnimationType.nextIsAnimating,
          onClick: () => onAction('merge_next_scene', sceneId),
        },
        {
          icon: DeleteIcon,
          type: 'delete_scene',
          isDisabled:
            (!nextScene && !prevScene) ||
            scene.task?.status === 'success' ||
            (animateStatus & AnimationType.isAnimating) ===
              AnimationType.isAnimating,
          onClick: () => onAction('delete_scene', sceneId),
        },
      ].filter(item => !disabledActions.includes(item.type as ActionType)),
    [
      animateStatus,
      nextScene,
      onAction,
      plan,
      prevScene,
      scene.subtitle,
      scene.task?.status,
      sceneId,
      scenesNumber,
      disabledActions,
    ]
  );
  const [currentToolTipType, setCurrentToolTipType] = useState<
    Extract<ActionType, 'split_scene'> | InsertType | null
  >(null);

  const isInsertDisabled = useMemo(
    () => scenesNumber >= PROJECT_RULE[plan].maxParagraphsNumber,
    [plan, scenesNumber]
  );

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles['insert-box'], styles.top)}
        onMouseEnter={() =>
          isInsertDisabled && setCurrentToolTipType('insert_before')
        }
        onMouseLeave={() => isInsertDisabled && setCurrentToolTipType(null)}
      >
        <IconButton
          Icon={InsertIcon}
          className={classNames(styles.insert, styles['insert-before'])}
          disabled={isInsertDisabled}
          onClick={e => {
            e.stopPropagation();
            onAction('insert_before', sceneId);
          }}
          iconType="stroke"
          hotspotSize={24}
          iconSize={16}
        />
        <ToolTip
          content={`Function not available with maximum ${PROJECT_RULE[plan].maxParagraphsNumber} scenes.`}
          isShowing={currentToolTipType === 'insert_before'}
        />
      </div>

      <ul className={styles['action-list']}>
        {actions.map(({icon, isDisabled, type, onClick}, index) => {
          return (
            <li
              key={index}
              className={styles.item}
              onMouseEnter={() =>
                type === 'split_scene' &&
                scenesNumber === PROJECT_RULE[plan].maxParagraphsNumber &&
                setCurrentToolTipType('split_scene')
              }
              onMouseLeave={() =>
                scenesNumber === PROJECT_RULE[plan].maxParagraphsNumber &&
                setCurrentToolTipType(null)
              }
            >
              {type === 'split_scene' && (
                <ToolTip
                  content={`Function not available with maximum ${PROJECT_RULE[plan].maxParagraphsNumber} scenes.`}
                  isShowing={currentToolTipType === 'split_scene'}
                />
              )}
              <IconButton
                Icon={icon}
                onClick={e => {
                  e.stopPropagation();
                  onClick();
                }}
                disabled={isDisabled}
                iconSize={18}
                hotspotSize={24}
                className={styles.button}
              />
            </li>
          );
        })}
      </ul>
      <div
        className={classNames(styles['insert-box'], styles.bottom)}
        onMouseEnter={() =>
          isInsertDisabled && setCurrentToolTipType('insert_after')
        }
        onMouseLeave={() => isInsertDisabled && setCurrentToolTipType(null)}
      >
        <IconButton
          Icon={InsertIcon}
          className={classNames(styles.insert, styles['insert-after'])}
          onClick={e => {
            e.stopPropagation();
            onAction('insert_after', sceneId);
          }}
          iconType="stroke"
          iconSize={16}
          disabled={isInsertDisabled}
          hotspotSize={24}
        />
        <ToolTip
          content={`Function not available with maximum ${PROJECT_RULE[plan].maxParagraphsNumber} scenes.`}
          isShowing={currentToolTipType === 'insert_after'}
        />
      </div>
    </div>
  );
}
