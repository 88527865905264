// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import styles from './List.module.scss';

interface ListProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  size?: 'default' | 'large' | 'small';
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // 接受任何额外的属性
}

export const List: React.FC<ListProps> = ({
  header,
  footer,
  size = 'default',
  children,
  className,
  ...props
}: ListProps) => {
  return (
    <div
      className={`${styles.list} ${styles[size] || ''} ${className || ''}`}
      {...props}
    >
      {header && <div className={styles.header}>{header}</div>}
      <ul className={styles.listContent}>{children}</ul>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};
