// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {saveLog} from 'api/server';
import {ReactComponent as MootionSvg} from 'assets/svg/3.0/LeftAsideMootion.svg';
import {noop} from 'lodash';
import {SystemErrorDialog} from 'pages/components/SystemError';
import {useEffect} from 'react';
import {useLocation, useRouteError} from 'react-router-dom';
import {WORKSPACE_PAGE_GENERATE_PATH} from 'utils/path';

import styles from './ErrorPage.module.scss';

// Format error object to a readable object
function formatObject(
  obj: object,
  deep = 1,
  maxDeep = 3
): Record<string, unknown> {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        typeof value === 'object'
          ? value === null
            ? 'null'
            : deep < maxDeep
            ? formatObject(value, deep + 1, maxDeep)
            : value.toString()
          : value,
    }),
    {constructor: obj.constructor.name}
  );
}

function formatError(error: unknown): string {
  try {
    if (error instanceof Error) {
      return `${error.name}: ${error.message}\n${error.stack ?? ''}\n${
        error.cause ?? ''
      }`;
    }
    if (typeof error !== 'object' || error === null) {
      return `${error}`;
    }
    return JSON.stringify(formatObject(error));
  } catch (e) {
    return `Format error failed: ${e}`;
  }
}

export function ErrorPage() {
  const error = useRouteError();
  const location = useLocation();

  useEffect(() => {
    const message = formatError(error);
    saveLog(
      'exceptions',
      `Device: ${navigator.userAgent}\nPath: ${location.pathname}\n${message}`
    );
  }, [error, location.pathname]);

  return (
    <div className={styles.container}>
      <MootionSvg className={styles.logo} />
      <SystemErrorDialog
        onCancel={() => (window.location.href = WORKSPACE_PAGE_GENERATE_PATH)}
        onCloseDialog={noop}
        dialogShowing={true}
      />
    </div>
  );
}
