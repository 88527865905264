// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ResourceContext, StyleData} from 'contexts/ResourceManager.type';
import {Task} from 'modules/ai-frontend/models/Task';
import {PollingCallback} from 'modules/ai-frontend/services';
import {
  TaskParams as AFTaskParams,
  TaskType as AFTaskType,
  UploadFormTarget as AFUploadFormTarget,
} from 'modules/ai-frontend/types';

export type UploadFormTarget = Extract<
  AFUploadFormTarget,
  'remove_image_bg' | 'style_transfer'
>;
export type TaskType = AFTaskType.RemoveImageBG | AFTaskType.StyleTransfer;
export type TaskData<T extends TaskType> = AFTaskParams<T> extends {
  data: infer D;
}
  ? D
  : never;

export type AssetActionType = 'styleTransfer' | 'rmBg' | 'crop';
export type AspectRatioType = number | undefined;
export type EditAssetProps = {
  type: AssetActionType;
  imageInfo?: [string, boolean];
  filename: string;
  imageSize: [number, number];
  onOk?: (imageUrl: string) => void;
  onReset?: () => void;
  onApply: () => void;
  handleApply: ({
    file,
    blobImageUrl,
    needUpload,
  }: {
    file: File;
    blobImageUrl: string;
    needUpload: boolean;
  }) => void;
  onChangeCurrentImageUrl: (url: string, isTransfer?: boolean) => void;
  onChangeActionType?: (e: React.MouseEvent<HTMLDivElement>) => void;
  uploadAsset: (
    target: UploadFormTarget,
    base64Image: string
  ) => Promise<string>;
  executeTask: <T extends TaskType>(
    type: T,
    data: TaskData<T>,
    timeout?: number
  ) => Promise<Task<T>>;
  uploadToAssetLibrary: (
    blobImageUrl: string,
    fileName: string
  ) => Promise<void>;
  changeFileName: (fileName: string) => void;
  errorToastVisible?: boolean;
  errorToastType?: ErrorType;
  hideErrorToast?: () => void;
};
export type EditAssetFooterProps = Pick<
  EditAssetProps,
  'onReset' | 'onApply'
> & {
  onDownload: () => void;
  showResetAll: boolean;
};
export type EditAssetHookProp = Pick<
  EditAssetProps,
  'type' | 'handleApply' | 'onReset'
> & {
  filename: string;
  imageWidth: number;
  imageHeight: number;
  imageUrl: string;
};
export type EditAssetHookReturn = Pick<
  EditAssetProps,
  | 'onReset'
  | 'onApply'
  | 'imageInfo'
  | 'onChangeCurrentImageUrl'
  | 'filename'
  | 'uploadAsset'
  | 'executeTask'
  | 'changeFileName'
  | 'imageSize'
>;
export type StyleTransferPanelProps = {
  handleTransferStyle: (style: string) => void;
  styleList: ReturnType<ResourceContext['getStyles']>;
  resUrl: string;
  style: string;
  setStyle: (style: string) => void;
};

export type RemoveBgPanelProps = {
  handleChangeFillBgColor: (color: string) => void;
  handleRemoveBg: () => void;
  fillColor: string | undefined;
};
export type CropPanelProps = {
  aspectRatio: AspectRatioType;
  handleChangeAspectRatio: (ratio: AspectRatioType) => void;
  confirmCrop: () => void;
};
export type PreviewImageProps = Pick<EditAssetProps, 'imageInfo' | 'type'>;

export type AssetToolAction = Exclude<AssetActionType, 'crop'>;
export type AssetToolProps = {
  type: AssetToolAction;
  handleClick: (type: AssetToolAction) => void;
};
export type HandleStyleStranferProps = {
  base64Image: string;
  styleInfo: StyleData;
  successCallback?: (newBase64Url: string) => void;
  failCallback?: () => void;
  resUrl: string;
};

export type HandleRemoveBgProps = {
  base64Image: string;
  successCallback?: (newBase64Url: string) => void;
  failCallback?: () => void;
};
export type TaskInfo<T extends TaskType> = {
  id: string;
  taskId?: string;
  task?: Parameters<PollingCallback<T>>[2];
  successCallback?: (...args: any[]) => any;
  failCallback?: () => void;
};
export enum ErrorType {
  Capacity = 'Capacity',
  UploadFailed = 'UploadFailed',
}
