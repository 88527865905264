// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import autosize from 'autosize';
import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';

import styles from './Textarea.module.scss';
import {Props} from './Textarea.types';

export function Textarea(props: Props) {
  const [isFocus, setIsFocus] = useState(props.autoFocus || false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const dom = textAreaRef.current;
    if (dom) {
      setTimeout(() => {
        autosize(dom);
      }, 0);
    }
    return () => {
      if (dom) {
        autosize.destroy(dom);
      }
    };
  }, [textAreaRef]);
  return (
    <div
      className={classNames(
        styles.container,
        isFocus && styles.focus,
        'text-area-wrap'
      )}
    >
      <textarea
        {...props}
        rows={1}
        className={classNames(styles.textarea, props.className)}
        onFocus={e => {
          setIsFocus(true);
          props.onFocus?.(e);
        }}
        onBlur={e => {
          setIsFocus(false);
          props.onBlur?.(e);
        }}
        ref={textAreaRef}
      />
    </div>
  );
}
