// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {getBillingPagePath} from 'api/payment';
import {ReactComponent as LockIcon} from 'assets/svg/3.0/Lock.svg';
import {ReactComponent as InfoIcon} from 'assets/svg/outline/Info.svg';
import {ReactComponent as WrongIcon} from 'assets/svg/outline/Plus2.svg';
import {ReactComponent as RightIcon} from 'assets/svg/outline/Tick.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useUserContext} from 'contexts/UserContext';
import {convertUTCToLocaleDateString} from 'pages/common/date';
import {Button} from 'pages/components/Button';
import {Toast} from 'pages/components/Toast';
import {ToggleSwitch} from 'pages/components/ToggleSwitch';
import {useCallback} from 'react';
import {Outlet} from 'react-router-dom';

import {BillingCard} from './BillingCard';
import {
  creditsPerMonthByLevel,
  PerMonthChargeMap,
  ToggleOptions,
} from './constData';
import {CreditCard} from './CreditCard';
import {getButtonProps} from './Plan.container';
import styles from './Plan.module.scss';
import {
  FeaturesOfLevelMapType,
  PlanListProps,
  PlanPageProps,
  TypeStateEnum,
} from './Plan.types';
import {SubscriptionCard} from './SubscriptionCard';

export const FeaturesOfLevelMap: FeaturesOfLevelMapType = {
  Free: [
    [
      {
        text: '20,000 characters prompt',
      },
    ],
    [
      {
        text: '16 scenes maximum per project',
      },
    ],
    [
      {
        text: '6 visual styles',
      },
    ],
    [
      {
        text: 'Limited voiceovers',
      },
    ],
    [
      {
        notAvailable: true,
        text: 'Watermark free',
      },
    ],
    [
      {
        notAvailable: true,
        text: 'Priority generation',
      },
      {
        text: (
          <Popover
            triggerElement={<InfoIcon className={styles['info-icon']} />}
            trigger={'hover'}
            direction="top"
            distance={7}
            style={{padding: '6px 8px', borderRadius: '4px'}}
            content={
              <div className={styles['tip-content']}>
                Your task will be given priority when more users are generating
                tasks
              </div>
            }
          />
        ),
        isIcon: true,
      },
    ],
    [
      {
        text: '1GB asset upload',
      },
    ],
    [
      {
        text: 'Precise pose & motion control',
      },
    ],
    [{text: 'Video clip generation'}],
    [{text: '1080p'}],
    [{text: 'General commercial license'}],
  ],
  Standard: [
    [
      {
        text: '20,000 characters prompt',
      },
    ],
    [
      {
        text: '16 scenes maximum per project',
      },
    ],
    [
      {
        text: '10+ ',
        className: styles.colorText,
      },
      {
        text: 'visual styles',
      },
    ],
    [{text: 'More realistic voiceovers'}],
    [
      {
        text: 'Watermark free',
      },
    ],
    [
      {
        text: 'Priority generation',
      },
      {
        text: (
          <Popover
            triggerElement={<InfoIcon className={styles['info-icon']} />}
            trigger={'hover'}
            direction="top"
            distance={7}
            style={{padding: '6px 8px', borderRadius: '4px'}}
            content={
              <div className={styles['tip-content']}>
                Your task will be given priority when more users are generating
                tasks
              </div>
            }
          />
        ),
        isIcon: true,
      },
    ],
    [
      {
        text: '10GB',
        className: styles.colorText,
      },
      {
        text: ' asset upload',
      },
    ],
    [
      {
        text: 'Precise pose & motion control',
      },
    ],
    [{text: 'Bulk video clip generation'}],
    [{text: '1080p'}],
    [{text: 'General commercial license'}],
  ],
  Pro: [
    [
      {
        text: '50,000',
        className: styles.colorText,
      },
      {
        text: ' characters prompt',
      },
    ],
    [
      {
        text: '32',
        className: styles.colorText,
      },
      {
        text: ' scenes maximum per project',
      },
    ],
    [
      {
        text: '10+',
        className: styles.colorText,
      },
      {
        text: ' visual styles',
      },
    ],
    [{text: 'All voiceovers'}],
    [
      {
        text: 'Watermark free',
      },
    ],
    [
      {
        text: 'Priority generation',
      },
      {
        text: (
          <Popover
            triggerElement={<InfoIcon className={styles['info-icon']} />}
            trigger={'hover'}
            direction="top"
            distance={7}
            style={{padding: '6px 8px', borderRadius: '4px'}}
            content={
              <div className={styles['tip-content']}>
                Your task will be given priority when more users are generating
                tasks
              </div>
            }
          />
        ),
        isIcon: true,
      },
    ],
    [
      {
        text: '100GB',
        className: styles.colorText,
      },
      {
        text: ' asset upload',
      },
    ],
    [
      {
        text: 'Precise pose & motion control',
      },
    ],
    [{text: 'Bulk video clip generation'}],
    [{text: '1080p'}],
    [{text: 'General commercial license'}],
  ],
};
export const planList: PlanListProps = {
  Monthly: [
    {
      title: 'Free',
      perMonthCharge: PerMonthChargeMap['Monthly']['Free'],
      creditsPerMonth: creditsPerMonthByLevel['Free'],
      creditsPerMonthText: "Can't buy more credits",
      creditsPerMonthTextIsLocked: true,
      features: FeaturesOfLevelMap['Free'],
      productId: 0,
    },
    {
      title: 'Standard',
      flag: 'Popular',
      perMonthCharge: PerMonthChargeMap['Monthly']['Standard'],
      creditsPerMonth: creditsPerMonthByLevel['Standard'],
      creditsPerMonthText: 'Buy more credits as you need',
      creditsPerMonthTextIsLocked: false,
      features: FeaturesOfLevelMap['Standard'],
      productId: 3,
    },
    {
      title: 'Pro',
      perMonthCharge: PerMonthChargeMap['Monthly']['Pro'],
      creditsPerMonth: creditsPerMonthByLevel['Pro'],
      creditsPerMonthText: 'Buy more credits as you need',
      creditsPerMonthTextIsLocked: false,
      features: FeaturesOfLevelMap['Pro'],
      productId: 5,
    },
  ],
  Yearly: [
    {
      title: 'Free',
      perMonthCharge: PerMonthChargeMap['Yearly']['Free'],
      creditsPerMonth: creditsPerMonthByLevel['Free'],
      creditsPerMonthText: "Can't buy more credits",
      creditsPerMonthTextIsLocked: true,
      features: FeaturesOfLevelMap['Free'],
      productId: 0,
    },
    {
      title: 'Standard',
      flag: 'Popular',
      perMonthCharge: PerMonthChargeMap['Yearly']['Standard'],
      perMonthChargeOfMonthly: PerMonthChargeMap['Monthly']['Standard'],
      perYearlyCharge: 96,
      creditsPerMonth: creditsPerMonthByLevel['Standard'],
      creditsPerMonthText: 'Buy more credits as you need',
      creditsPerMonthTextIsLocked: false,
      features: FeaturesOfLevelMap['Standard'],
      productId: 4,
    },
    {
      title: 'Pro',
      perMonthCharge: PerMonthChargeMap['Yearly']['Pro'],
      perMonthChargeOfMonthly: PerMonthChargeMap['Monthly']['Pro'],
      perYearlyCharge: 384,
      creditsPerMonth: creditsPerMonthByLevel['Pro'],
      creditsPerMonthText: 'Buy more credits as you need',
      creditsPerMonthTextIsLocked: false,
      features: FeaturesOfLevelMap['Pro'],
      productId: 6,
    },
  ],
};

export function PlanPage({
  typeState,
  setTypeState,
  currentPlanInfo,
  onAction,
  downgradeProductId,
  upgradeProductId,
  onDowngradeConfirm,
  clearActionProductId,
  onUpgradeConfirm,
  userInfo,
  isOnAction,
}: PlanPageProps) {
  const {activeProductId, subscriptionProductId} = useUserContext();

  const openBillingPage = useCallback(async () => {
    const {data: url} = await getBillingPagePath();
    window.open(url, '_blank');
  }, []);

  if (!typeState || !currentPlanInfo) return null;

  return (
    <div className={styles.container}>
      <h1 className={styles.containerTitle} id="choosePlan">
        Subscription
      </h1>
      <div className={styles.head}>
        <SubscriptionCard
          credit={userInfo.credit}
          creditPack={userInfo.creditPackAmount}
          creditAmount={userInfo.planCreditAmount}
          activeProductId={activeProductId}
          subscriptionProductId={subscriptionProductId}
          renewalDate={
            userInfo.periodEnd
              ? convertUTCToLocaleDateString(new Date(userInfo.periodEnd))
              : ''
          }
        />
        <BillingCard
          activeProductId={activeProductId}
          subscriptionProductId={subscriptionProductId}
          renewalDate={
            userInfo.periodEnd
              ? convertUTCToLocaleDateString(new Date(userInfo.periodEnd))
              : ''
          }
          onEditBilling={openBillingPage}
        />
        <CreditCard
          activeProductId={activeProductId}
          onBuyCredits={productId => onAction('Subscription', productId)}
        />
      </div>
      <ToggleSwitch
        options={ToggleOptions}
        activeIndex={typeState === 'Monthly' ? 0 : 1}
        onToggle={index => {
          setTypeState(ToggleOptions[index].label as TypeStateEnum);
        }}
        className={styles.toggleSwitch}
        totalWidth={248}
      />
      <div className={styles.planOptionWrap}>
        {planList[typeState].map(item => {
          const buttonProps = getButtonProps({
            userLevel: currentPlanInfo.level,
            userType: currentPlanInfo.type,
            currentLevel: item.title,
            currentType: typeState,
          });
          return (
            <div
              className={classNames(styles.planItem, {
                [styles.active]:
                  item.title === 'Standard' && currentPlanInfo.level === 'Free',
              })}
              key={item.title}
            >
              <div className={styles.top}>
                <div className={styles.title}>
                  {item.title}
                  {item.flag && <div className={styles.flag}>{item.flag}</div>}
                </div>
                <div className={styles.price}>
                  <div className={styles.priceText}>
                    <span className={styles.perMonthCharge}>
                      {item.perMonthCharge === 0
                        ? 'Free forever'
                        : '$' + item.perMonthCharge}
                    </span>
                    {item.title !== 'Free' && (
                      <div className={styles.append}>
                        <span>
                          {typeState === 'Yearly' &&
                          item.perMonthChargeOfMonthly !== undefined ? (
                            <span className={styles.sourcePrice}>
                              ${item.perMonthChargeOfMonthly}
                            </span>
                          ) : null}
                          /month
                        </span>
                        {typeState === 'Yearly' && (
                          <span>Billed yearly as ${item.perYearlyCharge}</span>
                        )}
                        {typeState === 'Monthly' && <span>Billed monthly</span>}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.creditsInfo}>
                  <div className={styles.creditsPerMonth}>
                    {item.creditsPerMonth} credits/month
                  </div>
                  <div className={styles.tip}>
                    {item.creditsPerMonthTextIsLocked ? <LockIcon /> : null}
                    {item.creditsPerMonthText}
                  </div>
                </div>
              </div>

              <Button
                size="large"
                disabled={isOnAction}
                className={styles.buttonSection}
                {...buttonProps}
                onClick={() => {
                  if (buttonProps.children === 'Current plan') {
                    return;
                  }
                  if (buttonProps.children === 'Upgrade') {
                    if (currentPlanInfo.level === 'Free') {
                      onAction('Subscription', item.productId);
                    } else {
                      onAction('Upgrade', item.productId);
                    }
                  } else {
                    onAction('Downgrade', item.productId);
                  }
                }}
              />

              <div className={styles.bottomTips}>
                {FeaturesOfLevelMap[item.title].map((feature, index) => {
                  return (
                    <div className={styles.tipItem} key={index}>
                      {feature.some(item => item.notAvailable) ? (
                        <WrongIcon
                          className={classNames(
                            styles.icon,
                            styles.notAvailable
                          )}
                        />
                      ) : (
                        <RightIcon className={styles.icon} />
                      )}
                      <span className={styles.textWrap}>
                        {feature.map((item, index) => {
                          return item.isIcon ? (
                            item.text
                          ) : (
                            <span
                              className={
                                item.className ? item.className : styles.text
                              }
                              key={index}
                            >
                              {item.text}
                            </span>
                          );
                        })}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Outlet />
      {(upgradeProductId !== null || downgradeProductId !== null) && (
        <Toast
          title={
            downgradeProductId !== null
              ? 'Are you sure to downgrade'
              : 'Upgrade for more'
          }
          visible={upgradeProductId !== null || downgradeProductId !== null}
          onOk={() => {
            if (downgradeProductId !== null) {
              onDowngradeConfirm();
            } else if (upgradeProductId !== null) {
              onUpgradeConfirm();
            }
          }}
          onCancel={() =>
            clearActionProductId(
              downgradeProductId !== null ? 'Downgrade' : 'Upgrade'
            )
          }
          showCloseBtn={false}
          btnTheme="primary"
          cancelText="Cancel"
          confirmText={
            downgradeProductId !== null
              ? 'Confirm downgrade'
              : 'Confirm upgrade'
          }
          className={styles.downgradeToast}
        >
          {upgradeProductId !== null &&
            'You will be charged and switch to new plan instantly.'}
          {downgradeProductId !== null &&
            'We’ll keep current plan active until the end of this subscription period?'}
        </Toast>
      )}
    </div>
  );
}
