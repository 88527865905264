// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  SerializedTextNode,
  TextNode,
} from 'lexical';

type SerializedLimitedTextNode = SerializedTextNode;

export class LimitedTextNode extends TextNode {
  static getType(): string {
    return 'limited-text';
  }

  static clone(node: LimitedTextNode): LimitedTextNode {
    const ret = new LimitedTextNode(node.__text, node.__key);
    ret.dom = node.dom;
    ret.config = node.config;
    ret.isError = node.isError;
    return ret;
  }

  constructor(text: string, key?: string) {
    super(text, key);
  }

  private dom: HTMLElement | null = null;
  private config: EditorConfig | null = null;
  private isError = false;

  createDOM(
    config: EditorConfig,
    _editor?: LexicalEditor | undefined
  ): HTMLElement {
    const span = document.createElement('span');
    this.dom = span;
    this.config = config;
    const theme = config.theme;
    const className = theme.limitedTextNode?.base;
    if (className !== undefined) {
      span.classList.add(className);
    }
    if (theme.limitedTextNode) {
      this.dom.classList.toggle(theme.limitedTextNode.overrun, this.isError);
    }
    span.textContent = this.__text;
    return span;
  }

  hasError() {
    return this.isError;
  }

  setIsError(isError: boolean): void {
    const self = this.getWritable();
    self.isError = isError;
    if (!this.dom || !this.config) return;
    const theme = this.config.theme;
    if (theme.limitedTextNode) {
      this.dom.classList.toggle(theme.limitedTextNode.overrun, isError);
    }
  }

  static importJSON(
    serializedNode: SerializedLimitedTextNode
  ): LimitedTextNode {
    return new LimitedTextNode(serializedNode.text);
  }

  exportJSON(): SerializedLimitedTextNode {
    return {
      ...super.exportJSON(),
      type: LimitedTextNode.getType(),
    };
  }
}

export function $createLimitedText(text: string): LimitedTextNode {
  return new LimitedTextNode(text);
}

export function $isLimitedTextNode(
  node: LexicalNode | null | undefined
): node is LimitedTextNode {
  return node instanceof LimitedTextNode;
}
