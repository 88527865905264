// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ErrorType} from 'contexts/ErrorContext';
import {useUserContext} from 'contexts/UserContext';
import {Toast} from 'pages/components/Toast';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'utils/path';

import {Props, ToastInfo} from './ErrorCatcher.types';

export function ErrorCatcher({children, error, clear}: Props) {
  const navigate = useNavigate();
  const [toastInfo, setToastInfo] = useState<ToastInfo | null>(null);
  const {isPaidUser} = useUserContext();

  useEffect(() => {
    if (error) {
      if (error.type === ErrorType.NoCredit) {
        setToastInfo({
          title: 'No Credit',
          content: isPaidUser
            ? 'Sorry, all credits have been depleted. You can recharge now.'
            : 'Sorry, all credits have been depleted. Subscribe to continue creation.',
          confirmText: isPaidUser ? 'Recharge' : 'Subscribe',
          onOk: () => {
            navigate(PLAN_PAGE_PATH);
            clear();
          },
        });
      } else if (
        error.type === ErrorType.Service ||
        error.type === ErrorType.CapacityLimiting
      ) {
        setToastInfo({
          title: 'Notice',
          content: 'System error. Please try again.',
          showCancel: false,
          confirmText: 'OK',
          onOk: () => {
            error.callback && error.callback();
            clear();
          },
        });
      } else if (error.type === ErrorType.Network) {
        setToastInfo({
          title: 'Notice',
          content: 'Please check your connection.',
          showCancel: false,
          confirmText: 'OK',
          onOk: () => {
            error.callback && error.callback();
            clear();
          },
        });
      } else {
        setToastInfo(null);
      }
    } else {
      setToastInfo(null);
    }
  }, [clear, error, navigate, isPaidUser]);
  return (
    <>
      {children}
      {error && toastInfo && (
        <Toast
          title={toastInfo.title}
          visible={true}
          showCloseBtn={false}
          onOk={toastInfo.onOk || clear}
          onCancel={clear}
          showCancel={toastInfo.showCancel}
          confirmText={toastInfo.confirmText}
        >
          {toastInfo.content}
        </Toast>
      )}
    </>
  );
}
