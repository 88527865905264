// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import {AxiosResponse} from 'axios';
import {combine} from 'components/Combine';
import {clearUserToken} from 'contexts/localStorage';
import {EMAIL_REG} from 'lib/auth';
import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CHECK_EMAIL_PATH, LOGIN_PATH} from 'utils/path';

import {
  EmailErrorType,
  HookParams,
  ResetPasswordPropsHookReturn,
} from './AuthForm.types';
import {AuthFormResetPassword} from './AuthFormResetPassword';

export function useHooks({
  setErrorToastType,
  showErrorToast,
}: HookParams): ResetPasswordPropsHookReturn {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [emailErrorType, setEmailErrorType] = useState<EmailErrorType | null>(
    null
  );
  const validEmail = useCallback(
    async (callbackFun?: () => void) => {
      const isValid = EMAIL_REG.test(email);

      if (isValid && emailErrorType === 'EMAIL_INVALID') {
        setEmailErrorType(null);
      } else if (!isValid) {
        setEmailErrorType('EMAIL_INVALID');
      }
      if (isValid && callbackFun) {
        callbackFun();
      }
      return isValid;
    },
    [email, emailErrorType]
  );

  const onConfirm = useCallback(async () => {
    validEmail(() => {
      apiServer
        .sendEmail(email)
        .then(() => {
          //点击后退出登录，避免验证邮箱跳转回来时，用户处于已经登录状态
          clearUserToken();
          navigate(CHECK_EMAIL_PATH, {state: {email}, replace: true});
        })
        .catch((err: AxiosResponse<apiServer.Response>) => {
          const {error, message} = err.data;
          if (!error && Array.isArray(message)) {
            setEmailErrorType('EMAIL_INVALID');
          } else if (error === apiServer.ResponseCode.USER_NOT_FOUND) {
            setEmailErrorType('USER_NOT_FOUND');
          } else if (error === apiServer.ResponseCode.USER_EMAIL_NO_PASSWORD) {
            //Toast提示后，点击OK，回到登录页
            setErrorToastType('cannotResetPassword');
            showErrorToast(true, '', () => {
              navigate(LOGIN_PATH);
            });
          }
          return;
        });
    });
  }, [email, navigate, setErrorToastType, showErrorToast, validEmail]);

  const changeEmail = useCallback(
    (value: string) => {
      if (emailErrorType && EMAIL_REG.test(value)) {
        setEmailErrorType(null);
      }
      setEmail(value);
    },
    [emailErrorType]
  );

  return {
    email,
    emailErrorType,
    validEmail,
    setEmail: changeEmail,
    onConfirm,
  };
}

export const AuthFormResetPasswordContainer = combine(useHooks, [
  'setErrorToastType',
  'showErrorToast',
])(AuthFormResetPassword);
