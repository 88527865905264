// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import styles from './NotificationBox.module.scss';
import {Props} from './NotificationBox.types';
import {NotificationBubble} from './NotificationBubble';

export function NotificationBox({
  notifications,
  onRemove,
  displayingDuration = 2000,
  dismissingDuration = 800,
}: Props) {
  return (
    <ul className={styles.container}>
      {notifications.map(notification => (
        <NotificationBubble
          key={notification.index}
          notification={notification}
          onRemove={onRemove}
          displayingDuration={displayingDuration}
          dismissingDuration={dismissingDuration}
        />
      ))}
    </ul>
  );
}
