// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';
import {SetStateAction, useCallback, useEffect, useRef, useState} from 'react';

import {create, fromJSON, Preference} from '../models/Preference';

const DEFAULT_DELAY = 10000;

export function usePreference(
  userId: string,
  loadPreference: () => Promise<Record<string, unknown>>,
  savePreference: (preference: Record<string, unknown>) => Promise<void>
) {
  const [data, setData] = useState<{
    preference: Partial<{[P in ProjectType]: Preference<P>}>;
    delay?: number | null;
  }>({preference: {}});

  const pendingSync = useRef<
    [NodeJS.Timeout, Partial<{[P in ProjectType]: Preference<P>}>] | null
  >(null);

  const checkoutPreference = useCallback(
    <T extends ProjectType>(type: T): Preference<T> => {
      return data.preference[type] ?? create(type);
    },
    [data.preference]
  );

  const updatePreference = useCallback(
    // delay is a number (in milliseconds) or a boolean or undefined (no sync)
    <T extends ProjectType>(
      type: T,
      state: SetStateAction<Preference<T>>,
      delay?: number | boolean
    ) => {
      setData(prev => {
        const {preference} = prev;
        const currentValue = preference[type] ?? create(type);
        const nextValue =
          typeof state === 'function' ? state(currentValue) : state;
        return {
          preference:
            nextValue === currentValue
              ? preference
              : {...preference, [type]: nextValue},
          ...(delay === undefined
            ? {}
            : {
                delay:
                  delay === false
                    ? null
                    : Math.max(delay === true ? DEFAULT_DELAY : delay, 0),
              }),
        };
      });
    },
    []
  );

  useEffect(() => {
    const {preference, delay} = data;
    if (Object.keys(preference).length === 0 || delay === undefined) return;

    if (pendingSync.current) {
      const [timer] = pendingSync.current;
      clearTimeout(timer);
      pendingSync.current = null;
    }

    if (delay) {
      pendingSync.current = [
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const [, preference] = pendingSync.current!;
          savePreference(preference);
          pendingSync.current = null;
        }, delay),
        preference,
      ];
    } else {
      savePreference(preference);
    }
  }, [savePreference, data]);

  useEffect(() => {
    if (!userId) {
      setData({preference: {}});
    } else {
      loadPreference().then(preference => {
        setData({preference: fromJSON(preference)});
      });
    }
    return () => {
      if (!pendingSync.current) return;
      const [timer, preference] = pendingSync.current;
      clearTimeout(timer);
      savePreference(preference);
      pendingSync.current = null;
    };
  }, [loadPreference, savePreference, userId]);

  return {
    checkoutPreference,
    updatePreference,
  };
}
