// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {getGalleryById} from 'api/server';
import {ReactComponent as DownloadIcon} from 'assets/svg/3.0/Download.svg';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow.svg';
import classNames from 'classnames';
import {ResultPreviewer} from 'components/ResultPreviewer';
import {download} from 'lib/download';
import {fromJSON} from 'modules/project/models/Project';
import {
  GeneralStoryProjectJSON,
  ProjectJSON,
  ProjectType,
} from 'modules/project/types';
import {convertProjectVersion, getSceneImage} from 'modules/project/utils';
import {BilingualDialogueStoryboard} from 'modules/storyboard/models/BilingualDialogueStoryboard';
import {BilingualStoryStoryboard} from 'modules/storyboard/models/BilingualStoryStoryboard';
import {Button} from 'pages/components/Button';
import {LoadingPrompt} from 'pages/components/LoadingPrompt';
import {useCallback, useEffect, useMemo, useState} from 'react';

import styles from './GalleryPreviewer.module.scss';
import {Props} from './GalleryPreviewer.types';

function transferHistoryData(gallery: Record<string, any>) {
  const lastHistoryItem =
    gallery.resultHistory?.[gallery.resultHistory.length - 1];
  const newStoryboard = lastHistoryItem?.storyboard ?? gallery.storyboard;
  return {
    ...gallery,
    storyboard: {
      ...(gallery.storyboard || {}),
      title: newStoryboard?.title || '',
      description: newStoryboard?.description || '',
      scenes: newStoryboard?.scenes || [],
      tags: newStoryboard?.tags || [],
    },
    language: lastHistoryItem?.language ?? gallery.language,
    size: lastHistoryItem?.size ?? gallery.size,
    result: {
      taskId: gallery.result?.taskId || '',
      thumbnail:
        lastHistoryItem?.thumbnail ??
        (newStoryboard && getSceneImage(newStoryboard.scenes[0])),
      videoPath:
        lastHistoryItem?.video || (gallery.result?.videoPath as string),
    },
  };
}

export function GalleryPreviewer({galleryId, onUseGallery}: Props) {
  const [gallery, setGallery] = useState<ProjectJSON<ProjectType> | null>(null);
  const project = useMemo(() => {
    if (gallery === null) return null;
    return fromJSON(gallery);
  }, [gallery]);

  const initGallery = useCallback(async () => {
    if (!galleryId) return;
    const gallery = await getGalleryById(galleryId);
    if (gallery) {
      const newGallery = convertProjectVersion(
        transferHistoryData(gallery) as unknown as ProjectJSON<ProjectType> //todo 整个模块都要改成通用类，先做其他的，后面再改
      );
      setGallery({...newGallery, histories: []} as GeneralStoryProjectJSON); //todo 整个模块都要改成通用类，先做其他的，后面再改
    }
  }, [galleryId]);

  useEffect(() => {
    if (gallery === null) {
      initGallery();
    }
  }, [initGallery, gallery]);

  return (
    <div className={styles.container}>
      <p className={styles.title}>Gallery</p>
      <div
        className={classNames(
          styles.content,
          project === null && styles.loading
        )}
      >
        {project && (
          <ResultPreviewer
            title={project.storyboard?.title || ''}
            language={project.language}
            detailWidth={580}
            description={project.storyboard?.description || ''}
            size={project.size}
            hashtags={project.storyboard?.hashtags || []}
            video={project.composition?.asset || ''}
            prompt={project.storyboard?.prompt || ''}
            scenes={(project.storyboard?.scenes || []).map(scene =>
              scene.toClosed()
            )}
            nativeLanguage={
              project.storyboard instanceof BilingualStoryStoryboard ||
              project.storyboard instanceof BilingualDialogueStoryboard
                ? project.storyboard?.nativeLanguage
                : undefined
            }
          />
        )}
        {project === null && <LoadingPrompt type="processing" />}
      </div>
      {project && gallery && (
        <div className={styles['button-box']}>
          <Button
            className={classNames(styles.button, styles.download)}
            type="ghost"
            onClick={() =>
              download(
                project.composition?.thumbnail || '',
                project.storyboard?.title || ''
              )
            }
          >
            <DownloadIcon className={styles.icon} />
            <span>Thumbnail(JPG)</span>
          </Button>
          <Button
            className={classNames(styles.button, styles.download)}
            type="ghost"
            onClick={() =>
              download(
                project.composition?.asset || '',
                project.storyboard?.title || ''
              )
            }
          >
            <DownloadIcon className={styles.icon} />
            <span>Video(MP4)</span>
          </Button>
          <Button
            className={classNames(styles.button, styles.use)}
            onClick={() => onUseGallery(gallery)}
            size="large"
          >
            Use <RightArrowIcon className={styles.icon} />
          </Button>
        </div>
      )}
    </div>
  );
}
