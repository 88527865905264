// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ComponentType, ReactNode, Suspense} from 'react';
import {Await, useLoaderData} from 'react-router-dom';

/**
 * 1. The resolve value is a promise, need use defer in loader.
 * 2. The resolve value is not a promise, don't use defer in loader.
 */

export function withAwait<K extends string>(key: K) {
  return function <P extends {[Key in K]: unknown}>(
    Component: ComponentType<P>
  ) {
    type ComponentProps = JSX.IntrinsicAttributes & P;
    type WrappedComponentProps = {
      suspenseFallback?: ReactNode;
      awaitErrorElement?: ReactNode;
    } & Omit<P, K>;
    type V = P extends {[Key in K]: infer T} ? T : never;

    return function WrappedComponent({
      suspenseFallback: fallback,
      awaitErrorElement: errorElement,
      ...props
    }: WrappedComponentProps) {
      const {[key]: resolve} = useLoaderData() as {[Key in K]: V | Promise<V>};
      if (resolve instanceof Promise) {
        return (
          <Suspense fallback={fallback}>
            <Await errorElement={errorElement} resolve={resolve}>
              {(value: V) => (
                <Component {...({...props, [key]: value} as ComponentProps)} />
              )}
            </Await>
          </Suspense>
        );
      } else {
        return (
          <Component {...({...props, [key]: resolve} as ComponentProps)} />
        );
      }
    };
  };
}
