// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DownloadIcon} from 'assets/svg/3.0/Download.svg';
import {PreviewBox} from 'components/PreviewBox';
import {VideoDownloadToast} from 'components/VideoDownloadToast';
import {useAnalysis} from 'contexts/AnalysisContext';
import {download} from 'lib/download';
import {ProjectType} from 'modules/project/types';
import {downloadStoryboard} from 'modules/project/utils';
import {ClosedScene} from 'modules/scene/types';
import {Storyboard} from 'modules/storyboard/models/Storyboard';
import {Button} from 'pages/components/Button';
import {
  ReactEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {PaymentCallbackEnum} from 'utils/stripe';

import {DetailBox} from '../DetailBox';
import styles from './ResultCard.module.scss';
import {Props} from './ResultCard.types';

export function ResultCard({
  thumbnail,
  isDisabled,
  outTradeNo,
  ratio,
  storyboard,
  historyDialogShowing,
  showDownloadToast,
  downloadToastVisible,
  assetProduct,
  projectId,
  onCloseDownloadToast,
  showDownloadDialog,
  ...props
}: Props) {
  const [duration, setDuration] = useState<number | null>(null);
  const {recordEvent} = useAnalysis();
  const [storyboardDownloading, setStoryboardDownloading] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const onVideoLoaded = useCallback<ReactEventHandler<HTMLVideoElement>>(e => {
    const element = e.currentTarget;
    videoRef.current = element;
    setDuration(element.duration);
  }, []);

  const onDownloadStoryboard = useCallback(
    async (
      storyboard: Required<
        Pick<
          Storyboard<ProjectType>,
          'title' | 'description' | 'hashtags' | 'prompt'
        > & {
          scenes: ClosedScene<ProjectType>[];
        }
      >
    ) => {
      setStoryboardDownloading(true);
      await downloadStoryboard(storyboard);
      setStoryboardDownloading(false);
    },
    []
  );

  const onDownloadVideo = useCallback(() => {
    download(props.video as string, props.title);
    recordEvent('Download-Video');
  }, [props.title, props.video, recordEvent]);

  useEffect(() => {
    const video = videoRef.current;
    // 如果视频正在播放，打开历史对话框时暂停视频
    if (video && historyDialogShowing && video.paused === false) {
      video.pause();
    }
  }, [historyDialogShowing]);

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles['preview-box']}>
          <PreviewBox
            preview={isDisabled ? '' : props.video ?? ''}
            ratio={ratio}
            type={props.video ? 'Video' : 'Image'}
            loading={isDisabled}
            onLoad={onVideoLoaded}
          />
        </div>
        <DetailBox
          {...{...props, isDisabled, storyboard}}
          duration={duration}
        />
      </div>
      <div className={styles['button-box']}>
        <Button
          className={styles.button}
          size="large"
          disabled={isDisabled || storyboard === undefined}
          onClick={
            storyboard
              ? () =>
                  onDownloadStoryboard({
                    scenes: storyboard.scenes || [],
                    title: storyboard.title || '',
                    description: storyboard.description || '',
                    hashtags: storyboard.hashtags || [],
                    prompt:
                      storyboard.promptPolicy &&
                      storyboard.promptPolicy.isIdeaPrompt
                        ? storyboard.prompt
                        : '',
                  })
              : undefined
          }
          icon={<DownloadIcon className={styles.icon} />}
          isLoading={storyboardDownloading}
        >
          Storyboard
        </Button>
        <Button
          className={styles.button}
          disabled={isDisabled}
          size="large"
          onClick={() => download(thumbnail, props.title)}
        >
          <DownloadIcon className={styles.icon} />
          Thumbnail(JPG)
        </Button>
        <Button
          className={styles.button}
          theme="primary"
          size="large"
          disabled={isDisabled || !props.video}
          onClick={() => {
            if (!showDownloadToast) {
              onDownloadVideo();
              return;
            }
            showDownloadDialog();
          }}
        >
          <DownloadIcon className={styles.icon} /> Video(MP4)
        </Button>
      </div>
      {assetProduct && showDownloadToast && (
        <VideoDownloadToast
          videoId={assetProduct}
          outTradeNo={outTradeNo}
          projectId={projectId}
          type={PaymentCallbackEnum.CompositePage}
          isVisible={downloadToastVisible}
          onDownload={() => {
            onDownloadVideo();
            onCloseDownloadToast();
          }}
          onCancel={onCloseDownloadToast}
        />
      )}
    </div>
  );
}
