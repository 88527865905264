// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Character} from 'modules/character/models/Character';
import {CharacterJSON} from 'modules/character/types';
import {PromptPolicy} from 'modules/project/models/PromptPolicy';
import {SceneJSON} from 'modules/scene/types';

import {ProjectType, Size} from '../../project/types';
import {Scene} from '../../scene/models/Scene';
import {Task} from '../../task/models/Task';
import {BaseStoryboardJSON, TaskType} from '../types';

export abstract class BaseStoryboard<T extends ProjectType> {
  constructor(
    readonly id: string,
    readonly size: Size,
    readonly language: string,
    readonly promptPolicy: PromptPolicy<T>,
    readonly prompt: string,
    readonly title?: string,
    readonly description?: string,
    readonly hashtags?: string[],
    readonly characters?: Character<T>[],
    readonly scenes?: Scene<T>[],
    readonly script?: string,
    readonly tasks?: Task<TaskType>[],
    readonly task?: Task<'generate_storyboard'>,
    readonly taskSession?: string,
    readonly closed?: boolean
  ) {}

  abstract patch(data: Partial<BaseStoryboard<T>>): BaseStoryboard<T>;

  toJSON(): BaseStoryboardJSON<T> {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      hashtags: this.hashtags,
      characters: this.characters?.map(character =>
        character.toJSON()
      ) as CharacterJSON<T>[],
      scenes: this.scenes?.map(scene => scene.toJSON()) as SceneJSON<T>[],
      script: this.script,
      tasks: this.tasks?.map(task => task.toJSON()),
      task: this.task?.id,
      task_session: this.taskSession,
      closed: this.closed,
    };
  }
}
