// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {RangeConstraintValueJSON} from '../types';
import {BaseConstraintValue} from './BaseConstraintValue';

export class RangeConstraintValue<
  A extends number | undefined = number,
  B extends number | undefined = number
> extends BaseConstraintValue {
  static fromJSON<A extends number | undefined, B extends number | undefined>({
    min,
    max,
  }: RangeConstraintValueJSON<A, B>) {
    return new RangeConstraintValue(min, max);
  }

  constructor(readonly min: A, readonly max: B) {
    super();
  }

  toJSON(): RangeConstraintValueJSON<A, B> {
    return {
      min: this.min,
      max: this.max,
    };
  }
}
