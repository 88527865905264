// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import {combine} from 'components/Combine';
import {ErrorType, useError} from 'contexts/ErrorContext';
import {useUserContext} from 'contexts/UserContext';
import {useEffect} from 'react';

import {ErrorCatcher} from './ErrorCatcher';
import {HookReturn} from './ErrorCatcher.types';

function useHook(): HookReturn {
  const {error, clear} = useError();
  const {logout, isLogin} = useUserContext();

  useEffect(() => {
    if (error && error.type === ErrorType.Token && isLogin) {
      clear();
      logout();
    }
  }, [clear, error, isLogin, logout]);

  useEffect(() => {
    if (error && error.type === ErrorType.Unknown) {
      throw error;
    }
  }, [error]);

  return {error, clear};
}

export const ErrorCatcherContainer = combine(useHook)(ErrorCatcher);
