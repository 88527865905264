// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getRoot} from 'lexical';
import {useEffect} from 'react';

import {LimitedParagraphNode, LimitedTextNode} from '../nodes';

export function AutoFixLinePlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      const firstParagraph = root.getFirstChild() as LimitedParagraphNode;
      const firstText = firstParagraph.getFirstChild();
      if (
        firstText instanceof LimitedTextNode &&
        firstText.getTextContent() === ''
      ) {
        firstText.remove();
      }
    });
  }, [editor]);
  return null;
}
