// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {usePoll} from 'lib/poll';
import {useEffect} from 'react';

import {ProgressHeader} from './ProgressHeader';
import {HookParams} from './ProgressHeader.types';

function useHook({task, onPoll}: HookParams) {
  const {start, stop} = usePoll();

  useEffect(() => {
    if (onPoll) {
      start(onPoll, task);
    }

    return () => {
      stop();
    };
  }, [onPoll, start, stop, task]);
}

export const ProgressHeaderContainer = combine(useHook, ['task', 'onPoll'])(
  ProgressHeader
);
