// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export const poseImageKeyMap = {
  mask: 'mask',
  depth: 'depth',
  openPose: 'open_pose',
};
export const ErrorToastConfirmTextMap = {
  systemError: 'OK',
  noPose: 'Yes',
  no_credit: 'Recharge',
};
export const ErrorToastshowCancelMap = {
  systemError: false,
  noPose: true,
  no_credit: true,
};
export const ErrorToastMap = {
  systemError: 'Notice',
  noPose: 'Notice',
  no_credit: 'No Credit',
};
export const ErrorToastContentMap = {
  systemError: () => 'System error. Please try again.',
  noPose: () => 'Continue without pose control?',
  no_credit: () =>
    'Sorry, all credits have been depleted. You can recharge now.',
};
export const PromptTips = [
  {
    text: 'A girl in a red skirt',
    desc: 'Character description',
    id: 1,
  },
  {
    text: 'dancing alone ',
    desc: 'Action description',
    id: 2,
  },
  {
    text: 'in a cozy room',
    desc: 'Scene description',
    id: 3,
  },
];
