// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Tone} from 'modules/project/types';
import {TONE_OPTIONS} from 'modules/project/utils';

import {CommonSelectWithoutLabel} from './CommonSelectWithoutLabel/CommonSelectWithoutLabel';

export interface Props {
  value: Tone;
  onChange: (value: Tone) => void;
}

export function ToneSelect({value, onChange}: Props) {
  return (
    <CommonSelectWithoutLabel
      options={TONE_OPTIONS}
      value={value}
      title="Tone"
      placeholderOfOtherInput="Type in customize tone here"
      onChange={value => onChange(value as Tone)}
      customizeValue="Other"
    />
  );
}
