// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  BaseObjectJSON,
  ImageObjectJSON,
  ObjectType,
  SceneObjectJSON,
} from '../types';

abstract class BaseObject {
  constructor(
    readonly x: number,
    readonly y: number,
    readonly width: number,
    readonly height: number
  ) {}

  abstract get type(): ObjectType;
  abstract patch(data: Partial<BaseObject>): BaseObject;
  abstract isValid(): boolean;

  toJSON(): BaseObjectJSON {
    return {
      type: this.type,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
    };
  }
}

export class ImageObject extends BaseObject {
  static fromJSON({x, y, width, height, asset}: ImageObjectJSON) {
    return new ImageObject(x, y, width, height, asset);
  }

  constructor(
    x: number,
    y: number,
    width: number,
    height: number,
    readonly asset?: string
  ) {
    super(x, y, width, height);
  }

  get type(): 'image' {
    return 'image';
  }

  isValid() {
    return !!this.asset;
  }

  patch(data: Partial<ImageObject>): ImageObject {
    const {x, y, width, height, asset} = Object.assign({}, this, data);
    return new ImageObject(x, y, width, height, asset);
  }

  toJSON(): ImageObjectJSON {
    return {
      ...super.toJSON(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      asset: this.asset!,
    };
  }
}

export type SceneObject = ImageObject;

export function fromJSON(json: SceneObjectJSON): SceneObject {
  switch (json.type) {
    case 'image':
      return ImageObject.fromJSON(json);
    default:
      throw new Error(`Unknown object type: ${json.type}`);
  }
}
