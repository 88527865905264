// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import {combine} from 'components/Combine';
import {useResourceManager} from 'contexts/ResourceManager';
import {StyleData} from 'contexts/ResourceManager.type';
import {TaskType} from 'modules/ai-frontend/types';
import {fetchAsset} from 'modules/user-asset/utils';
import {useCallback} from 'react';

import {StyleTransferal} from './StyleTransfer';
import {HookProps} from './StyleTransfer.types';

function useHook({
  uploadAsset,
  executeTask,
  onProcessing,
  innerRef,
}: HookProps) {
  const {resUrl} = useResourceManager();

  const transferStyle = useCallback(
    async (image: string, styleData: StyleData) => {
      try {
        onProcessing('start');
        const assetId = await uploadAsset('style_transfer', image);
        const task = await executeTask(TaskType.StyleTransfer, {
          style: styleData.name,
          prompt: '',
          ref_image_1: resUrl + styleData.ref_image_1,
          ref_image_2: resUrl + styleData.ref_image_2,
          image: assetId,
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const newImage = await fetchAsset(staticCombiner(task.asset!));
        onProcessing('success', {
          currentImage: newImage,
          fileSuffix: 'jpg',
        });
      } catch (e) {
        onProcessing('failed', e);
      }
    },
    [executeTask, onProcessing, resUrl, uploadAsset]
  );

  return {transferStyle, ref: innerRef};
}

export const StyleTransferContainer = combine(useHook, [
  'uploadAsset',
  'executeTask',
  'onProcessing',
  'innerRef',
])(StyleTransferal);
