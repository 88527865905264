// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import {ProgressHeader} from './ProgressHeader';
import styles from './TaskPage.module.scss';
import {Props} from './TaskPage.types';

export function TaskPage({isProcessing, children, ...props}: Props) {
  return (
    <div
      className={classNames(
        styles.container,
        isProcessing && styles.processing
      )}
    >
      <div className={styles.children}>
        {isProcessing && props.task && (
          <ProgressHeader {...props} task={props.task} />
        )}
        {children}
      </div>
    </div>
  );
}
