// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DropdownIcon} from 'assets/svg/3.0/Dropdown.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useEffect, useRef, useState} from 'react';

import styles from './Select.module.scss';
import {Props} from './Select.types';

export function Select({
  icon,
  children,
  isOpen,
  value,
  onVisibleChange,
  scrollElement: _scrollElement,
  innerRef,
  popoverClassName,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [scrollElement, setScrollElement] = useState<HTMLElement>();
  useEffect(() => {
    if (_scrollElement) {
      setScrollElement(_scrollElement);
    } else {
      if (!ref.current) return;
      let container = ref.current.parentElement;
      while (container) {
        const overflow = getComputedStyle(container).overflowY;
        if (overflow === 'auto' || overflow === 'scroll') {
          setScrollElement(container as HTMLElement);
        }
        container = container.parentElement;
      }
    }
  }, [_scrollElement]);

  return (
    <Popover
      visible={isOpen}
      trigger="click"
      direction="bottom"
      distance={6}
      ref={innerRef}
      scrollElement={scrollElement}
      onVisibleChange={onVisibleChange}
      popupMatchSelectWidth
      popoverClassName={popoverClassName}
      triggerElement={
        <div
          className={classNames(
            styles.container,
            isOpen && styles.active,
            'select-item'
          )}
          ref={ref}
        >
          <span className={styles.select}>
            <span className={styles['value-wrap']}>
              {icon && <span className={styles.icon}>{icon}</span>}
              {value}
            </span>
            <DropdownIcon className={styles.dropdown} />
          </span>
        </div>
      }
      content={children}
    />
  );
}
