// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Project = Record<string, any>;

export abstract class BaseProjectVersionManager {
  //私有属性targetVersion
  constructor(protected readonly targetVersion: number) {}

  abstract convert(project: Project): Project;
}
