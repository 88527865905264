// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {HTMLAttributes, PropsWithChildren} from 'react';

export enum CloseButtonType {
  None,
  Inside,
}

export type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    onClose?: () => void;
    closeButtonType?: CloseButtonType;
  }
>;
