// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import {RemoveBgPanel} from '../Panels/RemoveBgPanel';
import styles from './BackgroundRemoval.module.scss';
import {Props, RefProps} from './BackgroundRemoval.types';

export const BackgroundRemoval = forwardRef<RefProps, Props>(
  ({currentImageInfo, removeBG, fillBG, children, tools}, ref) => {
    const [fillColor, setFillColor] = useState<string>();

    const handleRemoveBG = useCallback(() => {
      //如果已经执行过移除背景操作，则不再执行
      if (!currentImageInfo) return;
      removeBG(currentImageInfo[0], fillColor || '');
    }, [currentImageInfo, fillColor, removeBG]);

    const handleChangeFillBgColor = useCallback(
      (color: string) => {
        if (fillColor === color) return;
        setFillColor(color);
        fillBG(color);
      },
      [fillColor, fillBG]
    );

    useEffect(() => {
      if (currentImageInfo && currentImageInfo[1])
        //执行reset
        setFillColor('');
    }, [currentImageInfo]);

    useImperativeHandle(ref, () => ({
      action: handleRemoveBG,
    }));

    return (
      <>
        {children}
        <div className={styles.left}>
          {tools}
          <RemoveBgPanel
            fillColor={fillColor}
            handleChangeFillBgColor={handleChangeFillBgColor}
            handleRemoveBg={handleRemoveBG}
          />
        </div>
      </>
    );
  }
);
BackgroundRemoval.displayName = 'BackgroundRemoval';
