// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {duplicateProjectById} from 'api/server';
import {combine} from 'components/Combine';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useDebouncedAsyncFunction} from 'lib/hooks';
import {useCallback, useState} from 'react';

import {useMyProjects, useProjectOrGalleryModal} from '../modules';
import {ProjectOrGallery} from './ProjectOrGallery';

function useHook() {
  const {
    projectItemList,
    refreshProjectList,
    isLoading: isProjectsFetchLoading,
    loadMoreRef,
    hasMore,
    refreshAfterDelete,
  } = useMyProjects();

  const {showNotification} = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);

  const [
    visible,
    hideModalVisible,
    projectContent,
    onclickProjectOrGalleryItem,
  ] = useProjectOrGalleryModal();

  const onClickDuplicateBtn = useCallback(
    async (projectId: string) => {
      setIsLoading(true);
      try {
        await duplicateProjectById(projectId);
        await refreshProjectList();
      } catch {
        showNotification({message: 'Duplicate Failed', type: 'ERROR'});
      }
      setIsLoading(false);
    },
    [refreshProjectList, showNotification]
  );

  const debouncedOnClickDuplicateBtn = useDebouncedAsyncFunction(
    onClickDuplicateBtn,
    300
  );

  return {
    projectOrGalleryItemList: projectItemList,
    visible,
    hideModalVisible,
    projectContent,
    isLoading,
    pageType: 'project',
    refreshProjectList,
    onclickProjectOrGalleryItem,
    onClickDuplicateBtn: debouncedOnClickDuplicateBtn,
    isProjectsFetchLoading,
    loadMoreRef,
    hasMore,
    refreshAfterDelete,
  };
}
export const MyProjectContainer = combine(useHook)(ProjectOrGallery);
