// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

const FIXED_HASHTAGS_MAP: Record<string, Record<string, string[]>> = {
  bilingual_story: {
    'en-GB': ['BilingualEducation', 'LanguageLearning'],
    'en-US': ['BilingualEducation', 'LanguageLearning'],
    'de-DE': ['ZweisprachigeBildung', 'Sprachenlernen'],
    'es-ES': ['EducaciónBilingüe', 'AprendizajeDeIdiomas'],
    'fr-FR': ['ÉducationBilingue', 'ApprentissageDesLangues'],
    'it-IT': ['Educazione bilingue', 'ApprendimentodelleLingue'],
    'pt-BR': ['EducaçãoBilingue', 'AprendizagemDeIdiomas'],
    'ru-RU': ['ДвуязычноеОбразование', 'ИзучениеЯзыка'],
    'zh-CN': ['双语教学', '学外语'],
    'ar-EG': ['التعليم الثنائي اللغة', 'تعلم_اللغة'],
    'ja-JP': ['バイリンガル教育', '言語学習'],
  },
  bilingual_dialogue: {
    'en-GB': ['BilingualEducation', 'LanguageLearning', 'Dialogue'],
    'en-US': ['BilingualEducation', 'LanguageLearning', 'Dialogue'],
    'de-DE': ['ZweisprachigeBildung', 'Sprachenlernen', 'Dialog'],
    'es-ES': ['EducaciónBilingüe', 'AprendizajeDeIdiomas', 'Diálogo'],
    'fr-FR': ['ÉducationBilingue', 'ApprentissageDesLangues', 'Dialogue'],
    'it-IT': ['Educazione bilingue', 'ApprendimentodelleLingue', 'Dialogo'],
    'pt-BR': ['EducaçãoBilingue', 'AprendizagemDeIdiomas', 'Diálogo'],
    'ru-RU': ['ДвуязычноеОбразование', 'ИзучениеЯзыка', 'Диалог'],
    'zh-CN': ['双语教学', '学外语', '情景对话'],
    'ar-EG': ['التعليم الثنائي اللغة', 'تعلم_اللغة', 'حوار'],
    'ja-JP': ['バイリンガル教育', '言語学習', '対話'],
  },
};
export function getFixedHashtags(
  key: keyof typeof FIXED_HASHTAGS_MAP,
  locale: string
) {
  return (FIXED_HASHTAGS_MAP[key] && FIXED_HASHTAGS_MAP[key][locale]) ?? [];
}
