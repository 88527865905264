// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {cloneDeep} from 'lodash';
import {GeneralStoryCompositionJSON} from 'modules/composition/types';
import {ThumbnailType} from 'modules/project-config/types';
import {GeneralStoryHistoryJSON} from 'modules/project-history/types';
import {GeneralStorySceneJSON} from 'modules/scene/types';
import {GeneralStoryStoryboardJSON} from 'modules/storyboard/types';
import {Task} from 'modules/task/task.types';
import {TaskJSON} from 'modules/task/types';
import {nanoid} from 'nanoid';

import {GeneralStoryProjectJSON, PromptPolicyJSON} from '../types';
import {getLastVersion} from '.';
import {BaseProjectVersionManager, Project} from './BaseProjectVersionManager';

export const LATEST_VERSION = 8;

const EMPTY_SCENE = {
  id: '',
  characters: [],
  type: '',
  shotType: 0,
  subtitle: '',
  prompt: '',
  image: '',
  status: 'ready',
};
type OldCharacter = {
  id: string;
  name: string;
  image: string;
  type: number;
  description: string;
};

export const VOICEOVER_MAP: Record<string, string> = {
  'Movie narration - Male - English': 'Andrew',
  'Educational storytelling - Male - English': 'Jason',
  'Funny commentary - Male - English': 'Jackson',
  'Witty commentary - Male - English': 'Jackson',
  'Newscast - Male - English': 'Guy',
  'Movie narration - Female - English': 'Ava',
  'Educational storytelling - Female - English': 'Emma',
  'Funny commentary - Female - English': 'Aria',
  'Witty commentary - Female - English': 'Aria',
  'Newscast - Female - English': 'Jenny',
  'Movie narration - Male - Chinese': 'Yunxi',
  'Educational storytelling - Male - Chinese': 'Yunze',
  'Funny commentary - Male - Chinese': 'Sponge',
  'Witty commentary - Male - Chinese': 'Sponge',
  'Newscast - Male - Chinese': 'Yunyang',
  'Movie narration - Female - Chinese': 'Xiaomei',
  'Educational storytelling - Female - Chinese': 'Xiaoqiu',
  'Funny commentary - Female - Chinese': 'Lili',
  'Witty commentary - Female - Chinese': 'Lili',
  'Newscast - Female - Chinese': 'Wangjing',
};

export class GeneralStoryProjectVersionManager extends BaseProjectVersionManager {
  constructor(version?: number) {
    super(version ?? getLastVersion('general_story'));
  }

  public convert(project: Project) {
    let newProject = cloneDeep(project);
    for (
      let version = project.version || 0;
      version < this.targetVersion;
      version++
    ) {
      switch (version) {
        case 0:
          newProject = this.convertProjectV0ToV1(newProject);
          break;
        case 1:
          newProject = this.convertProjectV1ToV2(newProject);
          break;
        case 2:
          newProject = this.convertProjectV2ToV3(newProject);
          break;
        case 3:
          newProject = this.convertProjectV3ToV4(newProject);
          break;
        case 4:
          newProject = this.convertProjectV4ToV5(newProject);
          break;
        case 5:
          newProject = this.convertProjectV5ToV6(newProject);
          break;
        case 6:
          newProject = this.convertProjectV6ToV7(newProject);
          break;
        case 7:
          newProject = this.convertProjectV7ToV8(newProject);
          break;
      }
    }

    return newProject;
  }

  private convertProjectV0ToV1(project: Project): Project {
    const newProject = cloneDeep(project);
    newProject.version = 1;
    if ((newProject.style as string) === '3d_art') {
      newProject.style = '3d_cartoon';
    }

    // 将thumbnailIncludeVideo字段从storyboard中移到config中
    if (newProject.config.thumbnailIncludeVideo === undefined) {
      if (newProject.storyboard) {
        newProject.config.thumbnailIncludeVideo =
          newProject.storyboard.thumbnailIncludeVideo ?? false;
        newProject.storyboard.thumbnailIncludeVideo = undefined;
      } else {
        newProject.config.thumbnailIncludeVideo = false;
      }
    }

    // 将删除的Funny 的 voiceover转换成 Witty 对应语言对应性别的voiceover
    const abandonedVoiceover = [
      'Funny commentary - Male - Chinese',
      'Funny commentary - Female - Chinese',
      'Funny commentary - Male - English',
      'Funny commentary - Female - English',
    ];
    if (abandonedVoiceover.includes(newProject.config.voiceover)) {
      newProject.config.voiceover = newProject.config.voiceover.replace(
        'Funny',
        'Witty'
      );
    }

    // 将之前存储的voiceover的value转换为voiceover的name
    if (newProject.config.voiceover !== null) {
      const voiceoverArr = newProject.config.voiceover.split(' - ');
      voiceoverArr.pop();
      const voiceoverName = voiceoverArr.join(' - ') || null;
      newProject.config.voiceover = voiceoverName;
    }

    // 将perParagraphAsScene移动到config外部
    newProject.perParagraphAsScene = newProject.config.perParagraphAsScene;
    newProject.config.perParagraphAsScene = undefined;

    // 改变no effect为空的值
    if (newProject.config.effect === undefined) {
      newProject.config.effect = null;
    }

    // 将旧的Project type 类型转换成新的Project type 类型
    if (newProject.type === 'Idea') {
      newProject.type = 'Prompt';
    } else if (newProject.type === 'Script') {
      newProject.type = 'Content';
    }

    return newProject;
  }

  private convertProjectV1ToV2(project: Project): Project {
    const newProject = cloneDeep(project);
    newProject.version = 2;

    if (newProject.resultHistory && newProject.resultHistory.length) {
      newProject.resultHistory = newProject.resultHistory.map(
        (item: Record<string, unknown>) => {
          return {
            video: item.video,
            size: item.size,
            thumbnail: item.thumbnail,
            createTime: item.createTime,
            storyboard: {
              taskId: '',
              title: item.title,
              description: item.description,
              scenes: Array(item.scenesNumber).fill({...EMPTY_SCENE}),
              characters: [],
              scriptFullText: '',
              tags: item.hashTags,
            },
          };
        }
      );
    }

    return newProject;
  }

  private convertProjectV2ToV3(project: Project): Project {
    const {version: _, storyboard, ...rest} = project;
    if (!storyboard) return {...rest, version: 3};

    const {scenes, ...restStoryboard} = storyboard;
    return {
      ...rest,
      version: 3,
      storyboard: {
        ...restStoryboard,
        scenes: scenes.map((scene: Record<string, unknown>) => ({
          ...scene,
          ...((scene.shotType as number) > 0 && scene.prompt
            ? {lastPrompt: scene.prompt}
            : {}),
        })),
      },
    };
  }

  private convertProjectV3ToV4(project: Project): Project {
    const {version: _, storyboard, resultHistory, ...rest} = project;
    function convertStoryboard(storyboard: Record<string, unknown>) {
      const {scenes, ...restStoryboard} = storyboard;
      return {
        ...restStoryboard,
        scenes: (scenes as []).map(
          ({characterNames, ...restScene}: Record<string, unknown>) => ({
            ...restScene,
            characters: ((characterNames ?? []) as []).map((name: string) => ({
              name,
            })),
          })
        ),
      };
    }
    return {
      ...rest,
      version: 4,
      ...(storyboard ? {storyboard: convertStoryboard(storyboard)} : {}),
      ...(resultHistory
        ? {
            resultHistory: (resultHistory as []).map(
              ({
                storyboard: resultStoryboard,
                ...restResult
              }: Record<string, unknown>) => ({
                ...restResult,
                storyboard: convertStoryboard(
                  resultStoryboard as Record<string, unknown>
                ),
              })
            ),
          }
        : {}),
    };
  }

  private convertProjectV4ToV5(project: Project): Project {
    const {version: _, config, ...rest} = project;
    function convertVoiceover() {
      return project.config.voiceover
        ? VOICEOVER_MAP[
            `${project.config.voiceover} - ${
              project.language === 'en' ? 'English' : 'Chinese'
            }`
          ] ?? null
        : null;
    }

    return {
      ...rest,
      config: {
        ...config,
        voiceover: convertVoiceover(),
      },
      version: 5,
    };
  }

  private convertProjectV5ToV6(project: Project): Project {
    const {config, ...rest} = project;

    return {
      ...rest,
      config: {
        ...config,
        bgm: null,
      },
      version: 6,
    };
  }

  private convertProjectV6ToV7(project: Project): Project {
    const {config, ...rest} = project;

    const LANGUAGE_CODES: string[] = [
      'de-DE',
      'en-GB',
      'en-US',
      'es-ES',
      'fr-FR',
      'pt-BR',
      'zh-CN',
      'ja-JP',
    ];

    const OLD_LANGUAGE_MAP: Record<string, string> = {
      'zh-cn': 'zh-CN',
      en: 'en-US',
    };

    let language = 'en-US';
    if (OLD_LANGUAGE_MAP[project.language]) {
      language = OLD_LANGUAGE_MAP[project.language];
    } else if (LANGUAGE_CODES.includes(project.language)) {
      language = project.language;
    }

    const OLD_TITLE_STYLES_MAP: Record<string, string[]> = {
      'zh-CN': [
        'Title_ZH_Stroke_01',
        'Title_SC_Stroke_02',
        'Title_ZH_Cute_01',
        'Title_ZH_Retro_01',
        'Title_ZH_Fill_01',
        'Title_SC_Stroke_03',
      ],
      'en-US': [
        'Title_EN_Stroke_01',
        'Title_EN_Handwrite_01',
        'Title_EN_Cute_01',
        'Title_EN_Retro_01',
        'Title_EN_Fill_01',
        'Title_EN_Cute_02',
        'Title_EN_Stroke_03',
        'Title_EN_Handwrite_02',
      ],
    };
    const OLD_SUBTILE_STYLES_MAP: Record<string, string[]> = {
      'zh-CN': [
        'Subtitle_SC_Fill_01',
        'Subtitle_SC_Stroke_01',
        'Subtitle_SC_Cute_01',
        'Subtitle_SC_Retro_01',
        'Subtitle_SC_Fill_02',
        'Subtitle_SC_Colorswitch_01',
        'Subtitle_SC_Colorswitch_02',
        'Subtitle_SC_Glow_01',
        'Subtitle_SC_Stroke_02',
      ],
      'en-US': [
        'Subtitle_EN_Fill_01',
        'Subtitle_EN_Handwrite_01',
        'Subtitle_EN_Cute_01',
        'Subtitle_EN_Retro_01',
        'Subtitle_EN_Fill_02',
        'Subtitle_EN_Stroke_01',
        'Subtitle_EN_Colorswitch_01',
        'Subtitle_EN_Colorswitch_02',
        'Subtitle_EN_Glow_01',
        'Subtitle_EN_Stroke_02',
      ],
    };

    let titleStyleName = null;
    let subtitleStyleName = null;

    if (typeof config.titleStyleIndex === 'number') {
      const titleStyleNames = OLD_TITLE_STYLES_MAP[language as string];
      if (
        titleStyleNames &&
        config.titleStyleIndex >= 0 &&
        config.titleStyleIndex < titleStyleNames.length
      ) {
        titleStyleName = titleStyleNames[config.titleStyleIndex];
      }
    }

    if (typeof config.subtitleStyleIndex === 'number') {
      const subtitleStyleNames = OLD_SUBTILE_STYLES_MAP[language as string];
      if (
        subtitleStyleNames &&
        config.subtitleStyleIndex >= 0 &&
        config.subtitleStyleIndex < subtitleStyleNames.length
      ) {
        subtitleStyleName = subtitleStyleNames[config.subtitleStyleIndex];
      }
    }

    return {
      ...rest,
      language,
      config: {
        ...config,
        titleStyleIndex: undefined,
        subtitleStyleIndex: undefined,
        titleStyleName,
        subtitleStyleName,
      },
      version: 7,
    };
  }

  private convertProjectV7ToV8(project: Project): Omit<
    GeneralStoryProjectJSON,
    'histories'
  > & {
    histories: (string | GeneralStoryHistoryJSON)[];
  } {
    const {
      id,
      authorId,
      size,
      style,
      language,
      script,
      type,
      perParagraphAsScene,
      tone,
      config,
      storyboard,
      tasks,
      result,
      resultHistory = [],
    } = project;
    const storyboardTaskTypes = [
      'generate_storyboard',
      'split_scene',
      'merge_prev_scene',
      'merge_next_scene',
      'regenerate_scene_by_prompt',
      'regenerate_scene_by_pose_prompt',
      'image_conditioning_video',
    ];
    const storyboardCharacters: {
      image: string;
      description: string;
      character_type: number;
      name: string;
      id: string;
    }[] =
      storyboard &&
      storyboard.characters.map((character: OldCharacter) => ({
        id: character.id,
        name: character.name,
        image: character.image,
        character_type: character.type,
        description: character.description,
      }));
    const projectPromptPolicy =
      type === 'Prompt'
        ? {keep_user_content: false, tone}
        : {keep_user_content: true, paragraph_as_shots: perParagraphAsScene};
    //将旧的scene转换成新的scene todo
    const storyboardScenes: GeneralStorySceneJSON[] =
      storyboard &&
      storyboard.scenes &&
      storyboard.scenes.map((oldScene: Record<string, any>) => ({
        id: oldScene.id,
        type: oldScene.type || 'empty_scene',
        last_prompt: oldScene.lastPrompt,
        prompt: oldScene.prompt,
        subtitle: oldScene.subtitle,
        shot_type: oldScene.shotType,
        characters: oldScene.characters, //oldScene与newScene的characters结构一样，不需要转换
        image: oldScene.image,
        video: oldScene.video,
        draft: oldScene.draft && {
          background: oldScene.draft.background,
          image: oldScene.draft.image,
          objects:
            (oldScene.draft.objects &&
              oldScene.draft.objects.map((object: Record<string, any>) => ({
                type: object.type,
                x: object.x,
                y: object.y,
                width: object.width,
                height: object.height,
                asset: object.url,
              }))) ||
            [],
        },
        task: oldScene.taskId,
      }));
    //过滤掉abandon的task,新的数据结构没有abandon的task
    const allTask =
      (tasks as Task[]).filter(task => task.status !== 'abandon') || [];
    const storyboardTasks = allTask
      .filter(task => storyboardTaskTypes.includes(task.type))
      .map((task: Task) => ({
        id: task.id,
        type: ['merge_prev_scene', 'merge_next_scene'].includes(task.type)
          ? 'merge_scenes'
          : task.type,
        status: task.status,
        session: task.sessionId,
      })) as GeneralStoryStoryboardJSON['tasks'];
    //获取generate_storyboard的task，存在代表进入第2步，就有storyboard
    const generateStoryboardTask = allTask
      .filter(
        task =>
          task.type === 'generate_storyboard' &&
          task.status !== 'abandon' &&
          task.status !== 'failure'
      )
      .slice(-1)[0];

    //构建composition,compositeTask存在代表进入第4步，就有composition
    const compositeTask = allTask
      .filter(
        task =>
          task.type === 'composite_video' &&
          task.status !== 'abandon' &&
          task.status !== 'failure'
      )
      .slice(-1)[0];
    const compositionCharacters =
      storyboardCharacters &&
      storyboardCharacters.filter(characters => !!characters.image);
    //过滤掉没有image的scene，以及多余的属性
    const compositionScenes =
      storyboardScenes &&
      storyboardScenes
        .filter(
          scene =>
            (scene.video ? scene.image : scene.draft?.image ?? scene.image) &&
            !!scene.subtitle
        )
        .map(scene => ({
          type: scene.type,
          prompt: scene.prompt,
          subtitle: scene.subtitle,
          shot_type: scene.shot_type,
          characters: scene.characters,
          image: scene.image!,
          video: scene.video,
        }));
    const composition: GeneralStoryCompositionJSON = compositeTask && {
      id: nanoid(),
      characters: compositionCharacters,
      scenes: compositionScenes,
      task: {
        id: compositeTask.id,
        type: compositeTask.type,
        status: compositeTask.status,
      } as TaskJSON<'composite_video'>,
      asset: result && result.videoPath,
      thumbnail: result && result.thumbnail,
    };
    //是否已经合成完成
    const compositeIsValid =
      composition && !!composition.thumbnail && !!composition.asset;
    if (compositeIsValid && resultHistory.slice(-1)[0]) {
      //合成完成的时候，需要将最后一个history的createTime设置给composition
      composition.create_time = resultHistory.slice(-1)[0].createTime;
    }

    //history相关处理，合成完成的时候需要将最后一个history去掉
    const histories = (
      compositeIsValid ? resultHistory.slice(0, -1) : resultHistory
    ).map((oldHistory: Record<string, any>) => {
      return {
        id: nanoid(),
        size: oldHistory.size,
        prompt: '',
        prompt_policy: projectPromptPolicy,
        language: '',
        title: oldHistory.storyboard.title,
        description: oldHistory.storyboard.description,
        hashtags: oldHistory.storyboard.tags,
        create_time: oldHistory.createTime,
        thumbnail: oldHistory.thumbnail,
        asset: oldHistory.video,
        characters: oldHistory.storyboard.characters.map(
          (oldCharacter: OldCharacter) => ({
            description: oldCharacter.description,
            character_type: oldCharacter.type,
            image: oldCharacter.image,
            name: oldCharacter.name,
          })
        ),
        scenes: oldHistory.storyboard.scenes.map(
          (scene: Record<string, any>) => ({
            type: scene.type,
            prompt: scene.prompt,
            subtitle: scene.subtitle,
            shot_type: scene.shotType,
            characters: scene.characters,
            image: scene.image,
            video: scene.video,
          })
        ),
        style: oldHistory.style,
      } as GeneralStoryHistoryJSON;
    });

    return {
      id,
      version: 8,
      author_id: authorId,
      type: 'general_story',
      size,
      style,
      language,
      prompt: script,
      prompt_policy: projectPromptPolicy as PromptPolicyJSON<'general_story'>,
      config: config && {
        bgm: config.bgm,
        voiceover: config.voiceover,
        effect: config.effect,
        title_style: config.titleStyleName,
        subtitle_style: config.subtitleStyleName,
        transition: config.transition,
        thumbnail_type: config.thumbnailType as ThumbnailType,
        thumbnail_include_video: config.thumbnailIncludeVideo,
      },
      constraint: {
        size: {
          options: [
            [1920, 1080],
            [1080, 1920],
            [1080, 1080],
          ],
        },
        prompt_length: {min: undefined, max: 20000},
        scene_subtitle_length: {min: undefined, max: 300},
        scene_quantity: {min: undefined, max: 16},
      }, //后面会根据plan重置为真实的，这里直接用模板的即可
      storyboard: (storyboard || generateStoryboardTask) && {
        id: storyboard ? storyboard.taskId : generateStoryboardTask.id,
        title: storyboard?.title,
        description: storyboard?.description,
        hashtags: storyboard?.tags,
        characters: storyboardCharacters,
        scenes: storyboardScenes,
        script: storyboard?.scriptFullText,
        tasks: storyboardTasks,
        task: generateStoryboardTask?.id,
        closed: storyboard?.isScenesReady,
        task_session: storyboard?.sessionId,
      },
      composition,
      histories,
    };
  }
}
