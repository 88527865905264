// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as NineToSixteenIcon} from 'assets/svg/3.0/NineToSixteen.svg';
import {ReactComponent as OneToOneIcon} from 'assets/svg/3.0/OneToOne.svg';
import {ReactComponent as SixteenToNineIcon} from 'assets/svg/3.0/SixteenToNine.svg';
import classNames from 'classnames';
import {Ratio} from 'lib/ratio';
import {useMemo} from 'react';

import styles from './RatioSelect.module.scss';
import {Props, SizeInfo} from './RatioSelect.types';

const RATIO_OPTIONS: Record<Ratio, SizeInfo> = {
  '16:9': {
    size: [1920, 1080],
    previewSize: [110, 62],
    previewSvg: SixteenToNineIcon,
  },
  '9:16': {
    size: [1080, 1920],
    previewSize: [62, 110],
    previewSvg: NineToSixteenIcon,
  },
  '1:1': {
    size: [1080, 1080],
    previewSize: [80, 80],
    previewSvg: OneToOneIcon,
  },
};

export function RatioSelect({value, onChange}: Props) {
  const selectValue = useMemo(() => {
    const values = Object.values(RATIO_OPTIONS);
    const keys = Object.keys(RATIO_OPTIONS) as Ratio[];
    const index = values.findIndex(
      v => v.size[0] === value[0] && v.size[1] === value[1]
    );
    return index === -1 ? keys[0] : keys[index];
  }, [value]);

  return (
    <ul className={classNames(styles['ratio-list'])}>
      {Object.entries(RATIO_OPTIONS).map(([key, {size, previewSvg: Svg}]) => {
        return (
          <li
            key={key}
            className={classNames(
              styles['ratio-item'],
              selectValue === key && styles.active
            )}
            onClick={() => {
              if (size[0] === value[0] && size[1] === value[1]) return;
              onChange(size);
            }}
          >
            <Svg fill="#7c7c7c" />
            <span>{key}</span>
          </li>
        );
      })}
    </ul>
  );
}
