// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  Effect,
  GeneralStoryProjectConfigJSON,
  ThumbnailType,
  VoiceoverSpeed,
} from '../types';
import {BaseProjectConfig} from './BaseProjectConfig';

export class GeneralStoryProjectConfig extends BaseProjectConfig {
  static fromJSON({
    voiceover,
    voiceover_speed,
    effect,
    title_style,
    subtitle_style,
    transition,
    thumbnail_type,
    thumbnail_include_video,
    bgm,
  }: GeneralStoryProjectConfigJSON) {
    return new GeneralStoryProjectConfig(
      voiceover,
      voiceover_speed ?? 'normal',
      effect,
      title_style,
      subtitle_style,
      transition,
      thumbnail_type,
      thumbnail_include_video,
      bgm
    );
  }

  constructor(
    readonly voiceover: string | null,
    readonly voiceoverSpeed: VoiceoverSpeed,
    readonly effect: Effect,
    readonly titleStyle: string | null,
    readonly subtitleStyle: string | null,
    readonly transition: string | null,
    readonly thumbnailType: ThumbnailType,
    readonly thumbnailIncludeVideo: boolean,
    bgm: string | null
  ) {
    super(bgm);
  }

  patch(data: Partial<GeneralStoryProjectConfig>) {
    const {
      voiceover,
      voiceoverSpeed,
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
    } = Object.assign({}, this, data);
    return new GeneralStoryProjectConfig(
      voiceover,
      voiceoverSpeed,
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm
    );
  }

  toJSON(): GeneralStoryProjectConfigJSON {
    return {
      ...super.toJSON(),
      voiceover: this.voiceover,
      voiceover_speed: this.voiceoverSpeed,
      effect: this.effect,
      title_style: this.titleStyle,
      subtitle_style: this.subtitleStyle,
      transition: this.transition,
      thumbnail_type: this.thumbnailType,
      thumbnail_include_video: this.thumbnailIncludeVideo,
    };
  }
}
