// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {GoogleOAuthProvider} from '@react-oauth/google';
import {ReactComponent as DiscordIcon} from 'assets/svg/3.0/Discord.svg';
import {ReactComponent as MootionIcon} from 'assets/svg/3.0/Mootion.svg';
import classNames from 'classnames';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useUserContext} from 'contexts/UserContext';
import {Button} from 'pages/components/Button';
import {Toast} from 'pages/components/Toast';
import {useState} from 'react';
import {Link, Navigate, useLocation} from 'react-router-dom';
import {
  HOME_PAGE,
  LOGIN_PATH,
  SIGNUP_PATH,
  WORKSPACE_PAGE_PATH,
} from 'utils/path';

import {
  AuthForm,
  AuthFormDoResetPassword,
  AuthFormResetPassword,
} from '../AuthForm';
import {useErrorToast} from '../AuthForm/AuthForm.module';
import {GoogleButton} from '../GoogleButton';
import {useResentEmail} from '../modules';
import styles from './AuthPanel.module.scss';
import {
  AuthFormLoginOrSignupParams,
  ContinueAuthType,
  Props,
} from './AuthPanel.types';

const DISCORD_AUTH_PATH = `${process.env.REACT_APP_API_URL}/auth/discord-login?state=storyteller`;
const titleMap: Record<string, string> = {
  login: 'Log in to Mootion',
  signup: 'Sign up to Mootion',
  'confirm-email': 'Confirm your email',
  'reset-password': 'Reset password',
  'check-email': 'Please check your email',
  'do-reset-password': 'Please reset your password',
};
const ErrorToastMap = {
  mergeAccountError: 'Notice',
  cannotResetPassword: 'Oops',
};
const ErrorToastContentMap = {
  mergeAccountError: (email?: string) =>
    `We've noticed the existing account under ${email}, confirm your email or log in with Google/Discord.`,
  cannotResetPassword: () =>
    'This email is attached with Google/Discord. Please log in with Google/Discord or set up an email account.',
};
const ErrorToastBtnTextMap = {
  mergeAccountError: 'OK',
  cannotResetPassword: 'OK',
};
export function AuthPanel({authType}: Props) {
  const {isLogin} = useUserContext();
  const [emailOfResetPassword] = useResentEmail(authType);
  const {
    errorToastVisible,
    errorToastType,
    setErrorToastVisible,
    setErrorToastType,
    onConfirmInErrorToast,
    hideErrorToast,
    showErrorToast,
    emailRef,
  } = useErrorToast();

  const {state} = useLocation();

  const [googleLoading, setGoogleLoading] = useState(false);

  if (
    (!state || !state.email) &&
    (authType === 'confirm-email' || authType === 'check-email')
  ) {
    return <Navigate to={LOGIN_PATH} replace />;
  }

  if (isLogin) {
    // 如果已登录，重定向到/home
    return <Navigate to={WORKSPACE_PAGE_PATH} replace />;
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.header}>
            <MootionIcon
              onClick={() => {
                location.href = HOME_PAGE + '';
              }}
            />
          </div>
          <video
            controls
            className={styles.video}
            poster={`${process.env.REACT_APP_HOME_PAGE_MEDIA_URL}/public/video-v2/promptVideo.jpeg`}
            src={`${process.env.REACT_APP_HOME_PAGE_MEDIA_URL}/public/video-v2/prompt-video.v3.mp4`}
            autoPlay
            loop
            muted
            playsInline
            controlsList="nodownload"
          >
            <source type="video/mp4" src="" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.right}>
          <div className={styles.title}>{titleMap[authType]}</div>
          <div className={styles['content-container']}>
            <AuthFormLoginOrSignup
              authType={authType}
              setErrorToastVisible={setErrorToastVisible}
              setErrorToastType={setErrorToastType}
              showErrorToast={showErrorToast}
              googleLoading={googleLoading}
              setGoogleLoading={setGoogleLoading}
            />
            {authType === 'reset-password' && (
              <div className={styles.formContainer}>
                <AuthFormResetPassword
                  authType={authType}
                  setErrorToastType={setErrorToastType}
                  showErrorToast={showErrorToast}
                />
              </div>
            )}
            {authType === 'do-reset-password' && (
              <div className={styles.formContainer}>
                <AuthFormDoResetPassword authType={authType} />
              </div>
            )}
            {authType === 'check-email' && (
              <>
                <p className={styles['check-email-content']}>
                  A link to reset your password has been sent to{' '}
                  {emailOfResetPassword}.
                </p>
              </>
            )}
            <FormTips
              googleLoading={googleLoading}
              email={state?.email || ''}
              authType={authType}
            />
          </div>
          {authType === 'confirm-email' && (
            <span className={styles['content-container']}>
              <span className={styles['confirm-info']}>
                <span>Already confirmed?</span>
                <Link
                  to={LOGIN_PATH + '?email=' + state.email}
                  className={styles.link}
                >
                  Log in
                </Link>
              </span>
            </span>
          )}
        </div>
        <Toast
          title={errorToastType && ErrorToastMap[errorToastType]}
          visible={errorToastVisible}
          onOk={onConfirmInErrorToast}
          onCancel={hideErrorToast}
          showCloseBtn={false}
          btnTheme="danger"
          footer={<></>}
        >
          {errorToastType &&
            ErrorToastContentMap[errorToastType] &&
            ErrorToastContentMap[errorToastType](emailRef.current)}
          {errorToastType && (
            <div className={styles.errorToastFooter}>
              <Button
                type="button"
                size="large"
                onClick={onConfirmInErrorToast}
              >
                {ErrorToastBtnTextMap[errorToastType]}
              </Button>
            </div>
          )}
        </Toast>
      </div>
    </>
  );
}

const AuthFormLoginOrSignup = function ({
  authType,
  showErrorToast,
  setErrorToastType,
  setErrorToastVisible,
  googleLoading,
  setGoogleLoading,
}: AuthFormLoginOrSignupParams) {
  const {recordEvent} = useAnalysis();

  if (!['login', 'signup'].includes(authType)) {
    return null;
  }
  return (
    <div className={styles.formContainer}>
      {process.env.REACT_APP_GOOGLE_CLIENT_ID && (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <GoogleButton
            googleLoading={googleLoading}
            setGoogleLoading={setGoogleLoading}
            styles={styles}
            setErrorToastType={setErrorToastType}
            showErrorToast={showErrorToast}
            setErrorToastVisible={setErrorToastVisible}
            authType={authType}
          />
        </GoogleOAuthProvider>
      )}
      <div
        className={classNames(styles.btnContainer, {
          [styles.disabled]: googleLoading,
        })}
        onClick={() => {
          if (googleLoading) return;
          recordEvent('Discord-Button-Click');
          location.href = DISCORD_AUTH_PATH;
        }}
      >
        <DiscordIcon className={styles.iconDiscord} />
        <span className={styles.text}>
          {authType === 'login'
            ? 'Log in with Discord'
            : 'Sign up with Discord'}
        </span>
      </div>
      {['signup', 'login'].includes(authType) && (
        <div className={styles.splitLine}>
          <span className={styles.line} />
          <span>or</span>
          <span className={styles.line} />
        </div>
      )}
      <AuthForm
        disabled={googleLoading}
        authType={authType}
        setErrorToastType={setErrorToastType}
        showErrorToast={showErrorToast}
        onCancel={() => {
          setGoogleLoading(false);
        }}
      />
    </div>
  );
};
const FormTips = function ({
  authType,
  googleLoading,
  email,
}: {
  authType: ContinueAuthType;
  googleLoading: boolean;
  email: string;
}) {
  return (
    <>
      {authType === 'confirm-email' && (
        <div className={styles['confirm-email']}>
          To continue signing up, click the link that we emailed to&nbsp;
          {email}.
        </div>
      )}
      {authType === 'signup' && (
        <p className={styles.to_login}>
          <span>Already have an account?</span>
          {googleLoading ? (
            <span className={styles.disabled}>Log in</span>
          ) : (
            <Link to={LOGIN_PATH} className={styles.link}>
              Log in
            </Link>
          )}
        </p>
      )}
      {(authType === 'do-reset-password' || authType === 'check-email') && (
        <p className={styles.to_login}>
          <span>Already have your password?</span>
          {googleLoading ? (
            <span className={styles.disabled}>Log in</span>
          ) : (
            <Link to={LOGIN_PATH} className={styles.link}>
              Log in
            </Link>
          )}
        </p>
      )}
      {['reset-password', 'login'].includes(authType) && (
        <p className={styles.to_signup}>
          <span>Don&apos;t have an account?&nbsp;</span>
          {googleLoading ? (
            <span className={styles.disabled}>Sign up for free</span>
          ) : (
            <Link to={SIGNUP_PATH} className={styles.link}>
              Sign up for free
            </Link>
          )}
        </p>
      )}
    </>
  );
};
