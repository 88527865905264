// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useVisible} from 'lib/hooks';
import {getRelativeImagePathOfDbBucketByUrl} from 'modules/user-asset/utils';
import {useEffect, useState} from 'react';

import {UseAssetModal} from './UseAssetModal';
import {UseHookParamsType, UseHookReturnType} from './UseAssetModal.type';

export function useHook({
  visible,
  handleOk,
  handleStartUpload,
  handleCancelUserAsset,
}: UseHookParamsType): UseHookReturnType {
  const [imageGridInModalVisible, hideImageGridInModal, showImageGridInModal] =
    useVisible(visible);
  const [useImageModalVisible, hideUseImageModal] = useVisible();
  const [assetImgUrl] = useState<string>('');

  useEffect(() => {
    if (visible) {
      showImageGridInModal();
    } else {
      hideImageGridInModal();
    }
  }, [hideImageGridInModal, showImageGridInModal, visible]);

  const handleClickAssets = async (e: React.MouseEvent<HTMLDivElement>) => {
    hideImageGridInModal();
    // showUseImageModal();
    const imageUrl = (e.currentTarget as HTMLDivElement).dataset.url;
    if (!imageUrl) {
      return;
    }

    const fileSplitInfo = getRelativeImagePathOfDbBucketByUrl(imageUrl, true);
    handleOk(fileSplitInfo?.relativePath || '');
    // if (fileSplitInfo) {
    //   const signedUrlsRes = await apiServer.getSignedUrl([
    //     {
    //       fileName: fileSplitInfo.relativePath,
    //     },
    //   ]);
    //   if (signedUrlsRes.data && signedUrlsRes.data.length > 0) {
    //     setAssetImgUrl(signedUrlsRes.data[0]);
    //   }
    // }
  };
  const doAfterUpload = () => {
    // hideImageGridInModal();
    // showUseImageModal();
    // setAssetImgUrl(args.blobImageUrl);
  };
  const closeUseImageModal = () => {
    hideUseImageModal();
    handleCancelUserAsset && handleCancelUserAsset();
  };
  const handleUseImageOk = (imgUrl: string) => {
    hideUseImageModal();
    handleOk(imgUrl);
  };
  const startUpload = () => {
    hideUseImageModal();
    handleStartUpload && handleStartUpload();
  };
  const _hideImageGridInModal = () => {
    handleCancelUserAsset && handleCancelUserAsset();
    hideImageGridInModal();
  };
  return {
    imageGridInModalVisible,
    useImageModalVisible,
    assetImgUrl,
    handleUseImageOk,
    closeUseImageModal,
    startUpload,
    hideImageGridInModal: _hideImageGridInModal,
    handleClickAssets,
    doAfterUpload,
  };
}

export const UseAssetModalContainer = combine(useHook, [
  'visible',
  'handleOk',
  'handleStartUpload',
  'handleCancelUserAsset',
])(UseAssetModal);
