// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {AIFrontendClient} from 'api/AIFrontendClient';
import {
  BackendClient,
  ResponseCode as BackendClientResponseCode,
} from 'api/BackendClient';
import {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {useFixedRef} from 'lib/hooks';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import {ErrorType, useError} from './ErrorContext';
import {getUserToken} from './localStorage';

interface Value {
  aiFrontendClient: AIFrontendClient;
  backendClient: BackendClient;
}

const apiContext = createContext({} as Value);

export function useAPI() {
  return useContext(apiContext);
}

export function APIProvider({children}: PropsWithChildren) {
  const aiFrontendClient = useFixedRef(() => {
    return new AIFrontendClient(
      AIFrontendClient.createAxios({
        baseURL: process.env.REACT_APP_FRONTEND_URL as string,
      })
    );
  });

  const {report} = useError();

  const generalRequestFulfilled = useCallback(
    ({headers, ...rest}: InternalAxiosRequestConfig) => {
      const token = getUserToken();
      return {
        ...rest,
        headers: headers.concat(
          token ? {Authorization: `Bearer ${token}`} : {}
        ),
      };
    },
    []
  );

  const generalResponseRejected = useCallback(
    (error: unknown) => {
      if (!(error instanceof AxiosError)) {
        return Promise.reject(error);
      }

      if (error.code === AxiosError.ERR_NETWORK) {
        return Promise.reject(report(ErrorType.Network));
      }

      const {response} = error;
      if (
        response &&
        ((response.status === 401 &&
          (!response.data || response.data.error === undefined)) ||
          (response.status === 403 &&
            response.data &&
            response.data.error === 70011))
      ) {
        return Promise.reject(report(ErrorType.Token, response));
      }

      return Promise.reject(error);
    },
    [report]
  );

  const aiFrontendResponseRejected = useCallback(
    (error: unknown) => {
      if (!(error instanceof AxiosError)) {
        return Promise.reject(error);
      }

      const {response} = error;
      if (response && response.status === 402) {
        return Promise.reject(report(ErrorType.NoCredit, response));
      }
      if (response && response.status === 503) {
        return Promise.reject(report(ErrorType.CapacityLimiting, response));
      }

      return Promise.reject(error);
    },
    [report]
  );
  const backendResponseFulfilled = useCallback(
    (response: AxiosResponse<any, any>) => {
      if (response.data.error === BackendClientResponseCode.TOKEN_INVALID) {
        return Promise.reject(report(ErrorType.Token, response));
      }
      return Promise.resolve(response);
    },
    [report]
  );

  const backendClient = useFixedRef(() => {
    return new BackendClient(
      BackendClient.createAxios({
        baseURL: process.env.REACT_APP_API_URL as string,
      })
    );
  });
  useEffect(() => {
    aiFrontendClient.setRequestInterceptor({
      onFulfilled: generalRequestFulfilled,
    });
    aiFrontendClient.setResponseInterceptor(
      {onRejected: generalResponseRejected},
      {onRejected: aiFrontendResponseRejected}
    );
  }, [
    aiFrontendClient,
    generalRequestFulfilled,
    aiFrontendResponseRejected,
    generalResponseRejected,
  ]);
  useEffect(() => {
    backendClient.setRequestInterceptor({
      onFulfilled: generalRequestFulfilled,
    });
    backendClient.setResponseInterceptor({
      onFulfilled: backendResponseFulfilled,
      onRejected: generalResponseRejected,
    });
  }, [
    backendClient,
    backendResponseFulfilled,
    generalRequestFulfilled,
    generalResponseRejected,
    report,
  ]);

  return (
    <apiContext.Provider value={{aiFrontendClient, backendClient}}>
      {children}
    </apiContext.Provider>
  );
}
