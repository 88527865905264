// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CollapseIcon} from 'assets/svg/3.0/CollapseIcon.svg';
import classNames from 'classnames';
import {PropsWithChildren, useEffect, useRef, useState} from 'react';

import styles from './Collapse.module.scss';
import {CollapseProps} from './Collapse.type';

export function Collapse({
  title,
  icon,
  disabled,
  children,
  className,
}: PropsWithChildren<CollapseProps>) {
  const [open, setOpen] = useState(true);
  const [contentHeight, setContentHeight] = useState<number | 'auto'>('auto');

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const height = entry.target.scrollHeight;
        setContentHeight(height);
      }
    });
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className={styles['collapse']}>
      <div
        className={classNames(styles['header'], className)}
        style={{cursor: disabled ? 'default' : 'pointer'}}
        onClick={() => setOpen(!open)}
      >
        <div className={styles['title']}>
          {icon}
          <span style={{marginLeft: icon ? 8 : 0}}>{title}</span>
        </div>
        {!disabled && (
          <div className={styles['handler']}>
            <CollapseIcon
              className={classNames({
                [styles['icon']]: true,
                [styles['open']]: open,
              })}
            />
          </div>
        )}
      </div>
      <div
        style={{
          height: open || disabled ? contentHeight : 0,
          // 这个实现会把 popover 的内容隐藏掉 改为 display none 后续再优化
          // overflow: 'hidden',
          transition: 'height 0.3s',
        }}
      >
        <div ref={contentRef} className={styles['content']}>
          <div className={open ? '' : styles.hidden}>{children}</div>
        </div>
      </div>
    </div>
  );
}
