// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useEffect, useState} from 'react';

import styles from './CharacterCounter.module.scss';
import {Props} from './CharacterCounter.types';

export function CharacterCounter({
  maxCharacterNumber,
  characterNumber,
  isFocused,
}: Props) {
  const [residue, setResidue] = useState(0);

  useEffect(() => {
    setResidue(Math.max(maxCharacterNumber - characterNumber, 0));
  }, [maxCharacterNumber, characterNumber]);

  return (
    <div className={styles.container} contentEditable={false}>
      <p
        className={classNames(
          styles.circle,
          isFocused && styles.focused,
          residue === 0 && styles.error
        )}
      />
      <span className={styles.residue}>{residue}</span>
    </div>
  );
}
