// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ErrorType, useError} from 'contexts/ErrorContext';
import {noop} from 'lodash';
import {NoPermissionErrorDialog} from 'pages/components/NoPermissionError';
import {useCallback, useEffect} from 'react';
import {useNavigate, useRouteError} from 'react-router-dom';
import {WORKSPACE_PAGE_GENERATE_PATH} from 'utils/path';

type LoaderErrorType = 'permission' | 'system' | 'network';

export class LoaderError extends Error {
  constructor(type: LoaderErrorType) {
    super(type);
    this.name = 'ProjectLoaderError';
  }

  get type() {
    return this.message as LoaderErrorType;
  }
}

export function ProjectPageLoaderError() {
  const error = useRouteError();
  if (!(error instanceof LoaderError)) {
    throw error ?? null;
  }
  const {report} = useError();
  const navigate = useNavigate();

  const backToWorkspace = useCallback(() => {
    navigate(WORKSPACE_PAGE_GENERATE_PATH, {replace: true});
  }, [navigate]);

  useEffect(() => {
    if (error.type === 'network') {
      report(ErrorType.Network, error, backToWorkspace);
    } else if (error.type === 'system') {
      report(ErrorType.Service, error, backToWorkspace);
    }
  }, [backToWorkspace, error, report]);

  if (error.type === 'permission') {
    return (
      <NoPermissionErrorDialog
        dialogShowing={true}
        onCloseDialog={noop}
        onConfirm={backToWorkspace}
      />
    );
  }
  return null;
}
