// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  bindInviter,
  recordAffiliatePayment,
  verifyInvitation,
} from 'api/server';

export function bindInviterAndInvitee() {
  window.rewardful('ready', async () => {
    // 如果cookie中存在邀请人信息，则绑定邀请人与被邀请人
    const viaToken = window.Rewardful.affiliate.token;
    if (viaToken) {
      await bindInviter(viaToken);
    }
  });
}

export function convertAffiliateOrder(email: string, outTradeNo: string) {
  window.rewardful('ready', async () => {
    const {
      data: {validateStatus},
    } = await verifyInvitation();
    if (validateStatus) {
      window.rewardful('convert', {email});
      await recordAffiliatePayment(outTradeNo);
    }
  });
}
