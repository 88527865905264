// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

export enum ErrorType {
  Network = 'Network',
  Token = 'Token', // 401
  NoCredit = 'NoCredit', // 402
  CapacityLimiting = 'CapacityLimiting', // 503(ai-frontend)
  Service = 'Service', // 500+
  Unknown = 'Unknown',
}

export class AppError extends Error {
  constructor(
    type: ErrorType,
    cause?: unknown,
    readonly callback?: () => void
  ) {
    super(type, {cause});
    this.name = 'AppError';
  }

  get type() {
    return this.message as ErrorType;
  }
}

interface Value {
  error: AppError | null;
  report: (type: ErrorType, cause?: unknown, callback?: () => void) => AppError;
  clear: () => void;
}

const errorContext = createContext<Value>({
  error: null,
  report: (type: ErrorType, cause?: unknown, callback?: () => void) =>
    new AppError(type, cause, callback),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clear: () => {},
});

export function useError() {
  return useContext(errorContext);
}

export function ErrorProvider({children}: PropsWithChildren) {
  const [error, setError] = useState<AppError | null>(null);

  const report = useCallback(
    (type: ErrorType, cause?: unknown, callback?: () => void) => {
      const error = new AppError(type, cause, callback);
      setError(error);
      return error;
    },
    []
  );

  const clear = useCallback(() => setError(null), []);

  return (
    <errorContext.Provider value={{error, report, clear}}>
      {children}
    </errorContext.Provider>
  );
}
