// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Ratio} from 'lib/ratio';
import {ProjectType} from 'modules/project/types';
import {Scene} from 'modules/scene/models/Scene';

import {ActionType} from './SceneActionMenu';

export interface Props<T extends ProjectType> {
  scenes: Scene<T>[];
  sceneSubtitleMaxLength: number;
  ratio: Ratio;
  isProcessing: boolean;
  selectedSceneIndex: number;
  newSceneIds: string[];
  animatingInfo: [number, number];
  characterNames?: string[];
  onAction: (
    type: ActionType | InsertType,
    sceneId: string,
    insertParams?: Partial<Scene<T>>
  ) => void;
  onUpdateScene: (
    sceneId: string,
    params: Parameters<Scene<T>['patch']>[0],
    delay?: boolean
  ) => void;
  onResetAnimate: (sceneId: string) => void;
  changeSelectedSceneIndex: (sceneIndex: number) => void;
  getImageWithObjectKey: (
    objectKey: string,
    cacheAble?: boolean
  ) => Promise<{src: string; width: number; height: number}>;
  refreshImage: (
    objectKey: string
  ) => Promise<{src: string; width: number; height: number}>;
  disabledAnimateAll?: boolean;
  onGenerateAnimationAll: (shouldCheckWaitingTime: boolean) => Promise<void>;
}

export type HookParams<T extends ProjectType> = Pick<Props<T>, 'scenes'> & {
  updateScene: (
    sceneId: string,
    state: React.SetStateAction<Scene<T>>,
    delay?: number | boolean
  ) => void;
};

export type InsertType = 'insert_after' | 'insert_before';

export const enum AnimationType {
  none = 0,
  isAnimating = 1,
  nextIsAnimating = 2,
  prevIsAnimating = 4,
}
export type ToastProps = {
  visible: boolean;
  title?: React.ReactNode;
  confirmText?: React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  maskClosable?: boolean;
  content?: React.ReactNode | string;
  showCancel?: boolean;
  cancelText?: string;
  largeLastBtn?: boolean;
};
