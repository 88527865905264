// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {sleep} from 'lib/sleep';

export function retry(
  errorCallback?: (current: number, error: unknown) => void
) {
  return async <P extends unknown[], R>(
    policy: number | {times: number; interval: number} = 1,
    fn: (...params: P) => Promise<R>,
    ...params: P
  ): Promise<R> => {
    const {times, interval} =
      typeof policy === 'number'
        ? {times: policy, interval: undefined}
        : policy;
    let current = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      current++;
      try {
        return await fn(...params);
      } catch (error) {
        if (errorCallback) {
          errorCallback(current, error);
        }
        if (current < times) {
          if (interval !== undefined) {
            await sleep(interval);
          }
          continue;
        }
        throw error;
      }
    }
  };
}

export const retryIgnoreError = retry();
