// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

const PROMPT_TAG_MAP: Record<string, string[]> = {
  bilingual_dialogue: [
    'A customer orders coffee at a café, asks about the menu, and chooses between different drink options.',
    'Two friends decide what to order, ask the waiter questions, and discuss their favorite foods.',
    'Friends discuss where to go for the weekend, what to pack, and how to get there.',
    'A conversation between two people at a gift shop trying to choose the perfect gift for a friend’s birthday.',
    'A traveler asks a local how to get to a famous landmark or the nearest train station.',
    'A patient calls a doctor’s office to schedule an appointment, discuss times, and ask questions about what to bring.',
    'A dialogue between a passenger and a check-in agent about flight details, baggage, and airport procedures.',
    'A shopper asks a store clerk for help finding specific items, and they discuss prices and ingredients.',
    'Friends talk about a movie they recently watched, sharing opinions about the plot, characters, and favorite scenes.',
    'A dialogue between a job applicant and an interviewer, covering questions about skills, experience, and availability.',
    'A conversation where one person invites another to a party, shares details about the event, and discusses plans.',
    'A shopper and a store assistant discuss sizes, colors, and styles of clothing while trying to find the right fit.',
    'Two friends chat about their favorite hobbies, sports, or activities, and make plans to do something together.',
    'A tourist asks a local about the weather forecast, and they discuss what to wear and how the weather might affect plans.',
    'A patient describes symptoms to a doctor, and they discuss possible causes, treatments, and next steps.',
    'A conversation between a guest and a hotel receptionist about room availability, prices, and amenities.',
  ],
  bilingual_story: [
    'Story about a child who moves to a new city and learns to make new friends.',
    'A young boy finds a pair of shoes that let him run super fast and comes great responsibility!',
    'Emily gets lost in a new neighborhood and learns how to ask for directions.',
    'A lonely girl discovers her pet cat can talk. Together, they go on secret adventures around the city.',
    'A family visits the zoo, discovering different animals and learning descriptive words.',
    'A new student joins a classroom and faces challenges while learning to communicate in English.',
    'A boy receives an old watch from his grandfather, only to discover it can transport him to different times in history.',
    'A story where children spend a day playing at the park, learning action verbs and words related to outdoor activities.',
    'Someone loses their wallet, and the story follows how they ask for help and deal with the situation.',
    'Kyle shares his hobbies and activities, allowing students to learn vocabulary about interests like sports, music, or arts.',
    'In a future world, a robot named Zeke dreams of becoming human and goes on a journey to discover “feelings”.',
    'Amy learns how to cook, ride a bike, or play a musical instrument, showcasing steps and vocabulary related to the process.',
    'A group of friends explores a forest, encountering different animals, plants, and using nature-related vocabulary.',
    'A young adult starts a new job, where they interact with colleagues, learn new tasks, and practice common workplace language.',
    'Kids build a tree house, only to find out it can fly to different parts of the world. Each journey takes them to a new exciting destination.',
    'A child breaks his favorite toy and learns how to describe the problem and find a solution.',
  ],
  general_story: [
    'Teach the word “Intelligence”for English beginners with a story',
    'Illustrate the poem A Fairy Song by William Shakespeare',
    'A bedtime story of dinosaurs for kids',
    'Fiction story about two teenagers exploring enchanting forest',
    'Introduce the history of Egypt',
    'A legacy of leadership, the life of Queen Elizabeth II',
    '10 motivational quotes to inspire teenagers',
    '5 fun facts about the college students',
    'what is the effects of gaslighting with a detailed case',
    '5 one-liner jokes which make my day',
    'Create a horror story including vampires',
    'A horror story with supernatural and unexpected twist at the end',
    'What is the meaning of life? How do we live a good life?',
    'What does it mean to be blessed by God?',
    'Painting skills for beginners',
    'What’s Ukiyo-e and its uniqueness',
  ],
  short_video: [
    "A story of success you won't believe",
    'Ten quotes that will change how you see failure',
    'This story of forgiveness will touch your heart',
    'The story of a shepherd’s unshakable faith.',
    '10 great behaviors to motivate you everyday',
    'Save $1000 in 30 days – here’s how!',
    'This simple budgeting trick could change your life!',
    'Three passive income ideas anyone can start today!',
    "10 tricks you don't know about your iphone",
    "5 hidden features of your smartwatch you're missing",
    'Want toned abs? Try this 5-minute morning workout!',
    '3 quick stretches to relieve back pain instantly!',
    'The greatest of all - find those who change the world',
    'Create the story about the life of Abraham Lincoln',
    'This easy fashion hack makes any outfit look expensive!',
    'Skincare routine under $20 – try this for a week!',
  ],
};
export function getPromptTags(projectType: string) {
  return PROMPT_TAG_MAP[projectType] ?? [];
}
