// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import styles from './SceneList.module.scss';

export function SceneListPlaceholder() {
  return (
    <ul className={styles['placeholder-container']}>
      {Array(5)
        .fill(0)
        .map((_, index) => {
          return (
            <li key={index} className={styles['placeholder-scene']}>
              <div className={styles.preview}></div>
              <ul className={styles.labels}>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </li>
          );
        })}
    </ul>
  );
}
