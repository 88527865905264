// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as BackgroundIcon} from 'assets/svg/3.0/Background.svg';
import {useResourceManager} from 'contexts/ResourceManager';
import {Collapse} from 'pages/components/Collapse';
import {Switch} from 'pages/components/Switch';

import {StyleCard} from '../StyleCard';
import styles from './ThumbnailList.module.scss';
import {Props} from './ThumbnailList.types';

export function ThumbnailList({
  thumbnailType,
  thumbnailIncludeVideo,
  thumbnailTypes,
  onThumbnailIncludeVideoChange,
  onChange,
}: Props) {
  const {resUrl} = useResourceManager();

  return (
    <Collapse
      icon={<BackgroundIcon className={styles['label-icon']} />}
      title="Thumbnail"
      disabled
      className={styles['collapse-header']}
    >
      <div className={styles.switch}>
        <span> Included in video (4s)</span>
        <Switch
          value={thumbnailIncludeVideo}
          onChange={onThumbnailIncludeVideoChange}
        />
      </div>
      <p className={styles.tips}>
        Select the thumbnail(cover image) for your video.
      </p>
      <ul className={styles.list}>
        {thumbnailTypes.map(({display_name, name, image, id}) => (
          <StyleCard
            preview={`${resUrl}${image}`}
            previewGif={`${resUrl}${image}`}
            key={id}
            isSelected={name === thumbnailType}
            label={display_name}
            onSelected={() => name !== thumbnailType && onChange(name)}
          />
        ))}
      </ul>
    </Collapse>
  );
}
