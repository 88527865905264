// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Size} from 'modules/project/types';

import {BilingualStoryConstraintJSON} from '../types';
import {BaseConstraint} from './BaseConstraint';
import {RangeConstraintValue} from './RangeConstraintValue';
import {SelectionConstraintValue} from './SelectionConstraintValue';

export class BilingualStoryConstraint extends BaseConstraint {
  static fromJSON({
    size,
    prompt_length,
    scene_quantity,
    scene_subtitle_length,
    vocabulary_phrase_length,
    vocabulary_phrase_quantity,
  }: BilingualStoryConstraintJSON) {
    return new BilingualStoryConstraint(
      SelectionConstraintValue.fromJSON(size),
      RangeConstraintValue.fromJSON(prompt_length),
      RangeConstraintValue.fromJSON(scene_quantity),
      RangeConstraintValue.fromJSON(scene_subtitle_length),
      RangeConstraintValue.fromJSON(vocabulary_phrase_length),
      RangeConstraintValue.fromJSON(vocabulary_phrase_quantity)
    );
  }
  constructor(
    size: SelectionConstraintValue<Size>,
    promptLength: RangeConstraintValue<undefined, number>,
    sceneQuantity: RangeConstraintValue<undefined, number>,
    sceneSubtitleLength: RangeConstraintValue<undefined, number>,
    readonly vocabularyPhraseLength: RangeConstraintValue<undefined, number>,
    readonly vocabularyPhraseQuantity: RangeConstraintValue<undefined, number>
  ) {
    super(size, promptLength, sceneQuantity, sceneSubtitleLength);
  }
  patch(data: Partial<BilingualStoryConstraint>) {
    const {
      size,
      promptLength,
      sceneQuantity,
      sceneSubtitleLength,
      vocabularyPhraseLength,
      vocabularyPhraseQuantity,
    } = Object.assign({}, this, data);

    return new BilingualStoryConstraint(
      size,
      promptLength,
      sceneQuantity,
      sceneSubtitleLength,
      vocabularyPhraseLength,
      vocabularyPhraseQuantity
    );
  }

  toJSON(): BilingualStoryConstraintJSON {
    return {
      ...super.toJSON(),
      vocabulary_phrase_length: this.vocabularyPhraseLength.toJSON(),
      vocabulary_phrase_quantity: this.vocabularyPhraseQuantity.toJSON(),
    };
  }
}
