// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Size} from 'api/frontend';

export type Ratio = '16:9' | '1:1' | '9:16';

export function formatAspectRatio(resolution: Size): Ratio {
  const [width, height] = resolution;
  let gcd = width;
  let remainder = height;
  while (remainder !== 0) {
    const temp = remainder;
    remainder = gcd % remainder;
    gcd = temp;
  }
  const simplifiedWidth = width / gcd;
  const simplifiedHeight = height / gcd;

  return `${simplifiedWidth}:${simplifiedHeight}` as Ratio;
}
