// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import {TutorialGuide} from 'components/TutorialGuide';
import {WelcomeModal} from 'pages/WorkspacePage/WelcomeModal/WelcomeModal';
import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

import {Aside} from './Aside';
import {useToggleWelcomeModal} from './modules';
import styles from './WorkspacePage.module.scss';
export function WorkspacePage() {
  const [isModalOpen, toggleModal] = useToggleWelcomeModal();

  useEffect(() => {
    toggleModal();
  }, [toggleModal]);

  return (
    <div className={styles.container}>
      <Aside />
      <Outlet />
      <WelcomeModal isOpen={isModalOpen} onClose={toggleModal} />
      <TutorialGuide />
    </div>
  );
}
