// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import styles from './NoPermissionError.module.scss';
import {Props} from './NoPermissionError.types';

export function NoPermissionError({onConfirm}: Props) {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Notice</p>
      <p className={styles.content}>Sorry, no access to this content</p>
      <button className={styles.button} onClick={onConfirm}>
        OK
      </button>
    </div>
  );
}
