// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import {useSize} from 'contexts/SizeContext';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {AssetLoader} from '../AssetLoader';
import {ImageLoading} from '../ImageLoading';
import {ObjectsEditor} from '../ObjectsEditor';
import styles from './PreviewBox.module.scss';
import {Props} from './PreviewBox.types';

const MAX_HEIGHT = {
  xs: 280,
  sm: 360,
  md: 576,
  lg: 576,
};

export function PreviewBox({
  preview,
  ratio,
  loading,
  type = 'Image',
  onLoad,
  onObjectsEditorAction,
  objectsEditorDisabled,
  draft,
  updateObject,
  getImageWithObjectKey,
  refreshImage,
  forceLoading,
  outerEl,
  sceneId,
}: Props) {
  const {size} = useSize();
  const [width, height]: [number, number] = useMemo(
    () => ratio.split(':').map(Number) as [number, number],
    [ratio]
  );
  const [[previewWidth, previewHeight], setPreviewSize] = useState([0, 0]);
  const [isLoaded, setIsLoaded] = useState(false);
  const prevPreviewRef = useRef(preview);

  const root = useRef<HTMLDivElement>(null);

  const resizePreview = useCallback(() => {
    const parent = root.current?.parentElement;
    if (!parent) return;
    const maxWidth = parent.clientWidth - 48;
    const minHeight = 160;
    const maxHeight = Math.max(
      Math.min(window.innerHeight - 320, MAX_HEIGHT[size]),
      minHeight
    );
    let _width = maxWidth;
    let _height = maxWidth / (width / height);
    if (_height < minHeight) {
      _height = minHeight;
      _width = _height * (width / height);
    } else if (_height > maxHeight) {
      _height = maxHeight;
      _width = _height * (width / height);
    }
    setPreviewSize([_width, _height]);
  }, [height, size, width]);

  useEffect(() => {
    resizePreview();
    window.addEventListener('resize', resizePreview);
    return () => {
      window.removeEventListener('resize', resizePreview);
    };
  }, [resizePreview]);

  useEffect(() => {
    // 防止useEffect的第二次执行会在onLoad事件之后执行，从而导致loaded始终为false
    if (prevPreviewRef.current === preview) return;

    if (!preview || (type === 'Image' && draft)) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
    prevPreviewRef.current = preview;
  }, [draft, preview, type]);

  const isEmpty = !preview;
  return (
    <div
      className={styles['preview-box']}
      style={{
        width: previewWidth,
        height: previewHeight,
        background: isEmpty ? '#1F1F1F' : undefined,
      }}
      ref={root}
    >
      {((!isLoaded && !isEmpty) || (loading && isEmpty)) && (
        <ImageLoading size={30} />
      )}
      {type === 'Image' &&
        preview &&
        (draft && getImageWithObjectKey && refreshImage ? (
          <ObjectsEditor
            key={sceneId}
            className={styles.preview}
            onLoad={() => {
              setIsLoaded(true);
            }}
            onAction={onObjectsEditorAction}
            draft={draft}
            disabled={objectsEditorDisabled}
            outerHeight={previewHeight}
            outerWidth={previewWidth}
            updateObject={updateObject}
            getImageWithObjectKey={getImageWithObjectKey}
            refreshImage={refreshImage}
            forceLoading={forceLoading}
            outerEl={outerEl}
          />
        ) : (
          <AssetLoader
            type="image"
            className={styles.preview}
            src={preview}
            onLoadedMetadata={onLoad}
            onLoad={() => {
              setIsLoaded(true);
            }}
          />
        ))}
      {type === 'Video' && preview && (
        <AssetLoader
          type="video"
          preload="auto"
          className={styles.preview}
          src={preview}
          controls={isLoaded}
          onLoadedMetadata={e => {
            onLoad && onLoad(e);
            setIsLoaded(true);
          }}
        />
      )}
    </div>
  );
}
