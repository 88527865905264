// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';

import {BilingualDialogueProjectConfig} from './models/BilingualDialogueProjectConfig';
import {BilingualStoryProjectConfig} from './models/BilingualStoryProjectConfig';
import {GeneralStoryProjectConfig} from './models/GeneralStoryProjectConfig';
import {ProjectConfig} from './models/ProjectConfig';
import {ShortVideoProjectConfig} from './models/ShortVideoProjectConfig';

export function getProjectTypeByProjectConfig<T extends ProjectType>(
  config: ProjectConfig<T>
): T {
  if (config instanceof BilingualStoryProjectConfig) {
    return 'bilingual_story' as T;
  } else if (config instanceof GeneralStoryProjectConfig) {
    return 'general_story' as T;
  } else if (config instanceof ShortVideoProjectConfig) {
    return 'short_video' as T;
  } else if (config instanceof BilingualDialogueProjectConfig) {
    return 'bilingual_dialogue' as T;
  } else {
    throw new Error(`Unknown project config type: ${config}`);
  }
}
export function isWithThumbnailByProjectConfig(
  config: ProjectConfig<ProjectType>
): config is
  | GeneralStoryProjectConfig
  | BilingualStoryProjectConfig
  | BilingualDialogueProjectConfig {
  return (
    config instanceof GeneralStoryProjectConfig ||
    config instanceof BilingualStoryProjectConfig ||
    config instanceof BilingualDialogueProjectConfig
  );
}
