// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import axios from 'axios';
import {retryIgnoreError as retryGetThumbnail} from 'lib/retry';
import {thumbnailCombiner} from 'modules/project/utils';
import {useCallback, useEffect, useState} from 'react';

import {ImageLoading} from '../ImageLoading';
import styles from './ThumbnailLoader.module.scss';
import {Props} from './ThumbnailLoader.types';

export function ThumbnailLoader({
  src,
  isProcessing,
  isDisabled,
  ...props
}: Props) {
  const [path, setPath] = useState<string | undefined>(undefined);
  const [prevSrc, setPrevSrc] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const getThumbnail = useCallback(async () => {
    const thumbnailPath = thumbnailCombiner(src);
    await axios.head(thumbnailPath);
    setPath(thumbnailPath);
  }, [src]);

  const initPath = useCallback(async () => {
    setPrevSrc(src);
    try {
      await retryGetThumbnail({times: 5, interval: 1000}, getThumbnail);
    } catch {
      setPath(staticCombiner(src));
    }
  }, [getThumbnail, src]);

  useEffect(() => {
    if (prevSrc !== src && src !== '') {
      initPath();
      setIsLoaded(false);
    } else if (src === '') {
      setIsLoaded(true);
    }
  }, [initPath, prevSrc, src]);

  return (
    <>
      {!src && isLoaded && <div className={styles.empty} />}
      <img
        {...{...props}}
        src={path}
        onLoad={() => {
          setIsLoaded(true);
        }}
      />
      {isDisabled && <div className={styles['disabled-mask']} />}
      {((!src && isProcessing) || !isLoaded) && <ImageLoading />}
    </>
  );
}
