// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Effect, ShortVideoProjectConfigJSON, VoiceoverSpeed} from '../types';
import {BaseProjectConfig} from './BaseProjectConfig';

export class ShortVideoProjectConfig extends BaseProjectConfig {
  static fromJSON({
    voiceover,
    voiceover_speed,
    effect,
    title_style,
    subtitle_style,
    transition,
    bgm,
  }: ShortVideoProjectConfigJSON) {
    return new ShortVideoProjectConfig(
      voiceover,
      voiceover_speed ?? 'fast',
      effect,
      title_style,
      subtitle_style,
      transition,
      bgm
    );
  }

  constructor(
    readonly voiceover: string | null,
    readonly voiceoverSpeed: VoiceoverSpeed,
    readonly effect: Effect,
    readonly titleStyle: string | null,
    readonly subtitleStyle: string | null,
    readonly transition: string | null,
    bgm: string | null
  ) {
    super(bgm);
  }

  patch(data: Partial<ShortVideoProjectConfig>) {
    const {
      voiceover,
      voiceoverSpeed,
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      bgm,
    } = Object.assign({}, this, data);
    return new ShortVideoProjectConfig(
      voiceover,
      voiceoverSpeed,
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      bgm
    );
  }

  toJSON(): ShortVideoProjectConfigJSON {
    return {
      ...super.toJSON(),
      voiceover: this.voiceover,
      voiceover_speed: this.voiceoverSpeed,
      effect: this.effect,
      title_style: this.titleStyle,
      subtitle_style: this.subtitleStyle,
      transition: this.transition,
    };
  }
}
