// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CoinIcon} from 'assets/svg/3.0/Coin.svg';
import {ReactComponent as MootionIcon} from 'assets/svg/3.0/Mootion.svg';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow.svg';
import {HOME_PAGE} from 'utils/path';

import {Header} from '../Header';
import styles from './GenerateHeader.module.scss';
import {Props} from './GenerateHeader.types';

export function GenerateHeader({
  isDisabled,
  isScrollTop,
  languageCode,
  isLogin,
  onGenerate,
}: Props) {
  return (
    <Header
      languageCode={languageCode}
      title={null}
      isScrollTop={isScrollTop}
      right={
        <button
          className={styles['generate-button']}
          disabled={isDisabled}
          onClick={onGenerate}
        >
          Generate
          {isLogin && (
            <span>
              <CoinIcon className={styles.coin} />
              20
            </span>
          )}
          <RightArrowIcon className={styles['right-arrow']} />
        </button>
      }
      showTag={false}
      showBg={false}
    >
      {isLogin ? null : (
        <MootionIcon
          style={{height: '48px', width: 'auto'}}
          onClick={() => {
            location.href = HOME_PAGE + '';
          }}
        />
      )}
    </Header>
  );
}
