// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as AnimateIcon} from 'assets/svg/3.0/Animate.svg';
import {ReactComponent as LoadingSvg} from 'assets/svg/outline/LoadingLine.svg';
import classNames from 'classnames';

type AnimateBtnProps = {
  onClick: () => void;
  styles: {[key: string]: string};
  isDisabled: boolean;
  animatingProgress: number | undefined;
};

export function AnimateBtn({
  onClick,
  styles,
  isDisabled,
  animatingProgress,
}: AnimateBtnProps) {
  return (
    <button
      className={classNames(styles.animate, styles.button)}
      disabled={isDisabled}
      onClick={onClick}
    >
      {animatingProgress !== undefined ? (
        <>
          <LoadingSvg className={styles.rotateLoading} />
          Animating {(animatingProgress * 100).toFixed(0)}%
        </>
      ) : (
        <>
          <AnimateIcon className={styles.icon} />
          Animate
        </>
      )}
    </button>
  );
}
