// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export function drawImage(
  images: {
    image: HTMLImageElement;
    x: number;
    y: number;
    width: number;
    height: number;
  }[],
  width: number,
  height: number
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;

  if (!ctx) throw new Error('Canvas context is not supported');

  images.forEach(({image, x, y, width, height}) => {
    ctx.drawImage(image, x, y, width, height);
  });

  return new Promise<{file: File; url: string}>((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) return reject(new Error('Failed to convert canvas to blob'));
      resolve({
        file: new File([blob], 'combine.jpg', {type: 'image/jpeg'}),
        url: URL.createObjectURL(blob),
      });
    }, 'image/jpeg');
  });
}
