// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useCallback, useRef} from 'react';

export function useInterval(callback: () => void, delay: number) {
  const timer = useRef<number | null>(null);
  const enabled = useRef<boolean>(false);
  const lastTime = useRef<number>(0);

  const start = useCallback(() => {
    enabled.current = true;
    const now = Date.now();
    if (now - lastTime.current >= delay) {
      lastTime.current = now;
      callback();
    }
    if (enabled.current) timer.current = requestAnimationFrame(start);
  }, [callback, delay]);

  const stop = useCallback(() => {
    if (timer.current) cancelAnimationFrame(timer.current);
    timer.current = null;
    enabled.current = false;
    lastTime.current = 0;
  }, []);

  return {start, stop};
}
