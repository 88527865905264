// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {BaseProjectConfigJSON} from '../types';

export abstract class BaseProjectConfig {
  constructor(readonly bgm: string | null) {}

  abstract patch(data: Partial<BaseProjectConfig>): BaseProjectConfig;

  toJSON(): BaseProjectConfigJSON {
    return {
      bgm: this.bgm,
    };
  }
}
