// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import './theme.scss';

import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {EditorRefPlugin} from '@lexical/react/LexicalEditorRefPlugin';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import classNames from 'classnames';
import {LexicalEditor, ParagraphNode, TextNode} from 'lexical';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {CharacterCounter} from './CharacterCounter';
import {LimitedParagraphNode, LimitedTextNode} from './nodes';
import {
  AutoFixLinePlugin,
  BlurPlugin,
  EditorSize,
  ErrorPlugin,
  LimitPlugin,
  PromptPlugin,
  SizePlugin,
} from './plugin';
import styles from './PromptEditor.module.scss';
import {Props} from './PromptEditor.types';

const theme = {
  text: {
    base: 'base-text',
  },
  limitedParagraphNode: {base: 'limited-paragraph', overrun: 'overrun'},
  limitedTextNode: {base: 'base-text', overrun: 'overrun-text'},
};

function onError(error: unknown) {
  console.log(error);
}

function createLexicalConfig(): InitialConfigType {
  return {
    namespace: 'prompt-editor',
    theme,
    nodes: [
      LimitedParagraphNode,
      {
        replace: ParagraphNode,
        with: () => new LimitedParagraphNode(),
      },
      LimitedTextNode,
      {
        replace: TextNode,
        with: (node: TextNode) => new LimitedTextNode(node.getTextContent()),
      },
    ],
    onError,
  };
}

export function PromptEditor({
  value,
  onChange,
  maxParagraphCount,
  maxParagraphCharacterCount,
  maxCharacterCount,
  setInfoMap,
  onBlur,
  setIsError,
  placeholder,
  children,
  onFocusCallBack,
  className,
}: PropsWithChildren<Props>) {
  const [editorSize, setEditorSize] = useState<EditorSize>('small');
  const [isFocused, setIsFocused] = useState(false);
  const [characterNumber, setCharacterNumber] = useState(0);

  const initialConfig = useMemo(() => createLexicalConfig(), []);

  const onPromptChange = useCallback(
    (promptInfo: {
      value: string;
      paragraphNumber: number;
      paragraphsCharacterNumber: number[];
    }) => {
      const {value, paragraphNumber, paragraphsCharacterNumber} = promptInfo;
      onChange(value);
      setInfoMap({
        'paragraph-number': paragraphNumber,
        'paragraphs-character-number': paragraphsCharacterNumber,
        'prompt-character-number': value.length,
      });
      setCharacterNumber(value.length);
    },
    [onChange, setInfoMap]
  );

  const ref = useRef<HTMLDivElement>(null);
  const editorRef = useRef<LexicalEditor>(null);

  useEffect(() => {
    const textarea = ref.current;
    if (!textarea) return;
    const onFocus = () => {
      textarea.focus({preventScroll: true});
      textarea.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    };
    onFocusCallBack && onFocusCallBack(onFocus);
  }, [onFocusCallBack]);

  useEffect(() => {
    const container = ref.current;
    const editor = editorRef.current;
    function onClick() {
      setTimeout(() => {
        editor && editor.focus();
      });
    }
    container && container.addEventListener('mousedown', onClick);
    return () => {
      container && container.removeEventListener('mousedown', onClick);
    };
  }, []);

  return (
    <div
      className={classNames(styles.box, styles[editorSize], {
        [styles.focused]: isFocused,
      })}
      ref={ref}
    >
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className={classNames(styles.container, className)}
              id={initialConfig.namespace}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          }
          placeholder={
            <span className={classNames(styles.placeholder, className)}>
              {placeholder}
            </span>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <SizePlugin editorSize={editorSize} setEditorSize={setEditorSize} />
        <LimitPlugin
          maxParagraphCount={maxParagraphCount}
          maxCharacterCount={maxCharacterCount}
          maxParagraphCharacterCount={maxParagraphCharacterCount}
        />
        <CharacterCounter
          maxCharacterNumber={maxCharacterCount}
          isFocused={isFocused}
          characterNumber={characterNumber}
        />
        {onBlur && <BlurPlugin onBlur={onBlur} />}
        {setIsError && <ErrorPlugin setIsError={setIsError} />}
        <AutoFixLinePlugin />
        <HistoryPlugin />
        <EditorRefPlugin editorRef={editorRef} />
        <PromptPlugin value={value} onPromptChange={onPromptChange} />
      </LexicalComposer>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
