// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import {ReactComponent as DownloadIcon} from 'assets/svg/3.0/Download.svg';
import {ReactComponent as LeftArrowIcon} from 'assets/svg/3.0/LeftArrow.svg';
import {ReactComponent as VideoIcon} from 'assets/svg/3.0/Video.svg';
import classNames from 'classnames';
import {LoadingDialog} from 'components/Loading';
import {VideoDownloadToast} from 'components/VideoDownloadToast';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {useSize} from 'contexts/SizeContext';
import {download} from 'lib/download';
import {noop} from 'lodash';
import {useRawVideoPayment} from 'modules/payment/services/raw-video';
import {
  downloadStoryboard,
  getTitleOrDefault,
  trimNewline,
} from 'modules/project/utils';
import {BilingualDialogueHistory} from 'modules/project-history/models/BilingualDialogueHistory';
import {BilingualStoryHistory} from 'modules/project-history/models/BilingualStoryHistory';
import {dateToUpdateTime} from 'pages/common/date';
import {useCallback, useEffect, useState} from 'react';
import {isRtl} from 'utils/is-rtl';
import {PaymentStatusEnum} from 'utils/stripe';

import {Button} from '../Button';
import {LoadingPrompt} from '../LoadingPrompt';
import {ResultPreviewer} from '../ResultPreviewer';
import styles from './HistoryPreviewer.module.scss';
import {Props} from './HistoryPreviewer.types';

export function HistoryPreviewer({
  resultHistories,
  loading,
  currentHistory,
  projectId,
  outTradeNo,
  paymentStatus,
  type,
  selectedHistoryIndex,
  assetUrl,
  refreshAssetUrl,
  setSelectedHistoryIndex,
  onClearOutTradeNo,
  onCurrentVideoPaymentSuccess,
}: Props) {
  const [storyboardDownloading, setStoryboardDownloading] = useState(false);
  const [isPaymentChecking, setIsPaymentChecking] = useState(false);

  const [downloadToastVisible, setDownloadToastVisible] = useState(false);

  const {getLanguages} = useResourceManager();
  const {checkPaymentStatus} = useRawVideoPayment();
  const {showNotification} = useNotificationContext();
  const languages = getLanguages();
  const assetLoading = assetUrl === undefined;
  const showDownloadToast = !!(
    currentHistory?.assetProduct && assetUrl === null
  );

  const downloadVideo = useCallback(() => {
    download(assetUrl ?? currentHistory.asset, currentHistory.title ?? '');
  }, [assetUrl, currentHistory?.asset, currentHistory?.title]);

  const onCloseDownloadToast = useCallback(() => {
    setDownloadToastVisible(false);
    onClearOutTradeNo && onClearOutTradeNo();
  }, [onClearOutTradeNo]);

  const onVideoPaymentCallback = useCallback(async () => {
    if (outTradeNo && paymentStatus) {
      setIsPaymentChecking(true);
      try {
        if (paymentStatus === PaymentStatusEnum.Success) {
          await checkPaymentStatus(outTradeNo);
          showNotification({message: 'Pay success', type: 'SUCCESS'});
          await refreshAssetUrl();
          selectedHistoryIndex === 0 && onCurrentVideoPaymentSuccess?.();
        } else {
          throw Error('Pay failed');
        }
      } catch (e) {
        showNotification({message: 'Pay failed', type: 'ERROR'});
        setDownloadToastVisible(true);
      }
      setIsPaymentChecking(false);
    }
  }, [
    outTradeNo,
    paymentStatus,
    checkPaymentStatus,
    showNotification,
    refreshAssetUrl,
    selectedHistoryIndex,
    onCurrentVideoPaymentSuccess,
  ]);

  useEffect(() => {
    if (!currentHistory) return;
    onVideoPaymentCallback();
  }, [currentHistory, onVideoPaymentCallback]);

  useEffect(() => {
    if (outTradeNo && paymentStatus && typeof assetUrl === 'string') {
      downloadVideo();
      onCloseDownloadToast();
    }
  }, [
    assetUrl,
    downloadVideo,
    onCloseDownloadToast,
    outTradeNo,
    paymentStatus,
  ]);

  const {size} = useSize();

  const [previewerActive, setPreviewerActive] = useState(false);

  return (
    <div className={styles.container}>
      <LoadingDialog
        dialogShowing={isPaymentChecking}
        onCloseDialog={noop}
        hasText={false}
        size={32}
      />
      <div
        className={classNames(styles.left, {
          [styles.loading]: loading,
          [styles.hide]: previewerActive,
        })}
      >
        {!loading && currentHistory && (
          <>
            <p className={styles.title}>
              <VideoIcon className={styles.icon} />
              <span>History</span>
            </p>
            <div className={styles['history-list']}>
              <ul className={styles.list}>
                {resultHistories.map((item, index) => {
                  return (
                    <li
                      className={classNames(
                        styles['history-item'],
                        index === selectedHistoryIndex && styles.selected
                      )}
                      key={index}
                      onClick={() => {
                        selectedHistoryIndex !== index &&
                          setSelectedHistoryIndex(index);
                        setPreviewerActive(true);
                      }}
                    >
                      <div
                        className={styles.thumbnail}
                        style={{
                          background: `url(${staticCombiner(
                            item.thumbnail
                          )}) no-repeat center center`,
                          backgroundSize: 'cover',
                        }}
                      ></div>
                      <div className={styles.info}>
                        <p
                          className={classNames(styles['project-title'], {
                            'rtl-element-global': isRtl(
                              languages,
                              item.language
                            ),
                          })}
                        >
                          {trimNewline(
                            getTitleOrDefault(
                              item.title,
                              item.language ?? 'en-US'
                            ),
                            item.language ?? 'en-US'
                          )}
                        </p>
                        <p className={styles.time}>
                          {dateToUpdateTime(new Date(`${item.createTime}Z`))}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
        {loading && (size === 'sm' || size === 'xs') && (
          <LoadingPrompt type="processing" />
        )}
      </div>
      <div
        className={classNames(styles.previewer, loading && styles.loading, {
          [styles.active]: previewerActive,
        })}
      >
        <LeftArrowIcon
          className={styles['left-arrow']}
          onClick={() => setPreviewerActive(false)}
        />
        {!loading && currentHistory && (
          <div className={styles.result}>
            <ResultPreviewer
              language={currentHistory.language}
              nativeLanguage={
                currentHistory instanceof BilingualStoryHistory ||
                currentHistory instanceof BilingualDialogueHistory
                  ? currentHistory.nativeLanguage
                  : undefined
              }
              detailWidth={284}
              description={currentHistory.description || ''}
              scenes={currentHistory.scenes}
              size={currentHistory.size}
              title={currentHistory.title || ''}
              video={assetUrl ?? currentHistory.asset}
              hashtags={currentHistory.hashtags || []}
              prompt={
                currentHistory.promptPolicy &&
                currentHistory.promptPolicy.isIdeaPrompt
                  ? currentHistory.prompt
                  : ''
              }
              loading={assetLoading}
            />
          </div>
        )}
        {loading && <LoadingPrompt type="processing" />}
        {!loading && currentHistory && (
          <div className={styles['button-box']}>
            <Button
              type={size === 'xs' ? 'ghost' : 'button'}
              className={styles.button}
              disabled={!currentHistory.scenes}
              onClick={async () => {
                setStoryboardDownloading(true);
                await downloadStoryboard({
                  title: currentHistory.title,
                  description: currentHistory.description,
                  hashtags: currentHistory.hashtags,
                  scenes: currentHistory.scenes,
                  prompt:
                    currentHistory.promptPolicy &&
                    currentHistory.promptPolicy.isIdeaPrompt
                      ? currentHistory.prompt
                      : '',
                });
                setStoryboardDownloading(false);
              }}
              icon={<DownloadIcon className={styles.icon} />}
              isLoading={storyboardDownloading}
            >
              Storyboard
            </Button>
            <Button
              type={size === 'xs' ? 'ghost' : 'button'}
              className={styles.button}
              onClick={() => {
                download(currentHistory.thumbnail, currentHistory.title || '');
              }}
              icon={<DownloadIcon className={styles.icon} />}
            >
              Thumbnail (JPG)
            </Button>
            <Button
              className={styles.button}
              disabled={assetLoading}
              onClick={() => {
                if (!showDownloadToast) {
                  downloadVideo();
                  return;
                }
                setDownloadToastVisible(true);
              }}
              icon={<DownloadIcon className={styles.icon} />}
            >
              Video (MP4)
            </Button>
          </div>
        )}
      </div>
      {currentHistory?.assetProduct && showDownloadToast && (
        <VideoDownloadToast
          isVisible={downloadToastVisible}
          onCancel={onCloseDownloadToast}
          outTradeNo={outTradeNo}
          historyId={currentHistory.id}
          videoId={currentHistory.assetProduct}
          projectId={projectId}
          type={type}
          onDownload={downloadVideo}
        />
      )}
    </div>
  );
}
