// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import React, {cloneElement, useCallback, useState} from 'react';

interface ClickOrDragProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDrag?: () => void;
  threshold?: number;
  children: React.ReactElement; // 确保 children 是单个 React 元素
  [key: string]: unknown;
}

export const ClickOrDrag: React.FC<ClickOrDragProps> = ({
  onClick,
  onDrag,
  threshold = 5,
  children,
  ...props
}) => {
  const [startPos, setStartPos] = useState<{x: number; y: number} | null>(null);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // 调用children自身的onMouseDown，如果有的话
      if (children.props.onMouseDown) {
        children.props.onMouseDown(event);
      }
      setStartPos({x: event.clientX, y: event.clientY});
    },
    [children.props]
  );

  const handleMouseUp = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!startPos) return;
      // 只处理鼠标左键的操作
      if (event.button !== 0) return;

      const deltaX = Math.abs(event.clientX - startPos.x);
      const deltaY = Math.abs(event.clientY - startPos.y);

      if (deltaX < threshold && deltaY < threshold) {
        onClick(event);
      } else {
        onDrag && onDrag();
      }

      setStartPos(null);
      // 调用children自身的onMouseUp，如果有的话
      if (children.props.onMouseUp) {
        children.props.onMouseUp(event);
      }
    },
    [children.props, onClick, onDrag, startPos, threshold]
  );

  // 使用 React.cloneElement 给子元素添加事件处理和属性
  const enhancedChild = cloneElement(children, {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    ...props,
  });

  return enhancedChild; // 直接返回增强后的子元素
};
