// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {setNotShowDialogAgain} from 'contexts/localStorage';
import {PrimaryCheckBox} from 'pages/components/PrimaryCheckBox';
import {useState} from 'react';

import styles from './BackBox.module.scss';
import {Props} from './BackBox.types';

export function BackBox({onBack, onClose}: Props) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={styles.container}>
      <p className={styles.title}>Warning</p>
      <p className={styles.content}>
        Current content will be discarded, are you sure?
      </p>
      <label className={styles.label}>
        <PrimaryCheckBox
          checked={isChecked}
          onChange={e => setIsChecked(e.currentTarget.checked)}
          className={styles.input}
        />
        <span>Do not show again</span>
      </label>
      <div className={styles.buttons}>
        <button
          className={classNames(styles.button, styles.cancel)}
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className={classNames(styles.button, styles.yes)}
          onClick={() => {
            if (isChecked) setNotShowDialogAgain();
            onBack();
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
}
