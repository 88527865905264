// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)

// All Rights Reserved.
type ImageMimetype = 'image/png' | 'image/jpeg' | 'image/webp';

export const mimeToExtMap: {[key: string]: string} = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'image/bmp': 'bmp',
  'image/tiff': 'tiff',
};

export function base64ToUint8Array(base64String: string) {
  if (base64String.startsWith('data:')) {
    base64String = base64String.split(',')[1];
  }
  const byteString = atob(base64String);
  const length = byteString.length;
  const uint8Array = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  return uint8Array;
}
export function getMimeTypeOfBase64(base64String: string): ImageMimetype {
  const result = /^data:(image\/[a-zA-Z]+);base64,/.exec(base64String);
  if (result) {
    return result[1] as ImageMimetype;
  }
  throw new Error('Invalid base64 image');
}
export function base64ToFile(
  base64String: string,
  fileName: string,
  mimeType: ImageMimetype
) {
  const u8arr = base64ToUint8Array(base64String);
  return new File([u8arr], fileName, {
    type: mimeType,
  });
}
//下载图片
export const handleDownload = async (
  blobImageUrl: string,
  filename: string
) => {
  const a = document.createElement('a');
  if (/^http/.test(blobImageUrl)) {
    const res = await fileUrlToBlobUrlAndMineType(blobImageUrl);
    if (res) blobImageUrl = res.blobUrl;
  }
  a.href = blobImageUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  // 下载完成后移除<a>元素
  document.body.removeChild(a);
};

export function getMimetypeByRelativePath(
  relativePath: string
): ImageMimetype | null {
  const ext = relativePath.split('.').pop();
  if (ext === 'png') {
    return 'image/png';
  } else if (ext === 'jpg' || ext === 'jpeg') {
    return 'image/jpeg';
  } else if (ext === 'webp') {
    return 'image/webp';
  } else {
    return null;
  }
}
export function getImageWidthAndHeightByUrl(url: string) {
  return new Promise<{width: number; height: number}>((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      resolve({width: image.width, height: image.height});
    };
    image.onerror = reject;
  });
}
export function getImageFileWidthAndHeight(file: File) {
  return getImageSizeByUrl(URL.createObjectURL(file));
}
export async function getImageSizeByUrl(url: string) {
  const image = await getImageByUrl(url);
  return {width: image.width, height: image.height};
}
export function getImageExtension(filePath: string): string {
  const match = filePath.match(/\.([^.]+)$/);
  if (match) {
    const extension = match[1].toLowerCase();
    const imageExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tiff',
      'svg',
      'webp',
    ];
    if (imageExtensions.includes(extension)) {
      return extension;
    }
  }
  return '';
}
export async function getImageFileSizeFromBLobUrl(imageUrl: string) {
  try {
    const response = await fetch(imageUrl);
    const contentLength = response.headers.get('Content-Length');
    if (contentLength === null) {
      throw new Error('Failed to get Content-Length');
    }
    return parseInt(contentLength, 10); // 转换为数字
  } catch (error) {
    return null;
  }
}
export async function fileUrlToBlobUrlAndMineType(fileUrl: string) {
  try {
    // 使用 fetch 获取文件数据
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    // 使用 URL.createObjectURL 创建 Blob URL
    const blobUrl = URL.createObjectURL(blob);

    return {blobUrl, mimeType: blob.type};
  } catch (error) {
    return null;
  }
}
export async function blobUrlToFile(blobUrl: string, fileName: string) {
  try {
    // 通过 fetch 获取 Blob 对象
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    // 创建 File 对象
    const file = new File([blob], fileName, {type: blob.type});

    return file;
  } catch (error) {
    throw new Error('Failed to convert Blob URL to File');
  }
}
export async function getBlobMimeType(
  blobUrl: string
): Promise<ImageMimetype | null> {
  try {
    // 使用 fetch 获取 Blob 对象
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    // 获取 Blob 对象的 MIME 类型
    const mimeType = blob.type;

    return mimeType as ImageMimetype;
  } catch (error) {
    return null;
  }
}

//克隆blob url
export async function cloneBlobUrl(blobUrl: string) {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return {url: URL.createObjectURL(blob), type: blob.type};
  } catch (error) {
    throw new Error('Failed to clone Blob URL');
  }
}

export function getTransferredSize(
  width: number,
  height: number,
  maxWidth: number,
  maxHeight: number
) {
  let ratio = 1;
  if (width > maxWidth) {
    ratio = maxWidth / width;
  }
  if (height > maxHeight) {
    ratio = Math.min(maxHeight / height, ratio);
  }
  return {width: width * ratio, height: height * ratio};
}

export function getImageByUrl(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = url;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = reject;
  });
}
