// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export async function copy(text: string) {
  // TODO: 需要传入onCopySuccess和onCopyFail，用于提示用户
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    return;
  }
}
