// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

// Uses var for global variable definitions.
/* eslint-disable no-var */

declare global {
  var splashDebug: SplashDebug | null;
}

export class SplashDebug {
  readonly version = process.env.REACT_APP_VERSION;
}

// TODO: 临时实现日志级别的管理，REACT_APP_LOG_LEVEL 定义为 log 的时候才会输出日志。当前
// 的方法也可以控制 Unity 的日志，未来可能需要在 Unity 中实现日志级别的管理，可以提升性能。
type ConsoleLogFunc = (...args: unknown[]) => void;

console.log = (function (originalLog: ConsoleLogFunc) {
  const logLevel = process.env.REACT_APP_LOG_LEVEL;
  return function (...args: unknown[]) {
    if (logLevel === 'log') {
      originalLog.apply(console, args);
    }
  };
})(console.log);
