// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';

const GET_GOOGLE_USER_INFO_URL =
  'https://www.googleapis.com/oauth2/v2/userinfo';

export async function getGoogleUserInfo(accessToken: string) {
  return (
    await axios.get(GET_GOOGLE_USER_INFO_URL + '?access_token=' + accessToken)
  ).data;
}
