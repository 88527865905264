// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import PlayingIcon from 'assets/images/playing.gif';
import {ReactComponent as FeMaleIcon} from 'assets/svg/3.0/FemaleIcon.svg';
import {ReactComponent as Lock} from 'assets/svg/3.0/Lock.svg';
import {ReactComponent as MaleIcon} from 'assets/svg/3.0/MaleIcon.svg';
import {ReactComponent as NoneIcon} from 'assets/svg/3.0/None.svg';
import VoiceoverIcon from 'assets/svg/3.0/Voiceover.svg';
import classNames from 'classnames';
import {Option} from 'components/Option';
import {useResourceManager} from 'contexts/ResourceManager';
import {useUserContext} from 'contexts/UserContext';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import styles from './VoiceoverList.module.scss';
import {Props} from './VoiceoverList.types';

export function VoiceoverList({
  voiceover,
  voiceovers,
  onChange,
  showNone,
  updatePosition,
}: Props) {
  const {resUrl} = useResourceManager();
  const [voiceoverPlayingOfPath, setVoiceoverPlayingOfPath] = useState<
    string | null
  >(null);
  const [showMore, setShowMore] = useState(false);
  const voiceOverList = useMemo(
    () => (showMore ? voiceovers : voiceovers.slice(0, 8)),
    [showMore, voiceovers]
  );
  const hasShowMore = useMemo(() => voiceovers.length > 8, [voiceovers.length]);
  const {
    userInfo: {plan},
  } = useUserContext();

  const audioRef = useRef<HTMLAudioElement>(document.createElement('audio'));

  const onStopVoiceover = useCallback(() => {
    setVoiceoverPlayingOfPath(null);
    audioRef.current.pause();
  }, []);

  const onPlayVoiceover = useCallback(
    async (path: string) => {
      setVoiceoverPlayingOfPath(path);
      audioRef.current.src = `${resUrl}${path}`;
      audioRef.current.onended = onStopVoiceover;
      await audioRef.current.play();
    },
    [onStopVoiceover, resUrl]
  );

  const onVoiceoverAction = useCallback(
    (audio: string) => {
      onStopVoiceover();
      if (!voiceoverPlayingOfPath || voiceoverPlayingOfPath !== audio) {
        onPlayVoiceover(audio);
      }
    },
    [onPlayVoiceover, onStopVoiceover, voiceoverPlayingOfPath]
  );

  useEffect(() => {
    return onStopVoiceover;
  }, [onStopVoiceover]);

  useEffect(() => {
    updatePosition && updatePosition();
  }, [showMore, updatePosition]);

  return (
    <>
      <ul className={styles.container}>
        {showNone && (
          <li className={styles.item}>
            <Option
              isDisabled={false}
              label={'None'}
              isChecked={voiceover === null}
              onCheck={() => {
                if (voiceover === null) return;
                onChange(null);
              }}
            />
            <NoneIcon className={styles.svg} />
          </li>
        )}
        {voiceOverList.map(
          ({
            display_name: displayName,
            name,
            id,
            audio,
            gender = 'Male',
            tags = [],
            supported_plans = ['FREE', 'STANDARD', 'PRO'],
          }) => (
            <li key={id} className={styles.item}>
              <Option
                isDisabled={!supported_plans.includes(plan)}
                label={
                  <div className={styles.title}>
                    {gender === 'Male' ? <MaleIcon /> : <FeMaleIcon />}
                    <div>{displayName}</div>
                    <div className={styles.labels}>
                      {tags.map(tag => (
                        <div key={tag} className={styles.label}>
                          {tag}
                        </div>
                      ))}
                      {supported_plans.includes(
                        'FREE'
                      ) ? null : supported_plans.includes('STANDARD') ? (
                        <div
                          className={classNames(styles.label, styles.standard)}
                        >
                          {plan === 'FREE' && <Lock />}
                          <span>Standard</span>
                        </div>
                      ) : (
                        <div className={classNames(styles.label, styles.pro)}>
                          {(plan === 'FREE' || plan === 'STANDARD') && <Lock />}
                          <span>Pro</span>
                        </div>
                      )}
                    </div>
                  </div>
                }
                isChecked={name === voiceover}
                onCheck={() => {
                  if (name === voiceover) return;
                  onChange(name);
                }}
              />
              <img
                className={styles.icon}
                src={
                  voiceoverPlayingOfPath === audio ? PlayingIcon : VoiceoverIcon
                }
                onClick={() => onVoiceoverAction(audio)}
              />
            </li>
          )
        )}
      </ul>
      {hasShowMore && (
        <div className={styles.showMore}>
          <span
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            Show {showMore ? 'less' : 'more'}
          </span>
        </div>
      )}
    </>
  );
}
