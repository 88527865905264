// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  AssetActionType,
  EditAssetProps,
} from 'components/AssetEditor/AssetEditor.types';
import {AssetItemType} from 'pages/WorkspacePage/WorkspacePage.types';

// import {AssetItemType} from '../WorkspacePage.types';

export type AssetsLibraryProps = {
  assetItemList: AssetItemType[] | null;
  deleteConfirmVisible: boolean;
  tempList: tempListItemType[] | null;
  isAssetsFetchLoading: boolean;
  loadMoreRef: React.RefObject<SVGSVGElement>;
  hasMore: boolean;
  errorToastVisible: boolean;
  errorToastType?: ErrorType;
  assetEditorModalVisible: boolean;
  assetImgUrl: string;
  actionType: AssetActionType;
  imageInfoRef: React.MutableRefObject<{
    width: number;
    height: number;
    filename: string;
    mimeType: string;
  }>;

  deleteAssetById: (assetId: string) => void;
  refreshAssetList: (
    refreshAssetListCallback?: (() => void) | undefined
  ) => void;
  hideDeleteConfirmModal: () => void;
  onConfirmDelete: () => void;
  handleUploadTempList: (tempList: tempListItemType[]) => void;
  updateBackupInfo: (assetId: string, imgUrl: string) => void;
  handleImageError: (event: React.SyntheticEvent<HTMLImageElement>) => void;
  hideErrorToast: () => void;
  handleApply: EditAssetProps['handleApply'];
  handleUpload: UseUploadAssetHookReturn['handleUpload'];
  uploadFileAndEditAsset: ({
    file,
    blobImageUrl,
  }: {
    file: File;
    blobImageUrl: string;
  }) => void;
  hideAssetEditorModal: () => void;
  showAssetEditorModal: () => void;
  setAssetImgUrl: React.Dispatch<React.SetStateAction<string>>;
  setActionType: React.Dispatch<React.SetStateAction<AssetActionType>>;
  openAssetEditorModal: ({
    file,
    blobImageUrl,
  }: {
    file: File;
    blobImageUrl: string;
  }) => void;
};
export type AssetsLibraryHookReturn = Pick<
  AssetsLibraryProps,
  | 'assetItemList'
  | 'deleteAssetById'
  | 'refreshAssetList'
  | 'deleteConfirmVisible'
  | 'hideDeleteConfirmModal'
  | 'onConfirmDelete'
  | 'handleUploadTempList'
  | 'tempList'
  | 'updateBackupInfo'
  | 'handleImageError'
  | 'isAssetsFetchLoading'
  | 'loadMoreRef'
  | 'hasMore'
  | 'handleApply'
  | 'errorToastVisible'
  | 'errorToastType'
  | 'hideErrorToast'
  | 'handleUpload'
  | 'uploadFileAndEditAsset'
  | 'assetEditorModalVisible'
  | 'hideAssetEditorModal'
  | 'showAssetEditorModal'
  | 'assetImgUrl'
  | 'setAssetImgUrl'
  | 'actionType'
  | 'setActionType'
  | 'imageInfoRef'
  | 'openAssetEditorModal'
>;
export type UseDeleteAssetHookProps = {
  deleteCallback: (id: string, attr: keyof AssetItemType) => void;
};
export type UseDeleteAssetHookReturn = {
  deleteConfirmVisible: boolean;
  deleteAssetById: (assetId: string) => void;
  onConfirmDelete: () => void;
  hideDeleteConfirmModal: () => void;
};
export type UseUploadAssetHookProps = {
  uploadCallback: (callback?: () => void) => void;
};
export enum ErrorType {
  Capacity = 'Capacity',
  UploadFailed = 'UploadFailed',
}
export type UseUploadAssetHookReturn = {
  tempList: tempListItemType[] | null;
  errorToastVisible: boolean;
  errorToastType?: ErrorType;
  handleUploadTempList: AssetsLibraryHookReturn['handleUploadTempList'];
  updateBackupInfo: (assetId: string, imgUrl: string) => void;
  handleImageError: (event: React.SyntheticEvent<HTMLImageElement>) => void;
  setUploadedImageUrl: (url: string | null) => void;
  handleUpload: (
    fileInfo: {
      file: File;
      blobImageUrl: string;
    },
    uploader: 'system' | 'user'
  ) => Promise<{objectKey: string; blobImageUrl: string}>;
  hideErrorToast: () => void;
};
export type tempListItemType = {
  uploadProgress: number;
  imgUrl: string;
};
