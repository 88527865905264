// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import styles from './ToolTip.module.scss';
import {Props} from './ToolTip.types';

export function ToolTip({content, isShowing, width = 214}: Props) {
  if (!isShowing) return null;

  return (
    <div className={styles.container} style={{width}}>
      <div className={styles.triangle} />
      {content}
    </div>
  );
}
