// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as BackToTopIcon} from 'assets/svg/3.0/BackToTop.svg';
import classNames from 'classnames';

import styles from './BackToTop.module.scss';

export function BackToTop({onBackToTop}: {onBackToTop(): void}) {
  return (
    <div className={styles['back-to-top-wrapper']}>
      <div
        className={classNames(styles['back-to-top'], 'back-to-top')}
        onClick={onBackToTop}
      >
        <BackToTopIcon />
        <div className={styles.button}>Back to top</div>
      </div>
    </div>
  );
}
