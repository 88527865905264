// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {fromJSON} from 'modules/ai-frontend/models/Task';
import {
  MotionParams,
  StoryboardAction,
  StoryboardParams,
  TaskParams,
  TaskSchema,
  TaskType,
  UploadFormParams,
  UploadFormSchema,
  UploadFormTarget,
} from 'modules/ai-frontend/types';

import {APIClient} from './Client';

export class AIFrontendClient extends APIClient {
  async preSubmitTask<
    T extends TaskType,
    P extends StoryboardParams | MotionParams | null,
    A = P extends StoryboardParams
      ? P extends {action: infer A}
        ? A
        : never
      : never,
    R = T extends TaskType.Storyboard
      ? A extends StoryboardAction
        ? never
        : {estimatedWaitingTime: number}
      : T extends TaskType.ImageConditioningVideo
      ? {
          estimatedWaitingTime: number;
          availableQuota: number;
          price: number;
          availableCredit: number;
        }
      : never
  >(
    type: T,
    params: T extends TaskType.Storyboard
      ? P extends StoryboardParams
        ? Pick<P, 'template'> &
            (A extends StoryboardAction ? {action: A} : unknown)
        : never
      : T extends TaskType.Motion
      ? P extends MotionParams
        ? P
        : never
      : null
  ): Promise<R> {
    const {headers} = await this.a.head(type, {params});
    if (type === TaskType.Storyboard) {
      if (params && Object.hasOwn(params, 'action')) {
        throw new Error('Unsupported action');
      } else {
        return {
          estimatedWaitingTime: +headers['x-st-estimated-waiting-time'],
        } as R;
      }
    } else if (type === TaskType.ImageConditioningVideo) {
      return {
        estimatedWaitingTime: +headers['x-st-estimated-waiting-time'],
        availableQuota: +headers['x-st-available-quota'],
        price: +headers['x-st-price'],
        availableCredit: +headers['x-st-available-credit'],
      } as R;
    } else {
      throw new Error('Unsupported task type');
    }
  }

  async createTask<
    T extends TaskType,
    P extends StoryboardParams | MotionParams | null
  >(type: T, args: TaskParams<T, P>) {
    const {data} = await this.a.post<string>(type, args.data, {
      ...('params' in args ? {params: args.params} : {}),
      ...('projectId' in args
        ? {
            headers: {
              'X-ST-PROJECT-ID': args.projectId,
            },
          }
        : {}),
    });
    return data;
  }

  async findTask<T extends TaskType>(type: T, id: string) {
    const {data} = await this.a.get<TaskSchema<T>>(`${type}/${id}`);
    return fromJSON(type, data);
  }

  async cancelTask(id: string) {
    await this.a.put<void>(`/tasks/${id}/cancel`);
  }

  async createUploadForm<T extends UploadFormTarget>(
    ...[target, data]: UploadFormParams<T>
  ) {
    const {data: form} = await this.a.post<UploadFormSchema<T>>(
      '/assets/upload-form',
      data,
      {params: {target}}
    );
    return form;
  }

  async getAssetUrl(assetProduct: string) {
    const {data} = await this.a.get<string>(`/asset-products/${assetProduct}`);
    return data;
  }
}
