// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './InfoLabel.module.scss';
import {Props} from './InfoLabel.types';

export function InfoLabel({icon, label, children, right, className}: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <div className={styles.label}>
          <span className={styles.left}>
            {icon}
            <span className={styles.text} style={{marginLeft: icon ? 8 : 0}}>
              {label}
            </span>
          </span>
          {right && <div className={styles.right}>{right}</div>}
        </div>
      )}
      {children}
    </div>
  );
}
