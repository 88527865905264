// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as RedoIcon} from 'assets/svg/outline/Redo.svg';
import classNames from 'classnames';
import {Button} from 'components/Button';
import {useMemo, useRef, useState} from 'react';

import styles from './PromptTag.module.scss';
type Props = {
  tags: string[];
  onClick: (prompt: string) => void;
};
const moveDistanceOnceTime = 32;

export function PromptTag({tags, onClick}: Props) {
  const [translateY, setTranslateY] = useState(0); // 用来保存当前的 translateY 值
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [isRotate, setIsRotate] = useState(false);

  const finalTags = useMemo(() => {
    const result = [...tags];
    while (result.length % 3 !== 0 || result.length % 2 !== 0) {
      result.push(...tags);
    }
    return result;
  }, [tags]);

  const onChangeTag = () => {
    if (!tagContainerRef.current) {
      return;
    }

    setIsRotate(true); // 开始旋转图标
    const height = tagContainerRef.current.clientHeight;

    // 判断是否已经滚动到底部，如果是则重置到顶部，否则向上滚动 64px
    if (height + translateY <= moveDistanceOnceTime) {
      setTranslateY(0);
    } else {
      setTranslateY(translateY - moveDistanceOnceTime);
    }

    setTimeout(() => {
      setIsRotate(false); // 400ms 后停止旋转
    }, 400);
  };
  const onTagClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick((e.target as HTMLDivElement).innerText || '');
  };
  return (
    <div className={styles['prompt-tag']}>
      <div className={styles['prompt-tag-box']}>
        <div
          className={styles['prompt-tag-wrap']}
          ref={tagContainerRef}
          style={{transform: `translateY(${translateY}px)`}}
        >
          {finalTags.map((tag, index) => (
            <div
              className={styles['tag']}
              key={`${tag}-${index}`}
              onClick={onTagClick}
            >
              <div className={styles['tag-content']}>{tag}</div>
            </div>
          ))}
        </div>
      </div>

      <Button
        icon={<RedoIcon />}
        type="link"
        className={classNames(
          styles['prompt-tag-button'],
          isRotate && styles['rotate']
        )}
        onClick={onChangeTag}
      >
        Change
      </Button>
    </div>
  );
}
