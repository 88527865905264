// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {EditorConfig, LexicalNode, NodeKey, ParagraphNode} from 'lexical';

export class LimitedParagraphNode extends ParagraphNode {
  static getType() {
    return 'limited-paragraph';
  }

  static clone(node: LimitedParagraphNode) {
    const ret = new LimitedParagraphNode(node.__key);
    ret.dom = node.dom;
    ret.config = node.config;
    return ret;
  }

  private dom: HTMLParagraphElement | null = null;
  private config: EditorConfig | null = null;

  constructor(key?: NodeKey) {
    super(key);
  }

  createDOM(config: EditorConfig) {
    const dom = document.createElement('p');
    const theme = config.theme;
    const className = theme.customParagraph;
    if (className !== undefined) {
      dom.className = className;
    }
    if (theme.limitedParagraphNode) {
      dom.classList.add(theme.limitedParagraphNode.base);
    }
    this.dom = dom;
    this.config = config;
    return dom;
  }

  hasError() {
    return this.dom?.classList.contains('over-limit-scene') || false;
  }

  setIsError(isError: boolean) {
    if (!this.dom || !this.config) return;
    const theme = this.config.theme;
    if (theme['limitedParagraphNode']) {
      this.dom.classList.toggle('over-limit-scene', isError);
    }
  }

  updateDOM() {
    return false;
  }

  static importJSON(): LimitedParagraphNode {
    const node = $createLimitedParagraph();
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: LimitedParagraphNode.getType(),
      version: 1,
    };
  }
}

export function $createLimitedParagraph(): LimitedParagraphNode {
  return new LimitedParagraphNode();
}

export function $isLimitedParagraphNode(
  node: LexicalNode | null | undefined
): node is LimitedParagraphNode {
  return node instanceof LimitedParagraphNode;
}
