// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {CompositionPage} from 'components/CompositionPage';
import {ConfigPage} from 'components/ConfigPage';
import {PromptPage} from 'components/PromptPage';
import {StoryboardPage} from 'components/StoryboardPage';
import {useAPI} from 'contexts/APIContext';
import {ErrorType, useError} from 'contexts/ErrorContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {formatAspectRatio} from 'lib/ratio';
import {BilingualDialogueProject} from 'modules/project/models/BilingualDialogueProject';
import {BilingualStoryProject} from 'modules/project/models/BilingualStoryProject';
import {patch as patchProject} from 'modules/project/models/Project';
import {ProjectType} from 'modules/project/types';
import {
  makeStoryboard,
  ProjectCacheSessionStorageMap,
} from 'modules/project/utils';
import {History} from 'modules/project-history/models/History';
import {makeComposition} from 'modules/storyboard/utils';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {isRtl} from 'utils/is-rtl';
import {generateIdPath, PROJECT_PATH} from 'utils/path';

import {Props} from './ProjectPage.types';

export function ProjectPage({
  project,
  updateProject,
  updateStoryboard,
  updateConfig,
  updateComposition,
  saveHistory,
  createProject,
  preference,
  updatePreference,
}: Props) {
  const {isResLoaded, getLanguages} = useResourceManager();
  const languages = getLanguages();
  const {backendClient} = useAPI();
  const navigate = useNavigate();
  const {report} = useError();

  useEffect(() => {
    if (project.id || project.storyboard) return;
    sessionStorage.setItem(
      ProjectCacheSessionStorageMap[project.type],
      JSON.stringify(project)
    );
  }, [project]);

  useEffect(() => {
    if (project.id || !project.storyboard) return;
    (async () => {
      try {
        const id = await createProject(project);
        sessionStorage.removeItem(ProjectCacheSessionStorageMap[project.type]);
        navigate(generateIdPath(PROJECT_PATH, id), {replace: true});
      } catch {
        updateProject(project => project.patch({storyboard: undefined}), false);
        report(ErrorType.Service);
      }
    })();
  }, [project, createProject, navigate, updateProject, report]);

  useEffect(() => {
    return () => updatePreference(prev => prev, false);
  }, [updatePreference]);

  if (!isResLoaded) {
    return null;
  }
  let child;
  if (project.composition !== undefined) {
    child = (
      <CompositionPage
        composition={project.composition}
        projectId={project.id}
        projectConfig={project.config}
        histories={project.histories || []}
        updateComposition={updateComposition}
        updateProject={updateProject}
        storyboardTasks={project.storyboard?.tasks ?? []}
        onBack={() => {
          saveHistory(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            project.composition!,
            project.histories ?? [],
            project.storyboard?.tasks ?? [],
            async (history: History<ProjectType>) => {
              await backendClient.uploadHistory(project.id, history);
            }
          );
          updateProject(
            project => project.patch({composition: undefined}),
            true
          );
        }}
        onBackToInitPage={() => {
          saveHistory(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            project.composition!,
            project.histories ?? [],
            project.storyboard?.tasks ?? [],
            async (history: History<ProjectType>) => {
              await backendClient.uploadHistory(project.id, history);
            }
          );
          updateProject(
            project.patch({
              storyboard: undefined,
              composition: undefined,
            }),
            true
          );
        }}
      />
    );
  } else if (project.storyboard !== undefined) {
    if (project.storyboard.closed) {
      child = (
        <ConfigPage
          config={project.config}
          characters={project.storyboard!.characters}
          language={project.language}
          nativeLanguage={
            project instanceof BilingualStoryProject ||
            project instanceof BilingualDialogueProject
              ? project.nativeLanguage
              : ''
          }
          title={project.storyboard.title ?? ''}
          thumbnail={project.thumbnail ?? ''}
          ratio={formatAspectRatio(project.size)}
          updateConfig={updateConfig}
          onBack={() => {
            updateStoryboard(project.storyboard!.patch({closed: false}), false);
          }}
          onNext={() => {
            updateProject(
              patchProject(project, {
                composition: makeComposition(project.storyboard!),
              } as Partial<typeof project>),
              false
            );
          }}
          updatePreference={updatePreference}
        />
      );
    } else {
      child = (
        <StoryboardPage
          constraint={project.constraint}
          projectId={project.id}
          storyboard={project.storyboard}
          updateStoryboard={updateStoryboard}
          languageCode={project.language}
          onBack={() => {
            const newProject = project.patch({storyboard: undefined});
            updateProject(newProject, false); //清空storyboard
          }}
        />
      );
    }
  } else {
    child = (
      <PromptPage
        project={project}
        updateProject={updateProject}
        onGenerate={() => {
          updateProject(
            patchProject(project, {
              storyboard: makeStoryboard(project),
            } as Partial<typeof project>),
            false
          );
        }}
        pageType="projectPage"
        preference={preference}
        updatePreference={updatePreference}
      />
    );
  }
  return (
    <div
      className={classNames({
        rtl: isRtl(languages, project.language),
        ['rtl-bilingual']:
          (project instanceof BilingualStoryProject ||
            project instanceof BilingualDialogueProject) &&
          isRtl(languages, project.nativeLanguage),
      })}
      style={{
        height: 'inherit',
        overflow: 'inherit',
      }}
    >
      {child}
    </div>
  );
}
