// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Draft} from 'modules/draft/models/Draft';
import {Task} from 'modules/task/models/Task';

import {BaseClosedScene, BaseSceneJSON, TaskType} from '../types';

export abstract class BaseScene {
  constructor(
    readonly id: string,
    readonly type: string,
    readonly prompt?: string,
    readonly lastPrompt?: string,
    readonly subtitle?: string,
    readonly shotType?: number,
    readonly characters?: BaseSceneJSON['characters'],
    readonly image?: string,
    readonly video?: string,
    readonly draft?: Draft,
    readonly task?: Task<TaskType>
  ) {}

  isValid() {
    return !!this.currentImage && !!this.subtitle;
  }

  abstract patch(data: Partial<BaseScene>): BaseScene;

  toClosed(): BaseClosedScene {
    return {
      type: this.type,
      prompt: this.currentPrompt,
      subtitle: this.subtitle,
      shot_type: this.shotType,
      characters: this.characters,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      image: this.currentImage!,
      video: this.video,
    };
  }

  toJSON(): BaseSceneJSON {
    return {
      id: this.id,
      type: this.type,
      prompt: this.prompt,
      last_prompt: this.lastPrompt,
      subtitle: this.subtitle,
      shot_type: this.shotType,
      characters: this.characters,
      image: this.image,
      video: this.video,
      draft: this.draft?.toJSON(),
      task: this.task?.id,
    };
  }
  get currentPrompt() {
    return this.prompt ?? this.lastPrompt;
  }

  get currentImage() {
    return this.video ? this.image : this.draft?.image ?? this.image;
  }

  get canAnimate() {
    return !!this.image;
  }
}
