// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as server from 'api/server';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import {useLocation} from 'react-router-dom';
import {reportEventToGoogle} from 'utils/gtag';

import {getActiveUserId, setActiveUserId} from './localStorage';
import {useUserContext} from './UserContext';

type ENV = 'dev' | 'stage' | 'prod';

type CommonEvent =
  | 'Google-Signup'
  | 'Discord-Signup'
  | 'Email-Signup'
  | 'Email-Verified'
  | 'Referral'
  | 'Google-Button-Click'
  | 'Discord-Button-Click'
  | 'Download-Video';

type SubscriptionEvent = 'Subscription';
type PageViewEvent = 'Page-View';

export type AnalysisEventType = CommonEvent | SubscriptionEvent | PageViewEvent;

interface EventInfo {
  googleAdsId?: string;
  googleAnalyticsEvent?: string;
  serverPath?: string;
}

const REPORT_INFO_MAP: Record<ENV, Record<AnalysisEventType, EventInfo>> = {
  dev: {
    Subscription: {
      googleAdsId: 'N5LBCP3fnbwZELqUouUq',
      googleAnalyticsEvent: 'storyteller_subscription',
    },
    'Google-Signup': {
      serverPath: 'new',
      googleAnalyticsEvent: 'signup_by_google',
    },
    'Discord-Signup': {
      serverPath: 'new',
      googleAnalyticsEvent: 'signup_by_discord',
    },
    'Google-Button-Click': {
      googleAnalyticsEvent: 'click_signup_by_google',
    },
    'Discord-Button-Click': {googleAnalyticsEvent: 'click_signup_by_discord'},
    'Email-Verified': {
      serverPath: 'new',
      googleAnalyticsEvent: 'email_verified',
      googleAdsId: 'C8pyCNyK-L0ZELqUouUq',
    },
    'Email-Signup': {
      googleAnalyticsEvent: 'signup_by_email',
      googleAdsId: 'sQwlCIyC-L0ZELqUouUq',
    },
    'Page-View': {
      googleAnalyticsEvent: 'storyteller_pageview',
      serverPath: 'active',
    },
    Referral: {
      googleAnalyticsEvent: 'referral',
    },
    'Download-Video': {
      googleAnalyticsEvent: 'video_download',
    },
  },
  stage: {
    Subscription: {
      googleAdsId: 'N5LBCP3fnbwZELqUouUq',
      googleAnalyticsEvent: 'storyteller_subscription',
    },
    'Google-Signup': {
      serverPath: 'new',
      googleAnalyticsEvent: 'signup_by_google',
    },
    'Discord-Signup': {
      serverPath: 'new',
      googleAnalyticsEvent: 'signup_by_discord',
    },
    'Google-Button-Click': {
      googleAnalyticsEvent: 'click_signup_by_google',
    },
    'Discord-Button-Click': {googleAnalyticsEvent: 'click_signup_by_discord'},
    'Email-Verified': {
      serverPath: 'new',
      googleAnalyticsEvent: 'email_verified',
      googleAdsId: 'C8pyCNyK-L0ZELqUouUq',
    },
    'Email-Signup': {
      googleAnalyticsEvent: 'signup_by_email',
      googleAdsId: 'sQwlCIyC-L0ZELqUouUq',
    },
    'Page-View': {
      googleAnalyticsEvent: 'storyteller_pageview',
      serverPath: 'active',
    },
    Referral: {
      googleAnalyticsEvent: 'referral',
    },
    'Download-Video': {
      googleAnalyticsEvent: 'video_download',
    },
  },
  prod: {
    Subscription: {
      googleAdsId: '0e_HCMLl_MsZEOX5wJA-',
      googleAnalyticsEvent: 'storyteller_subscription',
    },
    'Google-Signup': {
      serverPath: 'new',
      googleAnalyticsEvent: 'signup_by_google',
      googleAdsId: 'SEwECMT38ssZEOX5wJA-',
    },
    'Discord-Signup': {
      serverPath: 'new',
      googleAnalyticsEvent: 'signup_by_discord',
      googleAdsId: 'SEwECMT38ssZEOX5wJA-',
    },
    'Google-Button-Click': {
      googleAnalyticsEvent: 'click_signup_by_google',
    },
    'Discord-Button-Click': {googleAnalyticsEvent: 'click_signup_by_discord'},
    'Email-Verified': {
      serverPath: 'new',
      googleAnalyticsEvent: 'email_verified',
      googleAdsId: 'SEwECMT38ssZEOX5wJA-',
    },
    'Email-Signup': {
      googleAnalyticsEvent: 'signup_by_email',
      googleAdsId: 'jOBZCJzo770ZEOmog9E9',
    },
    'Page-View': {
      googleAnalyticsEvent: 'storyteller_pageview',
      serverPath: 'active',
    },
    Referral: {
      googleAnalyticsEvent: 'referral',
    },
    'Download-Video': {
      googleAnalyticsEvent: 'video_download',
    },
  },
};

function reportEvent(
  event: SubscriptionEvent,
  config: {data: {transaction_id: string}; userId?: string}
): void;

function reportEvent(event: CommonEvent, config?: {userId?: string}): void;

function reportEvent(
  event: PageViewEvent,
  config: {data: {path: string}; userId?: string}
): void;

function reportEvent(
  event: AnalysisEventType,
  config?: {data?: Record<string, unknown>; userId?: string}
) {
  const env = process.env.REACT_APP_ENV as ENV;

  const info = REPORT_INFO_MAP[env][event];
  if (!info) return;

  if (info.googleAdsId) {
    reportEventToGoogle('conversion', {
      send_to: `${process.env.REACT_APP_G_TAG_ADS_ID}/${info.googleAdsId}`,
      ...config?.data,
      user_id: config?.userId,
    });
  }

  if (info.googleAnalyticsEvent) {
    reportEventToGoogle(info.googleAnalyticsEvent, {
      event_name: info.googleAnalyticsEvent,
      send_to: process.env.REACT_APP_G_TAG_ANALYTICS_ID,
      ...config?.data,
      user_id: config?.userId,
    });
  }

  if (info.serverPath) {
    if (event === 'Page-View') {
      // 如果缓存期间有过记录，则不再记录，如果userId变化则重新记录
      const userId = getActiveUserId();
      if (!config?.userId || config.userId === userId) return;
      setActiveUserId(config?.userId as string);
    }
    server.reportEvent(info.serverPath);
  }
}

type ReportEvent = typeof reportEvent;

interface AnalysisContext {
  recordEvent: ReportEvent;
}

const analysisContext = createContext<AnalysisContext>({
  recordEvent: (_type: AnalysisEventType) => undefined,
});

export function useAnalysis() {
  return useContext(analysisContext);
}

export function AnalysisProvider({children}: PropsWithChildren) {
  const location = useLocation();
  const {userInfo} = useUserContext();

  const recordEvent: ReportEvent = useCallback(
    (event, config) => {
      const userId = config?.userId ?? userInfo?.userId;
      reportEvent(...([event, {...config, userId}] as Parameters<ReportEvent>));
    },
    [userInfo?.userId]
  );

  useEffect(() => {
    recordEvent('Page-View', {
      data: {path: location.pathname},
    });
  }, [location.pathname, recordEvent]);

  return (
    <analysisContext.Provider value={{recordEvent}}>
      {children}
    </analysisContext.Provider>
  );
}
