// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {TokenResponse, useGoogleLogin} from '@react-oauth/google';
import {getGoogleUserInfo} from 'api/googleApi';
import * as apiServer from 'api/server';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {
  clearCampaignId,
  clearReferralCode,
  getCampaignId,
  getReferralCode,
} from 'contexts/localStorage';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useUserContext} from 'contexts/UserContext';
import {useCallback} from 'react';
import {useOutletContext} from 'react-router-dom';
import {WORKSPACE_PAGE_PATH} from 'utils/path';
import {bindInviterAndInvitee} from 'utils/rewardful';

import {OutletContext} from '../AuthPage.types';
import {GoogleButton} from './GoogleButton';
import {HookReturn, useHooksParameters} from './GoogleButton.types';

function useHook({
  setErrorToastType,
  showErrorToast,
  googleLoading,
  setGoogleLoading,
}: useHooksParameters): HookReturn {
  const {changeNextPath}: OutletContext = useOutletContext();
  const {updateUserLogin} = useUserContext();
  const {showNotification} = useNotificationContext();
  const {recordEvent} = useAnalysis();

  const googleLoginHandler = useGoogleLogin({
    onSuccess: onSuccessGoogleLogin,
    onError: onErrorGoogleLogin,
    onNonOAuthError: () => setGoogleLoading(false),
  });

  const _googleLoginHandler = useCallback(() => {
    if (googleLoading) return;
    setGoogleLoading(true);
    googleLoginHandler();
  }, [googleLoading, googleLoginHandler, setGoogleLoading]);

  async function onSuccessGoogleLogin(
    tokenResponse: Omit<
      TokenResponse,
      'error' | 'error_description' | 'error_uri'
    >
  ) {
    setGoogleLoading(true);
    const googleUserInfo = await getGoogleUserInfo(tokenResponse.access_token);
    const {data, code} = await apiServer.googleAccount(
      tokenResponse.access_token,
      googleUserInfo.email,
      googleUserInfo.name
    );

    if (data.newRegisteredUser === true) {
      recordEvent('Google-Signup', {userId: data.userId});
    }
    const loginWithGoogle = async function () {
      updateUserLogin(
        data.email,
        data.userName,
        data.userId,
        data.portrait,
        data.token,
        data.emailConfirmStatus
      );

      if (data.newRegisteredUser === true) {
        bindInviterAndInvitee();
        const referralCode = getReferralCode();
        if (referralCode) {
          await apiServer.recordReferralCode(referralCode);
          clearReferralCode();
        }

        const campaignId = getCampaignId();
        if (campaignId) {
          await apiServer.recordCampaignId(campaignId);
          clearCampaignId();
        }
      }

      changeNextPath(WORKSPACE_PAGE_PATH);
    };
    if (data.accountMergeStatus === true) {
      setErrorToastType('mergeAccountError');
      showErrorToast(true, googleUserInfo.email, loginWithGoogle);
      return;
    }
    if (code === apiServer.SUCCESS_STATUS_CODE) {
      loginWithGoogle();
    }
  }

  function onErrorGoogleLogin() {
    showNotification({
      type: 'ERROR',
      message: 'Failed to connect google, try again',
    });
    setGoogleLoading(false);
  }

  return {googleLoading, googleLoginHandler: _googleLoginHandler};
}

export const GoogleButtonContainer = combine(useHook, [
  'setErrorToastType',
  'showErrorToast',
  'googleLoading',
  'setGoogleLoading',
])(GoogleButton);
