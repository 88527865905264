// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {gt} from 'semver';
import {useInterval} from 'utils/use-interval';

const URL = `${
  process.env.REACT_APP_ENV === 'dev'
    ? window.location.origin
    : process.env.REACT_APP_STORYTELLER_URL
}/ver.json`;

type UpgradeInfo = {
  version: string;
  force: boolean;
};

async function fetchUpgradeInfo(): Promise<UpgradeInfo> {
  try {
    const {data} = await axios.get(URL);
    return data;
  } catch {
    return {
      version: process.env.REACT_APP_VERSION!,
      force: false,
    };
  }
}

export function VersionManager({children}: PropsWithChildren) {
  const [version, setVersion] = useState<string>(
    process.env.REACT_APP_VERSION!
  );
  const [force, setForce] = useState<boolean>(false);

  const fetch = useCallback(async () => {
    const {version, force} = await fetchUpgradeInfo();
    setVersion(version);
    setForce(force);
  }, []);

  const {start, stop} = useInterval(fetch, 60000);

  useEffect(() => {
    start();
    return stop;
  }, [start, stop]);

  useEffect(() => {
    if (gt(version, process.env.REACT_APP_VERSION!) && force) {
      window.location.reload();
    }
  }, [force, version]);

  return <>{children}</>;
}
