// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import None from 'assets/svg/3.0/None.svg';
import {ReactComponent as TransitionIcon} from 'assets/svg/3.0/Transition.svg';
import {Collapse} from 'pages/components/Collapse';

import {StyleCard} from '../StyleCard';
import styles from './SceneTransitionList.module.scss';
import {Props} from './SceneTransitionList.types';

export function SceneTransitionList({
  resUrl,
  transition,
  transitions,
  onChange,
}: Props) {
  return (
    <Collapse
      icon={<TransitionIcon className={styles['label-icon']} />}
      title="Scene transitions"
    >
      <ul className={styles.list}>
        <StyleCard
          label="None"
          onSelected={() => onChange(null)}
          isSelected={transition === null}
          preview={None}
        />
        {transitions.map(({name, value, id, flow, preview}) => (
          <StyleCard
            label={name}
            onSelected={() => onChange(value)}
            key={id}
            isSelected={transition === value}
            preview={''}
            previewGif={preview ? resUrl + preview : ''}
            flow={flow}
          />
        ))}
      </ul>
    </Collapse>
  );
}
