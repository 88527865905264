// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  recordCampaignId,
  recordReferralCode,
  verifyDiscordCode,
} from 'api/server';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {
  clearCampaignId,
  clearReferralCode,
  getCampaignId,
  getReferralCode,
} from 'contexts/localStorage';
import {useUserContext} from 'contexts/UserContext';
import {useCallback, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {bindInviterAndInvitee} from 'utils/rewardful';

import {DiscordCallbackPage} from './DiscordCallbackPage';
import {HookReturn, Status} from './DiscordCallbackPage.types';

function useHook(): HookReturn {
  const [status, setStatus] = useState<Status>('loading');
  const {recordEvent} = useAnalysis();

  const {updateUserLogin} = useUserContext();
  const params = useSearchParams()[0];

  const initUserInfo = useCallback(async () => {
    try {
      const code = params.get('code');
      if (!code) {
        throw Error('No code found');
      }
      const {
        data: {newRegisteredUser, ...userInfo},
      } = await verifyDiscordCode(code);

      updateUserLogin(
        userInfo.email,
        userInfo.userName,
        userInfo.userId,
        userInfo.portrait,
        userInfo.token,
        userInfo.emailConfirmStatus
      );

      if (newRegisteredUser === true) {
        const referralCode = getReferralCode();
        if (referralCode) {
          await recordReferralCode(referralCode);
          clearReferralCode();
        }
        bindInviterAndInvitee();
        recordEvent('Discord-Signup', {userId: userInfo.userId});

        const campaignId = getCampaignId();
        if (campaignId) {
          await recordCampaignId(campaignId);
          clearCampaignId();
        }
      }
      setStatus('success');
    } catch {
      setStatus('failure');
    }
  }, [updateUserLogin, recordEvent, params]);

  useEffect(() => {
    initUserInfo();
  }, [initUserInfo]);

  return {status};
}

export const DiscordCallbackPageContainer =
  combine(useHook)(DiscordCallbackPage);
