// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  createProject,
  duplicateProjectById,
  getGalleryById,
  updateProject,
} from 'api/server';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow.svg';
import classNames from 'classnames';
import {HistoryPreviewerDialog} from 'components/HistoryPreviewer';
import {Loading} from 'components/Loading';
import {TagGroup} from 'components/TagGroup';
import {useNotificationContext} from 'contexts/NotificationContext';
import {sizeType, useSize} from 'contexts/SizeContext';
import {useUserContext} from 'contexts/UserContext';
import {useDebouncedAsyncFunction} from 'lib/hooks';
import {formatAspectRatio} from 'lib/ratio';
import {noop} from 'lodash';
import {
  GeneralStoryProjectJSON,
  ProjectJSON,
  ProjectType,
} from 'modules/project/types';
import {convertProjectVersion, duplicateGallery} from 'modules/project/utils';
import {Button} from 'pages/components/Button';
import {ClickOrDrag} from 'pages/components/ClickOrDrag';
import {LoadingPromptDialog} from 'pages/components/LoadingPrompt';
import {useCallback, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  generateIdPath,
  PROJECT_PATH,
  WORKSPACE_PAGE_GALLERY_PATH,
  WORKSPACE_PAGE_MYPROJECT_PATH,
} from 'utils/path';
import {PaymentCallbackEnum} from 'utils/stripe';

import {DoodlePreviewer} from '../DoodlePreviewer';
import {GalleryPreviewerDialog} from '../GalleryPreviewer';
import {
  useGalleryProjects,
  useProjectOrGalleryModal,
  useRecentProjects,
} from '../modules';
import {ProjectItem} from '../ProjectItem';
import styles from './Generate.module.scss';

const gallerySizeMap: Record<sizeType, number> = {
  lg: 15,
  md: 12,
  sm: 9,
  xs: 6,
};

export function Generate() {
  const [recentProjects, refreshRecentProjectsList, status] =
    useRecentProjects();

  const {
    galleryList: galleryProjects,
    changeTag,
    galleryTagList,
    isLoading: isGalleryLoading,
    activeTag,
  } = useGalleryProjects(50);
  const {showNotification} = useNotificationContext();
  const {userInfo} = useUserContext();

  const {size} = useSize();

  const finalProjects = useMemo(() => {
    if (!recentProjects) return [];
    switch (size) {
      case 'lg':
        return recentProjects.slice(0, 5);
      case 'md':
        return recentProjects.slice(0, 4);
      case 'sm':
        return recentProjects.slice(0, 3);
      case 'xs':
        return recentProjects.slice(0, 4);
    }
  }, [recentProjects, size]);

  const finalGalleryProjects = galleryProjects?.slice(0, gallerySizeMap[size]);

  const [historyProjectId, setHistoryProjectId] = useState<string | null>(null);
  const [currentGalleryId, setCurrentGalleryId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const galleryHeaderRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [, , , onclickProjectOrGalleryItem] = useProjectOrGalleryModal();

  const inputSections = useMemo(
    () => [
      {
        title: 'General creation',
        imgUrl: 'generateCreation',
        desc: 'Start creation with prompt or your content',
        key: 1,
        path: `${generateIdPath(
          PROJECT_PATH,
          'new'
        )}?type=general_story&prompt_type=idea`,
      },
      {
        title: 'Faceless short videos',
        imgUrl: 'facelessShotVideos',
        desc: 'Generate viral short videos on social media',
        key: 2,
        path: `${generateIdPath(
          PROJECT_PATH,
          'new'
        )}?type=short_video&prompt_type=idea`,
      },
      {
        title: 'Bilingual story',
        imgUrl: 'bilingualStory',
        desc: 'Generate engaging bilingual stories for language learners',
        key: 3,
        path: `${generateIdPath(
          PROJECT_PATH,
          'new'
        )}?type=bilingual_story&prompt_type=idea`,
      },
      {
        title: 'Bilingual dialogue',
        imgUrl: 'bilingualDialogue',
        desc: 'Master fluent conversations with AI generated dialogues',
        key: 4,
        path: `${generateIdPath(
          PROJECT_PATH,
          'new'
        )}?type=bilingual_dialogue&prompt_type=idea`,
      },
    ],
    []
  );

  const onClickDuplicateBtn = useCallback(
    async (projectId: string) => {
      setIsLoading(true);
      try {
        await duplicateProjectById(projectId);
        await refreshRecentProjectsList();
      } catch {
        showNotification({message: 'Duplicate Failed', type: 'ERROR'});
      }
      setIsLoading(false);
    },
    [refreshRecentProjectsList, showNotification]
  );

  const onUseGallery = useCallback(
    async (gallery: ProjectJSON<ProjectType>) => {
      try {
        setIsLoading(true);
        setCurrentGalleryId(null);
        const newProject = duplicateGallery(gallery);
        newProject.author_id = userInfo.userId;
        const {data: projectId} = await createProject({
          frameRatio: formatAspectRatio(newProject.size),
          projectJsonContent: JSON.stringify(newProject),
          lang: newProject.language,
        });
        await updateProject({
          projectId,
          projectName: `${gallery.storyboard?.title} copy`,
          lang: newProject.language,
        });
        showNotification({type: 'SUCCESS', message: 'A new project created'});
        navigate(generateIdPath(PROJECT_PATH, projectId));
      } catch {
        // TODO: 使用失败
      }
    },
    [userInfo.userId, showNotification, navigate]
  );

  const useGalleryWithGalleryId = useCallback(
    async (galleryId: string) => {
      const gallery = await getGalleryById(galleryId);
      if (!gallery) return;
      onUseGallery(
        convertProjectVersion(
          gallery as unknown as ProjectJSON<ProjectType> //todo 整个模块都要改成通用类，先做其他的，后面再改
        ) as GeneralStoryProjectJSON
      );
    },
    [onUseGallery]
  );

  //将 useGalleryWithGalleryId 用debounce防抖处理
  const debouncedUseGalleryWithGalleryId = useDebouncedAsyncFunction(
    useGalleryWithGalleryId,
    300
  );

  return (
    <div className={styles.container}>
      <DoodlePreviewer />
      <div className={styles.inputSectionWrap}>
        {inputSections.map(inputSectionItem => (
          <InputSection
            key={inputSectionItem.key}
            inputSectionItem={{
              ...inputSectionItem,
              onClick: () =>
                inputSectionItem.path && navigate(inputSectionItem.path),
            }}
          />
        ))}
      </div>
      {!!finalProjects.length && (
        <div
          className={classNames(
            styles.projectGrid,
            styles.projectGridWrap,
            styles.recentProjects
          )}
        >
          <div className={styles.projectGridHeader}>
            <div className={styles.projectGridHeaderTitle}>
              Your recent projects
              {!!(recentProjects && recentProjects.length) && (
                <div
                  className={styles.showMore + ' ' + styles.firstShowMore}
                  onClick={() => {
                    navigate(generateIdPath(WORKSPACE_PAGE_MYPROJECT_PATH, ''));
                  }}
                >
                  <Button type="text">
                    More
                    <RightArrowIcon />
                  </Button>
                </div>
              )}
            </div>
          </div>
          {finalProjects.map(project => (
            <ProjectItem
              project={project}
              projectId={project.projectId}
              key={project.projectId}
              pageType="project"
              onclickProjectOrGalleryItem={onclickProjectOrGalleryItem}
              refreshProjectList={refreshRecentProjectsList}
              onclickHistoryBtn={() => {
                setHistoryProjectId(project.projectId);
              }}
              onClickDuplicateBtn={onClickDuplicateBtn}
            />
          ))}
        </div>
      )}
      {!!finalGalleryProjects &&
        !!finalGalleryProjects.length &&
        status !== 'loading' && (
          <div
            ref={galleryHeaderRef}
            className={classNames(
              styles.projectGrid,
              styles.projectGridWrap,
              styles.galleryProjects
            )}
          >
            <div className={styles.projectGridHeader}>
              <div className={styles.projectGridHeaderTitle}>
                Gallery
                {!!galleryProjects &&
                  galleryProjects.length &&
                  !isGalleryLoading && (
                    <div
                      className={classNames(styles.showMore)}
                      onClick={() => {
                        navigate(
                          generateIdPath(WORKSPACE_PAGE_GALLERY_PATH, ''),
                          {
                            state: {tag: activeTag},
                          }
                        );
                      }}
                    >
                      <Button type="text">
                        More
                        <RightArrowIcon />
                      </Button>
                    </div>
                  )}
              </div>
              <TagGroup
                tags={galleryTagList}
                changeTag={changeTag}
                currentTag={activeTag}
              />
            </div>
            {isGalleryLoading && <Loading />}
            {!isGalleryLoading &&
              finalGalleryProjects.map(gallery => (
                <ProjectItem
                  project={gallery}
                  key={gallery.galleryId}
                  pageType="gallery"
                  onclickProjectOrGalleryItem={() =>
                    setCurrentGalleryId(gallery.galleryId)
                  }
                  onClickUseBtn={debouncedUseGalleryWithGalleryId}
                />
              ))}
          </div>
        )}
      <LoadingPromptDialog
        type="processing"
        dialogShowing={isLoading}
        onCloseDialog={noop}
      />
      {!!galleryProjects && galleryProjects.length && !isGalleryLoading && (
        <div
          className={classNames(styles.showMore)}
          onClick={() => {
            navigate(generateIdPath(WORKSPACE_PAGE_GALLERY_PATH, ''), {
              state: {tag: activeTag},
            });
          }}
        >
          <Button type="text">Show all</Button>
        </div>
      )}
      {historyProjectId && (
        <HistoryPreviewerDialog
          type={PaymentCallbackEnum.WorkspacePageHistory}
          projectId={historyProjectId}
          dialogShowing={historyProjectId !== null}
          onCloseDialog={() => setHistoryProjectId(null)}
        />
      )}
      {currentGalleryId && (
        <GalleryPreviewerDialog
          onCloseDialog={() => setCurrentGalleryId(null)}
          dialogShowing={currentGalleryId !== null}
          galleryId={currentGalleryId}
          onUseGallery={onUseGallery}
        />
      )}
    </div>
  );
}
type InputSectionProps = {
  inputSectionItem: {
    title: string;
    imgUrl: string;
    desc: string;
    key: number;
    onClick: () => void;
  };
};

function InputSection({inputSectionItem}: InputSectionProps) {
  return (
    <ClickOrDrag onClick={inputSectionItem.onClick}>
      <div className={styles.inputSection} key={inputSectionItem.key}>
        <div className={styles.header}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>{inputSectionItem.title} </div>
            <div className={styles.desc}>{inputSectionItem.desc}</div>
          </div>
        </div>
        <div className={styles.img_info}>
          <div
            className={classNames(styles.img, styles[inputSectionItem.imgUrl])}
          />
        </div>
      </div>
    </ClickOrDrag>
  );
}
