// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export enum DoodleType {
  DISPLAY = 'DISPLAY_ONLY',
  INNER_LINK = 'INNER_LINK',
  OUTER_LINK = 'OUTER_LINK',
  PROMOTION_DIALOG = 'PROMOTION_DIALOG',
}

interface BaseDoodle<T extends DoodleType> {
  type: T;
  id: number;
  imageUrl: string;
  content?: Record<string, unknown>;
}
interface DisplayOnlyDoodle extends BaseDoodle<DoodleType.DISPLAY> {
  content?: never;
}
interface InnerLinkDoodle extends BaseDoodle<DoodleType.INNER_LINK> {
  content: {
    target: string; // startsWith '/'
  };
}
interface OuterLinkDoodle extends BaseDoodle<DoodleType.OUTER_LINK> {
  content: {
    target: string; // startsWith 'http://' or 'https://'
  };
}
interface PromotionDialogDoodle
  extends BaseDoodle<DoodleType.PROMOTION_DIALOG> {
  content: {
    icon?: string;
    description: Record<string, string>;
    target: {
      type: 'inner-link' | 'outer-link' | 'create_project_from_gallery'; // or predefined action_name like 'create_project_from_gallery'
      title: Record<string, string>;
      value: string;
    };
  };
}

export type Doodle<T extends DoodleType> = T extends DoodleType.DISPLAY
  ? DisplayOnlyDoodle
  : T extends DoodleType.INNER_LINK
  ? InnerLinkDoodle
  : T extends DoodleType.OUTER_LINK
  ? OuterLinkDoodle
  : T extends DoodleType.PROMOTION_DIALOG
  ? PromotionDialogDoodle
  : never;
