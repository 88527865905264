// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {BaseCharacterJSON, BaseClosedCharacter} from '../types';

export abstract class BaseCharacter {
  constructor(readonly id: string, readonly name: string) {}

  abstract isValid(): boolean;
  abstract toClosed(): BaseClosedCharacter;

  toJSON(): BaseCharacterJSON {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
