// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useResourceManager} from 'contexts/ResourceManager';
import {useMemo} from 'react';

import {CommonSelectWithoutLabel} from './CommonSelectWithoutLabel/CommonSelectWithoutLabel';

export interface Props {
  value: string;
  onChange: (value: string) => void;
}
export function TargetLanguageSelect({value, onChange}: Props) {
  const {getTargetLanguages} = useResourceManager();

  const options = useMemo(() => {
    return getTargetLanguages().map(language => ({
      label: language.native_name,
      value: language.code,
      tooltip: language.tooltip,
    }));
  }, [getTargetLanguages]);
  return (
    <CommonSelectWithoutLabel
      options={options}
      value={value}
      title="Target language"
      onChange={value => onChange(value as string)}
    />
  );
}
