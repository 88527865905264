// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {loadStripe, Stripe} from '@stripe/stripe-js';
import * as paymentApi from 'api/payment';
import * as apiServer from 'api/server';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useUserContext} from 'contexts/UserContext';
import {usePayment} from 'modules/payment/services';
import {PlanType} from 'modules/payment/types';
import {useCallback, useEffect, useState} from 'react';
import {PAYMENT_CANCEL_PATH, PAYMENT_SUCCESS_PATH} from 'utils/path';

import {creditsPerMonthByLevel, PerMonthChargeMap} from './constData';
import {PlanPage} from './Plan';
import {
  ActionType,
  ButtonInfoProps,
  ButtonInfoReturn,
  LevelEnum,
  ProductIdEnum,
  TypeStateEnum,
  UseHookReturn,
} from './Plan.types';

function useHook(): UseHookReturn {
  const [typeState, setTypeState] = useState<TypeStateEnum | undefined>();
  const [isOnAction, setIsOnAction] = useState<boolean>(false);
  const [currentPlanInfo, setCurrentPlanInfo] =
    useState<UseHookReturn['currentPlanInfo']>(null);

  const {
    userInfo,
    updateUserInfo,
    activeProductId,
    subscriptionProductId,
    setActiveProductId,
    setSubscriptionProductId,
  } = useUserContext();
  const {subscribe, upgradePlan, downgradePlan} = usePayment();
  const {showNotification} = useNotificationContext();
  const {recordEvent} = useAnalysis();

  const [downgradeProductId, setDowngradeProductId] = useState<number | null>(
    null
  );
  const [upgradeProductId, setUpgradeProductId] = useState<number | null>(null);

  useEffect(() => {
    if (!userInfo.userId) return;
    paymentApi.getUserSubscription(userInfo.userId).then(res => {
      updateUserInfo({
        periodEnd: res.data.subscriptionDetail?.periodEnd ?? null,
      });
      setActiveProductId(res.data.activeProductId);
      setSubscriptionProductId(res.data.subscriptionProductId);
      setTypeState(
        res.data.activeProductId === 0
          ? 'Yearly'
          : getTypeByProductId(res.data.activeProductId)
      );
    });
  }, [
    setActiveProductId,
    setSubscriptionProductId,
    updateUserInfo,
    userInfo.userId,
  ]);
  useEffect(() => {
    const currentType = getTypeByProductId(activeProductId),
      currentLevel = getLevelByProductId(activeProductId),
      nextLevel = getLevelByProductId(subscriptionProductId),
      nextType = getTypeByProductId(subscriptionProductId);
    setCurrentPlanInfo(pre => {
      return {
        ...(pre || {}),
        type: currentType,
        level: currentLevel,
        nextLevel: nextLevel || currentLevel,
        nextType: nextType || currentType,
      };
    });
  }, [activeProductId, subscriptionProductId]);

  const initializeStripe = useCallback(async () => {
    const stripeInstance = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
    );
    return stripeInstance as Stripe;
  }, []);
  const afterUpgradeOrDownGrade = useCallback(
    async (actionType: 'Downgrade' | 'Upgrade', newProductId: number) => {
      const {
        data: {
          activeProductId: newActiveProductId,
          subscriptionProductId: newSubscriptionProductId,
          subscriptionDetail,
        },
      } = await paymentApi.getUserSubscription(userInfo.userId);
      setSubscriptionProductId(newSubscriptionProductId);
      setActiveProductId(newActiveProductId);

      const newRealLevel = getLevelByProductId(newProductId);
      if (actionType === 'Upgrade') {
        //升级时，前端优先响应用户，更新用户信息
        updateUserInfo({
          plan: newRealLevel.toUpperCase() as PlanType,
          planCreditAmount: creditsPerMonthByLevel[newRealLevel],
        });
      }
      //轮询获取用户信息，直到获取到用户信息为止,最多尝试10次，每次间隔1s，尝试失败无需提示用户
      let count = 0;
      const interval = setInterval(() => {
        if (count > 10) {
          clearInterval(interval);
          return;
        }
        count++;
        apiServer
          .getAccountDetails()
          .then((userCreditData: apiServer.GetAccountDetailsResponseType) => {
            if (
              actionType === 'Upgrade' &&
              userCreditData.planType === newRealLevel.toUpperCase() &&
              userCreditData.planCreditAmount ===
                creditsPerMonthByLevel[newRealLevel]
            ) {
              //升级时，planType和planCreditAmount接口返回数据都更新成功，就停止轮询
              clearInterval(interval);
              updateUserInfo({
                plan: userCreditData.planType,
                planCreditAmount: userCreditData.planCreditAmount,
                credit: userCreditData.creditWalletAmount,
                creditPackAmount: userCreditData.creditPackAmount,
                periodEnd: subscriptionDetail?.periodEnd ?? null,
                autoRenewal:
                  (subscriptionDetail &&
                    Boolean(subscriptionDetail.autoRenewal)) ||
                  false,
              });
            }
            if (actionType === 'Downgrade') {
              //降级时，planType和planCreditAmount不会更新不需要轮询
              clearInterval(interval);
              updateUserInfo({
                plan: userCreditData.planType,
                planCreditAmount: userCreditData.planCreditAmount,
                credit: userCreditData.creditWalletAmount,
                creditPackAmount: userCreditData.creditPackAmount,
                periodEnd: subscriptionDetail?.periodEnd ?? null,
                autoRenewal:
                  (subscriptionDetail &&
                    Boolean(subscriptionDetail.autoRenewal)) ||
                  false,
              });
            }
          });
      }, 1000);
    },
    [
      setActiveProductId,
      setSubscriptionProductId,
      updateUserInfo,
      userInfo.userId,
    ]
  );
  const clearActionProductId = useCallback(
    (actionType: Extract<ActionType, 'Downgrade' | 'Upgrade'>) => {
      if (actionType === 'Downgrade') {
        setDowngradeProductId(null);
      } else if (actionType === 'Upgrade') {
        setUpgradeProductId(null);
      }
      setIsOnAction(false);
    },
    []
  );

  const onDowngradeConfirm = useCallback(async () => {
    if (activeProductId === null || downgradeProductId === null) return;
    await downgradePlan(activeProductId, downgradeProductId as number)
      .then(async () => {
        showNotification({
          type: 'SUCCESS',
          message: 'Downgrade success',
        });
        afterUpgradeOrDownGrade('Downgrade', downgradeProductId);
      })
      .catch(() => {
        showNotification({
          type: 'ERROR',
          message: 'Downgrade failed',
        });
      })
      .finally(() => {
        clearActionProductId('Downgrade');
      });
  }, [
    activeProductId,
    afterUpgradeOrDownGrade,
    clearActionProductId,
    downgradePlan,
    downgradeProductId,
    showNotification,
  ]);

  const onUpgradeConfirm = useCallback(async () => {
    if (upgradeProductId === null) return;
    const strip = await initializeStripe();
    const clientSecret = await upgradePlan(activeProductId, upgradeProductId);
    if (!clientSecret) {
      throw new Error('No client secret');
    }
    strip
      .confirmCardPayment(clientSecret)
      .then(async _ => {
        showNotification({
          type: 'SUCCESS',
          message: 'Upgrade success',
        });
        afterUpgradeOrDownGrade('Upgrade', upgradeProductId);
        // Upgrade 成功发送转换统计
        recordEvent('Subscription', {data: {transaction_id: 'upgrade'}});
      })
      .catch(() => {
        showNotification({
          type: 'ERROR',
          message: 'Upgrade failed',
        });
      })
      .finally(() => {
        clearActionProductId('Upgrade');
      });
  }, [
    activeProductId,
    upgradeProductId,
    recordEvent,
    afterUpgradeOrDownGrade,
    initializeStripe,
    showNotification,
    upgradePlan,
    clearActionProductId,
  ]);

  const onAction = useCallback(
    async (type: ActionType, productId: number) => {
      setIsOnAction(true);
      switch (type) {
        case 'Downgrade':
          setDowngradeProductId(productId);
          return;
        case 'Upgrade':
          setUpgradeProductId(productId);
          return;
        case 'Subscription':
          {
            const session = await subscribe(
              productId,
              location.origin +
                PAYMENT_SUCCESS_PATH +
                '?productId=' +
                productId,
              location.origin + PAYMENT_CANCEL_PATH + '?productId=' + productId
            );
            const strip = await initializeStripe();
            await strip
              .redirectToCheckout({
                sessionId: session,
              })
              .catch(err => {
                console.log(
                  'click Subscription then redirectToCheckout error',
                  err
                );
              })
              .finally(() => {
                setIsOnAction(false);
              });
          }
          return;
      }
    },
    [initializeStripe, subscribe]
  );

  return {
    userInfo,
    typeState,
    setTypeState,
    currentPlanInfo,
    onAction,
    onDowngradeConfirm,
    downgradeProductId,
    upgradeProductId,
    clearActionProductId,
    onUpgradeConfirm,
    isOnAction,
  };
}
export const PlanContainer = combine(useHook)(PlanPage);
export const priceMap = {
  Monthly: {
    Standard: PerMonthChargeMap['Monthly']['Standard'],
    Pro: PerMonthChargeMap['Monthly']['Pro'],
    Free: PerMonthChargeMap['Monthly']['Free'],
  },
  Yearly: {
    Standard: PerMonthChargeMap['Yearly']['Standard'] * 12,
    Pro: PerMonthChargeMap['Yearly']['Pro'] * 12,
    Free: PerMonthChargeMap['Yearly']['Free'],
  },
};

export function getButtonProps({
  userLevel,
  userType,
  currentLevel,
  currentType,
}: ButtonInfoProps): ButtonInfoReturn {
  if (
    (userLevel === 'Free' && currentLevel === 'Free') ||
    (userLevel === currentLevel && userType === currentType)
  ) {
    return {
      type: 'ghost',
      children: 'Current plan',
      theme: 'default',
    };
  } else if (
    priceMap[userType][userLevel] < priceMap[currentType][currentLevel]
  ) {
    return {
      type: 'button',
      children: 'Upgrade',
    };
  } else {
    return {
      type: 'ghost',
      children: 'Downgrade',
    };
  }
}
export function getLevelByProductId(productId: number): LevelEnum {
  if (productId === undefined) {
    return productId;
  }
  if (productId === ProductIdEnum.FREE) {
    return 'Free';
  }
  if (
    productId === ProductIdEnum.PRO_MONTHLY ||
    productId === ProductIdEnum.PRO_YEARLY
  ) {
    return 'Pro';
  }
  return 'Standard';
}
export function getTypeByProductId(productId: number): TypeStateEnum {
  if (productId === undefined) {
    return 'Yearly';
  }
  if (
    [
      ProductIdEnum.FREE,
      ProductIdEnum.PRO_MONTHLY,
      ProductIdEnum.STANDARD_MONTHLY,
    ].includes(productId)
  ) {
    return 'Monthly';
  }
  return 'Yearly';
}
