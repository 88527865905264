// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Size} from 'modules/project/types';

import {BaseConstraintJSON} from '../types';
import {RangeConstraintValue} from './RangeConstraintValue';
import {SelectionConstraintValue} from './SelectionConstraintValue';

export abstract class BaseConstraint {
  constructor(
    readonly size: SelectionConstraintValue<Size>,
    readonly promptLength: RangeConstraintValue<undefined, number>,
    readonly sceneQuantity: RangeConstraintValue<undefined, number>,
    readonly sceneSubtitleLength: RangeConstraintValue<undefined, number>
  ) {}

  abstract patch(data: Partial<BaseConstraint>): BaseConstraint;

  toJSON(): BaseConstraintJSON {
    return {
      size: this.size.toJSON(),
      prompt_length: this.promptLength.toJSON(),
      scene_quantity: this.sceneQuantity.toJSON(),
      scene_subtitle_length: this.sceneSubtitleLength.toJSON(),
    };
  }
}
