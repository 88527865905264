// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as GenerateIcon} from 'assets/svg/3.0/GenerateIcon.svg';
import {ReactComponent as MootionIcon} from 'assets/svg/3.0/Mootion.svg';
import {ReactComponent as BuildStoryboardIcon} from 'assets/svg/outline/BuildStoryboard.svg';
import {ReactComponent as CharacterIcon} from 'assets/svg/outline/Character.svg';
import {ReactComponent as DesignSceneIcon} from 'assets/svg/outline/Image.svg';
import {ReactComponent as LoadingSvg} from 'assets/svg/outline/LoadingLine.svg';
import classNames from 'classnames';
import {Button} from 'components/Button';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {HOME_PAGE, SIGNUP_PATH} from 'utils/path';

import styles from './GeneratingPage.module.scss';

const listItem = [
  {
    icon: (
      <GenerateIcon className={styles['content-tasks-box-list-item-icon']} />
    ),
    text: 'Generate script',
  },
  {
    icon: (
      <CharacterIcon className={styles['content-tasks-box-list-item-icon']} />
    ),
    text: 'Create characters & scenes',
  },
  {
    icon: (
      <DesignSceneIcon className={styles['content-tasks-box-list-item-icon']} />
    ),
    text: 'Design scene visual prompts',
  },
  {
    icon: (
      <BuildStoryboardIcon
        className={styles['content-tasks-box-list-item-icon']}
      />
    ),
    text: 'Build storyboard',
  },
];

export function GeneratingPage() {
  const navigate = useNavigate();
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIdx(prev => prev + 1);
    }, Math.floor(3000 / 4));
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <MootionIcon
          className={styles.logo}
          onClick={() => {
            navigate(HOME_PAGE || '', {replace: true});
          }}
        />
        <div className={styles.right}>
          <Button
            type="ghost"
            className={styles.signUp}
            size="middle"
            onClick={() => navigate(SIGNUP_PATH, {replace: true})}
          >
            Sign up
          </Button>
        </div>
      </div>
      <div className={styles['content-box']}>
        <span className={styles['content-box-title']}>Generating</span>
        <div className={styles['content-tasks-box-list']}>
          {listItem.map((item, index) => {
            return (
              <div
                className={classNames(
                  styles['content-tasks-box-list-item'],
                  currentIdx > index ? styles['done'] : '',
                  currentIdx === index ? styles['active'] : ''
                )}
                key={index}
              >
                {currentIdx === index ? (
                  <LoadingSvg className={styles.rotateLoading} />
                ) : (
                  item.icon
                )}
                <span className={styles['content-tasks-box-list-item-text']}>
                  {item.text}
                </span>
              </div>
            );
          })}
        </div>
        <Button
          type="button"
          theme="primary"
          className={styles['check-button']}
          size="middle"
          onClick={() => navigate(SIGNUP_PATH, {replace: true})}
        >
          Check my storyboard
        </Button>
      </div>
    </div>
  );
}
