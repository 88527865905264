// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Composition} from 'modules/composition/models/Composition';
import {
  fromComposition as historyFromComposition,
  History,
} from 'modules/project-history/models/History';
import {Task} from 'modules/task/models/Task';
import {TaskType} from 'modules/task/types';
import {SetStateAction, useCallback, useEffect, useRef, useState} from 'react';

import {patch as patchProject, Project} from '../models/Project';
import {ProjectType} from '../types';
import {MAX_HISTORY_QUANTITY} from '../utils';

const DEFAULT_DELAY = 10000;

export function useProject<P extends Project<ProjectType>>(
  input: P,
  syncProject: (project: P) => void
) {
  const [data, setData] = useState<{
    project: P;
    delay?: number | null;
  }>({project: input});
  const pendingSync = useRef<[NodeJS.Timeout, P] | null>(null);

  const updateProject = useCallback(
    // delay is a number (in milliseconds) or a boolean or undefined (no sync)
    (state: SetStateAction<P>, delay?: number | boolean) => {
      setData(prev => {
        const {project} = prev;
        const nextProject =
          typeof state === 'function' ? state(project) : state;
        if (nextProject === project) return prev;
        return {
          project: nextProject,
          ...(delay === undefined
            ? {}
            : {
                delay:
                  delay === false
                    ? null
                    : Math.max(delay === true ? DEFAULT_DELAY : delay, 0),
              }),
        };
      });
    },
    []
  );

  useEffect(() => {
    const {project, delay} = data;
    if (!project.id || delay === undefined) return;

    if (pendingSync.current) {
      const [timer] = pendingSync.current;
      clearTimeout(timer);
      pendingSync.current = null;
    }

    if (delay) {
      pendingSync.current = [
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const [, project] = pendingSync.current!;
          syncProject(project);
          pendingSync.current = null;
        }, delay),
        project,
      ];
    } else {
      syncProject(project);
    }
  }, [data, syncProject]);

  useEffect(() => {
    setData({project: input});
    return () => {
      if (!pendingSync.current) return;
      const [timer, project] = pendingSync.current;
      clearTimeout(timer);
      syncProject(project);
      pendingSync.current = null;
    };
  }, [input, syncProject]);

  const updateConstraint = useCallback(
    <T extends P extends {constraint: infer V} ? V : never>(
      state: SetStateAction<T>,
      delay?: number | boolean
    ) => {
      updateProject(project => {
        const nextConstraint =
          typeof state === 'function' ? state(project.constraint as T) : state;
        if (nextConstraint === project.constraint) return project;
        return patchProject(project, {
          constraint: nextConstraint,
        } as unknown as Partial<P>);
      }, delay);
    },
    [updateProject]
  );

  const updateStoryboard = useCallback(
    <T extends P extends {storyboard?: infer V} ? V : never>(
      state: SetStateAction<T>,
      delay?: number | boolean
    ) => {
      updateProject(project => {
        const nextStoryboard =
          typeof state === 'function' ? state(project.storyboard as T) : state;
        if (nextStoryboard === project.storyboard) return project;
        return patchProject(project, {
          storyboard: nextStoryboard,
        } as unknown as Partial<P>);
      }, delay);
    },
    [updateProject]
  );

  const updateConfig = useCallback(
    <T extends P extends {config: infer V} ? V : never>(
      state: SetStateAction<T>,
      delay?: number | boolean
    ) => {
      updateProject(project => {
        const nextConfig =
          typeof state === 'function' ? state(project.config as T) : state;
        if (nextConfig === project.config) return project;
        return patchProject(project, {
          config: nextConfig,
        } as unknown as Partial<P>);
      }, delay);
    },
    [updateProject]
  );

  const updateComposition = useCallback(
    <T extends P extends {composition?: infer V} ? V : never>(
      state: SetStateAction<T>,
      delay?: number | boolean
    ) => {
      updateProject(project => {
        const nextComposition =
          typeof state === 'function' ? state(project.composition as T) : state;
        if (nextComposition === project.composition) return project;
        // Temporarily patch storyboard if composition is updated with specific fields
        const nextStoryboard =
          nextComposition.description === project.composition?.description &&
          nextComposition.hashtags === project.composition?.hashtags
            ? undefined
            : project.storyboard?.patch({
                description: nextComposition.description,
                hashtags: nextComposition.hashtags,
              });
        return patchProject(project, {
          composition: nextComposition,
          ...(nextStoryboard ? {storyboard: nextStoryboard} : {}),
        } as unknown as Partial<P>);
      }, delay);
    },
    [updateProject]
  );

  const saveHistory = useCallback(
    async <
      T extends P extends {composition?: infer V} ? V : never,
      H extends History<T extends Composition<infer X> ? X : never>
    >(
      composition: T,
      histories: string[],
      storyboardTasks: Task<TaskType>[],
      uploadHistory: (history: H) => Promise<void>
    ) => {
      if (!composition.isValid()) return;
      const [historyId, ...restHistories] =
        histories.length < MAX_HISTORY_QUANTITY
          ? ['', ...histories]
          : histories;
      const history = historyFromComposition(
        composition,
        storyboardTasks,
        historyId || undefined
      );
      await uploadHistory(history as unknown as H);
      const nextHistories = [...restHistories, history.id];
      updateProject(
        project =>
          patchProject(project, {
            histories: nextHistories,
          } as unknown as Partial<P>),
        false
      );
    },
    [updateProject]
  );

  return {
    project: data.project,
    updateProject,
    updateConstraint,
    updateStoryboard,
    updateConfig,
    updateComposition,
    saveHistory,
  };
}
