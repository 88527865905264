// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import styles from './SystemError.module.scss';
import {Props} from './SystemError.types';

export function SystemError({onCancel}: Props) {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Notice</p>
      <p className={styles.content}>System error. Please try again.</p>
      <button className={styles.button} onClick={onCancel}>
        OK
      </button>
    </div>
  );
}
