// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useCallback, useRef} from 'react';

export function usePoll(interval = 1000) {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isActive = useRef<boolean>(false);

  const poll = useCallback(
    async <P extends unknown[]>(
      fn: (...arg: P) => Promise<void>,
      ...arg: P
    ) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      await fn(...arg);
      if (isActive.current) {
        timerRef.current = setTimeout(() => {
          poll(fn, ...arg);
        }, interval);
      }
    },
    [interval]
  );

  const start = useCallback(
    <P extends unknown[]>(fn: (...arg: P) => Promise<void>, ...arg: P) => {
      isActive.current = true;
      poll(fn, ...arg);
    },
    [poll]
  );

  const stop = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    isActive.current = false;
  }, []);

  return {start, stop};
}

type PollingOptions<T> = {
  maxAttemptTimes: number;
  interval: number;
  checkCondition: () => boolean;
  requestFunction: () => Promise<T>;
  onSuccess: (data: T) => void;
  onFailure: () => void;
};

type PollingControl = {
  stop: () => void;
};

export function startPolling<T>({
  maxAttemptTimes,
  interval,
  checkCondition,
  requestFunction,
  onSuccess,
  onFailure,
}: PollingOptions<T>): PollingControl {
  const startTime = new Date().getTime();
  let isStopped = false;

  function checkStatus() {
    if (isStopped || new Date().getTime() - startTime > maxAttemptTimes) {
      onFailure();
      return;
    }

    if (!checkCondition()) {
      return;
    }

    requestFunction()
      .then(data => {
        if (!isStopped) {
          onSuccess(data);
        }
      })
      .catch(() => {
        if (!isStopped) {
          setTimeout(checkStatus, interval);
        }
      });
  }

  checkStatus();

  return {
    stop: () => {
      isStopped = true;
    },
  };
}
