// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';

import {BilingualDialogueStoryboard} from './BilingualDialogueStoryboard';
import {BilingualStoryStoryboard} from './BilingualStoryStoryboard';
import {GeneralStoryStoryboard} from './GeneralStoryStoryboard';
import {ShortVideoStoryboard} from './ShortVideoStoryboard';

export type Storyboard<T extends ProjectType> = T extends 'general_story'
  ? GeneralStoryStoryboard
  : T extends 'short_video'
  ? ShortVideoStoryboard
  : T extends 'bilingual_story'
  ? BilingualStoryStoryboard
  : T extends 'bilingual_dialogue'
  ? BilingualDialogueStoryboard
  : never;

export function patch<S extends Storyboard<ProjectType>>(
  storyboard: S,
  data: Partial<S>
): S {
  if (storyboard instanceof GeneralStoryStoryboard) {
    return storyboard.patch(data as Partial<GeneralStoryStoryboard>) as S;
  } else if (storyboard instanceof ShortVideoStoryboard) {
    return storyboard.patch(data as Partial<ShortVideoStoryboard>) as S;
  } else if (storyboard instanceof BilingualStoryStoryboard) {
    return storyboard.patch(data as Partial<BilingualStoryStoryboard>) as S;
  } else if (storyboard instanceof BilingualDialogueStoryboard) {
    return storyboard.patch(data as Partial<BilingualDialogueStoryboard>) as S;
  } else {
    throw new Error(`Unknown storyboard type: ${storyboard}`);
  }
}
