// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CheckBoxIcon} from 'assets/svg/3.0/CheckBox.svg';
import {ReactComponent as CheckedIcon} from 'assets/svg/3.0/CheckBox2.svg';
import classNames from 'classnames';

import styles from './CheckBox.module.scss';
import {Props} from './CheckBox.types';

export function CheckBox({
  label,
  isChecked,
  tip,
  contentClassNames,
  onCheck,
  disabled,
  ...props
}: Props) {
  return (
    <div {...props} className={classNames(styles.container, props.className)}>
      <div
        className={classNames(styles.content, contentClassNames)}
        onClick={() => !disabled && onCheck(!isChecked)}
      >
        {isChecked && (
          <CheckedIcon
            className={classNames(
              styles.icon,
              disabled && styles.disabled,
              styles.checked
            )}
          />
        )}
        {!isChecked && <CheckBoxIcon className={styles.icon} />}
        <span className={styles.text}>{label}</span>
      </div>
      {tip && <span>{tip}</span>}
    </div>
  );
}
