// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Effect} from 'api/server';
import {ProficiencyLevel, Size} from 'modules/project/types';
import {ThumbnailType} from 'modules/project-config/types';

import {BasePreference} from './BasePreference';

export class BilingualDialoguePreference extends BasePreference {
  static fromJSON(json: Record<string, any>): BilingualDialoguePreference {
    return new BilingualDialoguePreference(
      json.size,
      json.language,
      json.nativeLanguage,
      json.proficiencyLevel,
      json.characterOption,
      json.style,
      json.titleStyleName,
      json.subtitleStyleName,
      json.effect,
      json.transition,
      json.thumbnailType,
      json.thumbnailIncludeVideo,
      json.bgm,
      json.voiceoverOrder
    );
  }
  constructor(
    size?: Size,
    language?: string,
    readonly nativeLanguage?: string,
    readonly proficiencyLevel?: ProficiencyLevel,
    /**
     * 1: AI GENERATE
     * 2: CUSTOMIZE
     * */
    readonly characterOption?: '1' | '2',
    readonly style?: string,
    readonly titleStyleName?: string,
    readonly subtitleStyleName?: string,
    readonly effect?: Effect,
    readonly transition?: string,
    readonly thumbnailType?: ThumbnailType,
    readonly thumbnailIncludeVideo?: boolean,
    readonly bgm?: string,
    readonly voiceoverOrder?: string
  ) {
    super(size, language);
  }
  patch(
    data: Partial<BilingualDialoguePreference>
  ): BilingualDialoguePreference {
    const {
      size,
      language,
      style,
      titleStyleName,
      subtitleStyleName,
      effect,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      nativeLanguage,
      proficiencyLevel,
      voiceoverOrder,
      characterOption,
    } = Object.assign({}, this, data);
    return new BilingualDialoguePreference(
      size,
      language,
      nativeLanguage,
      proficiencyLevel,
      characterOption,
      style,
      titleStyleName,
      subtitleStyleName,
      effect,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceoverOrder
    );
  }
}
