// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useFixedRef} from 'lib/hooks';
import {useCallback, useEffect, useState} from 'react';

import {UnityController} from './controller';
import {UnityEvent} from './event';

export function useUnityService() {
  const [isReady, setIsReady] = useState(false);
  const [cameraMovedCount, setCameraMovedCount] = useState(0);
  const controller = useFixedRef(() => new UnityController());
  const event = useFixedRef(() => {
    return new UnityEvent({
      onResponse: controller.onResponse.bind(controller),
    });
  });
  useEffect(() => {
    event.bind('Story3DReady', () => {
      setIsReady(true);
    });
    event.bind('CameraMoved', () => {
      setCameraMovedCount(count => count + 1);
    });
    return () => {
      event.bind('Story3DReady');
      event.bind('CameraMoved');
    };
  }, [event]);

  const onConnect = useCallback(
    (...params: Parameters<UnityController['connect']>) => {
      controller.connect(...params);
    },
    [controller]
  );

  const onDisconnect = useCallback(() => {
    return controller.disconnect();
  }, [controller]);

  const onDispatch = useCallback(
    (...params: Parameters<UnityEvent['dispatch']>) => {
      event.dispatch(...params);
    },
    [event]
  );

  return {
    controller,
    event,
    isReady,
    cameraMovedCount,
    callback: {
      onConnect,
      onDisconnect,
      onDispatch,
    },
  };
}
