// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './UpgradeUnlockStyleBox.module.scss';
import {Props} from './UpgradeUnlockStyleBox.types';

export function UpgradeUnlockStyleBox({onCancel, onUpgrade}: Props) {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Upgrade to unlock</p>
      <p className={styles.content}>Upgrade your plan to enjoy more styles.</p>
      <div className={styles['button-box']}>
        <button
          className={classNames(styles.button, styles.cancel)}
          onClick={onCancel}
        >
          I&apos;m OK
        </button>
        <button
          className={classNames(styles.button, styles.upgrade)}
          onClick={onUpgrade}
        >
          Upgrade
        </button>
      </div>
    </div>
  );
}
