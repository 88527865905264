// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DeleteIcon} from 'assets/svg/3.0/Delete2.svg';
import {ReactComponent as Delete3Icon} from 'assets/svg/3.0/Delete3.svg';
import {ReactComponent as DuplicateIcon} from 'assets/svg/3.0/Duplicate.svg';
import {ReactComponent as HistoryIcon} from 'assets/svg/3.0/History.svg';
import {ReactComponent as MoreIcon} from 'assets/svg/3.0/More.svg';
import {ReactComponent as NoImageIcon} from 'assets/svg/3.0/NoImage.svg';
import {ReactComponent as RenameIcon} from 'assets/svg/3.0/Rename.svg';
import {ReactComponent as UseIcon} from 'assets/svg/3.0/Use.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {PopoverHandle} from 'components/PopoverNew/Popover';
import {useResourceManager} from 'contexts/ResourceManager';
import {getTitleOrDefault, trimNewline} from 'modules/project/utils';
import {dateToUpdateTime} from 'pages/common/date';
import {Button} from 'pages/components/Button';
import {ClickOrDrag} from 'pages/components/ClickOrDrag';
import {List} from 'pages/components/List';
import {Toast} from 'pages/components/Toast';
import React, {useEffect, useMemo, useRef} from 'react';
import {isRtl} from 'utils/is-rtl';

import {PageType} from '../ProjectOrGallery/ProjectOrGallery.types';
import {
  isGalleryItemType,
  isProjectItemType,
  ProjectOrGalleryItemType,
} from '../WorkspacePage.types';
import {UseHookReturnType} from './ProjectItem.container';
import styles from './ProjectItem.module.scss';

type ProjectItemProps = {
  project: ProjectOrGalleryItemType;
  projectId?: number | string;
  pageType: PageType;
} & UseHookReturnType;
export function ProjectItem({
  project,
  projectId,
  deleteConfirmVisible,
  hideDeleteConfirmModal,
  handleClick,
  isRename,
  divRef,
  popoverRef,
  onConfirmDelete,
}: ProjectItemProps) {
  const projectTitle = isProjectItemType(project)
    ? project.projectName
    : project.galleryName;
  const moreRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<PopoverHandle>(null);
  const {getLanguages} = useResourceManager();
  const languages = getLanguages();

  useEffect(() => {
    const handleHover = () => {
      titleRef.current?.clearHoverTimeout();
    };
    const moreDom = moreRef.current;
    if (moreDom) {
      moreDom.addEventListener('mouseenter', handleHover);
    }
    return () => {
      if (moreDom) {
        moreDom.removeEventListener('mouseenter', handleHover);
      }
    };
  }, [popoverRef]);
  const scrollElement = useMemo(() => {
    return (
      (document.getElementById('mootion-aside')
        ?.nextElementSibling as HTMLElement) || undefined
    );
  }, []);
  const MoreJsx = (
    <Popover
      ref={popoverRef}
      direction="right"
      className={styles.titleMorePopoverContainer}
      scrollElement={scrollElement}
      content={
        <List
          size="small"
          onMouseDown={(e: MouseEvent) => {
            e.stopPropagation();
          }}
          onMouseUp={(e: MouseEvent) => {
            e.stopPropagation();
          }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {isProjectItemType(project) ? (
            <>
              <li data-action="duplicate" onClick={handleClick}>
                <DuplicateIcon />
                Duplicate
              </li>
              {
                <li
                  data-action="history"
                  onClick={
                    !!project.historyNumber && project.historyNumber >= 1
                      ? handleClick
                      : undefined
                  }
                  className={
                    !(project.historyNumber && project.historyNumber >= 1)
                      ? 'disabled'
                      : ''
                  }
                >
                  <HistoryIcon />
                  History
                </li>
              }
              <li data-action="rename" onClick={handleClick}>
                <RenameIcon />
                Rename
              </li>
              <li data-action="delete" onClick={handleClick}>
                <Delete3Icon /> Delete
              </li>
            </>
          ) : (
            <li data-action="use" onClick={handleClick}>
              <UseIcon /> Use
            </li>
          )}
        </List>
      }
      trigger="click"
      triggerElement={
        <div
          ref={moreRef}
          onClick={e => e.stopPropagation()}
          onMouseUp={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
        >
          <Button type="icon">
            <MoreIcon className={styles.more} />
          </Button>
        </div>
      }
    />
  );
  const projectTitleAndTime = (
    <div
      className={classNames(styles.title_wrap, {
        [styles.isRename]: isRename,
      })}
    >
      <span
        className={classNames(styles.title, {
          'rtl-element-global': isRtl(languages, project.lang),
        })}
      >
        {trimNewline(
          getTitleOrDefault(projectTitle, project.lang ?? 'en-US'),
          project.lang ?? 'en-US'
        )}
      </span>
      {isProjectItemType(project) ? MoreJsx : null}
    </div>
  );
  const projectTitleAndTimeWithPopover = projectTitle ? (
    <Popover
      delay={1000}
      ref={titleRef}
      trigger="hover"
      disabled={isRename}
      triggerElement={projectTitleAndTime}
      content={
        <div
          className={classNames(styles['project-title-popover'], {
            'rtl-element-global': isRtl(languages, project.lang),
          })}
        >
          {projectTitle}
        </div>
      }
      direction="bottom"
      showBorder={false}
      distance={7}
    />
  ) : (
    projectTitleAndTime
  );
  return (
    <div key={projectId} ref={divRef} className={styles.projectContainer}>
      <ClickOrDrag
        className={classNames(styles.projectCard, {
          [styles.isGallery]: isGalleryItemType(project),
        })}
        data-action="click"
        onClick={ev => {
          handleClick && handleClick(ev);
        }}
      >
        <div>
          <div
            className={classNames(styles.cover, {
              [styles.noImage]: !project.thumbnailUrl,
            })}
            data-action="click"
          >
            {project.thumbnailUrl ? (
              <img
                src={project.thumbnailUrl}
                alt={projectTitle}
                className={styles.img}
              />
            ) : (
              <NoImageIcon className={styles.noImageSvg} />
            )}
          </div>
          <div className={styles.titleAndSubInfo}>
            {projectTitleAndTimeWithPopover}
            <div
              className={classNames(styles.prompt, {
                'rtl-element-global':
                  !isProjectItemType(project) && isRtl(languages, project.lang),
              })}
            >
              {isProjectItemType(project)
                ? project.updateTime &&
                  dateToUpdateTime(new Date(project.updateTime))
                : project.prompt &&
                  project.prompt.split('\\n').map((line, index, array) => (
                    <React.Fragment key={index}>
                      {line}
                      {index !== array.length - 1 && <br />}
                    </React.Fragment>
                  ))}
            </div>
          </div>
          {isGalleryItemType(project) ? MoreJsx : null}
          {isProjectItemType(project) &&
            (!project.historyNumber || project.historyNumber === 0) && (
              <div className={styles.badge}>Draft</div>
            )}
        </div>
      </ClickOrDrag>
      <Toast
        title={
          <>
            <DeleteIcon />
            &nbsp; Delete
          </>
        }
        visible={deleteConfirmVisible}
        onOk={onConfirmDelete}
        onCancel={hideDeleteConfirmModal}
        showCloseBtn={false}
        btnTheme="danger"
      >
        Confirm to delete?
      </Toast>
    </div>
  );
}
