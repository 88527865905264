// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  BaseTextStyleData,
  BGMDataItem,
  BilingualCombination,
  LanguageData,
  PosesData,
  ResourceContext,
  ResourceIndex,
  StyleData,
  SubtitleStyleData,
  SubtitleStyleDetail,
  TextBilingualCombination,
  ThumbnailTypeData,
  ThumbnailTypeDetail,
  TitleStyleData,
  TitleStyleDetail,
  TransitionData,
  VoiceoverDataItem,
  VoiceoverDataItemJson,
} from './ResourceManager.type';

const BILINGUAL_TARGET_TAG = 'Bilingual Target';
const EXCLUDE_TAGS: string[] = ['Word Cards', 'Bilingual'];
class ResourceManager {
  readonly index: ResourceIndex;
  private voiceovers = new Array<VoiceoverDataItemJson>();
  private titleStyles: TitleStyleData[] = [];
  private subtitleStyles: SubtitleStyleData[] = [];
  private titleBilingualCombinations: BilingualCombination[] = [];
  private subtitleBilingualCombinations: BilingualCombination[] = [];
  private languages: LanguageData[] = [];
  private styles: StyleData[] = [];
  private transitions: TransitionData[] = [];
  private thumbnailTypes: ThumbnailTypeData[] = [];
  private poses = new Array<PosesData>();
  private bgms = new Array<BGMDataItem>();

  constructor(index: ResourceIndex) {
    this.index = index;
  }

  private async loadResource<T>(name: string, initFunc: (entries: T) => void) {
    return axios
      .get(`${this.index.resUrl}/${name}/index.json`)
      .then(res => {
        initFunc(res.data);
      })
      .catch(err => {
        console.error(`Load ${name} failed: ${err}.`);
      });
  }

  private async loadResourceData<T>(
    name: string,
    initFunc: (entries: T[]) => void
  ) {
    const initFunction = (res: {data: T[]}) => initFunc(res.data);
    try {
      await this.loadResource(name, initFunction);
    } catch (err) {
      console.error(`Load ${name} failed: ${err}.`);
    }
  }

  loadLanguages() {
    return this.loadResourceData('languages', (languages: LanguageData[]) => {
      this.initLanguages(languages);
    });
  }

  private initLanguages(languages: LanguageData[]) {
    this.languages = languages;
  }

  getLanguages() {
    return this.languages;
  }

  getTargetLanguages() {
    return this.languages.filter(language =>
      language.tags.includes(BILINGUAL_TARGET_TAG)
    );
  }

  getNativeLanguages(_languageCode: string) {
    return this.languages.filter(
      language => !language.tags.includes(BILINGUAL_TARGET_TAG)
    );
  }

  loadBGM() {
    return this.loadResourceData('bgm', this.initBGM.bind(this));
  }

  private initBGM(bgms: BGMDataItem[]) {
    this.bgms = bgms;
  }

  bgmsOf(): BGMDataItem[] {
    return this.bgms;
  }

  loadThumbnailTypes() {
    return this.loadResourceData(
      'thumbnail_types',
      (thumbnailTypes: ThumbnailTypeData[]) => {
        this.initThumbnailTypes(thumbnailTypes);
      }
    );
  }

  private initThumbnailTypes(thumbnailTypes: ThumbnailTypeData[]) {
    this.thumbnailTypes = thumbnailTypes;
  }

  thumbnailTypesOf(languageCode: string): ThumbnailTypeDetail[] {
    return this.thumbnailTypes.reduce((value, thumbnailData) => {
      const supportedLanguages = thumbnailData.supported_languages;
      const supportedLanguage = supportedLanguages.find(
        ({code}) => code === languageCode
      );
      if (supportedLanguage) {
        value.push({...thumbnailData, image: supportedLanguage.image});
      }
      return value;
    }, [] as ThumbnailTypeDetail[]);
  }

  loadTransitions() {
    return this.loadResourceData(
      'transitions',
      (transitions: TransitionData[]) => {
        this.initTransitions(transitions);
      }
    );
  }

  private initTransitions(transitions: TransitionData[]) {
    this.transitions = transitions;
  }

  getTransitions() {
    return this.transitions;
  }

  loadVoiceover() {
    return this.loadResourceData(
      'voiceover',
      (revoiceovers: VoiceoverDataItemJson[]) => {
        this.initVoiceover(revoiceovers);
      }
    );
  }

  private initVoiceover(revoiceovers: VoiceoverDataItemJson[]) {
    this.voiceovers = revoiceovers;
  }

  loadStyles() {
    return this.loadResourceData('styles', (style: StyleData[]) => {
      this.initStyles(style);
    });
  }

  private initStyles(styles: StyleData[]) {
    this.styles = styles;
  }

  getStyles() {
    return this.styles;
  }

  getStyleDataByName(name: string): StyleData {
    return this.styles.find(style => style.name === name) as StyleData;
  }

  loadTitleStyles() {
    return this.loadResourceData(
      'title_styles',
      (titleStyles: TitleStyleData[]) => {
        this.initTitleStyles(titleStyles);
      }
    );
  }

  loadTitleBilingualCombinations() {
    return this.loadResource(
      'title_styles',
      (res: {bilingual_combinations: BilingualCombination[]}) => {
        this.titleBilingualCombinations = res.bilingual_combinations;
      }
    );
  }

  loadSubtitleBilingualCombinations() {
    return this.loadResource(
      'subtitle_styles',
      (res: {bilingual_combinations: BilingualCombination[]}) => {
        this.subtitleBilingualCombinations = res.bilingual_combinations;
      }
    );
  }

  private initTitleStyles(titleStyles: TitleStyleData[]) {
    this.titleStyles = titleStyles;
  }

  loadSubtitleStyles() {
    return this.loadResourceData(
      'subtitle_styles',
      (subtitleStyles: SubtitleStyleData[]) => {
        this.initSubtitleStyles(subtitleStyles);
      }
    );
  }
  private initSubtitleStyles(subtitleStyles: SubtitleStyleData[]) {
    this.subtitleStyles = subtitleStyles;
  }

  loadPoses() {
    return this.loadResourceData('poses', (poses: PosesData[]) => {
      this.initPoses(poses);
    });
  }
  private initPoses(poses: PosesData[]) {
    this.poses = poses;
  }
  getPosses() {
    return this.poses;
  }

  voiceoverOf(languageCode: string): VoiceoverDataItem[] {
    return this.voiceovers.reduce((value, voiceoverData) => {
      const supportedLanguages = voiceoverData.supported_languages;
      const supportedLanguage = supportedLanguages.find(
        ({code}) => code === languageCode
      );

      if (supportedLanguage) {
        value.push({
          ...voiceoverData,
          audio: supportedLanguage.audio,
        });
      }
      return value;
    }, [] as VoiceoverDataItem[]);
  }

  getBilingualStoryVoiceover(): VoiceoverDataItem[] {
    return this.voiceoverOf('MULTILINGUAL');
  }

  titleStylesOf(
    languageCode: string,
    excludeTags: string[] = EXCLUDE_TAGS
  ): TitleStyleDetail[] {
    return this.titleStyles.reduce((value, titleStyle) => {
      for (const tag of excludeTags) {
        if ((titleStyle.tags ?? []).includes(tag)) {
          return value;
        }
      }
      const supportedLanguages = titleStyle.supported_languages;
      const supportedLanguage = supportedLanguages.find(
        ({code}) => code === languageCode
      );
      if (supportedLanguage) {
        value.push({...titleStyle, image: supportedLanguage.image});
      }
      return value;
    }, [] as TitleStyleDetail[]);
  }
  getTitleBilingualCombinationsByLanguage(
    targetLanguage: string,
    nativeLanguage: string
  ): TextBilingualCombination[] {
    return this.titleBilingualCombinations.reduce((value, item) => {
      if (
        item.supported_languages.find(
          ({target_language, native_language}) =>
            target_language === targetLanguage &&
            native_language === nativeLanguage
        )
      ) {
        const {native_style, target_style} = item;

        const targetTextStyleImage = this.titleStylesOf(
          targetLanguage,
          []
        ).find(data => data.name === target_style)?.image as string;
        const nativeTextStyleImage = this.titleStylesOf(
          nativeLanguage,
          []
        ).find(data => data.name === native_style)?.image as string;
        value.push({...item, targetTextStyleImage, nativeTextStyleImage});
      }
      return value;
    }, [] as TextBilingualCombination[]);
  }

  getSubtitleBilingualCombinationsByLanguage(
    targetLanguage: string,
    nativeLanguage: string
  ): TextBilingualCombination[] {
    return this.subtitleBilingualCombinations.reduce((value, item) => {
      if (
        item.supported_languages.find(
          ({target_language, native_language}) =>
            target_language === targetLanguage &&
            native_language === nativeLanguage
        )
      ) {
        const {native_style, target_style} = item;

        const targetTextStyleImage = this.subtitleStylesOf(
          targetLanguage,
          []
        ).find(data => data.name === target_style)?.image as string;
        const nativeTextStyleImage = this.subtitleStylesOf(
          nativeLanguage,
          []
        ).find(data => data.name === native_style)?.image as string;
        value.push({...item, targetTextStyleImage, nativeTextStyleImage});
      }
      return value;
    }, [] as TextBilingualCombination[]);
  }

  getDefaultTitleStyle(languageCode: string): TitleStyleDetail {
    return this.titleStylesOf(languageCode)[0];
  }

  async getTextStyleJson<T extends BaseTextStyleData>(
    resUrl: string,
    textStyle: T
  ): Promise<Record<string, string>> {
    return await (await fetch(`${resUrl}${textStyle.style}`)).json();
  }

  subtitleStylesOf(
    languageCode: string,
    excludeTags: string[] = EXCLUDE_TAGS
  ): SubtitleStyleDetail[] {
    return this.subtitleStyles.reduce((value, subtitleStyle) => {
      for (const tag of excludeTags) {
        if ((subtitleStyle.tags ?? []).includes(tag)) {
          return value;
        }
      }
      const supportedLanguages = subtitleStyle.supported_languages;
      const supportedLanguage = supportedLanguages.find(
        ({code}) => code === languageCode
      );
      if (supportedLanguage) {
        value.push({
          ...subtitleStyle,
          image: supportedLanguage.image,
          lineLength: supportedLanguage.line_length,
        });
      }
      return value;
    }, [] as SubtitleStyleDetail[]);
  }

  getDefaultSubtitleStyle(languageCode: string): SubtitleStyleDetail {
    return this.subtitleStylesOf(languageCode)[0];
  }

  getDefaultVoiceover(language: string): VoiceoverDataItem {
    const voiceovers = this.voiceoverOf(language);
    return voiceovers[0];
  }

  getTitleStyleByName(
    name: string | null | undefined
  ): TitleStyleData | undefined {
    if (!name) return undefined;
    return this.titleStyles.find(style => style.name === name);
  }
  getSubtitleStyleByName(
    name: string | null | undefined
  ): SubtitleStyleData | undefined {
    if (!name) return undefined;
    return this.subtitleStyles.find(style => style.name === name);
  }
  getTitleBilingualCombinationByName(
    name: string | null | undefined
  ): BilingualCombination | undefined {
    if (!name) return undefined;
    return this.titleBilingualCombinations.find(style => style.name === name);
  }
  getSubtitleBilingualCombinationByName(
    name: string | null | undefined
  ): BilingualCombination | undefined {
    if (!name) return undefined;
    return this.subtitleBilingualCombinations.find(
      style => style.name === name
    );
  }
}

const resourceContext = createContext<ResourceContext>({} as ResourceContext);
const REACT_APP_RESOURCE_URL =
  process.env.REACT_APP_ENV === 'dev'
    ? window.location.origin
    : process.env.REACT_APP_STORYTELLER_URL;
export function useResourceManager() {
  return useContext(resourceContext);
}

interface Props {
  children: ReactNode;
}

export function ResourceManagerProvider({children}: Props) {
  const [isIndexLoaded, setIsIndexLoaded] = useState(false);
  const [isResLoaded, setIsResLoaded] = useState(false);
  const resourceManagerRef = useRef<ResourceManager>();

  useEffect(() => {
    let ignore = false;
    axios.get(REACT_APP_RESOURCE_URL + '/res.json').then(async res => {
      if (!ignore) {
        resourceManagerRef.current = new ResourceManager(res.data);
        //统计资源加载时间
        setIsIndexLoaded(true);
        await Promise.all([
          resourceManagerRef.current.loadLanguages(),
          resourceManagerRef.current.loadVoiceover(),
          resourceManagerRef.current.loadTitleStyles(),
          resourceManagerRef.current.loadSubtitleStyles(),
          resourceManagerRef.current.loadTitleBilingualCombinations(),
          resourceManagerRef.current.loadSubtitleBilingualCombinations(),
          resourceManagerRef.current.loadPoses(),
          resourceManagerRef.current.loadStyles(),
          resourceManagerRef.current.loadTransitions(),
          resourceManagerRef.current.loadBGM(),
          resourceManagerRef.current.loadThumbnailTypes(),
        ]);
        setIsResLoaded(true);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  const value = useMemo(() => {
    const resourceManager = resourceManagerRef.current;
    if (!isIndexLoaded || !resourceManager) {
      return null;
    }
    return {
      isResLoaded,
      resUrl: resourceManager.index.resUrl,
      u3dUrl: resourceManager.index.u3dUrl,
      getStyles: resourceManager.getStyles.bind(resourceManager),
      getStyleDataByName:
        resourceManager.getStyleDataByName.bind(resourceManager),
      getPosses: resourceManager.getPosses.bind(resourceManager),
      getLanguages: resourceManager.getLanguages.bind(resourceManager),
      getNativeLanguages:
        resourceManager.getNativeLanguages.bind(resourceManager),
      getTargetLanguages:
        resourceManager.getTargetLanguages.bind(resourceManager),
      getTransitions: resourceManager.getTransitions.bind(resourceManager),
      titleStylesOf: resourceManager.titleStylesOf.bind(resourceManager),
      subtitleStylesOf: resourceManager.subtitleStylesOf.bind(resourceManager),
      getTitleBilingualCombinationsByLanguage:
        resourceManager.getTitleBilingualCombinationsByLanguage.bind(
          resourceManager
        ),
      getSubtitleBilingualCombinationsByLanguage:
        resourceManager.getSubtitleBilingualCombinationsByLanguage.bind(
          resourceManager
        ),

      voiceoversOf: resourceManager.voiceoverOf.bind(resourceManager),
      getBilingualStoryVoiceover:
        resourceManager.getBilingualStoryVoiceover.bind(resourceManager),
      thumbnailTypesOf: resourceManager.thumbnailTypesOf.bind(resourceManager),
      getDefaultTitleStyle:
        resourceManager.getDefaultTitleStyle.bind(resourceManager),
      getDefaultSubtitleStyle:
        resourceManager.getDefaultSubtitleStyle.bind(resourceManager),
      getTextStyleJson: resourceManager.getTextStyleJson.bind(resourceManager),
      getDefaultVoiceover:
        resourceManager.getDefaultVoiceover.bind(resourceManager),
      bgmsOf: resourceManager.bgmsOf.bind(resourceManager),
      getTitleStyleByName:
        resourceManager.getTitleStyleByName.bind(resourceManager),
      getSubtitleStyleByName:
        resourceManager.getSubtitleStyleByName.bind(resourceManager),
      getTitleBilingualCombinationByName:
        resourceManager.getTitleBilingualCombinationByName.bind(
          resourceManager
        ),
      getSubtitleBilingualCombinationByName:
        resourceManager.getSubtitleBilingualCombinationByName.bind(
          resourceManager
        ),
    };
  }, [isIndexLoaded, isResLoaded]);

  return value ? (
    <resourceContext.Provider value={value}>
      {children}
    </resourceContext.Provider>
  ) : null;
}
