// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as Delete3Icon} from 'assets/svg/3.0/Delete3.svg';
import {ReactComponent as DownloadIcon} from 'assets/svg/3.0/Download.svg';
import {ReactComponent as LoadingSvg} from 'assets/svg/3.0/loading-rotate.svg';
import {ReactComponent as MoreIcon} from 'assets/svg/3.0/More.svg';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow3.svg';
import {ReactComponent as AssetstoolCropIcon} from 'assets/svg/outline/AssetstoolCrop.svg';
import {ReactComponent as AssetsToolRemoveBgIcon} from 'assets/svg/outline/AssetsToolRemoveBg.svg';
import {ReactComponent as AssetsToolStyleTransferIcon} from 'assets/svg/outline/AssetsToolStyleTransfer.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {dateToUpdateTime} from 'pages/common/date';
import {BlankData} from 'pages/components/BlankData';
import {Button} from 'pages/components/Button';
import {List} from 'pages/components/List';
import {Toast} from 'pages/components/Toast';
import {Upload} from 'pages/components/Upload';
import {useMemo} from 'react';

import styles from './ImageGrid.module.scss';
import {ImageGridProps, TriggerElementParamsType} from './ImageGrid.type';

export function ImageGrid({
  tempList,
  assetItemList,
  deleteConfirmVisible,
  isAssetsFetchLoading,
  loadMoreRef,
  hasMore,
  isInModal = false,
  handleImageError,
  deleteAssetById,
  onConfirmDelete,
  hideDeleteConfirmModal,
  handleClickAssets,
  uploadFileAndEditAsset,
  doActionToImage,
}: ImageGridProps) {
  const hasData =
    (tempList && tempList.length > 0) ||
    (assetItemList && assetItemList.length > 0);
  const scrollElement = useMemo(() => {
    return (
      (document.getElementById('mootion-aside')
        ?.nextElementSibling as HTMLElement) || undefined
    );
  }, []);
  const onClickAction: TriggerElementParamsType['onClickAction'] = (
    assetId,
    actionType
  ) => {
    const assetInfo = assetItemList?.find(item => item.assetId === assetId);

    switch (actionType) {
      case 'delete':
        deleteAssetById(assetId);
        break;
      case 'crop':
      case 'rmBg':
      case 'styleTransfer':
      case 'download':
        if (!assetInfo) {
          throw new Error('assetInfo not found');
        }
        doActionToImage &&
          doActionToImage(
            actionType,
            assetInfo.assetThumbUrl,
            assetInfo.assetName
          );
        break;
    }
  };
  return (
    <>
      {hasData ? (
        <div
          className={classNames(styles.image_grid + ' ' + 'image_grid', {
            [styles['simple']]: isInModal,
          })}
        >
          <div className={styles.image_container}>
            <div className={styles.image_wrap_outer}>
              <div className={styles.image_wrap + ' ' + styles.uploader}>
                <Upload
                  accept="image/jpg, image/png, image/webp"
                  uploadFileAndEditAsset={uploadFileAndEditAsset}
                />
              </div>
            </div>
            {!isInModal && <div className={styles.footer_upload}>Upload</div>}
          </div>
          {tempList &&
            tempList.map(tempItem => (
              <div className={styles.image_container} key={tempItem.imgUrl}>
                <div className={styles.image_wrap_outer}>
                  <div className={styles.image_wrap}>
                    <img
                      className={styles.image + ' ' + styles.image_uploading}
                      src={tempItem.imgUrl}
                    />
                    <div className={styles.image_wrap_mask_content}>
                      <span>uploading</span>
                      <span className={styles.image_wrap_mask_num}>
                        {tempItem.uploadProgress}%
                      </span>
                    </div>
                  </div>
                </div>
                {!isInModal && <div className={styles.footer}>uploading</div>}
              </div>
            ))}
          {assetItemList &&
            assetItemList.map(assetItem => (
              <div
                className={styles.image_container + ' ' + 'image_container'}
                key={assetItem.assetId}
                data-url={assetItem.assetThumbUrl}
                data-asset-name={assetItem.assetName}
                onClick={handleClickAssets}
              >
                <div className={styles.image_wrap_outer}>
                  <div className={styles.image_wrap}>
                    <img
                      className={styles.image}
                      src={assetItem.assetThumbUrl}
                      alt={assetItem.assetName}
                      data-asset-id={assetItem.assetId}
                      onError={handleImageError}
                    />
                  </div>
                </div>
                {isInModal ? null : (
                  <div className={styles.footer}>
                    <div className={styles.updateTime}>
                      {dateToUpdateTime(new Date(assetItem.updateTime))}
                    </div>
                    <Popover
                      key={assetItem.assetId}
                      className={styles.more_popover}
                      scrollElement={scrollElement}
                      content={
                        <PopoverContent
                          assetId={assetItem.assetId}
                          onClickAction={onClickAction}
                        />
                      }
                      triggerElement={
                        <Button type="icon" onClick={e => e.stopPropagation()}>
                          <MoreIcon className={styles.more} />
                        </Button>
                      }
                    />
                  </div>
                )}
              </div>
            ))}
          {hasMore && !isAssetsFetchLoading && (
            <LoadingSvg
              ref={loadMoreRef}
              className={styles.rotateLoading + ' loadMore'}
            />
          )}
        </div>
      ) : null}
      {assetItemList &&
      assetItemList.length === 0 &&
      (!tempList || tempList?.length === 0) ? (
        <BlankData
          quotation="CREATIVITY IS INTELLIGENCE HAVING FUN."
          quotationAuthor="Albert Einstein"
          footerMsg="You have no assets yet"
          footerAction={
            <Button type="link" className={styles.blanckDataButton}>
              Upload
              <RightArrowIcon />
              <Upload
                accept="image/jpg, image/png, image/webp"
                className={styles.uploadBtnInBlanckData}
                uploadFileAndEditAsset={uploadFileAndEditAsset}
              />
            </Button>
          }
          showQuota={!isInModal}
          className={classNames(styles.blankDataSection, {
            [styles['isInModal']]: isInModal,
          })}
        />
      ) : null}
      <Toast
        title="Delete"
        visible={deleteConfirmVisible}
        onOk={onConfirmDelete}
        onCancel={hideDeleteConfirmModal}
        showCloseBtn={false}
        btnTheme="danger"
      >
        Confirm to delete
      </Toast>
    </>
  );
}

const PopoverContent = function (props: TriggerElementParamsType) {
  const {assetId, onClickAction} = props;
  return (
    <List size="small" onClick={e => e.stopPropagation()}>
      <li onClick={() => onClickAction(assetId, 'crop')}>
        <AssetstoolCropIcon /> Crop
      </li>
      <li onClick={() => onClickAction(assetId, 'rmBg')}>
        <AssetsToolRemoveBgIcon /> Remove BG
      </li>
      <li onClick={() => onClickAction(assetId, 'styleTransfer')}>
        <AssetsToolStyleTransferIcon /> Style transfer
      </li>
      <li onClick={() => onClickAction(assetId, 'download')}>
        <DownloadIcon /> Download
      </li>
      <li onClick={() => onClickAction(assetId, 'delete')}>
        <Delete3Icon /> Delete
      </li>
    </List>
  );
};
