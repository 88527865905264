// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ImageGridInModal} from '../ImageGrid';
import {UseImageModal} from '../UseImageModal';
import {UseAssetModalPropsType} from './UseAssetModal.type';

export function UseAssetModal({
  imageGridInModalVisible,
  useImageModalVisible,
  handleClickAssets,
  doAfterUpload,
  hideImageGridInModal,
  closeUseImageModal,
  handleUseImageOk,
  assetImgUrl,
  sourceImgUrl,
  startUpload,
  ratio,
}: UseAssetModalPropsType) {
  return (
    <>
      <ImageGridInModal
        visible={imageGridInModalVisible}
        handleClickAssets={handleClickAssets}
        hideModal={hideImageGridInModal}
        doAfterUpload={doAfterUpload}
      />
      {useImageModalVisible && (
        <UseImageModal
          visible={useImageModalVisible}
          hideImageModal={closeUseImageModal}
          handleStartUpload={startUpload}
          handleOk={handleUseImageOk}
          sourceImgUrl={sourceImgUrl}
          assetImgUrl={assetImgUrl}
          ratio={ratio}
        />
      )}
    </>
  );
}
