// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {LevelEnum} from './Plan.types';
export const creditsPerMonthByLevel = {
  Free: 200,
  Standard: 1000,
  Pro: 5000,
};
export const PerMonthChargeMap = {
  Monthly: {
    Standard: 10,
    Pro: 40,
    Free: 0,
  },
  Yearly: {
    Standard: 8,
    Pro: 32,
    Free: 0,
  },
};

export const ToggleOptions = [
  {label: 'Monthly', width: 114},
  {label: 'Yearly', discount: '20% off', width: 138},
];
export const subscriptionMap: {
  [key in LevelEnum]: string;
} = {
  Free: 'Free plan',
  Standard: 'Standard plan',
  Pro: 'Pro plan',
};
export const billingTipsMap = {
  Free: {
    Monthly: 'Free plan will be valid next billing cycle.',
    Yearly: 'Free plan will be valid next billing cycle.',
  },
  Standard: {
    Monthly: 'Standard plan (monthly) will be valid next billing cycle.',
    Yearly: 'Standard plan (yearly) will be valid next billing cycle.',
  },
  Pro: {
    Monthly: 'Pro plan (monthly) will be valid next billing cycle..',
    Yearly: 'Pro plan (yearly) will be valid next billing cycle.',
  },
};

export const creditOptions: {
  credit: number;
  price: number;
  productId: number;
}[] = [
  {credit: 200, price: 2, productId: 7},
  {credit: 500, price: 5, productId: 8},
  {credit: 1000, price: 8, productId: 9},
];
export const AnimatorFeaturesTips = {
  Free: [
    'Text-to-Motion (100 motions)',
    'Text-to-Pose (200 poses)',
    'Motion-to-Video (100s videos)',
    'Video Mocap (100s mocaps)',
  ],
  Standard: [
    'Text-to-Motion (500 motions)',
    'Text-to-Pose (1000 poses)',
    'Motion-to-Video (500s videos)',
    'Video Mocap (500s mocaps)',
  ],
  Pro: [
    'Text-to-Motion (2500 motions)',
    'Text-to-Pose (5000 poses)',
    'Motion-to-Video (2500s videos)',
    'Video Mocap (2500s mocaps)',
  ],
};
