// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {getBlobMimeType, mimeToExtMap} from 'lib/image';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {Cropper, ReactCropperElement} from 'react-cropper';

import {CropPanel} from '../Panels/CropPanel';
import styles from './ImageCropper.module.scss';
import {Props, RefProps} from './ImageCropper.type';

const getMimeTypeAndSuffixByBlobUrl = async (blobUrl: string) => {
  let mimeType = await getBlobMimeType(blobUrl);
  if (!mimeType) throw new Error('unknown mime type');
  //如果是透明图片，保持原来格式，否则裁剪后一律是jpg
  const isAlphaImage = ['image/png', 'image/webp'].includes(mimeType);
  mimeType = isAlphaImage ? mimeType : 'image/jpeg';
  return {
    mimeType,
    suffix: mimeToExtMap[mimeType],
    quality: isAlphaImage ? 0.5 : 0.9,
  };
};
export const ImageCropper = forwardRef<RefProps, Props>(
  ({imageInfo, onProcessing, tools}, ref) => {
    const cropperRef = useRef<ReactCropperElement>(null);
    const [ratio, setRatio] = useState<number | undefined>();
    //改变裁剪比例
    const handleChangeAspectRatio = (ratio: number | undefined) => {
      setRatio(ratio);
      if (ratio) {
        cropperRef.current?.cropper.setAspectRatio(ratio);
      } else {
        cropperRef.current?.cropper.setAspectRatio(NaN);
      }
    };
    //确认裁剪
    const confirmCrop = async () => {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        //crop时，如果原图有alpha通道，要保持透明度，否则图片会变黑
        const {mimeType, suffix, quality} = await getMimeTypeAndSuffixByBlobUrl(
          imageInfo[0]
        );
        croppedCanvas.toBlob(
          blob => {
            if (blob) {
              const croppedImageUrl = URL.createObjectURL(blob);
              onProcessing('success', {
                currentImage: croppedImageUrl,
                fileSuffix: suffix,
              });
            }
          },
          mimeType,
          quality
        );
      }
    };
    useEffect(() => {
      //如果图片改变，重置crop
      if (imageInfo[1]) {
        setRatio(undefined);
        cropperRef.current?.cropper.setAspectRatio(NaN);
      }
    }, [imageInfo]);

    useImperativeHandle(ref, () => ({
      action: confirmCrop,
    }));

    return (
      <>
        <div className="image-wrap">
          {imageInfo && (
            <Cropper
              checkCrossOrigin={false}
              className={styles['crop-image-wrap']}
              src={imageInfo[0]}
              ref={cropperRef}
              viewMode={1}
              initialAspectRatio={ratio}
              aspectRatio={ratio}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              guides={true}
              dragMode={'crop'}
              crossOrigin="anonymous"
            />
          )}
        </div>
        <div className={styles.left}>
          {tools}
          <CropPanel
            confirmCrop={confirmCrop}
            handleChangeAspectRatio={handleChangeAspectRatio}
            aspectRatio={ratio}
          />
        </div>
      </>
    );
  }
);

ImageCropper.displayName = 'ImageCropper';
