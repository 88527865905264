// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {getLastVersion} from '.';
import {BaseProjectVersionManager, Project} from './BaseProjectVersionManager';

export const LATEST_VERSION = 1;

export class ShortVideoProjectVersionManager extends BaseProjectVersionManager {
  constructor(version?: number) {
    super(version ?? getLastVersion('short_video'));
  }

  public convert(project: Project) {
    return project;
  }
}
