// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  useAssetsList,
  useDeleteAssetHook,
  useUploadAssetHook,
} from 'components/AssetsLibrary/AssetsLibrary.container';
import {combine} from 'components/Combine';
import {useCallback} from 'react';

import {
  ImageGridInModalHookProps,
  ImageGridInModalHookReturn,
} from './ImageGrid.type';
import {ImageGridInModal} from './ImageGridInModal';

function useHook({
  doAfterUpload,
  hideModal,
}: ImageGridInModalHookProps): ImageGridInModalHookReturn {
  const {
    assetItemList,
    refreshAssetList,
    isLoading: isAssetsFetchLoading,
    loadMoreRef,
    hasMore,
    refreshAfterDelete,
  } = useAssetsList();

  const {
    deleteConfirmVisible,
    deleteAssetById,
    hideDeleteConfirmModal,
    onConfirmDelete,
  } = useDeleteAssetHook({
    deleteCallback: refreshAfterDelete,
  });

  const {
    tempList,
    errorToastVisible,
    errorToastType,
    handleUploadTempList,
    handleImageError,
    hideErrorToast,
    handleUpload,
  } = useUploadAssetHook({uploadCallback: refreshAssetList});

  const handleUploadFileAndEditAsset = useCallback(
    async (args: {file: File; blobImageUrl: string}) => {
      await handleUpload(args, 'system').then(res => {
        if (res.objectKey) {
          doAfterUpload && doAfterUpload(args);
        }
      });
    },
    [handleUpload, doAfterUpload]
  );
  return {
    tempList,
    assetItemList,
    deleteConfirmVisible,
    isAssetsFetchLoading,
    loadMoreRef,
    hasMore,
    errorToastVisible,
    errorToastType,
    hideErrorToast,
    hideModal,
    deleteAssetById,
    refreshAssetList,
    hideDeleteConfirmModal,
    onConfirmDelete,
    handleUploadTempList,
    handleImageError,
    handleUploadFileAndEditAsset,
  };
}
export const ImageGridInModalContainer = combine(useHook, [
  'doAfterUpload',
  'hideModal',
])(ImageGridInModal);
