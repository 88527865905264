// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProgressSchema} from '../types';

export class Progress {
  static fromJSON({current, total}: ProgressSchema) {
    return new Progress(current, total);
  }

  constructor(readonly current: number, readonly total: number) {}

  get ratio() {
    if (!(this.current > 0 && this.total > 0)) {
      return 0;
    }
    return Math.min(1, Math.floor((this.current / this.total) * 10000) / 10000);
  }
}
