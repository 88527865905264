// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {CustomizedClosedCharacter} from 'modules/character/types';
import {PromptPolicy} from 'modules/project/models/PromptPolicy';
import {Size} from 'modules/project/types';
import {ShortVideoClosedScene} from 'modules/scene/types';

import {Task} from '../../task/models/Task';
import {ShortVideoCompositionJSON} from '../types';
import {BaseComposition} from './BaseComposition';

export class ShortVideoComposition extends BaseComposition<'short_video'> {
  static fromJSON(
    size: Size,
    language: string,
    style: string,
    promptPolicy: PromptPolicy<'short_video'>,
    prompt: string,
    title: string,
    description: string,
    hashtags: string[],
    {
      id,
      characters,
      scenes,
      task,
      thumbnail,
      asset,
      create_time,
      asset_product,
    }: ShortVideoCompositionJSON
  ) {
    return new ShortVideoComposition(
      id,
      size,
      language,
      style,
      promptPolicy,
      prompt,
      title,
      description,
      hashtags,
      characters,
      scenes,
      task ? Task.fromJSON(task) : undefined,
      create_time,
      thumbnail,
      asset,
      asset_product
    );
  }

  constructor(
    id: string,
    size: Size,
    language: string,
    readonly style: string,
    promptPolicy: PromptPolicy<'short_video'>,
    prompt: string,
    title: string,
    description: string,
    hashtags: string[],
    characters: CustomizedClosedCharacter[],
    scenes: ShortVideoClosedScene[],
    task?: Task<'composite_video'>,
    createTime?: string,
    thumbnail?: string,
    asset?: string,
    assetProduct?: string
  ) {
    super(
      id,
      size,
      language,
      promptPolicy,
      prompt,
      title,
      description,
      hashtags,
      characters,
      scenes,
      task,
      createTime,
      thumbnail,
      asset,
      assetProduct
    );
  }

  isValid(): boolean {
    return (
      !!this.task &&
      this.task.status === 'success' &&
      !!this.createTime &&
      !!this.thumbnail &&
      !!this.asset
    );
  }

  patch(
    data: Partial<{
      [K in
        | 'task'
        | 'createTime'
        | 'thumbnail'
        | 'asset'
        | 'assetProduct'
        | 'description'
        | 'hashtags']: ShortVideoComposition[K];
    }>
  ): ShortVideoComposition {
    const {
      id,
      size,
      language,
      style,
      promptPolicy,
      prompt,
      title,
      description,
      hashtags,
      characters,
      scenes,
      task,
      createTime,
      thumbnail,
      asset,
      assetProduct,
    } = Object.assign({}, this, data);
    return new ShortVideoComposition(
      id,
      size,
      language,
      style,
      promptPolicy,
      prompt,
      title,
      description,
      hashtags,
      characters,
      scenes,
      task,
      createTime,
      thumbnail,
      asset,
      assetProduct
    );
  }

  toJSON(): ShortVideoCompositionJSON {
    return {
      ...super.toJSON(),
    };
  }
}
