// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useMemo, useState} from 'react';

import {TextEditor} from '../../TextEditor';
import styles from './BilingualSubtitleEditor.module.scss';
import {Props} from './BilingualSubtitleEditor.types';

export function BilingualSubtitleEditor({
  subtitle,
  isProcessing,
  isSelected,
  isAnimating,
  onChange,
  onFocus,
  nativeSubtitle,
  onNativeSubtitleChange,
  subtitleMaxLength,
  speaker,
}: Props) {
  const [isFocus, setIsFocus] = useState([true, false]);

  const isSubtitleOverLength = useMemo(
    () => !!subtitle && subtitle.length > subtitleMaxLength,
    [subtitle, subtitleMaxLength]
  );
  const isNativeSubtitleOverLength = useMemo(
    () => !!nativeSubtitle && nativeSubtitle.length > subtitleMaxLength,
    [nativeSubtitle, subtitleMaxLength]
  );

  return (
    <div
      className={classNames(styles.container)}
      onClick={() => (isProcessing || isAnimating) && onFocus()}
    >
      <div className={styles.editorBox}>
        <TextEditor
          text={subtitle}
          isDisabled={isAnimating || isProcessing}
          onChange={val => onChange(val)}
          label={false}
          size="medium"
          isFocused={isSelected && isFocus[0]}
          onFocus={() => setIsFocus([true, false])}
          isOverLength={isSubtitleOverLength}
          overLengthTips={`Try summarize the content or split this scene, max ${subtitleMaxLength} characters per scene.`}
          prefix={speaker}
          placeholder="Input text in target language"
        />
        {isAnimating && (
          <div
            className={classNames(styles.mask, {
              [styles.focused]: isSelected && isFocus[0],
            })}
            onClick={() => setIsFocus([true, false])}
          />
        )}
      </div>
      <div className={styles.editorBox}>
        <TextEditor
          text={nativeSubtitle}
          isDisabled={isAnimating || isProcessing}
          onChange={val => onNativeSubtitleChange(val)}
          label={false}
          size="medium"
          isFocused={isSelected && isFocus[1]}
          onFocus={() => setIsFocus([false, true])}
          isOverLength={isNativeSubtitleOverLength}
          overLengthTips={`Try summarize the content or split this scene, max ${subtitleMaxLength} characters per scene.`}
          rtlClassName="rtl-bilingual-element"
          prefix={speaker}
          placeholder="Input text in native language"
        />
        {isAnimating && (
          <div
            className={classNames(styles.mask, {
              [styles.focused]: isSelected && isFocus[1],
            })}
            onClick={() => setIsFocus([false, true])}
          />
        )}
      </div>
    </div>
  );
}
