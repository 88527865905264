// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {DraftJSON} from '../types';
import {fromJSON as objectFromJSON, SceneObject} from './SceneObject';

export class Draft {
  static fromJSON({background, objects, image}: DraftJSON) {
    return new Draft(background, objects.map(objectFromJSON), image);
  }

  constructor(
    readonly background: string,
    readonly objects: SceneObject[],
    readonly image?: string
  ) {}

  patch(data: Partial<Draft>) {
    const {background, objects, image} = Object.assign({}, this, data);
    return new Draft(background, objects, image);
  }

  toJSON(): DraftJSON {
    return {
      background: this.background,
      objects: this.objects.filter(o => o.isValid()).map(o => o.toJSON()),
      image: this.image,
    };
  }
}
