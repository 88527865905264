// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import defaultDoodle from 'assets/images/default-doodle.png';
import {useAPI} from 'contexts/APIContext';
import {Doodle, DoodleType} from 'modules/doodle/types';
import {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useInterval} from 'utils/use-interval';

import styles from './DoodlePreviewer.module.scss';
import {PromotionDialog} from './PromotionDialog';

export function DoodlePreviewer() {
  const [doodle, setDoodle] = useState<Doodle<DoodleType>>();
  const [dialogVisible, setDialogVisible] = useState(false);

  const {backendClient} = useAPI();
  const fetch = useCallback(async () => {
    setDoodle(await backendClient.getDoodle());
  }, [backendClient]);
  const {start, stop} = useInterval(fetch, 60000);
  useEffect(() => {
    start();
    return stop;
  }, [start, stop]);

  const [pos, setPos] = useState({left: 0, top: 0});

  switch (doodle?.type) {
    case DoodleType.DISPLAY:
      return (
        <div className={styles.container}>
          <div className={styles.image} style={{cursor: 'default'}}>
            <img src={doodle.imageUrl} />
          </div>
        </div>
      );
    case DoodleType.INNER_LINK:
      return (
        <Link
          to={doodle.content.target}
          className={styles.container}
          onClick={() => backendClient.reportClickBanner(doodle.id)}
        >
          <div className={styles.image}>
            <img src={doodle.imageUrl} />
          </div>
        </Link>
      );
    case DoodleType.OUTER_LINK:
      return (
        <a
          href={doodle.content.target}
          target="_blank"
          className={styles.container}
          rel="noreferrer"
          onClick={() => backendClient.reportClickBanner(doodle.id)}
        >
          <div className={styles.image}>
            <img src={doodle.imageUrl} />
          </div>
        </a>
      );
    case DoodleType.PROMOTION_DIALOG:
      return (
        <div className={styles.container}>
          <div
            className={styles.image}
            onClick={e => {
              setPos({left: e.pageX, top: e.pageY});
              backendClient.reportClickBanner(doodle.id);
              setDialogVisible(true);
            }}
          >
            <img src={doodle.imageUrl} />
          </div>

          <PromotionDialog
            visible={dialogVisible}
            left={pos.left}
            top={pos.top}
            {...doodle.content}
            onClose={() => setDialogVisible(false)}
          />
        </div>
      );
    default:
      return (
        <div className={styles.container}>
          <div className={styles.image} style={{cursor: 'default'}}>
            <img src={defaultDoodle} />
          </div>
        </div>
      );
  }
}
