// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {clearUserToken, getUserToken} from 'contexts/localStorage';
import {retry} from 'lib/retry';

import {ResponseCode} from './server';

const LOGIN_PATH = '/auth/login';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  fullResponse?: boolean;
}
export function axiosFactory({
  baseURL,
  timeout = 10000,
}: {
  baseURL: string;
  timeout?: number;
}) {
  const api = axios.create({
    baseURL,
    timeout,
  });

  api.interceptors.request.use(config => {
    const token = getUserToken();
    token &&
      config.headers &&
      (config.headers['Authorization'] = `Bearer ${token}`);
    return config;
  });

  api.interceptors.response.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: AxiosResponse<any, CustomAxiosRequestConfig>) => {
      if (response.data && response.data.error === ResponseCode.TOKEN_INVALID) {
        //token失效重新登录
        clearUserToken();
      }
      // 检查配置中是否请求了完整的response
      if (response.config.data?.fullResponse) {
        return Promise.resolve(response);
      }
      return Promise.resolve(response.data);
    },
    error => {
      if (!error.response && error.request) {
        // 网络错误或者请求未发出
        throw new Error('Network Error');
      }
      if (
        error.response.status === 401 &&
        error.response.data.error === undefined
      ) {
        //token失效,清除token
        clearUserToken();
        if (location.pathname !== LOGIN_PATH) {
          location.href = LOGIN_PATH;
        }
      }
      return Promise.reject(error.response);
    }
  );
  return api;
}

export const retryRequest = retry((current, error) => {
  // 这里是request通用错误处理
  console.log(`retry ${current} times, error: ${error}`);
});
