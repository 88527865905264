// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Navigate} from 'react-router-dom';
import {ROOT_PATH} from 'utils/path';

import {Props} from './DiscordCallbackPage.types';

export function DiscordCallbackPage({status}: Props) {
  if (status === 'loading') return null;
  else return <Navigate to={ROOT_PATH} replace />;
}
