// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import styles from './SceneEditor.module.scss';

export function SceneEditorPlaceholder() {
  return (
    <div className={styles['placeholder-container']}>
      <div className={styles.preview} />
      <ul className={styles.buttons}>
        {Array.from({length: 3}).map((_, index) => {
          return <li className={styles.button} key={index}></li>;
        })}
      </ul>
    </div>
  );
}
