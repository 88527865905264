// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import {combine} from 'components/Combine';
import {useAPI} from 'contexts/APIContext';
import {useFixedRef} from 'lib/hooks';
import {
  blobUrlToFile,
  getBlobMimeType,
  getImageWidthAndHeightByUrl,
} from 'lib/image';
import {Task} from 'modules/ai-frontend/models/Task';
import {
  CreateTask,
  FindTask,
  PollingCallback,
  useTaskManager,
} from 'modules/ai-frontend/services';
import {TaskParams} from 'modules/ai-frontend/types';
import {constructFormDataItem} from 'modules/user-asset/utils';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {AssetEditor} from './AssetEditor';
import {
  EditAssetHookProp,
  TaskData,
  TaskType,
  UploadFormTarget,
} from './AssetEditor.types';

type TaskExecutor<T extends TaskType = TaskType> = {
  resolve: (value: Task<T>) => void;
  reject: (reason?: unknown) => void;
};

function useNewHook() {
  const tasks = useFixedRef<{id: string; executor: TaskExecutor}[]>(() => []);
  const {aiFrontendClient} = useAPI();

  const uploadAsset = useCallback(
    async (target: UploadFormTarget, blobImageUrl: string) => {
      const mimeType = await getBlobMimeType(blobImageUrl);
      if (!mimeType) throw new Error('unknown mime type');
      const form = await aiFrontendClient.createUploadForm(target, {
        content_type: mimeType,
      });
      const file = await blobUrlToFile(
        blobImageUrl,
        'temp.' + mimeType.split('/')[1]
      );
      const constructRes = constructFormDataItem(form, file);
      await axios.post(constructRes.url, constructRes.formData);
      return constructRes.assetId;
    },
    [aiFrontendClient]
  );

  const pollingCallback = useCallback(
    (...[, taskId, task]: Parameters<PollingCallback<TaskType>>) => {
      if (!task.closed) return;

      const index = tasks.findIndex(t => t.id === taskId);
      if (index < 0) throw new Error('task not found');
      const {executor} = tasks[index];
      tasks.splice(index, 1);
      if (task.status === 'success') {
        executor.resolve(task);
      } else {
        executor.reject(new Error(task.failureReason?.message ?? 'unknown'));
      }
    },
    [tasks]
  );

  const createTask: CreateTask<TaskType, null> = useMemo(
    () => aiFrontendClient.createTask.bind(aiFrontendClient),
    [aiFrontendClient]
  );

  const findTask: FindTask<TaskType> = useMemo(
    () => aiFrontendClient.findTask.bind(aiFrontendClient),
    [aiFrontendClient]
  );

  const {execute} = useTaskManager<TaskType, null>(
    createTask,
    findTask,
    pollingCallback
  );

  const executeTask = useCallback(
    async <T extends TaskType>(
      type: T,
      data: TaskData<T>,
      timeout?: number
    ) => {
      const taskId = await execute(type, {data} as unknown as TaskParams<T>, {
        timeout,
      });
      return await new Promise<Task<T>>((resolve, reject) => {
        tasks.push({
          id: taskId,
          executor: {resolve, reject} as unknown as TaskExecutor,
        });
      });
    },
    [tasks, execute]
  );

  useEffect(() => {
    return () => {
      for (const {executor} of tasks) {
        executor.reject(new Error('cancel'));
      }
      tasks.length = 0;
    };
  }, [tasks]);

  return {uploadAsset, executeTask};
}

function useHook({
  imageUrl,
  filename,
  imageWidth,
  imageHeight,
  handleApply,
  onReset: _onReset,
}: EditAssetHookProp) {
  const sourceImageUrlRef = useRef<string>(imageUrl);
  //imageUrlAfterTransferRef 用于记录styleTransfer后的图片, 用于判断是否需要上传
  const imageUrlAfterTransferRef = useRef<string>(imageUrl);
  const filenameRef = useRef<string>(filename);
  //currentImageInfo[1] 为true代表是reset后的图片
  const [currentImageInfo, setCurrentImageUrl] = useState<[string, boolean]>();
  const [imageSize, setImageSize] = useState<[number, number]>([
    imageWidth,
    imageHeight,
  ]);

  const onReset = () => {
    _onReset?.();
    //重置到原始上传的图片
    setTimeout(() => {
      setImageSize([imageWidth, imageHeight]);
      setCurrentImageUrl([sourceImageUrlRef.current, true]);
    }, 0);
  };

  const onApply = async () => {
    if (!currentImageInfo) return;
    const file = await blobUrlToFile(currentImageInfo[0], filenameRef.current);
    const hasChanged =
      currentImageInfo[0] !== sourceImageUrlRef.current &&
      currentImageInfo[0] !== imageUrlAfterTransferRef.current;

    handleApply({
      file,
      blobImageUrl: currentImageInfo[0],
      needUpload: hasChanged,
    });
  };

  useEffect(() => {
    //初始化
    if (imageUrl) {
      filenameRef.current = filename;
      sourceImageUrlRef.current = imageUrl;
      setImageSize([imageWidth, imageHeight]);
      setCurrentImageUrl([imageUrl, true]);
    }
  }, [filename, imageHeight, imageUrl, imageWidth]);

  useEffect(() => {
    setImageSize([imageWidth, imageHeight]);
  }, [imageWidth, imageHeight]);

  useEffect(() => {
    if (!currentImageInfo) return;
    return () => {
      const currentImageBlobUrl = currentImageInfo[0];
      if (currentImageBlobUrl === sourceImageUrlRef.current) return;
      setTimeout(() => {
        URL.revokeObjectURL(currentImageBlobUrl);
      }, 2000);
    };
  }, [currentImageInfo]);

  const onChangeCurrentImageUrl = (url: string, isTransfer?: boolean) => {
    if (isTransfer) {
      imageUrlAfterTransferRef.current = url;
    }
    //改变图片的width和height
    getImageWidthAndHeightByUrl(url).then(({width, height}) => {
      setImageSize([width, height]);
      setCurrentImageUrl([url, false]);
    });
  };
  const {uploadAsset, executeTask} = useNewHook();

  const changeFileName = useCallback((filename: string) => {
    filenameRef.current = filename;
  }, []);

  return {
    imageInfo: currentImageInfo,
    filename: filenameRef.current,
    imageSize,
    onReset,
    onApply,
    onChangeCurrentImageUrl,
    uploadAsset,
    executeTask,
    changeFileName,
  };
}

export const AssetEditorContainer = combine(useHook, [
  'imageUrl',
  'filename',
  'handleApply',
  'imageWidth',
  'imageHeight',
  'onReset',
])(AssetEditor);
