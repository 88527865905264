// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  PaymentCycle,
  Plan,
  PlanType,
  ProductIdEnum,
  ProjectRule,
} from './types';

export const PLAN_MAP: Record<PlanType, string> = {
  FREE: 'Free',
  STANDARD: 'Standard',
  PRO: 'Pro',
};

export const PAYMENT_CYCLE_MAP: Record<PaymentCycle, string> = {
  monthly: 'Monthly',
  yearly: 'Yearly',
};

export const PLANS: Plan[] = [
  {
    type: 'FREE',
    price: 0,
    totalPrice: 0,
    credit: 200,
    describes: [
      {
        content: '200 credits/month',
        type: 'feature',
        tips: [
          '200 credits=100 3D motions',
          '200 credits=100s videos',
          'Plan credits refresh every month starting from your subscription date.',
        ],
      },
      {
        content: 'Text-to-Motion',
        type: 'feature',
      },
      {
        content: 'Motion-to-Video',
        type: 'feature',
      },
      {
        content: 'No credits top up',
        type: 'restriction',
      },
      {
        content: 'Fast generations',
        type: 'restriction',
      },
      {
        content: 'Private generations',
        type: 'feature',
      },
      {
        content: 'General commercial term',
        type: 'feature',
      },
    ],
    productId: ProductIdEnum.FREE,
  },
  {
    type: 'STANDARD',
    price: 10,
    totalPrice: 10,
    credit: 1000,
    describes: [
      {
        content: '1000 credits/month',
        type: 'feature',
        tips: [
          '1000 credits=500 3D motions',
          '1000 credits=500s videos',
          'Plan credits refresh every month starting from your subscription date.',
        ],
      },
      {
        content: 'Text-to-Motion',
        type: 'feature',
      },
      {
        content: 'Motion-to-Video',
        type: 'feature',
      },
      {
        content: 'Extra credits top up (coming soon)',
        type: 'feature',
      },
      {
        content: 'Fast generations(coming soon)',
        type: 'feature',
      },
      {
        content: 'Private generations',
        type: 'feature',
      },
      {
        content: 'General commercial term',
        type: 'feature',
      },
    ],
    productId: ProductIdEnum.STANDARD_MONTHLY,
    paymentCycle: 'monthly',
  },
  {
    type: 'STANDARD',
    price: 8,
    totalPrice: 96,
    credit: 1000,
    describes: [
      {
        content: '1000 credits/month',
        type: 'feature',
        tips: [
          '1000 credits=500 3D motions',
          '1000 credits=500s videos',
          'Plan credits refresh every month starting from your subscription date.',
        ],
      },
      {
        content: 'Text-to-Motion',
        type: 'feature',
      },
      {
        content: 'Motion-to-Video',
        type: 'feature',
      },
      {
        content: 'Extra credits top up (coming soon)',
        type: 'feature',
      },
      {
        content: 'Fast generations(coming soon)',
        type: 'feature',
      },
      {
        content: 'Private generations',
        type: 'feature',
      },
      {
        content: 'General commercial term',
        type: 'feature',
      },
    ],
    productId: ProductIdEnum.STANDARD_YEARLY,
    paymentCycle: 'yearly',
  },
];

export const PROJECT_RULE: Record<PlanType, ProjectRule> = {
  FREE: {
    maxContentCharacterNumber: 2500,
    maxPromptCharacterNumber: 20000,
    maxParagraphCharacterNumber: 300,
    maxParagraphsNumber: 16,
  },
  STANDARD: {
    maxContentCharacterNumber: 5000,
    maxPromptCharacterNumber: 20000,
    maxParagraphCharacterNumber: 300,
    maxParagraphsNumber: 16,
  },
  PRO: {
    maxContentCharacterNumber: 5000,
    maxPromptCharacterNumber: 50000,
    maxParagraphCharacterNumber: 300,
    maxParagraphsNumber: 32,
  },
};
