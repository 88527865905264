// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import {useUserContext} from 'contexts/UserContext';
import {useRef} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import styles from './AuthPage.module.scss';
import {OutletContext} from './AuthPage.types';

export function AuthPage() {
  const nextPath = useRef<string>();
  const {isLogin} = useUserContext();
  const location = useLocation();

  if (isLogin && location.pathname !== nextPath.current && nextPath.current) {
    return <Navigate to={nextPath.current} replace={true} />;
  }

  return (
    <div className={styles.container}>
      <Outlet
        context={
          {
            changeNextPath: (path: string) => (nextPath.current = path),
          } as OutletContext
        }
      />
    </div>
  );
}
