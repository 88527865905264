// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as PlusIcon} from 'assets/svg/3.0/AddAsset.svg';
import classNames from 'classnames';
import React, {useRef} from 'react';

import {Button} from '../Button';
import {Toast} from '../Toast';
import styles from './Upload.module.scss';
import {UploadProps} from './Upload.type';

export const Upload = ({
  accept,
  disabled,
  errorToastVisible,
  errorInfo,
  className,
  handleFileChange,
  handleDrop,
  hideErrorToast,
}: UploadProps) => {
  const _handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    handleDrop(event);
  };
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={classNames(styles.uploadContainer, className)}>
      <div
        className={styles.file}
        onDrop={_handleDrop}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <input
          className={styles.fileInput}
          type="file"
          onChange={handleFileChange}
          accept={accept}
          disabled={disabled}
          ref={inputRef}
        />
      </div>
      <PlusIcon />
      <Toast
        title="Upload Failed"
        visible={errorToastVisible}
        showCloseBtn={false}
        btnTheme="danger"
        footer={<></>}
      >
        <div className={styles.errorToastContent}>
          <p>{errorInfo}</p>
        </div>

        <div className={styles.errorToastFooter}>
          <Button type="button" size="large" onClick={hideErrorToast}>
            OK
          </Button>
        </div>
      </Toast>
    </div>
  );
};
