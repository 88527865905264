// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {noop} from 'lodash';

import styles from './Option.module.scss';
import {Props} from './Option.types';

export function Option({isDisabled, label, isChecked, onCheck}: Props) {
  // 把onCheck绑定在label上的原因是，即使点击相同的选项，也会触发onChange事件
  // 使用div不使用label的原因是，label会触发默认事件，导致onCheck触发两次
  return (
    <div className={styles.container} onClick={isDisabled ? noop : onCheck}>
      <input
        type="radio"
        disabled={isDisabled}
        checked={isChecked}
        onChange={noop}
        className={styles.input}
      />
      <span className={classNames(styles.label, isDisabled && styles.disabled)}>
        {label}
      </span>
    </div>
  );
}
