// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {CloseButtonType, withDialog} from 'pages/components/Dialog';

import {HistoryPreviewerContainer as HistoryPreviewer} from './HistoryPreviewer.container';

export const HistoryPreviewerDialog = withDialog({
  closeButtonType: CloseButtonType.Inside,
})(HistoryPreviewer);
