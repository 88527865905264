// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as FastIcon} from 'assets/svg/3.0/FastIcon.svg';
import {ReactComponent as LockIcon} from 'assets/svg/3.0/Lock2.svg';
import {ReactComponent as SlowIcon} from 'assets/svg/3.0/SlowIcon.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';

import styles from './StyleCard.module.scss';
import {Props} from './StyleCard.types';

export function StyleCard({
  label,
  preview,
  previewGif,
  isSelected,
  onSelected,
  disabled,
  flow,
}: Props) {
  return (
    <li
      className={classNames(styles.container, isSelected && styles.selected)}
      onClick={() => !disabled && !isSelected && onSelected()}
    >
      <div
        className={classNames(
          styles['preview-box'],
          previewGif && styles['has-preview-gif'],
          (isSelected || !preview) && styles['preview-box-selected']
        )}
        style={{
          backgroundImage: previewGif ? `url(${previewGif})` : undefined,
        }}
      >
        {disabled && (
          <div className={styles['preview-mask']}>
            <div className={styles['preview-lock']}>
              <LockIcon />
            </div>
          </div>
        )}
        {preview && <img src={preview} className={styles.preview} />}
      </div>
      {label && (
        <div className={styles.label}>
          {flow === 'Fast' && <FastIcon />}
          {flow === 'Slow' && <SlowIcon />}
          <Popover
            className={classNames(
              'label-popover',
              flow === 'Fast' || flow === 'Slow' ? 'has-icon' : ''
            )}
            triggerElement={<div className={styles['label-text']}>{label}</div>}
            trigger="hover"
            content={<div className={styles['popover-content']}>{label}</div>}
            direction="right"
            isControlByOverflow={true}
          />
        </div>
      )}
    </li>
  );
}
