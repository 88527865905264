// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Effect} from 'api/server';
import {Size, Tone} from 'modules/project/types';
import {ThumbnailType} from 'modules/project-config/types';

import {BasePreference} from './BasePreference';

export class GeneralStoryPreference extends BasePreference {
  static fromJSON(json: Record<string, any>): GeneralStoryPreference {
    return new GeneralStoryPreference(
      json.size,
      json.language,
      json.style,
      json.paragraphAsShots,
      json.quickPace,
      json.tone,
      json.titleStyleName,
      json.subtitleStyleName,
      json.effect,
      json.transition,
      json.thumbnailType,
      json.thumbnailIncludeVideo,
      json.bgm,
      json.voiceover,
      json.voiceoverSpeed
    );
  }
  constructor(
    size?: Size,
    language?: string,
    readonly style?: string,
    readonly paragraphAsShots?: boolean,
    readonly quickPace?: boolean,
    readonly tone?: Tone,
    readonly titleStyleName?: string,
    readonly subtitleStyleName?: string,
    readonly effect?: Effect,
    readonly transition?: string,
    readonly thumbnailType?: ThumbnailType,
    readonly thumbnailIncludeVideo?: boolean,
    readonly bgm?: string,
    readonly voiceover?: string,
    readonly voiceoverSpeed?: 'normal' | 'fast'
  ) {
    super(size, language);
  }
  patch(data: Partial<GeneralStoryPreference>): GeneralStoryPreference {
    const {
      size,
      language,
      style,
      paragraphAsShots,
      quickPace,
      tone,
      titleStyleName,
      subtitleStyleName,
      effect,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceover,
      voiceoverSpeed,
    } = Object.assign({}, this, data);
    return new GeneralStoryPreference(
      size,
      language,
      style,
      paragraphAsShots,
      quickPace,
      tone,
      titleStyleName,
      subtitleStyleName,
      effect,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceover,
      voiceoverSpeed
    );
  }
}
