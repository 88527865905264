// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import EmptyAvatar from 'assets/images/Avatar2.png';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/ArrowRight.svg';
import {ReactComponent as LeftArrowIcon} from 'assets/svg/3.0/LeftArrow.svg';
import {ReactComponent as CloseIcon} from 'assets/svg/outline/Plus2.svg';
import classNames from 'classnames';
import {useSize} from 'contexts/SizeContext';
import {useUserContext} from 'contexts/UserContext';
import {Popover} from 'pages/components/Popover';
import {useState} from 'react';

import {Menu} from '../Menu';
import styles from './UserCard.module.scss';

interface Props {
  onClickChangePW: () => void;
  onClose?: () => void;
}

export function UserCard({onClickChangePW, onClose}: Props) {
  const {
    userInfo: {userName, portrait},
  } = useUserContext();

  const [isSelected, setIsSelected] = useState(false);
  const {size} = useSize();

  return (
    <div
      className={classNames(styles.container, isSelected && styles.selected)}
      onClick={() => setIsSelected(true)}
    >
      {size !== 'xs' && size !== 'sm' && isSelected && (
        <Popover onClose={() => setIsSelected(false)}>
          <Menu
            onClose={() => setIsSelected(false)}
            onClickChangePW={onClickChangePW}
          />
        </Popover>
      )}
      {(size === 'xs' || size === 'sm') && isSelected && (
        <div className={styles.menu}>
          <div className={styles.header}>
            <LeftArrowIcon
              onClick={e => {
                e.stopPropagation();
                setIsSelected(false);
              }}
            />
            <CloseIcon
              onClick={e => {
                e.stopPropagation();
                setIsSelected(false);
                onClose?.();
              }}
            />
          </div>
          <Menu
            className={styles.content}
            onClose={() => {
              setIsSelected(false);
              onClose?.();
            }}
            onClickChangePW={onClickChangePW}
          />
        </div>
      )}
      <img className={styles.avatar} src={portrait || EmptyAvatar} />
      <p className={styles.username}>{userName}</p>
      <RightArrowIcon className={styles.icon} />
    </div>
  );
}
