// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {checkOrder, getUserSubscription} from 'api/payment';
import * as apiServer from 'api/server';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {clearOutTradeNo, getOutTradeNo} from 'contexts/localStorage';
import {useUserContext} from 'contexts/UserContext';
import {getQueryStringParameters} from 'lib/common';
import {isSubscription} from 'modules/payment/services';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {convertAffiliateOrder} from 'utils/rewardful';

import {creditOptions} from '../constData';
import {getLevelByProductId, getTypeByProductId} from '../Plan.container';
import {PaymentResult} from './PaymentResult';
import {CheckState, HookParams, HookReturn} from './PaymentResult.types';

export function useHook({status}: HookParams): HookReturn {
  const [checkState, setCheckState] = useState<CheckState>('CHECKING');

  const {recordEvent} = useAnalysis();

  const productInfo: HookReturn['productInfo'] = useMemo(() => {
    const params = getQueryStringParameters(location.href);
    const is_subscription = isSubscription(Number(params.productId));
    const payCreditItem =
      !is_subscription &&
      creditOptions.find(
        item => Number(item.productId) === Number(params.productId)
      );
    return {
      is_subscription,
      level:
        (is_subscription && getLevelByProductId(Number(params.productId))) ||
        undefined,
      type:
        (is_subscription && getTypeByProductId(Number(params.productId))) ||
        undefined,
      productId: Number(params.productId),
      payCredit: (payCreditItem && payCreditItem.credit) || undefined,
    };
  }, []);
  const outTradeNo = useMemo(() => getOutTradeNo(), []);
  const {userInfo, updateUserInfo} = useUserContext();

  const checkOrderSuccess = useCallback(async () => {
    if (!outTradeNo) return setCheckState('FAIL');
    const {
      data: {isSuccessful: isPaymentSuccessful, customerEmail},
    } = await checkOrder(outTradeNo);

    // 支付成功页面并且订单编号验证成功，则发送转化统计
    if (status === 'SUCCESS' && isPaymentSuccessful) {
      if (customerEmail) {
        convertAffiliateOrder(customerEmail, outTradeNo);
      }

      recordEvent('Subscription', {data: {transaction_id: outTradeNo}});
    }

    if (
      (status === 'SUCCESS' && isPaymentSuccessful) ||
      (status === 'FAILED' && !isPaymentSuccessful)
    ) {
      setCheckState('SUCCESS');
      apiServer
        .getAccountDetails()
        .then((userCreditData: apiServer.GetAccountDetailsResponseType) => {
          updateUserInfo({
            plan: userCreditData.planType,
            credit: userCreditData.creditWalletAmount,
            planCreditAmount: userCreditData.planCreditAmount,
            creditPackAmount: userCreditData.creditPackAmount,
          });
        });
      if (productInfo.is_subscription) {
        const {
          data: {subscriptionDetail},
        } = await getUserSubscription(userInfo.userId);
        if (subscriptionDetail) {
          updateUserInfo({
            periodEnd: subscriptionDetail.periodEnd,
            autoRenewal: Boolean(subscriptionDetail.autoRenewal),
          });
        }
      }
    } else if (
      (status === 'SUCCESS' && !isPaymentSuccessful) ||
      (status === 'FAILED' && isPaymentSuccessful)
    ) {
      setCheckState('FAIL');
    }
    clearOutTradeNo();
  }, [
    outTradeNo,
    status,
    recordEvent,
    productInfo.is_subscription,
    updateUserInfo,
    userInfo.userId,
  ]);

  useEffect(() => {
    checkOrderSuccess();
  }, [checkOrderSuccess]);

  return {checkState, status, productInfo, userInfo};
}

export const PaymentResultContainer = combine(useHook, ['status'])(
  PaymentResult
);
