// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Effect} from 'api/server';
import CameraShakePreview from 'assets/images/camera_shake.gif';
import KenBurnsPreview from 'assets/images/ken-burns.gif';
import MiniZoomPreview from 'assets/images/mini-zoom.gif';
import None from 'assets/svg/3.0/None.svg';
import {ReactComponent as StarIcon} from 'assets/svg/3.0/Star.svg';
import {Collapse} from 'pages/components/Collapse';
import {OptionType} from 'pages/components/Option';
import {useCallback} from 'react';

import {StyleCard} from '../StyleCard';
import styles from './SceneEffectList.module.scss';
import {Props} from './SceneEffectList.types';

const EFFECT_MAP: (OptionType<Effect> & {
  preview: string;
  previewGif?: string;
  flow?: 'Fast' | 'Slow';
})[] = [
  {
    label: 'None',
    value: null,
    preview: None,
  },
  {
    label: 'Mini zoom',
    value: {
      type: 'ken_burns',
      ratio: 0.9,
    },
    preview: '', //TODO
    previewGif: MiniZoomPreview,
  },
  {
    label: 'Ken burns',
    value: 'ken_burns',
    preview: '', //TODO
    previewGif: KenBurnsPreview,
  },
  {
    label: 'Shake move',
    value: 'camera_shake',
    preview: '', //TODO
    previewGif: CameraShakePreview,
    flow: 'Fast',
  },
];

export function SceneEffectList({effect, onChange}: Props) {
  const isSameEffect = useCallback((effects: [Effect, Effect]) => {
    if (typeof effects[0] !== typeof effects[1]) return false;
    else {
      if (
        typeof effects[0] === 'object' &&
        typeof effects[1] === 'object' &&
        effects[0] &&
        effects[1]
      ) {
        return (
          effects[0].type === effects[1].type &&
          effects[0].ratio === effects[1].ratio
        );
      } else {
        return effects[0] === effects[1];
      }
    }
  }, []);

  return (
    <Collapse
      icon={<StarIcon className={styles['label-icon']} />}
      title="Scene effects"
    >
      <ul className={styles.list}>
        {EFFECT_MAP.map(({label, value, preview, previewGif, flow}) => (
          <StyleCard
            label={label}
            onSelected={() => onChange(value)}
            key={label}
            isSelected={isSameEffect([effect, value])}
            preview={preview || ''}
            previewGif={previewGif || ''}
            flow={flow}
          />
        ))}
      </ul>
    </Collapse>
  );
}
