// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {CustomizedClosedCharacter} from 'modules/character/types';
import {BilingualStoryComposition} from 'modules/composition/models/BilingualStoryComposition';
import {
  fromJSON as fromJSONPromptPolicy,
  PromptPolicy,
} from 'modules/project/models/PromptPolicy';
import {Size} from 'modules/project/types';
import {BilingualStoryClosedScene} from 'modules/scene/types';
import {Task} from 'modules/task/models/Task';
import {TaskType} from 'modules/task/types';

import {BilingualStoryHistoryJSON} from '../types';
import {BaseHistory} from './BaseHistory';

export class BilingualStoryHistory extends BaseHistory<'bilingual_story'> {
  static fromComposition(
    composition: BilingualStoryComposition,
    storyboardTasks: Task<TaskType>[],
    id?: string
  ): BilingualStoryHistory {
    if (!composition.isValid()) {
      throw new Error('Invalid composition');
    }
    return new BilingualStoryHistory(
      id ?? composition.id,
      composition.size,
      composition.language,
      composition.nativeLanguage,
      composition.vocabulary,
      composition.style,
      composition.promptPolicy,
      composition.prompt,
      composition.title,
      composition.nativeTitle,
      composition.description,
      composition.hashtags,
      composition.characters,
      composition.scenes,
      composition.createTime!,
      composition.thumbnail!,
      composition.asset!,
      [...storyboardTasks, composition.task!],
      composition.assetProduct
    );
  }

  static fromJSON({
    id,
    size,
    language,
    native_language,
    vocabulary,
    style,
    prompt_policy,
    prompt,
    title,
    native_title,
    description,
    hashtags,
    characters,
    scenes,
    create_time,
    thumbnail,
    asset,
    tasks,
    asset_product,
  }: BilingualStoryHistoryJSON) {
    return new BilingualStoryHistory(
      id,
      size,
      language,
      native_language,
      vocabulary.filter(Boolean),
      style,
      fromJSONPromptPolicy('bilingual_story', prompt_policy),
      prompt,
      title,
      native_title,
      description,
      hashtags,
      characters,
      scenes,
      create_time,
      thumbnail,
      asset,
      tasks?.map(Task.fromJSON),
      asset_product
    );
  }

  constructor(
    id: string,
    size: Size,
    language: string,
    readonly nativeLanguage: string,
    readonly vocabulary: string[],
    readonly style: string,
    promptPolicy: PromptPolicy<'bilingual_story'>,
    prompt: string,
    title: string,
    readonly nativeTitle: string,
    description: string,
    hashtags: string[],
    characters: CustomizedClosedCharacter[],
    scenes: BilingualStoryClosedScene[],
    createTime: string,
    thumbnail: string,
    asset: string,
    tasks?: Task<TaskType>[],
    assetProduct?: string
  ) {
    super(
      id,
      size,
      language,
      promptPolicy,
      prompt,
      title,
      description,
      hashtags,
      characters,
      scenes,
      createTime,
      thumbnail,
      asset,
      assetProduct,
      tasks
    );
  }

  toJSON(): BilingualStoryHistoryJSON {
    return {
      ...super.toJSON(),
      style: this.style,
      native_language: this.nativeLanguage,
      native_title: this.nativeTitle,
      vocabulary: this.vocabulary,
    };
  }
}
