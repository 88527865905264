// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useRef} from 'react';
import {useClickOutside} from 'utils/use-click-outside';

import styles from './Popover.module.scss';
import {MaskType, Props} from './Popover.types';

export function Popover({
  children,
  onClose,
  maskType = MaskType.Transparent,
  scrollable = false,
}: Props) {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(popoverRef, onClose);

  return (
    <>
      <div
        data-tag="popover-mask"
        className={classNames(styles['popover-mask'], styles[maskType], {
          [styles.scrollable]: scrollable,
        })}
      ></div>
      <div
        className={styles.popover}
        ref={popoverRef}
        onMouseDown={e => e.stopPropagation()}
      >
        {children}
      </div>
    </>
  );
}
