// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {CustomizedCharacter} from 'modules/character/models/CustomizedCharacter';
import {PromptPolicy} from 'modules/project/models/PromptPolicy';

import {Size} from '../../project/types';
import {BilingualDialogueScene} from '../../scene/models/BilingualDialogueScene';
import {Task} from '../../task/models/Task';
import {
  BilingualDialogueCustomizedCharacters,
  BilingualDialogueStoryboardJSON,
  TaskType,
} from '../types';
import {BaseStoryboard} from './BaseStoryboard';

export class BilingualDialogueStoryboard extends BaseStoryboard<'bilingual_dialogue'> {
  static fromJSON(
    size: Size,
    language: string,
    native_language: string,
    vocabulary: string[],
    style: string,
    promptPolicy: PromptPolicy<'bilingual_dialogue'>,
    prompt: string,
    {
      id,
      title,
      native_title,
      description,
      hashtags,
      characters,
      scenes,
      script,
      tasks,
      task,
      task_session,
      closed,
      customized_characters,
    }: BilingualDialogueStoryboardJSON
  ): BilingualDialogueStoryboard {
    const _tasks = tasks?.map(Task.fromJSON);
    const _task = task && _tasks ? _tasks.find(t => t.id === task) : undefined;
    if (task && !_task) {
      throw new Error(`Task with id ${task} not found`);
    }
    if (_task && _task.type !== 'generate_storyboard') {
      throw new Error(
        `Invalid task type: generate_storyboard vs ${_task.type}`
      );
    }
    return new BilingualDialogueStoryboard(
      id,
      size,
      language,
      native_language,
      vocabulary,
      style,
      promptPolicy,
      prompt,
      customized_characters,
      title,
      native_title,
      description,
      hashtags,
      characters?.map(CustomizedCharacter.fromJSON),
      scenes?.map(scene => BilingualDialogueScene.fromJSON(scene, _tasks)),
      script,
      _tasks,
      _task as Task<'generate_storyboard'> | undefined,
      task_session,
      closed
    );
  }

  constructor(
    id: string,
    size: Size,
    language: string,
    readonly nativeLanguage: string,
    readonly vocabulary: string[],
    readonly style: string,
    promptPolicy: PromptPolicy<'bilingual_dialogue'>,
    prompt: string,
    readonly customizedCharacters?: BilingualDialogueCustomizedCharacters,
    title?: string,
    readonly nativeTitle?: string,
    description?: string,
    hashtags?: string[],
    characters?: CustomizedCharacter[],
    scenes?: BilingualDialogueScene[],
    script?: string,
    tasks?: Task<TaskType>[],
    task?: Task<'generate_storyboard'>,
    taskSession?: string,
    closed?: boolean
  ) {
    super(
      id,
      size,
      language,
      promptPolicy,
      prompt,
      title,
      description,
      hashtags,
      characters,
      scenes,
      script,
      tasks,
      task,
      taskSession,
      closed
    );
  }

  patch(
    data: Partial<{
      [K in
        | 'title'
        | 'nativeTitle'
        | 'description'
        | 'hashtags'
        | 'characters'
        | 'scenes'
        | 'script'
        | 'tasks'
        | 'task'
        | 'taskSession'
        | 'closed']: BilingualDialogueStoryboard[K];
    }>
  ) {
    const {
      id,
      size,
      language,
      nativeLanguage,
      vocabulary,
      style,
      promptPolicy,
      prompt,
      title,
      nativeTitle,
      description,
      hashtags,
      characters,
      scenes,
      script,
      tasks,
      task,
      taskSession,
      closed,
      customizedCharacters,
    } = Object.assign({}, this, data);
    return new BilingualDialogueStoryboard(
      id,
      size,
      language,
      nativeLanguage,
      vocabulary,
      style,
      promptPolicy,
      prompt,
      customizedCharacters,
      title,
      nativeTitle,
      description,
      hashtags,
      characters,
      scenes,
      script,
      tasks,
      task,
      taskSession,
      closed
    );
  }

  toJSON(): BilingualDialogueStoryboardJSON {
    return {
      ...super.toJSON(),
      native_title: this.nativeTitle,
      customized_characters: this.customizedCharacters,
    };
  }
}
