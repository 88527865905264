// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import {AxiosResponse} from 'axios';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useUserContext} from 'contexts/UserContext';
import {EMAIL_REG} from 'lib/auth';
import {getQueryStringParameters} from 'lib/common';
import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CONFIRM_EMAIL_PATH, RESET_PASSWORD_PATH, ROOT_PATH} from 'utils/path';

import {usePasswordConfirmPassword} from '../modules';
import {AuthForm} from './AuthForm';
import {EmailErrorType, HookParams, HookReturn} from './AuthForm.types';

export function useHooks({
  authType,
  setErrorToastType,
  showErrorToast,
}: HookParams): HookReturn {
  const [email, setEmail] = useState(() => {
    const params = getQueryStringParameters(location.href);
    return params.email || '';
  });
  const {recordEvent} = useAnalysis();
  const {
    password,
    confirmPassword,
    passwordErrorType,
    validPassword,
    validConfirmPassword,
    changePassword,
    changeConfirmPassword,
    handleSetPasswordErrorType,
  } = usePasswordConfirmPassword();

  const [emailErrorType, setEmailErrorType] = useState<EmailErrorType | null>(
    null
  );
  const {updateUserLogin} = useUserContext();
  const navigate = useNavigate();
  const loginWithEmail = useCallback(
    async (email: string, password: string) => {
      apiServer
        .login({
          email,
          password,
        })
        .then(res => {
          updateUserLogin(
            email,
            res.data.userName,
            res.data.userId,
            res.data.portrait,
            res.data.token,
            res.data.emailConfirmStatus
          );
          navigate(ROOT_PATH);
          return true;
        })
        .catch((err: AxiosResponse<apiServer.Response>) => {
          const {error} = err.data || {};
          switch (error) {
            case apiServer.ResponseCode.PASSCODE_NOT_MATCH:
              handleSetPasswordErrorType('PASSCODE_NOT_MATCH');
              break;
            case apiServer.ResponseCode.USER_NOT_FOUND:
              setEmailErrorType('USER_NOT_FOUND');
              break;
            case apiServer.ResponseCode.USER_EMAIL_NOT_CONFIRM_YET:
              navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
              break;
          }
          return err;
        });
    },
    [updateUserLogin, navigate, handleSetPasswordErrorType]
  );
  const login = useCallback(() => {
    loginWithEmail(email, password);
  }, [email, loginWithEmail, password]);

  const signup = useCallback(() => {
    apiServer
      .signUp({
        email,
        password,
        confirmPassword: confirmPassword,
      })
      .then(res => {
        // Google signup为发送邮件埋点
        recordEvent('Email-Signup');
        if (res.data && res.data.accountMergeStatus === true) {
          //合并账户有异常
          setErrorToastType('mergeAccountError');
          showErrorToast(true, email, () => {
            navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
          });
          return;
        }
        navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
      })
      .catch((err: AxiosResponse<apiServer.Response>) => {
        const {error} = err.data || {};
        switch (error) {
          case apiServer.SUCCESS_STATUS_CODE:
            loginWithEmail(email, password);
            break;
          case apiServer.ResponseCode.EMAIL_HAD_REGISTER_YET:
            setEmailErrorType('EMAIL_HAD_REGISTER_YET');
            break;
        }
      });
  }, [
    confirmPassword,
    email,
    loginWithEmail,
    navigate,
    recordEvent,
    password,
    setErrorToastType,
    showErrorToast,
  ]);

  const checkEmailExist = useCallback(async () => {
    const canUse = await apiServer.isUserCanSignup(email);
    if (!canUse) {
      if (authType === 'signup') {
        setEmailErrorType('EMAIL_HAD_REGISTER_YET');
      }
      return false;
    }
    return true;
  }, [authType, email]);

  const validEmail = useCallback(
    async (callbackFun?: () => void) => {
      let isValid = EMAIL_REG.test(email);

      if (isValid && emailErrorType === 'EMAIL_INVALID') {
        setEmailErrorType(null);
      } else if (!isValid) {
        setEmailErrorType('EMAIL_INVALID');
        return false;
      }
      if (authType === 'signup') {
        isValid = await checkEmailExist();
      }
      if (isValid && callbackFun) {
        callbackFun();
      }
      return isValid;
    },
    [authType, checkEmailExist, email, emailErrorType]
  );
  const onConfirm = useCallback(async () => {
    const passwordPass = validPassword();
    const confirmPasswordPass =
      authType === 'signup'
        ? passwordPass
          ? validConfirmPassword()
          : false
        : true;
    if (!passwordPass && authType === 'login') {
      handleSetPasswordErrorType('PASSCODE_NOT_MATCH');
    }
    if (!passwordPass || !confirmPasswordPass) return;

    const validEmailRes = await validEmail();
    if (
      validEmailRes &&
      passwordPass &&
      ((authType === 'signup' && confirmPasswordPass) || authType === 'login')
    ) {
      if (authType === 'login') {
        login();
      } else {
        signup();
      }
    }
  }, [
    authType,
    handleSetPasswordErrorType,
    login,
    signup,
    validConfirmPassword,
    validEmail,
    validPassword,
  ]);

  const changeEmail = useCallback(
    (value: string) => {
      setEmailErrorType(null);
      if (passwordErrorType === 'PASSCODE_NOT_MATCH') {
        handleSetPasswordErrorType(null);
      }
      setEmail(value);
    },
    [handleSetPasswordErrorType, passwordErrorType]
  );

  const onForgotPassword = useCallback(() => {
    navigate(RESET_PASSWORD_PATH);
  }, [navigate]);

  return {
    authType,
    email,
    password,
    confirmPassword,
    emailErrorType,
    passwordErrorType,
    validEmail,
    validPassword,
    setEmail: changeEmail,
    setPassword: changePassword,
    setConfirmPassword: changeConfirmPassword,
    onConfirm,
    validConfirmPassword,
    onForgotPassword,
  };
}
export const AuthFormContainer = combine(useHooks, [
  'authType',
  'setErrorToastType',
  'showErrorToast',
])(AuthForm);
