// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Draft} from 'modules/draft/models/Draft';
import {TaskType as StoryboardTaskType} from 'modules/storyboard/types';
import {Task} from 'modules/task/models/Task';

import {
  GeneralStoryClosedScene,
  GeneralStorySceneJSON,
  TaskType,
} from '../types';
import {isSceneTask} from '../utils';
import {BaseScene} from './BaseScene';

export class GeneralStoryScene extends BaseScene {
  static fromJSON(
    {
      id,
      type,
      prompt,
      last_prompt,
      subtitle,
      shot_type,
      characters,
      image,
      video,
      draft,
      task,
    }: GeneralStorySceneJSON,
    tasks?: Task<StoryboardTaskType>[]
  ) {
    const _task = task && tasks ? tasks.find(t => t.id === task) : undefined;
    if (task && !_task) {
      throw new Error(`Task with id ${task} not found`);
    }
    if (_task && !isSceneTask(_task)) {
      throw new Error(`Invalid task type: ${_task.type}`);
    }
    return new GeneralStoryScene(
      id,
      type,
      prompt,
      last_prompt,
      subtitle,
      shot_type,
      characters,
      image,
      video,
      draft ? Draft.fromJSON(draft) : undefined,
      _task as Task<TaskType> | undefined
    );
  }

  patch(
    data: Partial<{
      [K in
        | 'prompt'
        | 'subtitle'
        | 'image'
        | 'video'
        | 'draft'
        | 'task']: GeneralStoryScene[K];
    }>
  ) {
    const {
      id,
      type,
      prompt,
      lastPrompt,
      subtitle,
      shotType,
      characters,
      image,
      video,
      draft,
      task,
    } = Object.assign({}, this, data);
    return new GeneralStoryScene(
      id,
      type,
      prompt,
      lastPrompt,
      subtitle,
      shotType,
      characters,
      image,
      video,
      draft,
      task
    );
  }

  toJSON(): GeneralStorySceneJSON {
    return super.toJSON();
  }

  toClosed(): GeneralStoryClosedScene {
    return super.toClosed();
  }
}
