// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {TaskSchema, TaskType} from '../types';
import {ShootingTask} from './ShootingTask';
import {SimpleTask, SimpleTaskType} from './SimpleTask';
import {StoryboardTask} from './StoryboardTask';

export type Task<T extends TaskType = TaskType> = T extends TaskType.Storyboard
  ? StoryboardTask
  : T extends TaskType.Shooting
  ? ShootingTask
  : T extends SimpleTaskType
  ? SimpleTask<T>
  : never;

export function fromJSON<T extends TaskType>(type: T, data: TaskSchema<T>) {
  switch (type) {
    case TaskType.Storyboard:
      return StoryboardTask.fromJSON(
        data as TaskSchema<TaskType.Storyboard>
      ) as Task<T>;
    case TaskType.Shooting:
      return ShootingTask.fromJSON(
        data as TaskSchema<TaskType.Shooting>
      ) as Task<T>;
    case TaskType.Motion:
    case TaskType.RemoveImageBG:
    case TaskType.PoseSceneImage:
    case TaskType.ImageConditioningVideo:
    case TaskType.StyleTransfer:
      return SimpleTask.fromJSON(
        type,
        data as TaskSchema<SimpleTaskType>
      ) as Task<T>;
    default:
      throw new Error(`Unknown task type: ${type}`);
  }
}
