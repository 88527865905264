// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  Character,
  FailureReason,
  Scene,
  TaskSchema,
  TaskStatus,
  TaskType,
} from '../types';
import {BaseTask} from './BaseTask';
import {Progress} from './Progress';

export class StoryboardTask extends BaseTask {
  static fromJSON({
    id,
    status,
    type,
    create_time,
    progress,
    failure_reason,
    estimated_remaining_time,
    elapsed_total_time,
    script_full_text,
    title,
    native_title,
    abstract,
    hashtags,
    characters,
    scenes,
  }: TaskSchema<TaskType.Storyboard>) {
    return new StoryboardTask(
      id,
      status,
      type,
      create_time,
      progress && Progress.fromJSON(progress),
      failure_reason,
      estimated_remaining_time,
      elapsed_total_time,
      script_full_text,
      title,
      native_title,
      abstract,
      hashtags,
      characters,
      scenes
    );
  }

  constructor(
    id: string,
    status: TaskStatus,
    type: string,
    createTime: string,
    progress?: Progress,
    failureReason?: FailureReason,
    estimatedRemainingTime?: number,
    elapsedTotalTime?: number,
    readonly script?: string,
    readonly title?: string,
    readonly nativeTitle?: string,
    readonly abstract?: string,
    readonly hashtags?: string[],
    private readonly _characters?: Character[],
    private readonly _scenes?: Scene[]
  ) {
    super(
      id,
      status,
      type,
      createTime,
      progress,
      failureReason,
      estimatedRemainingTime,
      elapsedTotalTime
    );
  }

  get characters() {
    return this._characters ?? [];
  }

  get scenes() {
    return this._scenes ?? [];
  }
}
