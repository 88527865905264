// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {RequestName, ResponseParam, ResponseStatus} from 'api/unity';
import {catchSync} from 'lib/exception';

import {UnityInstance} from '../types';

// The object name in Unity to receive messages.
const UNITY_SERVICE_PROVIDER = 'ServiceProvider';

const MAX_SESSION_ID = 999999;

type OnResponseFunction = (status: ResponseStatus, param: string) => void;

export class UnityConnection {
  instance: UnityInstance | null = null;

  private nextSessionId = 0;
  // A map from session ids to response callback functions.
  // TODO: Add timeout mechanism for responses.
  private responseFuncs = new Map<number, OnResponseFunction>();

  sendRequest<T>(name: RequestName, param?: T) {
    return new Promise((resolve, reject) => {
      this.internalSendRequest(name, param, (status, param) => {
        if (status === 'OK') {
          resolve(catchSync(JSON.parse.bind(JSON, param)));
        } else {
          reject(new Error(param));
        }
      });
    });
  }

  onResponse(response: ResponseParam) {
    if (response.status !== 'OK') {
      console.error(`Received an error response from Unity: ${response.param}`);
    }
    const responseFunc = this.responseFuncs.get(response.sessionId);
    if (responseFunc) {
      responseFunc(response.status, response.param);
    }
    this.responseFuncs.delete(response.sessionId);
  }

  private internalSendRequest<T>(
    requestName: RequestName,
    param?: T,
    onResponse?: OnResponseFunction
  ) {
    if (!this.instance) {
      if (onResponse) {
        onResponse('Error', 'No Unity instance');
      }
      return;
    }

    const sessionId = this.nextSessionId;
    this.increaseNextSessionId();
    if (onResponse) {
      this.responseFuncs.set(sessionId, onResponse);
    }
    this.instance.SendMessage(
      UNITY_SERVICE_PROVIDER,
      'SendRequest',
      JSON.stringify({
        sessionId: sessionId,
        name: requestName,
        param: param ? JSON.stringify(param) : '',
      })
    );
  }

  private increaseNextSessionId() {
    this.nextSessionId++;
    if (this.nextSessionId > MAX_SESSION_ID) {
      this.nextSessionId = 0;
    }
  }
}
