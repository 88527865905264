// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useCallback, useEffect, useRef, useState} from 'react';

export function useUserAsset({
  getImage,
}: {
  getImage: (
    objectKey: string
  ) => Promise<{src: string; width: number; height: number}>;
}) {
  const imageCache = useRef<
    Record<
      string,
      Promise<{src: string; width: number; height: number}> | undefined
    >
  >({});

  const getImageWithObjectKey = useCallback(
    async (objectKey: string, cacheAble = true) => {
      if (!cacheAble) return getImage(objectKey);
      if (imageCache.current[objectKey] !== undefined) {
        return imageCache.current[objectKey]!;
      }
      const result = getImage(objectKey).catch(e => {
        imageCache.current[objectKey] = undefined;
        return Promise.reject(e);
      });
      imageCache.current[objectKey] = result;
      return result;
    },
    [getImage]
  );

  const refreshImage = useCallback(
    async (objectKey: string) => {
      imageCache.current[objectKey] = undefined;
      return getImageWithObjectKey(objectKey, true);
    },
    [getImageWithObjectKey]
  );

  return {getImageWithObjectKey, refreshImage};
}

export const useImage = ({
  objectKey,
  getImageWithObjectKey,
  refreshImage,
  cacheAble = true,
}: {
  getImageWithObjectKey: (
    objectKey: string,
    cacheAble?: boolean
  ) => Promise<{src: string; width: number; height: number}>;
  objectKey: string;
  cacheAble?: boolean;
  refreshImage?: (
    objectKey: string
  ) => Promise<{src: string; width: number; height: number}>;
}) => {
  const [src, setSrc] = useState('');
  const [loading, setLoading] = useState(true);
  const onError = useCallback(async () => {
    const image = await refreshImage?.(objectKey);
    setSrc(image?.src || '');
    setLoading(false);
  }, [objectKey, refreshImage]);
  useEffect(() => {
    setSrc('');
    setLoading(true);
    const getImage = async () => {
      const image = await getImageWithObjectKey(objectKey, cacheAble);
      setSrc(image.src);
      setLoading(false);
    };
    getImage();
  }, [cacheAble, getImageWithObjectKey, objectKey]);

  return {src, loading, onError};
};
