// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Composition} from 'modules/composition/models/Composition';
import {CompositionJSON} from 'modules/composition/types';
import {Constraint} from 'modules/constraint/models/Constraint';
import {ConstraintJSON} from 'modules/constraint/types';
import {ProjectConfig} from 'modules/project-config/models/ProjectConfig';
import {ProjectConfigJSON} from 'modules/project-config/types';
import {Storyboard} from 'modules/storyboard/models/Storyboard';
import {StoryboardJSON} from 'modules/storyboard/types';

import {
  BaseProjectJSON,
  ProjectType,
  PromptPolicyJSON,
  PromptType,
  Size,
} from '../types';
import {checkoutPromptType} from '../utils';
import {PromptPolicy} from './PromptPolicy';

export abstract class BaseProject<T extends ProjectType> {
  constructor(
    readonly id: string,
    readonly version: number,
    readonly authorId: string,
    readonly constraint: Constraint<T>,
    readonly size: Size,
    readonly language: string,
    readonly promptPolicy: PromptPolicy<T>,
    readonly config: ProjectConfig<T>,
    protected readonly _prompt: Record<PromptType, string>,
    readonly storyboard?: Storyboard<T>,
    readonly composition?: Composition<T>,
    readonly histories?: string[]
  ) {}

  abstract get type(): T;
  abstract get thumbnail(): string | undefined;
  abstract patch(data: Partial<BaseProject<T>>): BaseProject<T>;

  get prompt(): string | undefined {
    return this._prompt[checkoutPromptType(this.promptPolicy)];
  }

  get historyQuantity() {
    return (
      (this.histories?.length ?? 0) +
      (this.composition && this.composition.isValid() ? 1 : 0)
    );
  }

  toJSON(): BaseProjectJSON<T> {
    return {
      id: this.id,
      version: this.version,
      author_id: this.authorId,
      type: this.type,
      constraint: this.constraint.toJSON() as ConstraintJSON<T>,
      size: this.size,
      language: this.language,
      prompt_policy: this.promptPolicy.toJSON() as PromptPolicyJSON<T>,
      prompt: this._prompt,
      storyboard: this.storyboard?.toJSON() as StoryboardJSON<T>,
      composition: this.composition?.toJSON() as CompositionJSON<T>,
      config: this.config?.toJSON() as ProjectConfigJSON<T>,
      histories: this.histories,
    };
  }
}
