// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useRawVideoPayment} from 'modules/payment/services/raw-video';
import {useCallback} from 'react';
import {openStripeCheckout} from 'utils/stripe';

import {VideoDownloadToast} from './VideoDownloadToast';
import {HookParams, HookReturn} from './VideoDownloadToast.types';

function useHook({
  videoId,
  type,
  outTradeNo,
  historyId,
  projectId,
  onCancel,
  onDownload,
}: HookParams): HookReturn {
  const {buyVideo} = useRawVideoPayment();

  const onConfirm = useCallback(
    async (isChecked: boolean) => {
      if (!isChecked) {
        onDownload(false);
        return onCancel();
      }
      const params = {type, projectId};
      const session = await buyVideo(videoId, {
        ...params,
        historyId: historyId ?? '',
      });
      await openStripeCheckout(session);
    },
    [historyId, onDownload, onCancel, buyVideo, projectId, type, videoId]
  );

  return {
    outTradeNo,
    onConfirm,
    onCancel,
  };
}

export const VideoDownloadToastContainer = combine(useHook, [
  'videoId',
  'type',
  'onCancel',
  'outTradeNo',
  'projectId',
  'onDownload',
  'historyId',
])(VideoDownloadToast);
