// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

/* eslint-disable no-constant-condition */

import {Button} from 'pages/components/Button';
import {Modal} from 'pages/components/Modal';
import {SketchPicker} from 'react-color';
import Draggable from 'react-draggable';
import {ResizableBox} from 'react-resizable';

import styles from './UseImageModal.module.scss';
import {UseImageModalParamsType} from './UseImageModal.type';
const RADIOITEMS = [
  {id: 'keep', label: 'Keep original'},
  {id: 'fill', label: 'Fill with color'},
];
// 自定义样式对象
const SketchPickerStyles = {
  default: {
    color: {
      display: 'none',
    },
  },
};
export const UseImageModal = ({
  bgActionType,
  onChangeRadio,
  handleModalOk,
  draggablebounds,
  onloadSourceImg,
  onloadDragImg,
  color,
  handleChangeComplete,

  hideImageModal,
  visible,
  sourceImgUrl,
  assetImgUrl,
  dragContainerRef,
  sourceImgRef,
  dragImgRef,
  dragImageWrapRef,
  leftPanelRef,
  resizableHeight,
  resizableWidth,
  onResize,
  onResizeStart,
  onResizeStop,

  position,
  handleDrag,
  handleStop,
}: UseImageModalParamsType) => {
  return (
    <Modal
      visible={visible}
      title="Use image"
      onCancel={hideImageModal}
      maskClosable={false}
    >
      <div className={styles.container}>
        <div className={styles.leftPanel} ref={leftPanelRef}>
          {sourceImgUrl ? (
            <img
              src={sourceImgUrl}
              alt="sourceImg"
              className={styles.sourceImg}
              ref={sourceImgRef}
              onLoad={onloadSourceImg}
              crossOrigin="anonymous"
            />
          ) : null}

          <div
            ref={dragContainerRef}
            className={styles.dragContainer}
            style={{
              backgroundColor: bgActionType === 'fill' ? color : 'transparent',
            }}
          >
            <Draggable
              bounds={draggablebounds}
              handle=".handle"
              cancel=".react-resizable-handle"
              position={position}
              onDrag={handleDrag}
              onStop={handleStop}
            >
              <ResizableBox
                width={resizableWidth}
                height={resizableHeight}
                handleSize={[10, 10]}
                resizeHandles={['nw', 'ne', 'sw', 'se']}
                onResize={onResize}
                onResizeStart={onResizeStart}
                onResizeStop={onResizeStop}
                minConstraints={[10, 10]}
              >
                <div
                  style={{
                    width: `${resizableWidth}px`,
                    height: `${resizableHeight}px`,
                  }}
                  className={styles.dragImageWrap + ' handle'}
                  ref={dragImageWrapRef}
                >
                  <img
                    src={assetImgUrl}
                    className={styles.dragImage}
                    ref={dragImgRef}
                    onLoad={onloadDragImg}
                    crossOrigin="anonymous"
                  />
                  <div className={styles.mask + ' top'}></div>
                  <div className={styles.mask + ' right'}></div>
                  <div className={styles.mask + ' bottom'}></div>
                  <div className={styles.mask + ' left'}></div>
                </div>
              </ResizableBox>
            </Draggable>
          </div>
          {/* <div className={styles.overlay} ref={leftPanelOverlayRef}></div> */}
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.top}>
            <div className={styles.crop}>
              <div className={styles.title}>Background</div>
              {RADIOITEMS.map(ratio => (
                <div className={styles.options} key={ratio.id}>
                  <input
                    type="radio"
                    name="bgActionType"
                    id={ratio.id}
                    value={ratio.id}
                    checked={bgActionType === ratio.id}
                    onChange={() => onChangeRadio(ratio.id)}
                  />
                  <label htmlFor={ratio.id}>{ratio.label}</label>
                </div>
              ))}
              {bgActionType === 'fill' && (
                <div className={styles.sketchPickerWrap}>
                  <div className={styles.colorInfoWrap}>
                    <span
                      className={styles.colorBlock}
                      style={{backgroundColor: color}}
                    />
                    <span className={styles.colorText}>
                      <span className={styles.icon}>#</span>
                      {color.replace('#', '')}
                    </span>
                  </div>
                  <SketchPicker
                    color={color}
                    onChangeComplete={handleChangeComplete}
                    width="160px"
                    presetColors={[]}
                    disableAlpha={true}
                    styles={SketchPickerStyles}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button type="ghost" onClick={hideImageModal}>
              Cancel
            </Button>
            <Button type="button" onClick={handleModalOk}>
              OK
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
