// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ShortVideoConstraintJSON} from '../types';
import {BaseConstraint} from './BaseConstraint';
import {RangeConstraintValue} from './RangeConstraintValue';
import {SelectionConstraintValue} from './SelectionConstraintValue';

export class ShortVideoConstraint extends BaseConstraint {
  static fromJSON({
    size,
    prompt_length,
    scene_quantity,
    scene_subtitle_length,
  }: ShortVideoConstraintJSON) {
    return new ShortVideoConstraint(
      SelectionConstraintValue.fromJSON(size),
      RangeConstraintValue.fromJSON(prompt_length),
      RangeConstraintValue.fromJSON(scene_quantity),
      RangeConstraintValue.fromJSON(scene_subtitle_length)
    );
  }

  patch(data: Partial<ShortVideoConstraint>) {
    const {size, promptLength, sceneQuantity, sceneSubtitleLength} =
      Object.assign({}, this, data);

    return new ShortVideoConstraint(
      size,
      promptLength,
      sceneQuantity,
      sceneSubtitleLength
    );
  }

  toJSON(): ShortVideoConstraintJSON {
    return super.toJSON();
  }
}
