// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useMemo} from 'react';

import {TextEditor} from '../../TextEditor';
import styles from './SubtitleEditor.module.scss';
import {Props} from './SubtitleEditor.types';

export function SubtitleEditor({
  subtitle,
  isProcessing,
  isSelected,
  isAnimating,
  onChange,
  onFocus,
  subtitleMaxLength,
}: Props) {
  const isOverLength = useMemo(
    () => !!subtitle && subtitle.length > subtitleMaxLength,
    [subtitleMaxLength, subtitle]
  );

  return (
    <div
      className={classNames(
        styles.container,
        isAnimating && styles.isEditorDisabled
      )}
      onClick={() => (isProcessing || isAnimating) && onFocus()}
    >
      <TextEditor
        text={subtitle}
        isDisabled={isAnimating || isProcessing}
        onChange={val => onChange(val)}
        label={false}
        size="large"
        placeholder="Type in subtitles"
        isFocused={isSelected}
        isOverLength={isOverLength}
        overLengthTips={`Try summarize the content or split this scene, max ${subtitleMaxLength} characters per scene.`}
      />
      {isAnimating && <div className={styles.mask} />}
    </div>
  );
}
