// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

export type sizeType = 'xs' | 'sm' | 'md' | 'lg';

function getScreenWidthSize() {
  const screenWidth = window.innerWidth;
  let size: sizeType = 'md';
  // 定义断点
  const breakpoints = {
    small: 640,
    medium: 1008,
    large: 1440,
  };
  // 判断当前屏幕宽度并应用相应的类
  if (screenWidth < breakpoints.small) {
    size = 'xs';
  } else if (
    screenWidth >= breakpoints.small &&
    screenWidth < breakpoints.medium
  ) {
    size = 'sm';
  } else if (
    screenWidth >= breakpoints.medium &&
    screenWidth < breakpoints.large
  ) {
    size = 'md';
  } else if (screenWidth >= breakpoints.large) {
    size = 'lg';
  }
  return size;
}

interface SizeContext {
  size: sizeType;
}

const SizeContext = createContext<SizeContext>({
  size: getScreenWidthSize(),
} as SizeContext);

export function useSize() {
  return useContext(SizeContext);
}

export function SizeProvider({children}: PropsWithChildren) {
  const [size, setSize] = useState<SizeContext['size']>(getScreenWidthSize());

  useEffect(() => {
    const handleResize = () => {
      setSize(getScreenWidthSize());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <SizeContext.Provider value={{size}}>{children}</SizeContext.Provider>;
}
