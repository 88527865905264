// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as FeMaleIcon} from 'assets/svg/3.0/FemaleIcon.svg';
import {ReactComponent as MaleIcon} from 'assets/svg/3.0/MaleIcon.svg';
import {Select} from 'pages/components/Select';
import {SelectCard} from 'pages/components/SelectCard';
import {useState} from 'react';

import {VoiceoverList} from '../VoiceoverList';
import styles from './CharacterVoiceoverList.module.scss';
import {
  CharacterVoiceoverItemProps,
  Props,
} from './CharacterVoiceoverList.type';

function CharacterVoiceoverItem(props: CharacterVoiceoverItemProps) {
  const {value, onChange, list, characterName} = props;
  const [isOpen, setIsOpen] = useState(false);

  const renderSelectedVoiceover = () => {
    const voiceover = list.find(item => item.name === value);
    return (
      <div className={styles['character-voiceover']}>
        <span className={styles['character-name']}>{characterName} - </span>
        <span className={styles['voiceover']}>
          {voiceover?.gender === 'Male' ? <MaleIcon /> : <FeMaleIcon />}
          <span className={styles['voiceover-name']}>{value}</span>
          {voiceover?.tags.map(tag => (
            <div key={tag} className={styles.label}>
              {tag}
            </div>
          ))}
        </span>
      </div>
    );
  };
  return (
    <Select
      label=""
      isOpen={isOpen}
      value={renderSelectedVoiceover()}
      onVisibleChange={setIsOpen}
    >
      <SelectCard label={`Character voiceover - ${characterName}` || ''}>
        <VoiceoverList
          voiceover={value}
          voiceovers={list}
          onChange={val => {
            if (val) {
              onChange(val);
              setIsOpen(false);
            }
          }}
          showNone={false}
        />
      </SelectCard>
    </Select>
  );
}

export function CharacterVoiceoverList(props: Props) {
  const {
    characterVoiceover,
    voiceovers,
    characters,
    onCharacterVoiceoverChange,
  } = props;

  return (
    <div className={styles.container}>
      {characterVoiceover.map((voiceover, index) => {
        return (
          <CharacterVoiceoverItem
            key={index}
            value={voiceover}
            onChange={val => {
              const newValue: [string, string, string] = [
                ...characterVoiceover,
              ];
              newValue[index] = val;
              onCharacterVoiceoverChange(newValue);
            }}
            list={voiceovers}
            characterName={characters[index]?.name || 'Narrator'}
          />
        );
      })}
    </div>
  );
}
