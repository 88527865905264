// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CheckBoxIcon} from 'assets/svg/3.0/CheckBox.svg';
import {ReactComponent as PrimaryCheckBoxIcon} from 'assets/svg/3.0/PrimaryCheckBox.svg';
import classNames from 'classnames';

import styles from './CheckBox.module.scss';
import {Props} from './CheckBox.types';

export function CheckBox(props: Props) {
  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        {...props}
        className={classNames(styles.input, props.className)}
      />
      {!props.checked && <CheckBoxIcon className={styles.icon} />}
      {props.checked && <PrimaryCheckBoxIcon className={styles.icon} />}
    </div>
  );
}
