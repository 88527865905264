// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  FailureReason,
  Synthesis,
  TaskSchema,
  TaskStatus,
  TaskType,
} from '../types';
import {BaseTask} from './BaseTask';
import {Progress} from './Progress';

export class ShootingTask extends BaseTask {
  static fromJSON({
    id,
    status,
    type,
    create_time,
    progress,
    failure_reason,
    estimated_remaining_time,
    elapsed_total_time,
    synthesis,
  }: TaskSchema<TaskType.Shooting>) {
    return new ShootingTask(
      id,
      status,
      type,
      create_time,
      progress && Progress.fromJSON(progress),
      failure_reason,
      estimated_remaining_time,
      elapsed_total_time,
      synthesis
    );
  }

  constructor(
    id: string,
    status: TaskStatus,
    type: string,
    createTime: string,
    progress?: Progress,
    failureReason?: FailureReason,
    estimatedRemainingTime?: number,
    elapsedTotalTime?: number,
    readonly synthesis?: Synthesis
  ) {
    super(
      id,
      status,
      type,
      createTime,
      progress,
      failureReason,
      estimatedRemainingTime,
      elapsedTotalTime
    );
  }
}
