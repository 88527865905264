// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import {Tag} from './Tag';
import styles from './TagGroup.module.scss';
import {TagGroupProps} from './TagGroup.types';

export function TagGroup({
  tags,
  currentTag,
  changeTag,
  className,
}: TagGroupProps) {
  return (
    <div className={classNames(styles['tag-group-container'], className)}>
      {tags.map(tag => (
        <Tag
          key={tag.value}
          label={tag.label}
          active={tag.value === currentTag}
          value={tag.value}
          onClick={changeTag}
        />
      ))}
    </div>
  );
}
