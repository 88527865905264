// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './Switch.module.scss';
import {Props} from './Switch.types';

export function Switch({isDisabled = false, value, onChange}: Props) {
  return (
    <div
      className={classNames(
        styles.container,
        !isDisabled && 'cursor-pointer',
        value && styles.checked
      )}
      onClick={e => {
        if (e.currentTarget !== e.target) return;
        onChange(!value);
      }}
    >
      <div className={styles.content} />
      <input
        type="checkbox"
        className={styles.checkbox}
        disabled={isDisabled}
        value={value ? 'on' : 'off'}
      />
    </div>
  );
}
