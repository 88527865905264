// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {loadStripe, Stripe} from '@stripe/stripe-js';

import {
  generateIdPath,
  PROJECT_PATH,
  WORKSPACE_PAGE_MYPROJECT_PATH,
} from './path';

interface BaseUrlParams {
  type: PaymentCallbackEnum;
  projectId: string;
}

interface CompositeUrlParams extends BaseUrlParams {
  type: PaymentCallbackEnum.CompositePage;
}

interface CompositeHistoryUrlParams extends BaseUrlParams {
  type: PaymentCallbackEnum.CompositePageHistory;
  historyId: string;
}

interface WorkspaceHistoryUrlParams extends BaseUrlParams {
  type: PaymentCallbackEnum.WorkspacePageHistory;
  historyId: string;
}

export type StripeCallbackParams =
  | CompositeUrlParams
  | CompositeHistoryUrlParams
  | WorkspaceHistoryUrlParams;

export enum PaymentCallbackEnum {
  CompositePage = 'cp',
  CompositePageHistory = 'ch',
  WorkspacePageHistory = 'wph',
}

export enum PaymentStatusEnum {
  Success = 's',
  Fail = 'f',
}

export const OUT_TRADE_NO_QUERY_KEY = 'otn';
export const PROJECT_ID_QUERY_KEY = 'pi';
export const HISTORY_ID_QUERY_KEY = 'hi';
export const PAYMENT_STATUS_QUERY_KEY = 'ps';

export function createStripeCallbackUrl(
  outTradeNo: string,
  params: StripeCallbackParams
) {
  const {type, projectId} = params;
  const url = new URL(
    process.env.REACT_APP_ENV === 'dev'
      ? 'http://localhost:3000'
      : (process.env.REACT_APP_STORYTELLER_URL as string)
  );
  let path = '';
  switch (type) {
    case PaymentCallbackEnum.CompositePage:
      path += generateIdPath(PROJECT_PATH, projectId);
      break;
    case PaymentCallbackEnum.CompositePageHistory:
      path += generateIdPath(PROJECT_PATH, projectId);
      url.searchParams.set(HISTORY_ID_QUERY_KEY, params.historyId);
      break;
    case PaymentCallbackEnum.WorkspacePageHistory:
      path += WORKSPACE_PAGE_MYPROJECT_PATH;
      url.searchParams.set(PROJECT_ID_QUERY_KEY, projectId);
      url.searchParams.set(HISTORY_ID_QUERY_KEY, params.historyId);
      break;
  }
  url.pathname = path;
  url.searchParams.set(OUT_TRADE_NO_QUERY_KEY, outTradeNo);
  const successUrl = new URL(url.href);
  successUrl.searchParams.set(
    PAYMENT_STATUS_QUERY_KEY,
    PaymentStatusEnum.Success
  );
  const failUrl = new URL(url.href);
  failUrl.searchParams.set(PAYMENT_STATUS_QUERY_KEY, PaymentStatusEnum.Fail);
  return {successUrl: successUrl.href, failUrl: failUrl.href};
}

export function getOutTradeNoFromUrl(): string | null {
  const url = new URL(window.location.href);
  return url.searchParams.get(OUT_TRADE_NO_QUERY_KEY);
}

export async function openStripeCheckout(session: string) {
  const stripeInstance = (await loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
  )) as Stripe;

  stripeInstance.redirectToCheckout({
    sessionId: session,
  });
}
