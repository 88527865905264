// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';

import {ImageProps, Props, VideoProps} from './AssetLoader.types';

export function AssetLoader({type, src, ...props}: Props) {
  if (type === 'video') {
    return (
      <video
        {...(props as VideoProps)}
        src={src ? staticCombiner(src) : undefined}
        controlsList="nodownload"
      />
    );
  }
  if (type === 'image') {
    return (
      <img
        {...(props as ImageProps)}
        src={src ? staticCombiner(src) : undefined}
      />
    );
  }

  return null;
}
