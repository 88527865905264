// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ClosedCharacter} from 'modules/character/types';
import {BaseComposition} from 'modules/composition/models/BaseComposition';
import {PromptPolicy} from 'modules/project/models/PromptPolicy';
import {ProjectType, PromptPolicyJSON, Size} from 'modules/project/types';
import {ClosedScene} from 'modules/scene/types';
import {Task} from 'modules/task/models/Task';
import {TaskType} from 'modules/task/types';

import {BaseHistoryJSON} from '../types';

export abstract class BaseHistory<
  T extends ProjectType
> extends BaseComposition<T> {
  constructor(
    id: string,
    size: Size,
    language: string,
    promptPolicy: PromptPolicy<T>,
    prompt: string,
    title: string,
    description: string,
    hashtags: string[],
    characters: ClosedCharacter<T>[],
    scenes: ClosedScene<T>[],
    readonly createTime: string,
    readonly thumbnail: string,
    readonly asset: string,
    assetProduct?: string,
    readonly tasks?: Task<TaskType>[]
  ) {
    super(
      id,
      size,
      language,
      promptPolicy,
      prompt,
      title,
      description,
      hashtags,
      characters,
      scenes,
      undefined,
      createTime,
      thumbnail,
      asset,
      assetProduct
    );
  }

  isValid(): boolean {
    return true;
  }

  patch(_data: Partial<BaseHistory<T>>): BaseHistory<T> {
    throw new Error('Could not patch "History"');
  }

  toJSON(): BaseHistoryJSON<T> {
    return {
      id: this.id,
      size: this.size,
      language: this.language,
      prompt_policy: this.promptPolicy.toJSON() as PromptPolicyJSON<T>,
      prompt: this.prompt,
      title: this.title,
      description: this.description,
      hashtags: this.hashtags,
      characters: this.characters,
      scenes: this.scenes,
      create_time: this.createTime,
      thumbnail: this.thumbnail,
      asset: this.asset,
      asset_product: this.assetProduct,
      tasks: this.tasks?.map(task => task.toJSON()),
    };
  }
}
