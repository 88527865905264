// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {last} from 'lodash';
import {Notification, RawNotification} from 'modules/notification/types';
import {useCallback, useState} from 'react';

export function useNotificationReducer() {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const showNotification = useCallback(
    (notification: RawNotification) =>
      setNotifications(notifications => [
        ...notifications,
        {...notification, index: (last(notifications)?.index ?? -1) + 1},
      ]),
    []
  );

  const removeNotification = useCallback(
    (notification: Notification) =>
      setNotifications(notifications =>
        notifications.filter(n => n.index !== notification.index)
      ),
    []
  );

  return {notifications, showNotification, removeNotification};
}
