// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './ConfigCard.module.scss';
import {Props} from './ConfigCard.types';

export function ConfigCard({children, className, style}: Props) {
  return (
    <div
      className={classNames(styles.container, 'config-card', className)}
      style={style}
    >
      {children}
    </div>
  );
}
