// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getRoot} from 'lexical';
import {useEffect} from 'react';

import {$isLimitedParagraphNode, $isLimitedTextNode} from '../nodes';

export function ErrorPlugin({
  setIsError,
}: {
  setIsError: (isError: boolean) => void;
}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const setIsErrorFn = () => {
      setIsError(
        $getRoot()
          .getChildren()
          .filter($isLimitedParagraphNode)
          .some(
            p =>
              p.hasError() ||
              p
                .getChildren()
                .filter($isLimitedTextNode)
                .some(t => t.hasError())
          )
      );
    };
    editor.getEditorState().read(setIsErrorFn);
    const removeUpdateListener = editor.registerUpdateListener(
      ({editorState}) => {
        editorState.read(setIsErrorFn);
      }
    );
    return removeUpdateListener;
  }, [editor, setIsError]);

  return null;
}
