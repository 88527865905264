// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as SoundIcon} from 'assets/svg/3.0/Sound.svg';
import {Option} from 'components/Option';
import {Collapse} from 'pages/components/Collapse';
import {Switch} from 'pages/components/Switch';

import {CharacterVoiceoverList} from '../CharacterVoiceoverList';
import {VoiceoverList} from '../VoiceoverList';
import styles from './VoiceoverCollapse.module.scss';
import {
  Props,
  PropsWithCharacterVoiceover,
  PropsWithVoiceoverOrder,
  PropsWithVoiceoverSpeed,
} from './VoiceoverCollapse.types';

export function VoiceoverCollapse(props: PropsWithVoiceoverSpeed): JSX.Element;
export function VoiceoverCollapse(props: PropsWithVoiceoverOrder): JSX.Element;
export function VoiceoverCollapse(
  props: Omit<
    PropsWithVoiceoverOrder & PropsWithCharacterVoiceover,
    'voiceover' | 'onChange'
  >
): JSX.Element;

export function VoiceoverCollapse({voiceovers, ...props}: Props) {
  const renderVoiceoverSpeed = () => {
    if ('voiceoverSpeed' in props) {
      const {voiceoverSpeed, onSpeedChange} = props;
      return (
        <div className={styles.switch}>
          <span> Speed up</span>
          <Switch value={voiceoverSpeed} onChange={onSpeedChange} />
        </div>
      );
    }
    return <></>;
  };
  const renderVoiceoverOrder = () => {
    if ('voiceoverOrder' in props) {
      const {voiceoverOrder, onOrderChange} = props;
      return (
        <dl className={styles.list}>
          <dt className={styles.title}>Voiceover order</dt>
          <dd className={styles.item}>
            <Option
              isDisabled={false}
              label={'Target language first'}
              isChecked={voiceoverOrder === 1}
              onCheck={() => onOrderChange(1)}
            />
          </dd>
          <dd className={styles.item}>
            <Option
              isDisabled={false}
              label={'Native language first'}
              isChecked={voiceoverOrder === 2}
              onCheck={() => onOrderChange(2)}
            />
          </dd>
          <dd className={styles.item}>
            <Option
              isDisabled={false}
              label={'Only target language'}
              isChecked={voiceoverOrder === 3}
              onCheck={() => onOrderChange(3)}
            />
          </dd>
          <dt className={styles.title}>
            {('characterVoiceover' in props && 'Character voiceover') ||
              'Voiceover'}
          </dt>
        </dl>
      );
    }
    return <></>;
  };
  const renderVoiceoverList = () => {
    // bilingual dialogue
    if ('characterVoiceover' in props) {
      const {characterVoiceover, onCharacterVoiceoverChange, characters} =
        props;
      return (
        <CharacterVoiceoverList
          voiceovers={voiceovers}
          characters={characters}
          characterVoiceover={characterVoiceover}
          onCharacterVoiceoverChange={onCharacterVoiceoverChange}
        />
      );
    }
    // gengeral story & bilingual story
    const {voiceover, onChange} = props;
    return (
      <VoiceoverList
        voiceover={voiceover}
        voiceovers={voiceovers}
        onChange={onChange}
        showNone
      />
    );
  };

  return (
    <Collapse icon={<SoundIcon className={styles.icon} />} title="Voiceover">
      {renderVoiceoverSpeed()}
      {renderVoiceoverOrder()}
      {renderVoiceoverList()}
    </Collapse>
  );
}
