// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {TaskStatus as AFTaskStatus} from 'modules/ai-frontend/types';

export const ANIMATE_PRICE = 8;

export function checkoutTaskStatus(aStatus: AFTaskStatus) {
  const status =
    aStatus === AFTaskStatus.Cancelled || aStatus === AFTaskStatus.Timeout
      ? AFTaskStatus.Failure
      : aStatus;
  return [status, ['success', 'failure'].includes(status)] as const;
}
