// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {CloseButtonType, withDialog} from 'pages/components/Dialog';

import {GalleryPreviewer} from './GalleryPreviewer';

export const GalleryPreviewerDialog = withDialog({
  closeButtonType: CloseButtonType.Inside,
})(GalleryPreviewer);
