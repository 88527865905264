// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Vector2} from 'api/unity';
import classNames from 'classnames';
import {useResourceManager} from 'contexts/ResourceManager';
import {noop} from 'lodash';
import {UIEvent, useEffect, useRef, WheelEvent} from 'react';

import {useUnityInstanceManager} from './UnityCanvas.instance';
import styles from './UnityCanvas.module.scss';
import {Props} from './UnityCanvas.types';

const DEFAULT_ZOOM = 1;
const BUILD_NAME = 'view3d';

function calcScrollRatio(el: HTMLDivElement): Vector2 {
  const {
    scrollTop,
    scrollLeft,
    scrollWidth,
    scrollHeight,
    clientWidth,
    clientHeight,
  } = el;

  // 如果是zoom===100时，scrollWidth与clientWidth相等 或 scrollHeight与clientHeight相等
  // 除数如果为0，除得答案在js端为Infinity 转为JSON字符串为Null 会导致unity报错
  // if (scrollWidth === clientWidth || scrollHeight === clientHeight)
  //   return zeroVector2;

  const verticalRatio = 1 - scrollTop / (scrollHeight - clientHeight);
  const horizontalRatio = scrollLeft / (scrollWidth - clientWidth);

  return {y: verticalRatio, x: horizontalRatio};
}

function scrollTo(el: HTMLDivElement, ratio: Vector2) {
  const {x, y} = ratio;
  const {scrollWidth, scrollHeight, clientWidth, clientHeight} = el;

  // 针对Unity传回来的坐标系对Y轴取反处理
  el.scrollTo(
    x * (scrollWidth - clientWidth),
    scrollHeight - clientHeight - y * (scrollHeight - clientHeight)
  );
  // el.scrollTop =
  //   scrollHeight - clientHeight - y * (scrollHeight - clientHeight);
  // el.scrollLeft = x * (scrollWidth - clientWidth);
}

export function UnityCanvas({
  className,
  style,
  unityCallback: {onConnect, onDisconnect, onDispatch: dispatchEvent},
  zoomInfo,
  updateScrollBarLocation,
}: Props) {
  const {canvasId, create, cancel} = useUnityInstanceManager();
  const {u3dUrl} = useResourceManager();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const canvas = document.getElementById(
      canvasId
    ) as HTMLCanvasElement | null;
    const container = containerRef.current;
    if (!canvas || !container) return;
    container.appendChild(canvas);

    async function onScriptLoad() {
      if (!canvas) return;

      try {
        const instance = await create({
          dataUrl: `${u3dUrl}/${BUILD_NAME}.data`,
          frameworkUrl: `${u3dUrl}/${BUILD_NAME}.framework.js`,
          codeUrl: `${u3dUrl}/${BUILD_NAME}.wasm`,
          devicePixelRatio: window.devicePixelRatio,
          productName: 'splash',
          productVersion: process.env.REACT_APP_VERSION,
          companyName: 'SeedV',
          streamingAssetsUrl: `${u3dUrl}/StreamingAssets`,
        });
        onConnect(instance);
      } catch (error) {
        // console.error('Failed to create Unity3D instance.', error);
      }
    }

    function onScriptError() {
      console.error('Failed to load Unity3D.');
    }

    const script = document.createElement('script');
    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);
    script.type = 'text/javascript';
    script.async = true;
    script.src = `${u3dUrl}/${BUILD_NAME}.loader.js`;
    document.body.appendChild(script);

    return () => {
      (async () => {
        document.body.appendChild(canvas);
        canvas.width = canvas.height = 0;
        const instance = onDisconnect();
        if (instance) {
          const ctx = canvas.getContext('webgl2');
          if (ctx) {
            // 跳转前后两个页面都使用这个canvas，会有一瞬间显示上一个页面的画面，所以需要在退出时先清除画面
            ctx.clear(
              ctx.COLOR_BUFFER_BIT |
                ctx.DEPTH_BUFFER_BIT |
                ctx.STENCIL_BUFFER_BIT
            );
          }

          await instance.Quit();
        }

        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
        document.body.removeChild(script);
      })();
      cancel();
    };
  }, [cancel, canvasId, create, onConnect, onDisconnect, u3dUrl]);

  useEffect(() => {
    window.dispatchReactUnityEvent = dispatchEvent;
    return () => {
      window.dispatchReactUnityEvent = noop;
    };
  }, [dispatchEvent]);

  useEffect(() => {
    if (!zoomInfo) return;
    if (!containerRef.current) return;

    scrollTo(containerRef.current, zoomInfo.scrollBarLocation);
  }, [zoomInfo]);

  function onWheel({
    deltaX,
    deltaY,
    currentTarget: el,
  }: WheelEvent<HTMLDivElement>) {
    if (
      !zoomInfo ||
      !(zoomInfo.zoom > DEFAULT_ZOOM) ||
      !updateScrollBarLocation
    )
      return;

    console.warn('onWheel, if onScroll too', el);
    if ((deltaX === 0 && deltaY === 0) || zoomInfo.zoom <= DEFAULT_ZOOM) return;

    el.scrollLeft += deltaX;
    el.scrollTop += deltaY;
    const scrollRatio = calcScrollRatio(el);
    updateScrollBarLocation(scrollRatio);
  }

  function onScroll({currentTarget: el}: UIEvent<HTMLDivElement>) {
    if (
      !zoomInfo ||
      !(zoomInfo.zoom > DEFAULT_ZOOM) ||
      !updateScrollBarLocation
    )
      return;

    console.warn('onScroll, if onWheel too', el);
    const scrollRatio = calcScrollRatio(el);
    updateScrollBarLocation(scrollRatio);
  }

  return (
    <div
      className={classNames(styles.container, className)}
      style={style}
      ref={containerRef}
      onScroll={onScroll}
      onWheel={onWheel}
    >
      {zoomInfo && zoomInfo.zoom > DEFAULT_ZOOM && (
        <div
          className={styles.fill}
          style={{
            width: `${zoomInfo.zoom * 100}%`,
            height: `${zoomInfo.zoom * 100}%`,
          }}
        />
      )}
    </div>
  );
}
