// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {Button} from 'components/Button';
import {ReactNode} from 'react';

import styles from './Card.module.scss';

interface CardProps {
  content?: React.ReactNode | string;
  isActive?: boolean;
  btnText?: string;
  btnTheme?: 'primary' | 'danger';
  button?: ReactNode;
  onBtnClick?: () => void;
}

export function Card(props: CardProps) {
  const {
    content,
    isActive,
    btnText,
    button,
    onBtnClick,
    btnTheme = 'primary',
  } = props;
  return (
    <div className={classNames(styles.container, isActive && styles.active)}>
      <div className={styles.content}>{content}</div>
      {btnText && !button && (
        <Button
          theme={btnTheme}
          onClick={onBtnClick}
          size="middle"
          type={isActive ? 'button' : 'ghost'}
          className={classNames(styles.btn, isActive && styles.active)}
        >
          {btnText}
        </Button>
      )}
      {button && button}
    </div>
  );
}
