// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as MootionIcon} from 'assets/svg/3.0/Mootion-icon.svg';
import {ReactComponent as LoadingSvg} from 'assets/svg/outline/LoadingLine.svg';

import styles from './ProjectLoading.module.scss';

export function ProjectLoading() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <MootionIcon className={styles.icon} />
          </div>
          <div className={styles.title}></div>
        </div>
        <div className={styles.right}></div>
      </div>
      <div className={styles['loading-info']}>
        <LoadingSvg className={styles.rotateLoading} />
        <div className={styles.text}>Your project is loading...</div>
      </div>
    </div>
  );
}
