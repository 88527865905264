// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CloseBtn} from 'assets/svg/3.0/CloseBtn.svg';
import React, {useCallback, useEffect} from 'react';
import {createPortal} from 'react-dom';

import styles from './Modal.module.scss';

interface ModalProps {
  visible: boolean;
  getContainer?: () => HTMLElement;
  keyboard?: boolean;
  maskClosable?: boolean;
  footer?: ((params: {close: () => void}) => React.ReactNode) | React.ReactNode;
  onOk?: (close: () => void) => void | Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  className?: string;
  disableClose?: boolean;
  showCloseIcon?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  visible,
  getContainer = () => document.body,
  keyboard = true,
  maskClosable = true,
  footer,
  onCancel,
  children,
  title,
  className,
  disableClose = false,
  showCloseIcon = true,
}) => {
  const close = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (keyboard && event.key === 'Escape' && maskClosable) {
        close();
      }
    };

    if (visible && keyboard) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [visible, keyboard, close, maskClosable]);

  const modalClassName = [styles.modal, className].filter(Boolean).join(' ');

  const modalContent = visible ? (
    <div className={modalClassName} onClick={() => maskClosable && close()}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <span className={styles.modalTitle}>{title}</span>
          {showCloseIcon && (
            <button
              className={styles.closeButton}
              onClick={close}
              disabled={disableClose}
            >
              <CloseBtn />
            </button>
          )}
        </div>

        {children}
        {footer ? (
          <div className={styles.footer}>
            {typeof footer === 'function' ? footer({close}) : footer}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;

  return createPortal(
    modalContent,
    getContainer() ? getContainer() : document.body
  );
};
