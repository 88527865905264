// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {FailureReason, TaskStatus} from '../types';
import {Progress} from './Progress';

export abstract class BaseTask {
  constructor(
    readonly id: string,
    private _status: TaskStatus,
    readonly type: string,
    readonly createTime: string,
    readonly progress?: Progress,
    readonly failureReason?: FailureReason,
    readonly estimatedRemainingTime?: number,
    readonly elapsedTotalTime?: number
  ) {}

  get status() {
    return this._status;
  }

  set status(status: TaskStatus) {
    this._status = status;
  }

  get closed() {
    return [
      TaskStatus.Success,
      TaskStatus.Failure,
      TaskStatus.Cancelled,
      TaskStatus.Timeout,
    ].includes(this.status);
  }
}
