// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {SelectionConstraintValueJSON} from '../types';
import {BaseConstraintValue} from './BaseConstraintValue';

export class SelectionConstraintValue<T> extends BaseConstraintValue {
  static fromJSON<T>({options}: SelectionConstraintValueJSON<T>) {
    return new SelectionConstraintValue(options);
  }

  constructor(readonly options: T[]) {
    super();
  }

  toJSON(): SelectionConstraintValueJSON<T> {
    return {
      options: this.options,
    };
  }
}
