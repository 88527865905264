// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Button} from '../Button';
import {Toast} from '../Toast';
import styles from './Firewall.module.scss';
import {Props} from './Firewall.types';

const ErrorToastMap = {
  networkError: 'Notice',
};
const ErrorToastContentMap = {
  networkError: () => 'Please check your connection.',
};
const ErrorToastBtnTextMap = {
  networkError: 'OK',
};
export function Firewall({
  children,
  status,
  errorToastVisible,
  errorToastType,
  hideErrorToast,
}: Props) {
  if (status === 'loading' || status === 'NotLoggedIn') {
    return null;
  }
  return (
    <>
      {children}
      {errorToastType && (
        <Toast
          title={ErrorToastMap[errorToastType]}
          visible={errorToastVisible}
          showCloseBtn={false}
          btnTheme="danger"
          footer={<></>}
        >
          {ErrorToastContentMap[errorToastType] &&
            ErrorToastContentMap[errorToastType]()}
          <div className={styles.errorToastFooter}>
            <Button type="button" size="large" onClick={hideErrorToast}>
              {ErrorToastBtnTextMap[errorToastType]}
            </Button>
          </div>
        </Toast>
      )}
    </>
  );
}
