// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useMemo} from 'react';

import styles from './LoadingPrompt.module.scss';
import {PromptType, PromptValue, Props} from './LoadingPrompt.types';

const PROMPT_MAP: Record<PromptType, PromptValue> = {
  generating: {
    status: 'Generating 1',
    text: 'Please wait for a few seconds.',
  },
  processing: {
    status: 'In processing',
    text: 'Please wait for a few seconds.',
  },
};

export function LoadingPrompt({type}: Props) {
  const prompt = useMemo(() => PROMPT_MAP[type], [type]);
  return (
    <div className={styles.container}>
      <p className={styles.status}>{prompt.status}...</p>
      <p className={styles.text}>{prompt.text}</p>
    </div>
  );
}
