// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {setReferralCode} from 'contexts/localStorage';
import {Navigate, useSearchParams} from 'react-router-dom';
import {ROOT_PATH, SIGNUP_PATH} from 'utils/path';

export function ReferralPage() {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');

  if (code) {
    setReferralCode(code);
    return <Navigate to={SIGNUP_PATH} />;
  } else {
    return <Navigate to={ROOT_PATH} />;
  }
}
