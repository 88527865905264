// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CropIcon} from 'assets/svg/outline/AssetstoolCrop.svg';
import {ReactComponent as DeleteIcon} from 'assets/svg/outline/AssetsToolDelete.svg';
import {ReactComponent as RemoveBgIcon} from 'assets/svg/outline/AssetsToolRemoveBg.svg';
import {ReactComponent as StyleTransferIcon} from 'assets/svg/outline/AssetsToolStyleTransfer.svg';

import styles from './ImageBar.module.scss';
import {ImageBarActionTypes, ImageBarProps} from './ImageBar.type';

const actions = [
  {action: ImageBarActionTypes.crop, icon: <CropIcon />},
  {action: ImageBarActionTypes.rmBg, icon: <RemoveBgIcon />},
  {action: ImageBarActionTypes.styleTransfer, icon: <StyleTransferIcon />},
  {action: ImageBarActionTypes.delete, icon: <DeleteIcon />},
];

export function ImageBar({onAction}: ImageBarProps) {
  return (
    <div className={styles['image-bar']}>
      {actions.map(({action, icon}) => (
        <div
          key={action}
          className={styles['image-bar-item']}
          onMouseDown={e => {
            e.stopPropagation();
            onAction(action);
          }}
        >
          {icon}
        </div>
      ))}
    </div>
  );
}
