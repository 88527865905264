// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './Tag.module.scss';
import {TagProps} from './Tag.types';

export function Tag({active, disabled, onClick, label, value}: TagProps) {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    if (active) return;
    if (onClick && !disabled) {
      const targetDom = event.target as HTMLButtonElement;
      onClick(targetDom.dataset.val!);
    }
  };

  return (
    <button
      className={classNames(styles.container, active && styles.active)}
      disabled={disabled}
      data-val={value}
      onClick={handleClick}
    >
      {label}
    </button>
  );
}
