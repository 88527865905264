// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {BackendClient} from 'api/BackendClient';
import {combine} from 'components/Combine';
import {useAPI} from 'contexts/APIContext';
import {clearPreProject, getPreProject} from 'contexts/localStorage';
import {useResourceManager} from 'contexts/ResourceManager';
import {ResourceContext} from 'contexts/ResourceManager.type';
import {formatAspectRatio} from 'lib/ratio';
import {GeneralStoryProject} from 'modules/project/models/GeneralStoryProject';
import {fromJSON} from 'modules/project/models/Project';
import {getCachedProject, reviewProject} from 'modules/project/utils';
import {GeneralStoryStoryboard} from 'modules/storyboard/models/GeneralStoryStoryboard';
import {nanoid} from 'nanoid';
import {useEffect} from 'react';

// import {useCallback, useMemo, useState} from 'react';
import {WorkspacePage} from './WorkspacePage';
function useHook(): object {
  const {backendClient} = useAPI();
  const resourceManager = useResourceManager();

  useCreateProjectFromCached({backendClient, resourceManager});

  return {};
}
export const WorkspaceContainer = combine(useHook)(WorkspacePage);

interface UseCachedProjectParams {
  backendClient: BackendClient;
  resourceManager: ResourceContext;
}

export const useCreateProjectFromCached = ({
  backendClient,
  resourceManager,
}: UseCachedProjectParams) => {
  useEffect(() => {
    // 检查 localStorage 中是否存在 PreProject
    const cacheData = getCachedProject(getPreProject());
    if (cacheData) {
      // 创建项目
      const project = reviewProject(
        fromJSON(cacheData),
        resourceManager,
        'FREE'
      ) as GeneralStoryProject;

      // 跳过到第二步骤，但不发起生成 storyboard 任务
      const projectWithStoryboard = project.patch({
        storyboard: new GeneralStoryStoryboard(
          nanoid(),
          project.size,
          project.language,
          project.style!,
          project.promptPolicy!,
          project.prompt!
        ),
      });

      backendClient
        .createProject({
          frameRatio: formatAspectRatio(projectWithStoryboard.size),
          projectJsonContent: JSON.stringify(projectWithStoryboard),
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          // 清空 LocalStorageKey.PreProject
          clearPreProject();
        });
    }
  }, [backendClient, resourceManager]);
};
