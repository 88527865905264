// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {MutableRefObject, ReactNode, useEffect, useRef, useState} from 'react';

interface ScrollSpyProps {
  children: ReactNode;

  // refs
  navContainerRef?: MutableRefObject<HTMLDivElement | null>;
  parentScrollContainerRef?: MutableRefObject<HTMLDivElement | null>;

  // callback
  onUpdateCallback?: (id: string) => void;

  // offsets
  offsetTop?: number;

  // customize attributes
  useDataAttribute?: string;
  activeClass?: string;

  updateHistoryStack?: boolean;
}

export function ScrollSpy({
  children,

  // refs
  navContainerRef,
  parentScrollContainerRef,

  // callback
  onUpdateCallback,

  // offsets
  offsetTop = 0,

  // customize attributes
  useDataAttribute = 'to-scrollspy-id',
  activeClass = 'active-scroll-spy',

  updateHistoryStack = false,
}: ScrollSpyProps) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [navContainerItems, setNavContainerItems] = useState<NodeListOf<Element> | undefined>(); // prettier-ignore

  // keeps track of the Id in navcontainer which is active
  // so as to not update classLists unless it has been updated
  const prevIdTracker = useRef('');

  // To get the nav container items depending on whether the parent ref for the nav container is passed or not
  useEffect(() => {
    navContainerRef
      ? setNavContainerItems(
          navContainerRef.current?.querySelectorAll(
            `[data-${useDataAttribute}]`
          )
        )
      : setNavContainerItems(
          document.querySelectorAll(`[data-${useDataAttribute}]`)
        );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navContainerRef]);

  // fire once after nav container items are set
  useEffect(() => {
    checkAndUpdateActiveScrollSpy();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navContainerItems]);

  const isVisible = (el: HTMLElement) => {
    const rectInView = el.getBoundingClientRect();
    const rectParent = (
      parentScrollContainerRef?.current ?? document.body
    ).getBoundingClientRect();

    const isInViewPort =
      rectInView.top <= rectParent.top + offsetTop &&
      rectInView.bottom > rectParent.top + offsetTop;

    return isInViewPort;
  };

  const checkAndUpdateActiveScrollSpy = () => {
    const scrollParentContainer = scrollContainerRef.current;

    // if there are no children, return
    if (!(scrollParentContainer && navContainerItems)) return;

    const changeActiveClass = (id: string) => {
      const changeHighlightedItemId = id;

      // if the element was same as the one currently active ignore it
      if (prevIdTracker.current === changeHighlightedItemId) return;

      // now loop over each element in the nav Container
      navContainerItems.forEach(el => {
        const attrId = el.getAttribute(`data-${useDataAttribute}`);

        // if the element contains 'active' the class remove it
        if (el.classList.contains(activeClass)) {
          el.classList.remove(activeClass);
        }

        // check if its ID matches the ID we got from the viewport
        // also make sure it does not already contain the 'active' class
        if (
          attrId === changeHighlightedItemId &&
          !el.classList.contains(activeClass)
        ) {
          el.classList.add(activeClass);

          if (onUpdateCallback) {
            onUpdateCallback(changeHighlightedItemId);
          }

          prevIdTracker.current = changeHighlightedItemId;
          if (updateHistoryStack) {
            window.history.replaceState({}, '', `#${changeHighlightedItemId}`);
          }
        }
      });
    };

    let hasVisible = false;

    // loop over all children in scroll container
    for (let i = 0; i < scrollParentContainer.children.length; i++) {
      const useChild = scrollParentContainer.children.item(i) as HTMLDivElement;

      const elementIsVisible = isVisible(useChild);

      // check if the element is in the viewport
      if (elementIsVisible) {
        hasVisible = true;
        // if so, get its ID
        changeActiveClass(useChild.id);
        break;
      }
    }

    if (!hasVisible) {
      changeActiveClass(scrollParentContainer.children[0].id);
    }
  };

  useEffect(() => {
    // listen for scroll event
    parentScrollContainerRef
      ? // if ref for scrollable div is provided
        parentScrollContainerRef.current?.addEventListener(
          'scroll',
          checkAndUpdateActiveScrollSpy
        )
      : // else listen for scroll in window
        window.addEventListener('scroll', checkAndUpdateActiveScrollSpy);
  });

  return <div ref={scrollContainerRef}>{children}</div>;
}
