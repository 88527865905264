// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './TextStyleGroupCard.module.scss';
import {Props} from './TextStyleGroupCard.types';

export function TextStyleGroupCard({
  stylePreview,
  nativeStylePreview,
  isSelected,
  background,
  onSelect,
}: Props) {
  return (
    <li
      className={classNames(styles.container, isSelected && styles.selected)}
      onClick={() => !isSelected && onSelect()}
      style={{
        background: background
          ? `url(${background}) no-repeat center`
          : undefined,
      }}
    >
      {stylePreview && <img src={stylePreview} className={styles.preview} />}
      {nativeStylePreview && (
        <img src={nativeStylePreview} className={styles.preview} />
      )}
    </li>
  );
}
