// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectJSON, ProjectType} from '../types';
import {BilingualDialogueProject} from './BilingualDialogueProject';
import {BilingualStoryProject} from './BilingualStoryProject';
import {GeneralStoryProject} from './GeneralStoryProject';
import {ShortVideoProject} from './ShortVideoProject';

export type Project<T extends ProjectType> = T extends 'general_story'
  ? GeneralStoryProject
  : T extends 'short_video'
  ? ShortVideoProject
  : T extends 'bilingual_story'
  ? BilingualStoryProject
  : T extends 'bilingual_dialogue'
  ? BilingualDialogueProject
  : never;

export function fromJSON<T extends ProjectType>(
  json: ProjectJSON<T>
): Project<T> {
  switch (json.type) {
    case 'general_story':
      return GeneralStoryProject.fromJSON(json) as Project<T>;
    case 'short_video':
      return ShortVideoProject.fromJSON(json) as Project<T>;
    case 'bilingual_story':
      return BilingualStoryProject.fromJSON(json) as Project<T>;
    case 'bilingual_dialogue':
      return BilingualDialogueProject.fromJSON(json) as Project<T>;
    default:
      throw new Error(`Unknown project type: ${json}`);
  }
}

export function patch<P extends Project<ProjectType>>(
  project: P,
  data: Partial<P>
): P {
  if (project instanceof GeneralStoryProject) {
    return project.patch(data as Partial<GeneralStoryProject>) as P;
  } else if (project instanceof ShortVideoProject) {
    return project.patch(data as Partial<ShortVideoProject>) as P;
  } else if (project instanceof BilingualStoryProject) {
    return project.patch(data as Partial<BilingualStoryProject>) as P;
  } else if (project instanceof BilingualDialogueProject) {
    return project.patch(data as Partial<BilingualDialogueProject>) as P;
  } else {
    throw new Error(`Unknown project type: ${project}`);
  }
}
