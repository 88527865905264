// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Toast} from 'pages/components/Toast';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'utils/path';

import {
  ErrorToastConfirmTextMap,
  ErrorToastContentMap,
  ErrorToastMap,
  ErrorToastshowCancelMap,
} from './const';
import {GeneratorToastProps} from './Generator.types';
export function GeneratorToast({
  errorToastType,
  errorDialogVisible,
  hideErrorDialog,
  onRegenerate,
}: GeneratorToastProps) {
  const navigate = useNavigate();
  const handleDialogConfirm = useCallback(() => {
    hideErrorDialog();
    if (errorToastType === 'systemError') {
      return;
    } else if (errorToastType === 'noPose') {
      onRegenerate();
    } else if (errorToastType === 'no_credit') {
      navigate(PLAN_PAGE_PATH);
    }
  }, [errorToastType, hideErrorDialog, navigate, onRegenerate]);
  return (
    <Toast
      title={errorToastType && ErrorToastMap[errorToastType]}
      visible={errorDialogVisible}
      showCloseBtn={false}
      onCancel={hideErrorDialog}
      onOk={handleDialogConfirm}
      showCancel={errorToastType && ErrorToastshowCancelMap[errorToastType]}
      confirmText={errorToastType && ErrorToastConfirmTextMap[errorToastType]}
    >
      {errorToastType &&
        ErrorToastContentMap[errorToastType] &&
        ErrorToastContentMap[errorToastType]()}
    </Toast>
  );
}
