// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';
import {Scene} from 'modules/scene/models/Scene';
import {TaskType as SceneTaskType} from 'modules/scene/types';
import {Task} from 'modules/task/models/Task';
import {checkoutTaskStatus} from 'modules/task/utils';
import {SetStateAction, useCallback} from 'react';

import {patch as patchStoryboard, Storyboard} from '../models/Storyboard';
import {AFTask, AFTaskType, TaskSceneIds, TaskType} from '../types';
import {
  checkoutTaskType,
  getProjectTypeByStoryboard,
  scenesFromAFTask,
  storyboardFromAFTask,
} from '../utils';

export type MergeTaskCallbackParams = [
  Task,
  Partial<{
    scenes: {id: string; idx: number}[];
    failureCode: number;
  }>
];

export function useStoryboard<S extends Storyboard<ProjectType>>(
  updateStoryboard: (state: SetStateAction<S>, delay?: number | boolean) => void
) {
  const addTask = useCallback(
    <T extends TaskType>(
      type: T,
      taskId: string,
      sceneIds: TaskSceneIds<T>,
      session?: string
    ) => {
      updateStoryboard(storyboard => {
        const task = new Task(taskId, type, 'created', session);
        const tasks = (storyboard.tasks ?? []).concat(task);
        const next = patchStoryboard(storyboard, {
          tasks,
          ...(!sceneIds
            ? {task: task as Task<'generate_storyboard'>}
            : {
                scenes: storyboard.scenes?.map(scene =>
                  sceneIds.includes(scene.id)
                    ? scene.patch({task: task as Task<SceneTaskType>})
                    : scene
                ),
              }),
        } as Partial<S>);
        return next;
      }, false);
    },
    [updateStoryboard]
  );

  const mergeTask = useCallback(
    <T extends AFTaskType<TaskType>>(
      aType: T,
      taskId: string,
      task: AFTask<T>,
      callback?: (...params: MergeTaskCallbackParams) => void
    ) => {
      const [status, closed] = checkoutTaskStatus(task.status);

      updateStoryboard(storyboard => {
        if (!storyboard.tasks) throw new Error('Tasks are not found');
        const currentTask = storyboard.tasks?.find(t => t.id === taskId);
        if (!currentTask) throw new Error('Task is not found');

        const type = checkoutTaskType(aType, currentTask);
        switch (type) {
          case 'generate_storyboard': {
            if (!storyboard.task || currentTask !== storyboard.task)
              throw new Error('Invalid task');
            const nextStoryboard = storyboardFromAFTask(
              storyboard,
              task as AFTask<'generate_storyboard'>,
              status
            );
            if ((closed || !!task.estimatedRemainingTime) && callback) {
              Promise.resolve<MergeTaskCallbackParams>([
                nextStoryboard?.task ?? currentTask,
                {
                  ...(status === 'failure' && task.failureReason
                    ? {failureCode: task.failureReason.code}
                    : {}),
                },
              ]).then(params => callback(...params));
            }
            return nextStoryboard ?? storyboard;
          }
          case 'split_scene':
          case 'merge_scenes':
          case 'regenerate_scene_by_prompt':
          case 'regenerate_scene_by_pose_prompt':
          case 'image_conditioning_video': {
            if (!storyboard.scenes) throw new Error('Scenes are not found');
            if (
              !closed &&
              currentTask.status === status &&
              currentTask.progress === task.progress?.ratio
            ) {
              return storyboard;
            }
            const nextTask = currentTask.patch({
              status,
              progress: task.progress?.ratio,
            });
            const nextTasks = storyboard.tasks.map(t =>
              t.id === taskId ? nextTask : t
            );

            const sceneInfos: {id: string; idx: number}[] = [];
            const nextScenes: typeof storyboard.scenes = [];
            for (const scene of storyboard.scenes) {
              if (scene.task?.id !== taskId) {
                nextScenes.push(scene);
              } else {
                const nextScene: (typeof scene)[] = [];
                if (status === 'success') {
                  if (sceneInfos.length > 0) continue;

                  if (type === 'split_scene' || type === 'merge_scenes') {
                    nextScene.push(
                      ...scenesFromAFTask(
                        getProjectTypeByStoryboard(storyboard),
                        task as AFTask<'split_scene' | 'merge_scenes'>
                      )
                    );
                  } else if (
                    type === 'regenerate_scene_by_prompt' ||
                    type === 'regenerate_scene_by_pose_prompt'
                  ) {
                    const image =
                      type === 'regenerate_scene_by_prompt'
                        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          (task as AFTask<'regenerate_scene_by_prompt'>)
                            .scenes[0].image!
                        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          (task as AFTask<'regenerate_scene_by_pose_prompt'>)
                            .asset!;
                    nextScene.push(
                      scene.patch({
                        image,
                        video: undefined,
                        draft: scene.draft
                          ? scene.draft.patch({
                              background: image,
                              image: undefined,
                            })
                          : undefined,
                        task: undefined,
                      })
                    );
                  } else {
                    nextScene.push(
                      scene.patch({
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        video: (task as AFTask<'image_conditioning_video'>)
                          .asset!,
                        task: undefined,
                      })
                    );
                  }
                } else if (status === 'failure') {
                  nextScene.push(scene.patch({task: undefined}));
                } else {
                  nextScene.push(
                    scene.patch({task: nextTask as Task<SceneTaskType>})
                  );
                }
                sceneInfos.push(
                  ...nextScene.map((s, idx) => {
                    return {id: s.id, idx: nextScenes.length + idx};
                  })
                );
                nextScenes.push(...nextScene);
              }
            }
            if (callback) {
              Promise.resolve<MergeTaskCallbackParams>([
                nextTask,
                {
                  scenes: sceneInfos,
                  ...(status === 'failure' && task.failureReason
                    ? {failureCode: task.failureReason.code}
                    : {}),
                },
              ]).then(params => callback(...params));
            }
            return storyboard.patch({
              tasks: nextTasks,
              scenes: nextScenes,
            }) as S;
          }
          default:
            return storyboard;
        }
      }, ...(closed ? [false] : []));
    },
    [updateStoryboard]
  );

  const updateScenes = useCallback(
    (state: SetStateAction<Scene<ProjectType>[]>, delay?: number | boolean) => {
      updateStoryboard(storyboard => {
        if (!storyboard.scenes) throw new Error('Scenes are not found');
        const nextScenes =
          typeof state === 'function' ? state(storyboard.scenes) : state;
        if (nextScenes === storyboard.scenes) return storyboard;
        return storyboard.patch({
          scenes: nextScenes,
        }) as S;
      }, delay);
    },
    [updateStoryboard]
  );

  const updateScene = useCallback(
    (
      sceneId: string,
      state: SetStateAction<Scene<ProjectType>>,
      delay?: number | boolean
    ) => {
      updateStoryboard(storyboard => {
        const scene = storyboard.scenes?.find(s => s.id === sceneId);
        if (!scene) throw new Error('Scene is not found');
        const nextScene = typeof state === 'function' ? state(scene) : state;
        if (nextScene === scene) return storyboard;
        return storyboard.patch({
          scenes: storyboard.scenes?.map(s =>
            s.id === sceneId ? nextScene : s
          ),
        }) as S;
      }, delay);
    },
    [updateStoryboard]
  );

  return {addTask, mergeTask, updateScenes, updateScene};
}
