// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {forwardRef, Ref} from 'react';

import styles from './GlassInputBox.module.scss';
import {Props} from './GlassInputBox.types';

const GlassInputBox = forwardRef(
  ({...attributes}: Props, ref: Ref<HTMLInputElement>) => {
    return (
      <div className={styles.container}>
        <input
          {...attributes}
          className={classNames(attributes.className, styles.input)}
          ref={ref}
        />
      </div>
    );
  }
);

GlassInputBox.displayName = 'GlassInputBox';

export {GlassInputBox};
