// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as TriangleIcon} from 'assets/svg/3.0/Triangle.svg';
import classNames from 'classnames';
import {useResourceManager} from 'contexts/ResourceManager';
import {useMemo, useState} from 'react';

import {Option} from '../Option';
import {Select} from '../Select';
import {SelectCard} from '../SelectCard';
import styles from './LanguageSelect.module.scss';
import {Props} from './LanguageSelect.types';

export function LanguageSelect({value, onChange}: Props) {
  const {getLanguages} = useResourceManager();

  const [isOpen, setIsOpen] = useState(false);
  const selectedLanguageName = useMemo(
    () =>
      getLanguages().find(languageData => languageData.code === value)
        ?.native_name || '',
    [getLanguages, value]
  );
  return (
    <Select
      label="Output"
      value={selectedLanguageName}
      isOpen={isOpen}
      onVisibleChange={setIsOpen}
      popoverClassName={styles['language-select-popover']}
    >
      <SelectCard label="Output language">
        <ul className={styles['language-list']}>
          {getLanguages().map(languageData => (
            <li
              key={languageData.code}
              className={classNames(
                styles['language-item'],
                languageData.code === value && styles.active
              )}
            >
              <Option
                label={
                  <span className={styles['language-label']}>
                    {languageData.native_name}
                    {languageData.tooltip && (
                      <span className={styles.bubble}>
                        <TriangleIcon className={styles.icon} />
                        {languageData.tooltip}
                      </span>
                    )}
                  </span>
                }
                isChecked={languageData.code === value}
                isDisabled={false}
                onCheck={() => {
                  setIsOpen(false);
                  if (languageData.code === value) return;
                  onChange(languageData.code);
                }}
              />
            </li>
          ))}
        </ul>
      </SelectCard>
    </Select>
  );
}
