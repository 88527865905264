// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {CustomizedCharacterJSON, CustomizedClosedCharacter} from '../types';
import {BaseCharacter} from './BaseCharacter';

export class CustomizedCharacter extends BaseCharacter {
  static fromJSON({
    id,
    name,
    description,
    character_type,
    image,
  }: CustomizedCharacterJSON) {
    return new CustomizedCharacter(
      id,
      name,
      description,
      character_type,
      image
    );
  }

  constructor(
    id: string,
    name: string,
    readonly description: string,
    readonly characterType: number,
    readonly image?: string
  ) {
    super(id, name);
  }

  isValid() {
    return !!this.image;
  }

  patch(
    data: Partial<{[K in 'image']: CustomizedCharacter[K]}>
  ): CustomizedCharacter {
    const {id, name, description, characterType, image} = Object.assign(
      {},
      this,
      data
    );
    return new CustomizedCharacter(id, name, description, characterType, image);
  }

  toJSON(): CustomizedCharacterJSON {
    return {
      ...super.toJSON(),
      description: this.description,
      character_type: this.characterType,
      image: this.image,
    };
  }

  toClosed(): CustomizedClosedCharacter {
    return {
      name: this.name,
      description: this.description,
      character_type: this.characterType,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      image: this.image!,
    };
  }
}
