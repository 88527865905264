// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {LanguageData} from 'contexts/ResourceManager.type';

export function isRtl(languages: LanguageData[], languageCode = '') {
  return languages.some(
    language =>
      language.code === languageCode && language.text_direction === 'rtl'
  );
}
