// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import * as frontend from 'api/frontend';
import {ResourceContext} from 'contexts/ResourceManager.type';
import JSZip from 'jszip';
import {cleanFilename} from 'lib/download';
import {SessionStorageKey} from 'lib/hooks';
import {patch as patchConstraint} from 'modules/constraint/models/Constraint';
import {getConstraintByProjectAndType} from 'modules/constraint/utils';
import {PlanType} from 'modules/payment/types';
import {BilingualDialoguePreference} from 'modules/preference/models/BilingualDialoguePreference';
import {BilingualStoryPreference} from 'modules/preference/models/BilingualStoryPreference';
import {GeneralStoryPreference} from 'modules/preference/models/GeneralStoryPreference';
import {Preference} from 'modules/preference/models/Preference';
import {ShortVideoPreference} from 'modules/preference/models/ShortVideoPreference';
import {BilingualStoryProjectConfig} from 'modules/project-config/models/BilingualStoryProjectConfig';
import {GeneralStoryProjectConfig} from 'modules/project-config/models/GeneralStoryProjectConfig';
import {ShortVideoProjectConfig} from 'modules/project-config/models/ShortVideoProjectConfig';
import {
  BilingualStoryProjectConfigJSON,
  GeneralStoryProjectConfigJSON,
  ShortVideoProjectConfigJSON,
  ThumbnailType,
} from 'modules/project-config/types';
import {HistoryJSON} from 'modules/project-history/types';
import {BilingualDialogueScene} from 'modules/scene/models/BilingualDialogueScene';
import {BilingualStoryScene} from 'modules/scene/models/BilingualStoryScene';
import {GeneralStoryScene} from 'modules/scene/models/GeneralStoryScene';
import {Scene} from 'modules/scene/models/Scene';
import {ShortVideoScene} from 'modules/scene/models/ShortVideoScene';
import {ClosedScene} from 'modules/scene/types';
import {getScriptCopyContent} from 'modules/scene/utils';
import {BilingualDialogueStoryboard} from 'modules/storyboard/models/BilingualDialogueStoryboard';
import {BilingualStoryStoryboard} from 'modules/storyboard/models/BilingualStoryStoryboard';
import {GeneralStoryStoryboard} from 'modules/storyboard/models/GeneralStoryStoryboard';
import {ShortVideoStoryboard} from 'modules/storyboard/models/ShortVideoStoryboard';
import {Storyboard} from 'modules/storyboard/models/Storyboard';
import {nanoid} from 'nanoid';

import {BilingualDialogueProject} from './models/BilingualDialogueProject';
import {BilingualStoryProject} from './models/BilingualStoryProject';
import {GeneralStoryProject} from './models/GeneralStoryProject';
import {patch as patchProject, Project} from './models/Project';
import {
  BilingualStoryIdeaPromptPolicy,
  GeneralStoryIdeaPromptPolicy,
  PromptPolicy,
  ScriptPromptPolicy,
  ShortVideoIdeaPromptPolicy,
} from './models/PromptPolicy';
import {ShortVideoProject} from './models/ShortVideoProject';
import {
  BilingualDialogueProjectJSON,
  BilingualStoryProjectJSON,
  GeneralStoryIdeaPromptPolicyJSON,
  GeneralStoryProjectJSON,
  IdeaPromptPolicyJSON,
  Option,
  ProficiencyLevel,
  ProjectJSON,
  ProjectType,
  PromptType,
  ShortVideoIdeaPromptPolicyJSON,
  Tone,
} from './types';
import {getProjectVersionManagerInstance, isLastVersion} from './version';

export const MAX_HISTORY_QUANTITY = 10;

const GENERAL_STORY_TYPE_LIKE: ProjectType[] = ['general_story', 'short_video'];

export const PROFICIENCY_LEVEL_OPTIONS: Option<ProficiencyLevel>[] = [
  {label: 'Neutral', value: "normal language learners'"},
  {label: 'A1 - Beginner', value: 'CEFR Level A1 or Beginner'},
  {label: 'A2 - Elementary', value: 'CEFR Level A2 or Elementary'},
  {label: 'B1 - Intermediate', value: 'CEFR Level B1 or Intermediate'},
  {
    label: 'B2 - Upper-intermediate',
    value: 'CEFR Level B2 or Upper-intermediate',
  },
  {label: 'C1 - Advanced', value: 'CEFR Level C1 or Advanced'},
  {label: 'C2 - Proficient', value: 'CEFR Level C2 or Proficient'},
  {label: 'Other', value: {type: 'Other', value: ''}},
];

export const TONE_OPTIONS: Option<Tone>[] = [
  {label: 'Neutral', value: 'Neutral'},
  {label: 'Humor and satire', value: 'Humor and Satire'},
  {label: "Children's", value: "Children's"},
  {label: 'Business', value: 'Business'},
  {label: 'Academic', value: 'Academic'},
  {label: 'Other', value: {type: 'Other', value: ''}},
];

export const ProjectCacheSessionStorageMap: Record<ProjectType, string> = {
  ['general_story']: SessionStorageKey.ProjectCache,
  ['bilingual_story']: SessionStorageKey.ProjectCache_Bilingual_Story,
  ['bilingual_dialogue']: SessionStorageKey.ProjectCache_Bilingual_Dialogue,
  ['short_video']: SessionStorageKey.ProjectCache_ShortVideo,
};

export function getSceneImage(
  scene: Scene<ProjectType> | ClosedScene<ProjectType>
) {
  if (
    scene instanceof GeneralStoryScene ||
    scene instanceof BilingualStoryScene ||
    scene instanceof BilingualDialogueScene ||
    scene instanceof ShortVideoScene
  ) {
    return (
      (scene?.video ? scene?.image : scene?.draft?.image ?? scene?.image) || ''
    );
  } else {
    return scene?.image || '';
  }
}

export type ConvertResult = Omit<ProjectJSON<ProjectType>, 'histories'> & {
  histories?: (HistoryJSON<ProjectType> | string)[];
};
export function convertProjectVersion(
  projectJSON: ProjectJSON<ProjectType>
): ConvertResult {
  const projectVersionManager = getProjectVersionManagerInstance(
    reviewProjectType(projectJSON.type)
  );
  return projectVersionManager.convert(projectJSON) as ConvertResult;
}

export function reviewProjectType(type: string): ProjectType {
  if (['Idea', 'Script', 'Prompt', 'Content'].includes(type)) {
    return 'general_story';
  } else if (
    [
      'general_story',
      'short_video',
      'bilingual_story',
      'bilingual_dialogue',
    ].includes(type)
  ) {
    return type as ProjectType;
  } else {
    throw new Error('unknown project type: ' + type);
  }
}
//修正project中的错误数据：包括错误的历史数据、不同类型共用同一个字段且在存在了preference中导致的错误数据
export function reviewProject<T extends ProjectType>(
  project: Project<T>,
  resourceManager: ResourceContext,
  plan: PlanType
): Project<T> {
  const {
    titleStylesOf,
    subtitleStylesOf,
    voiceoversOf,
    getStyles,
    bgmsOf,
    getTransitions,
    getLanguages,
    thumbnailTypesOf,
    getTargetLanguages,
    getNativeLanguages,
    getBilingualStoryVoiceover,
    getTitleBilingualCombinationsByLanguage,
    getSubtitleBilingualCombinationsByLanguage,
  } = resourceManager;

  //  Verify language
  const OLD_LANGUAGE_MAP: Record<string, string> = {
    'zh-cn': 'zh-CN',
    en: 'en-US',
  };
  const languages = getLanguages();
  let newLanguage = 'en-US';

  if (Object.keys(OLD_LANGUAGE_MAP).includes(project.language)) {
    newLanguage = OLD_LANGUAGE_MAP[project.language];
  } else {
    const languageCode = languages.find(
      ({code}) => code === project.language
    )?.code;
    if (languageCode) {
      newLanguage = languageCode;
    }
  }

  //  Verify NativeLanguage
  let newNativeLanguage;
  if (
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    //如果是双语故事。language得是en-US、en-GB中的一种
    const targetLanguages = getTargetLanguages();
    newLanguage = targetLanguages.find(
      language => language.code === newLanguage
    )
      ? newLanguage
      : targetLanguages[0].code;
    const nativeLanguages = getNativeLanguages(newLanguage);
    newNativeLanguage = nativeLanguages[0].code;
    const languageCode = nativeLanguages.find(
      ({code}) => code === project.nativeLanguage
    )?.code;
    if (languageCode) {
      newNativeLanguage = languageCode;
    }
  }

  // Verify Style
  const styles = getStyles();
  const newStyle =
    styles.find(
      ({name, supported_plans}) =>
        name === project.style && supported_plans.includes(plan)
    )?.name ?? styles[0]?.name;

  // Verify proficiency level
  let proficiencyLevel = undefined;
  if (
    (project instanceof BilingualStoryProject ||
      project instanceof BilingualDialogueProject) &&
    project.promptPolicy.keepUserContent === false
  ) {
    const proficiencyLevels = PROFICIENCY_LEVEL_OPTIONS.map(({value}) => value);

    const defaultProficiencyLevel = project.promptPolicy.proficiencyLevel;
    if (typeof defaultProficiencyLevel === 'string') {
      proficiencyLevel = proficiencyLevels.includes(defaultProficiencyLevel)
        ? defaultProficiencyLevel
        : proficiencyLevels[0];
    } else {
      proficiencyLevel = {
        type: 'Other',
        value: defaultProficiencyLevel.value ?? '',
      } as ProficiencyLevel;
    }
  }

  //  Verify config
  const titleStyles = titleStylesOf(newLanguage);
  const subtitleStyles = subtitleStylesOf(newLanguage);
  const voiceovers = voiceoversOf(newLanguage);
  const bgms =
    project instanceof ShortVideoProject
      ? bgmsOf().filter(bgm => bgm.flow !== 'Slow')
      : bgmsOf();
  const transitions =
    project instanceof ShortVideoProject
      ? getTransitions().filter(transition => transition.flow !== 'Slow')
      : getTransitions();
  const thumbnailTypes = thumbnailTypesOf(newLanguage);

  let voiceover = null;
  if (GENERAL_STORY_TYPE_LIKE.includes(project.type)) {
    const tempVoiceover = (
      project.config as GeneralStoryProjectConfig | ShortVideoProjectConfig
    ).voiceover;
    if (tempVoiceover !== null) {
      voiceover =
        voiceovers.find(
          ({name, supported_plans}) =>
            name === tempVoiceover && supported_plans.includes(plan)
        )?.name ?? voiceovers[0].name;
    }
  } else if (project.type === 'bilingual_story') {
    const voiceoverList = getBilingualStoryVoiceover();
    voiceover =
      voiceoverList.find(
        ({name}) =>
          name === (project.config as BilingualStoryProjectConfig).voiceover
      )?.name ?? voiceover;
  }
  let newVoiceovers = undefined;
  if (project instanceof BilingualDialogueProject) {
    const voiceoverList = getBilingualStoryVoiceover();
    newVoiceovers = project.config.voiceovers.map(
      voiceoverName =>
        voiceoverList.find(item => item.name === voiceoverName)?.name ||
        voiceoverList[0].name
    ) as [string, string, string];
  }

  const voiceoverSpeed =
    project.config instanceof GeneralStoryProjectConfig ||
    project.config instanceof ShortVideoProjectConfig
      ? ['normal', 'fast'].includes(project.config.voiceoverSpeed)
        ? project.config.voiceoverSpeed
        : project.type === 'short_video'
        ? 'fast'
        : 'normal'
      : undefined;

  let titleStyle = null;
  if (GENERAL_STORY_TYPE_LIKE.includes(project.type)) {
    if (project.config.titleStyle !== null) {
      titleStyle =
        titleStyles.find(
          ({name, supported_plans}) =>
            name === project.config.titleStyle && supported_plans.includes(plan)
        )?.name ?? titleStyles[0].name;
    }
  } else if (['bilingual_story', 'bilingual_dialogue'].includes(project.type)) {
    const titleStyleCombination = getTitleBilingualCombinationsByLanguage(
      newLanguage,
      newNativeLanguage as string
    );
    titleStyle =
      titleStyleCombination.find(({name}) => name === project.config.titleStyle)
        ?.name ?? titleStyleCombination[0].name;
  }

  let subtitleStyle = null;
  if (GENERAL_STORY_TYPE_LIKE.includes(project.type)) {
    if (project.config.subtitleStyle !== null) {
      subtitleStyle =
        subtitleStyles.find(
          ({name, supported_plans}) =>
            name === project.config.subtitleStyle &&
            supported_plans.includes(plan)
        )?.name ?? subtitleStyles[0].name;
    }
  } else if (['bilingual_story', 'bilingual_dialogue'].includes(project.type)) {
    const subtitleStyleCombination = getSubtitleBilingualCombinationsByLanguage(
      newLanguage,
      newNativeLanguage as string
    );
    subtitleStyle =
      subtitleStyleCombination.find(
        ({name}) => name === project.config.subtitleStyle
      )?.name ?? subtitleStyleCombination[0].name;
  }

  let voiceoverOrder = undefined;
  if (
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    if (typeof project.config.voiceoverOrder === 'number') {
      voiceoverOrder = project.config.voiceoverOrder;
    } else {
      voiceoverOrder = 1;
    }
  }

  let transition = null;
  if (project.config.transition !== null) {
    transition =
      transitions.find(
        transition => transition.value === project.config.transition
      )?.value ?? transitions[0].value;
  }

  let thumbnailType, thumbnailIncludeVideo;
  if (
    project instanceof GeneralStoryProject ||
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    thumbnailType = (thumbnailTypes.find(
      ({name}) => name === project.config.thumbnailType
    )?.name ?? thumbnailTypes[0].name) as ThumbnailType;
    thumbnailIncludeVideo =
      typeof project.config.thumbnailIncludeVideo === 'boolean'
        ? project.config.thumbnailIncludeVideo
        : false;
  }

  let bgm = null;
  if (project.config.bgm !== null) {
    bgm =
      bgms.find(
        ({name, supported_plans}) =>
          name === project.config.bgm && supported_plans.includes(plan)
      )?.name ?? bgms[0].name;
  }

  let effect = null;
  if (
    typeof project.config.effect === 'string' &&
    ['ken_burns', 'camera_shake'].includes(project.config.effect)
  ) {
    effect = project.config.effect;
  } else if (project.config.effect !== null) {
    effect = {
      type: 'ken_burns',
      ratio: 0.9,
    };
  }

  return patchProject(project, {
    constraint: patchConstraint(
      project.constraint,
      getConstraintByProjectAndType(
        project.type,
        project.promptPolicy.keepUserContent === false ? 'idea' : 'content',
        plan
      )
    ),
    language: newLanguage,
    ...((project instanceof BilingualStoryProject ||
      project instanceof BilingualDialogueProject) &&
    proficiencyLevel
      ? {promptPolicy: project.promptPolicy.patch({proficiencyLevel})}
      : {}),
    ...((project instanceof BilingualStoryProject ||
      project instanceof BilingualDialogueProject) &&
    newNativeLanguage
      ? {nativeLanguage: newNativeLanguage}
      : {}),
    style: newStyle,
    config: project.config.patch({
      voiceover,
      voiceoverSpeed,
      voiceoverOrder,
      titleStyle,
      subtitleStyle,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      effect,
      voiceovers: newVoiceovers,
    }),
  } as Partial<Project<T>>);
}

export function createProjectByUserPreference<T extends ProjectType>(
  initProject: ProjectJSON<T>,
  preference: Preference<T>
): ProjectJSON<T> {
  return {
    ...initProject,
    language: preference.language ?? initProject.language,
    native_language: ['bilingual_story', 'bilingual_dialogue'].includes(
      initProject.type
    )
      ? (preference as BilingualStoryPreference | BilingualDialoguePreference)
          .nativeLanguage ||
        (initProject as ProjectJSON<'bilingual_dialogue' | 'bilingual_story'>)
          .native_language
      : undefined,
    size: preference.size || initProject.size,
    style: preference.style || initProject.style,
    ...((initProject.type === 'bilingual_dialogue' && {
      characters:
        (preference as BilingualDialoguePreference).characterOption === '2'
          ? [{name: undefined}, {name: undefined}]
          : null,
    }) ||
      {}),
    config: {
      ...initProject.config,
      voiceover:
        preference instanceof BilingualDialoguePreference
          ? undefined
          : preference.voiceover === undefined
          ? (
              initProject.config as
                | GeneralStoryProjectConfigJSON
                | BilingualStoryProjectConfigJSON
                | ShortVideoProjectConfigJSON
            ).voiceover
          : preference.voiceover,
      effect:
        preference.effect === undefined
          ? initProject.config.effect
          : preference.effect,
      title_style:
        preference.titleStyleName === undefined
          ? initProject.config.title_style
          : preference.titleStyleName,
      subtitle_style:
        preference.subtitleStyleName === undefined
          ? initProject.config.subtitle_style
          : preference.subtitleStyleName,
      transition:
        preference.transition === undefined
          ? initProject.config.transition
          : preference.transition,
      thumbnail_type: isWithThumbnailByProjectJSON(initProject)
        ? (preference as GeneralStoryPreference | BilingualStoryPreference)
            .thumbnailType ?? initProject.config.thumbnail_type
        : undefined,
      thumbnail_include_video: isWithThumbnailByProjectJSON(initProject)
        ? (preference as GeneralStoryPreference | BilingualStoryPreference)
            .thumbnailIncludeVideo ?? initProject.config.thumbnail_include_video
        : undefined,
      bgm:
        preference.bgm === undefined ? initProject.config.bgm : preference.bgm,
      voiceover_speed:
        initProject.type === 'general_story' ||
        initProject.type === 'short_video'
          ? (preference as GeneralStoryPreference).voiceoverSpeed ??
            initProject.config.voiceover_speed
          : undefined,
    },
    prompt_policy: {
      ...initProject.prompt_policy,
      tone:
        GENERAL_STORY_TYPE_LIKE.includes(initProject.type) &&
        initProject.prompt_policy.keep_user_content === false
          ? (preference as GeneralStoryPreference | ShortVideoPreference)
              .tone ??
            (
              initProject.prompt_policy as
                | GeneralStoryIdeaPromptPolicyJSON
                | ShortVideoIdeaPromptPolicyJSON
            ).tone
          : undefined,
      quick_pace:
        initProject.type === 'general_story' &&
        initProject.prompt_policy.keep_user_content === false
          ? (preference as GeneralStoryPreference).quickPace ??
            (initProject.prompt_policy as GeneralStoryIdeaPromptPolicyJSON)
              .quick_pace
          : undefined,
      paragraph_as_shots:
        initProject.prompt_policy.keep_user_content === true
          ? (!(preference instanceof BilingualDialoguePreference) &&
              preference.paragraphAsShots) ??
            initProject.prompt_policy.paragraph_as_shots
          : undefined,
      proficiency_level:
        ['bilingual_story', 'bilingual_dialogue'].includes(initProject.type) &&
        initProject.prompt_policy.keep_user_content === false
          ? (
              preference as
                | BilingualStoryPreference
                | BilingualDialoguePreference
            ).proficiencyLevel ??
            (
              initProject.prompt_policy as IdeaPromptPolicyJSON<
                'bilingual_story' | 'bilingual_dialogue'
              >
            ).proficiency_level
          : undefined,
    },
  };
}
export async function downloadStoryboard(
  storyboard: Pick<
    Storyboard<ProjectType>,
    'title' | 'description' | 'hashtags' | 'prompt'
  > & {
    scenes: ClosedScene<ProjectType>[];
  }
) {
  const {title, description, hashtags, scenes = [], prompt = ''} = storyboard;
  const zip = new JSZip();
  const fileName = cleanFilename(title!);

  const folder = zip.folder(fileName);
  if (!folder) return;

  let txtContent = `${title}\n`;

  if (description) {
    txtContent += '\n[Summary]\n';
    txtContent += `\n${description}\n`;
  }

  if (hashtags && hashtags.length > 0) {
    txtContent += '\n[Hashtags]\n';
    txtContent += `\n#${hashtags.join(' #')}\n`;
  }

  txtContent += '\n[Script]\n';

  txtContent += `${getScriptCopyContent(scenes)}`;

  if (prompt) {
    txtContent += '\n[Prompt]\n';
    txtContent += `\n${prompt}\n`;
  }
  folder.file(`${fileName}.txt`, txtContent);

  const images = scenes.map(scene =>
    frontend.staticCombiner(getSceneImage(scene))
  );

  const blobs = await Promise.all(
    images.map(async image => {
      const response = await fetch(image);
      return response.blob();
    })
  );
  blobs.forEach((blob, index) =>
    folder.file(`Scene ${index + 1}.${images[index].split('.').pop()}`, blob, {
      binary: true,
    })
  );

  zip.generateAsync({type: 'blob'}).then(content => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = `${fileName}.zip`;
    link.click();
  });
}
export function duplicateGallery(
  project: ProjectJSON<ProjectType>
): ProjectJSON<ProjectType> {
  return {
    ...project,
    id: '',
    storyboard: undefined,
    composition: undefined,
    histories: [],
  };
}
export function getTitleOrDefault(title: string, languageCode: string) {
  let defaultTitle = 'Untitled';
  switch (languageCode) {
    case 'ar-EG':
      defaultTitle = 'بدون عنوان';
      break;
    default:
      break;
  }
  return title || defaultTitle;
}
export function trimNewline(str: string, languageCode: string) {
  if (languageCode === 'zh-CN' || languageCode === 'ja-JP') {
    return str.replace(/\r?\n|\r/g, '');
  }
  return str;
}
export function thumbnailCombiner(path: string) {
  return `${process.env.REACT_APP_THUMBNAIL_URL}/${path}`;
}
export function getCachedProject(project: string | null) {
  try {
    const cache = JSON.parse(project ?? '');
    if (!isLastVersion(cache)) {
      return null;
    }
    return {...cache, id: ''};
  } catch {
    return null;
  }
}
export function makeBilingualDialogueStoryboardCustomizedCharacters(
  characters: Project<'bilingual_dialogue'>['characters']
): [{name: string}, {name: string}] | undefined {
  if (characters === null) return undefined;

  return characters && characters.some(c => !c.name)
    ? undefined
    : (characters as [{name: string}, {name: string}]);
}
export function makeStoryboard<T extends ProjectType>(
  project: Project<T>
): Storyboard<T> {
  if (project instanceof GeneralStoryProject) {
    return new GeneralStoryStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.style,
      project.promptPolicy,
      project.prompt!
    ) as GeneralStoryStoryboard as Storyboard<T>;
  } else if (project instanceof ShortVideoProject) {
    return new ShortVideoStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.style,
      project.promptPolicy,
      project.prompt!
    ) as ShortVideoStoryboard as Storyboard<T>;
  } else if (project instanceof BilingualStoryProject) {
    return new BilingualStoryStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.nativeLanguage,
      (project.vocabulary || []).filter(Boolean) as string[],
      project.style,
      project.promptPolicy,
      project.prompt!
    ) as BilingualStoryStoryboard as Storyboard<T>;
  } else if (project.type === 'bilingual_dialogue') {
    return new BilingualDialogueStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.nativeLanguage,
      (project.vocabulary || []).filter(Boolean) as string[],
      project.style,
      project.promptPolicy,
      project.prompt!,
      makeBilingualDialogueStoryboardCustomizedCharacters(project.characters)
    ) as BilingualDialogueStoryboard as Storyboard<T>;
  } else {
    throw new Error(`Unknown project type: ${project}`);
  }
}

export function getPromptPolicyByProjectAndPromptType<P extends ProjectType>(
  project: Project<P>,
  promptType: 'idea' | 'content',
  preference: Preference<P>
): PromptPolicy<P> {
  if (promptType === 'content') {
    if (project instanceof BilingualDialogueProject) {
      // 不匹配的 promptType 和 preference
      throw new Error(
        `Could not match the promptType:${project} with preference:${preference}`
      );
    }
    return new ScriptPromptPolicy(
      (
        preference as
          | GeneralStoryPreference
          | BilingualStoryPreference
          | ShortVideoPreference
      ).paragraphAsShots ?? false
    );
  } else {
    if (project instanceof GeneralStoryProject) {
      return new GeneralStoryIdeaPromptPolicy(
        (preference as GeneralStoryPreference).tone ?? 'Neutral',
        (preference as GeneralStoryPreference).quickPace ?? false
      ) as PromptPolicy<P>;
    } else if (project instanceof ShortVideoProject) {
      return new ShortVideoIdeaPromptPolicy(
        (preference as ShortVideoPreference).tone ?? 'Neutral'
      ) as PromptPolicy<P>;
    } else if (project instanceof BilingualStoryProject) {
      return new BilingualStoryIdeaPromptPolicy(
        (preference as BilingualStoryPreference).proficiencyLevel ??
          PROFICIENCY_LEVEL_OPTIONS[0].value
      ) as PromptPolicy<P>;
    } else {
      throw new Error(`Unknown project type: ${project}`);
    }
  }
}

function isWithThumbnailByProjectJSON(
  projectJSON: ProjectJSON<ProjectType>
): projectJSON is
  | GeneralStoryProjectJSON
  | BilingualStoryProjectJSON
  | BilingualDialogueProjectJSON {
  const projectType = reviewProjectType(projectJSON.type);
  return (
    projectType === 'general_story' ||
    projectType === 'bilingual_story' ||
    projectType === 'bilingual_dialogue'
  );
}

export function checkoutPromptType(
  promptPolicy: PromptPolicy<ProjectType>
): PromptType {
  return promptPolicy instanceof ScriptPromptPolicy ? 'script' : 'idea';
}

export function parsePrompt(
  promptType: PromptType,
  prompt?: string | Record<PromptType, string>
): Record<PromptType, string> {
  return (
    typeof prompt === 'string' ? {[promptType]: prompt} : prompt ?? {}
  ) as Record<PromptType, string>;
}

export function mergePrompt(
  prompt: Record<PromptType, string>,
  promptType: PromptType,
  data: Partial<{prompt: string}>
) {
  return 'prompt' in data
    ? {...prompt, [promptType]: data.prompt ?? ''}
    : prompt;
}
