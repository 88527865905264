// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Task} from '../models/Task';
import {TaskStatus, TaskType} from '../types';

class ClearedError extends Error {}

export type Callback<T extends TaskType> = (
  type: T,
  taskId: string,
  task: Task<T>
) => boolean;

export class Polling<T extends TaskType = TaskType> {
  private timeoutId: number | null = null;
  private executor: {
    resolve: (value: Task<T>) => void;
    reject: (reason?: unknown) => void;
  } | null = null;

  constructor(
    readonly type: T,
    readonly taskId: string,
    private readonly find: (type: T, taskId: string) => Promise<Task<T>>,
    private readonly callback: Callback<T>, // return true to stop polling
    private interval: number = 1000,
    private taskTimeout?: number
  ) {}

  setInterval(value: number) {
    this.interval = value;
    return this;
  }

  start(immediately = false) {
    if (this.timeoutId !== null) return;
    this.timeoutId = setTimeout(
      async () => {
        let closed = false;
        try {
          const task = await new Promise<Task<T>>((resolve, reject) => {
            this.executor = {resolve, reject};
            this._poll();
          });
          this._checkTimeout(task);
          closed = this.callback(this.type, this.taskId, task);
        } catch (e) {
          closed = e instanceof ClearedError;
        } finally {
          this.timeoutId = null;
          if (!closed) {
            this.start();
          }
        }
      },
      immediately ? 0 : this.interval
    ) as unknown as number;
  }

  clear() {
    if (this.executor !== null) {
      this.executor.reject(new ClearedError());
      this.executor = null;
    }
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  private async _poll() {
    try {
      const task = await this.find(this.type, this.taskId);
      this.executor?.resolve(task);
    } catch (e) {
      this.executor?.reject(e);
    } finally {
      this.executor = null;
    }
  }

  private _checkTimeout(task: Task<T>) {
    if (task.closed) return;
    if (
      !this.taskTimeout ||
      !task.elapsedTotalTime ||
      this.taskTimeout > task.elapsedTotalTime
    )
      return;
    task.status = TaskStatus.Timeout;
  }
}
