// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {MaskType, Popover} from './Popover';

interface Props {
  size?: number;
  borderWidth?: number;
}

export function Loading({size, borderWidth}: Props) {
  return (
    <div
      className="rotate-circle"
      style={{
        width: size + 'px',
        height: size + 'px',
        borderWidth: borderWidth + 'px',
      }}
    />
  );
}

export function PopoverLoading(props: Props) {
  return (
    <Popover
      onClose={() => {
        // No changes
      }}
      maskType={MaskType.White}
    >
      <Loading {...props} />
    </Popover>
  );
}
