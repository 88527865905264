// Copyright 2022 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export function catchSync<T>(fn: () => T, defaultValue?: T) {
  try {
    return fn();
  } catch (error) {
    return defaultValue;
  }
}

export async function catchAsync<T>(
  fn: Promise<T> | (() => Promise<T>),
  defaultValue?: T
) {
  try {
    return await (typeof fn === 'function' ? fn() : fn);
  } catch (error) {
    return defaultValue;
  }
}
