// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as ResetIcon} from 'assets/svg/outline/Refresh.svg';
import {Button} from 'pages/components/Button';
import {PropsWithChildren} from 'react';

import styles from './AssetEditor.module.scss';
import {EditAssetFooterProps} from './AssetEditor.types';

export function Footer({
  onReset,
  onApply,
  onDownload,
  showResetAll,
  children,
}: PropsWithChildren<EditAssetFooterProps>) {
  return (
    <div className={styles.footer}>
      {showResetAll && (
        <Button
          type="link"
          onClick={onReset}
          className={styles['footer-reset']}
        >
          <ResetIcon />
          Reset All
        </Button>
      )}
      {children}
      <Button
        type="ghost"
        className={styles['footer-Download']}
        onClick={onDownload}
      >
        Download
      </Button>
      <Button type="button" theme="primary" onClick={onApply}>
        Done
      </Button>
    </div>
  );
}
