// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import * as apiServerAi from 'api/server-ai';
import axios, {AxiosRequestConfig} from 'axios';
import {AssetActionType} from 'components/AssetEditor';
import {combine} from 'components/Combine';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useLazyLoad, useVisible} from 'lib/hooks';
import {cloneBlobUrl, getImageFileWidthAndHeight} from 'lib/image';
import {useCallback, useEffect, useRef, useState} from 'react';

import {AssetsLibraryPage} from './AssetsLibrary';
import {
  AssetsLibraryHookReturn,
  ErrorType,
  tempListItemType,
  UseDeleteAssetHookProps,
  UseDeleteAssetHookReturn,
  UseUploadAssetHookProps,
  UseUploadAssetHookReturn,
} from './AssetsLibrary.type';

function useHook(): AssetsLibraryHookReturn {
  const [assetEditorModalVisible, hideAssetEditorModal, showAssetEditorModal] =
    useVisible();
  const [assetImgUrl, setAssetImgUrl] = useState<string>('');
  const [actionType, setActionType] = useState<AssetActionType>('crop');
  const imageInfoRef = useRef<{
    width: number;
    height: number;
    filename: string;
    mimeType: string;
  }>({
    width: 0,
    height: 0,
    filename: '',
    mimeType: '',
  });
  const {
    assetItemList,
    refreshAssetList,
    isLoading: isAssetsFetchLoading,
    loadMoreRef,
    hasMore,
    refreshAfterDelete,
  } = useAssetsList();

  const {
    deleteConfirmVisible,
    deleteAssetById,
    hideDeleteConfirmModal,
    onConfirmDelete,
  } = useDeleteAssetHook({
    deleteCallback: refreshAfterDelete,
  });

  const {
    tempList,
    errorToastVisible,
    errorToastType,
    handleUploadTempList,
    updateBackupInfo,
    handleImageError,
    handleUpload,
    hideErrorToast,
  } = useUploadAssetHook({uploadCallback: refreshAssetList});

  const handleApply: AssetsLibraryHookReturn['handleApply'] = async (args: {
    file: File;
    blobImageUrl: string;
  }) => {
    const {url} = await cloneBlobUrl(args.blobImageUrl);
    handleUpload({...args, blobImageUrl: url}, 'system');
    hideAssetEditorModal();
  };

  const openAssetEditorModal = useCallback(
    (args: {file: File; blobImageUrl: string}) => {
      setAssetImgUrl(args.blobImageUrl);
      showAssetEditorModal();
      imageInfoRef.current.filename = args.file.name || '';
      getImageFileWidthAndHeight(args.file).then(({width, height}) => {
        imageInfoRef.current.width = width;
        imageInfoRef.current.height = height;
      });
    },
    [showAssetEditorModal]
  );

  const uploadFileAndEditAsset = useCallback(
    async (args: {file: File; blobImageUrl: string}) => {
      handleUpload(args, 'user').then(res => {
        if (res.objectKey) {
          setActionType('crop');
          openAssetEditorModal({
            file: args.file,
            blobImageUrl: res.blobImageUrl,
          });
        }
      });
    },
    [handleUpload, openAssetEditorModal]
  );

  return {
    isAssetsFetchLoading,
    loadMoreRef,
    hasMore,
    assetItemList,
    deleteConfirmVisible,
    tempList,
    errorToastVisible,
    errorToastType,
    assetEditorModalVisible,
    assetImgUrl,
    actionType,
    imageInfoRef,
    setActionType,
    setAssetImgUrl,
    hideAssetEditorModal,
    showAssetEditorModal,
    hideErrorToast,
    deleteAssetById,
    refreshAssetList,
    hideDeleteConfirmModal,
    onConfirmDelete,
    handleUploadTempList,
    updateBackupInfo,
    handleImageError,
    handleApply,
    handleUpload,
    uploadFileAndEditAsset,
    openAssetEditorModal,
  };
}

export const AssetsLibraryContainer = combine(useHook)(AssetsLibraryPage);

export function useUploadAssetHook({
  uploadCallback,
}: UseUploadAssetHookProps): UseUploadAssetHookReturn {
  const [tempList, setTempList] = useState<tempListItemType[] | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const imageBackupRef = useRef<Record<string, string>>({});
  const {showNotification} = useNotificationContext();
  const [errorToastVisible, hideErrorToast, showErrorToast, errorToastType] =
    useVisible<ErrorType>();

  const {uploadFile, isUploading, uploadProgress, assetIdRef} =
    useAwsFileUpload(
      () => {
        setUploadedImageUrl('');
        uploadCallback &&
          uploadCallback(() => {
            handleUploadTempList && handleUploadTempList([]);
          });
      },
      () => {
        setUploadedImageUrl('');
        handleUploadTempList && handleUploadTempList([]);
        showNotification({type: 'ERROR', message: 'Upload failed'});
      }
    );

  const handleUploadTempList = useCallback((tempList: tempListItemType[]) => {
    setTempList(tempList);
  }, []);
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const target = event.target as HTMLImageElement;
    const assetId = target.dataset.assetId || '';
    if (assetId && imageBackupRef.current[assetId]) {
      target.src = imageBackupRef.current[assetId];
      imageBackupRef.current[assetId] = '';
    }
  };
  const updateBackupInfo = useCallback((assetId: string, imgUrl: string) => {
    imageBackupRef.current[assetId] = imgUrl;
  }, []);

  //根据uploadProgress，更新上传进度
  useEffect(() => {
    if (isUploading) {
      uploadedImageUrl &&
        handleUploadTempList([
          {
            uploadProgress: uploadProgress,
            imgUrl: uploadedImageUrl,
          },
        ]);
    }
  }, [handleUploadTempList, isUploading, uploadProgress, uploadedImageUrl]);

  const handleUpload: UseUploadAssetHookReturn['handleUpload'] = (
    {file, blobImageUrl},
    uploader
  ) => {
    setUploadedImageUrl(blobImageUrl);
    return uploadFile({file}, 'db', undefined, undefined, {uploader: uploader})
      .then(objectKey => {
        updateBackupInfo(assetIdRef.current, blobImageUrl);
        return {objectKey: objectKey || '', blobImageUrl};
      })
      .catch(e => {
        if (
          e.data &&
          e.data.error === apiServer.ResponseCode.USER_ASSET_CAPACITY_FULL
        ) {
          showErrorToast(ErrorType.Capacity);
        } else {
          showErrorToast(ErrorType.UploadFailed);
        }
        return {objectKey: '', blobImageUrl};
      });
  };

  return {
    tempList,
    errorToastVisible,
    errorToastType,
    hideErrorToast,
    handleUploadTempList,
    updateBackupInfo,
    handleImageError,
    setUploadedImageUrl,
    handleUpload,
  };
}

export function useDeleteAssetHook({
  deleteCallback,
}: UseDeleteAssetHookProps): UseDeleteAssetHookReturn {
  const {showNotification} = useNotificationContext();

  const [deleteConfirmVisible, hideDeleteConfirmModal, showDeleteConfirmModal] =
    useVisible();
  const currentAssetIdRef = useRef<string | null>(null);
  const deleteAssetById = async (assetId: string) => {
    currentAssetIdRef.current = assetId;
    showDeleteConfirmModal();
  };

  const doDeleteAssetById = useCallback(() => {
    if (!currentAssetIdRef.current) return;
    apiServer
      .deleteAsset(currentAssetIdRef.current)
      .then(_ => {
        showNotification({
          type: 'SUCCESS',
          message: 'Delete successfully',
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        deleteCallback && deleteCallback(currentAssetIdRef.current!, 'assetId');
      })
      .catch(response => {
        if (response.data.error === apiServer.ResponseCode.ASSET_NOT_FOUND) {
          showNotification({
            type: 'ERROR',
            message: 'Delete failed, asset is not exist',
          });
        } else {
          showNotification({
            type: 'ERROR',
            message: 'Delete failed',
          });
        }
      });
  }, [deleteCallback, showNotification]);

  const onConfirmDelete = useCallback(() => {
    hideDeleteConfirmModal();
    doDeleteAssetById();
  }, [doDeleteAssetById, hideDeleteConfirmModal]);

  return {
    deleteConfirmVisible,
    deleteAssetById,
    onConfirmDelete,
    hideDeleteConfirmModal,
  };
}

export function useAssetsList() {
  const fetchItems = useCallback((page: number, pageSize: number) => {
    return apiServer.getAssetList(page, pageSize).then(res => res.data);
  }, []);
  const {
    data,
    loadMore,
    hasMore,
    isLoading,
    refresh,
    setLoadMoreTrigger,
    loadMoreRef,
    refreshAfterDelete,
  } = useLazyLoad<{
    assetId: string;
    assetName: string;
    assetThumbUrl: string;
    assetSize: number;
    updateTime: number;
  }>({
    fetchData: fetchItems,
    pageSize: 10, // 每页展示条数,
  });
  useEffect(() => {
    if (hasMore && !isLoading) {
      setLoadMoreTrigger(loadMoreRef.current);
    }
  }, [hasMore, isLoading, loadMoreRef, setLoadMoreTrigger]);
  return {
    assetItemList: data,
    refreshAssetList: refresh,
    hasMore,
    loadMore,
    isLoading,
    loadMoreRef,
    refreshAfterDelete,
  };
}

export type UploadTypeEnum = 'db' | 'cdn-st';
export type uploadFormTargetType =
  | 'remove_image_bg'
  | 'scene_image'
  | 'pose_scene_ref';

export type PoseImageType = 'image/png' | 'image/jpeg';
type constructFormDataResponseType = {
  formData: FormData;
  url: string;
  assetId: string;
  objectKey: string;
};
type constructFormDataFunctionType = (
  file: Record<string, File>,
  uploadType: UploadTypeEnum,
  target?: uploadFormTargetType,
  content_type?: Record<string, PoseImageType>,
  options?: {uploader?: 'system' | 'user'}
) => Promise<
  constructFormDataResponseType | constructFormDataResponseType[] | undefined
>;
export function constructFormDataItem(
  data: apiServerAi.GetImageListResponse,
  file: File
) {
  const formData = new FormData();
  Object.entries(data.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append('key', data.object_id);
  formData.append('Content-Type', file.type);
  formData.append('file', file);
  return {
    formData,
    url: data.url,
    assetId: data.object_id,
    objectKey: data.object_id,
  };
}
//构建formData表单对象
export const constructFormData: constructFormDataFunctionType = async (
  fileMap,
  uploadType,
  target,
  content_type,
  options
) => {
  if (uploadType === 'db') {
    const file = fileMap.file;
    if (
      file.type === '' ||
      !['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(
        file.type
      )
    ) {
      throw new Error('文件类型不支持');
    }
    const res = await apiServer.createAssest({
      fileName:
        file.name.substr(0, file.name.lastIndexOf('.')).substring(0, 90) +
        file.name.substring(file.name.lastIndexOf('.')),
      fileSizeKb: Math.ceil(file.size / 1024),
      contentType: file.type,
      transferred: options?.uploader === 'system' ? true : undefined,
    });

    const {
      data: {signedUrl: {fields = null, url = ''} = {}, assetId = ''},
    } = res;
    if (!fields) {
      throw new Error('获取上传文件签名失败');
    }
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('Content-Type', file.type);
    formData.append('file', file);
    return {formData, url, assetId: assetId, objectKey: fields['key']};
  } else {
    if (target && ['remove_image_bg', 'scene_image'].includes(target)) {
      const data = await apiServerAi.getUploadForm(fileMap.file.type, target);
      return constructFormDataItem(data, fileMap.file);
    } else if (target && ['pose_scene_ref'].includes(target)) {
      if (!content_type) {
        throw new Error('content_type不能为空');
      }
      const someImageUploadFormData = await apiServerAi.getSomeUploadForm(
        content_type,
        target
      );
      return Object.keys(someImageUploadFormData).map(key => {
        return constructFormDataItem(
          someImageUploadFormData[key],
          fileMap[key]
        );
      });
    } else {
      throw new Error('target类型不支持');
    }
  }
};
export function useAwsFileUpload(
  successfullyUploadCallback: (objectKey: string | string[]) => void,
  failUploadCallback: (objectKey: string | string[]) => void
) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const assetIdRef = useRef<string>('');

  const uploadFile = async (
    fileInfo: Record<string, File>,
    uploadType: UploadTypeEnum = 'db',
    target?: uploadFormTargetType,
    content_type?: Record<string, PoseImageType>,
    options?: {uploader?: 'system' | 'user'}
  ) => {
    if (!fileInfo) return;

    const constructFormDataRes = (await constructFormData(
      fileInfo,
      uploadType,
      target,
      content_type,
      options
    )) as constructFormDataResponseType;
    if (Array.isArray(constructFormDataRes)) {
      setUploadProgress(0);
      setIsUploading(true);
      const allPromise: Promise<string>[] = [];
      constructFormDataRes.forEach(constructFormDataResTtem => {
        allPromise.push(
          new Promise((resolve, reject) => {
            const {formData, url, objectKey} = constructFormDataResTtem;
            return axios
              .post(url, formData)
              .then(_ => {
                setUploadProgress(prev =>
                  prev + Math.ceil(100 / constructFormDataRes.length) > 99
                    ? 100
                    : prev + Math.ceil(100 / constructFormDataRes.length)
                );

                resolve(objectKey);
              })
              .catch(_ => {
                reject(objectKey);
              });
          })
        );
      });
      Promise.all(allPromise)
        .then(res => {
          successfullyUploadCallback && successfullyUploadCallback(res);
        })
        .catch(res => {
          failUploadCallback && failUploadCallback(res);
        });
    } else {
      setUploadProgress(0);
      setIsUploading(true);
      const {formData, url, assetId, objectKey} = constructFormDataRes;
      assetIdRef.current = assetId;
      const config: AxiosRequestConfig = {
        onUploadProgress: progressEvent => {
          setUploadProgress(Math.ceil((progressEvent.progress || 0) * 100));
        },
      };
      return axios
        .post(url, formData, config)
        .then(_ => {
          setUploadProgress(100);
          if (uploadType === 'db') {
            apiServer
              .updateAssestState(assetId, {status: 'confirm'})
              .then(() => {
                successfullyUploadCallback &&
                  successfullyUploadCallback(objectKey);
              });
          } else {
            successfullyUploadCallback && successfullyUploadCallback(objectKey);
          }
          return objectKey;
        })
        .catch(_ => {
          if (uploadType === 'db') {
            apiServer.updateAssestState(assetId, {status: 'failure'});
          }
          failUploadCallback && failUploadCallback(objectKey);
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  return {uploadFile, isUploading, uploadProgress, assetIdRef};
}
